import React, { Component } from 'react';
import translator from "../../../lang/translator";
import { connect } from "react-redux";
import { Switch } from "antd";
import { apiUrl } from '../../../config/config';

class MultiFactorAuth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tow_factor: this.props.user.factorAuthentication,
            errorMsg: '',
        }
        this.lang = translator(this.props.lang);
    }
    changeTowFactorCheck = (checked) => {
        this.setState({
            tow_factor: checked
        })
        this.onChange2FactorStatus(checked)
    }
    onChange2FactorStatus = (checked) => {
        const formData = new FormData();
        formData.append("factorAuthentication", checked);
        fetch(apiUrl + "api/admin/updateFactorAuthentication", {
            method: "POST",
            mode: "cors",
            headers: { Authorization: "Bearer " + this.props.auth.token },
            body: formData,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 200) {

                    let action = { type: "SET_MULTIFACTOR", tow_factor: checked };
                    this.props.dispatch(action);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {

        return (
            <div className={this.props.lang + "_language_container"}>
                <div className={this.props.lang + '_selectLanguage'}>
                    <span >{this.lang.multiFactorAuth1}</span>
                </div>
                <div className='flex_center'>
                    <div className={this.props.lang + "_react-switch-labelTxt"}>{this.lang.on}</div>
                    <Switch
                        onChange={this.changeTowFactorCheck}
                        checked={this.state.tow_factor}
                        className='tow_factor_switch'
                        handleSwitch={() => this.handleSwitch()} />
                    <div className={this.props.lang + "_react-switch-labelTxt"}>{this.lang.off}</div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        auth: state.auth,
        user: state.user
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiFactorAuth);