import React, { Component } from "react";
import { Menu, Row, Col, Dropdown } from "antd";
import logo from "../../assets/icons/logo.svg";
import setting from "../../assets/icons/setting.svg";
import blackSetting from "../../assets/icons/blackSetting.svg";
import messenger from "../../assets/icons/message.svg";
import blackMessenger from "../../assets/icons/messageBlack.svg";
import arabicLogo from "../../assets/images/arabicLogo.svg";
import translator from "../../lang/translator";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../Redux/Actions";
import menuIcon from "../../assets/icons/menuIcon.png"
import { apiUrl } from "../../config/config";
import { createBrowserHistory } from "history";
class NavBar extends Component {
  translator = null;
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      current: this.props.select,
    };
    this.lang = translator(this.props.lang);
  }

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };
  getCurrentRoute = () => {
    const history = createBrowserHistory();
    if (history.location.pathname.includes("Home")) {
      this.setState({
        current: "landlord"
      })
    } else if (history.location.pathname.includes("Transfers")) {
      this.setState({
        current: "Transfers"
      })
    }
    else if (history.location.pathname.includes("Rentals")) {
      this.setState({
        current: "Rentals"
      })
    }
    else if (history.location.pathname.includes("PendingBanks")) {
      this.setState({
        current: "PendingBanks"
      })
    }
    else if (history.location.pathname.includes("PendingProperties")) {
      this.setState({
        current: "PendingProperties"
      })
    }
    else if (history.location.pathname.includes("settings")) {
      this.setState({
        current: "settings"
      })
    }
    else {
      this.setState({
        current: "landlord"
      })
    }
  }
  getPendingNotifications = () => {
    fetch(apiUrl + 'api/admin/getPendingBankNotification', {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token,
      },
    }).then(response => response.json())
      .then(data => {
        if (data.status === 200) {
          let action = {
            type: "SET_PENDING_NOTIF",
            totalPendingBanks: data.totalNotificationPendingBanks,
            totalPendingClaims: data.totalNotificationNewClaims,
            totalPendingProperties: data.totalNotificationPendingProperties
          };
          this.props.dispatch(action);
        }
      })
  }
  componentDidMount() {
    this.getCurrentRoute()
    this.getPendingNotifications()
    window.addEventListener("resize", () => {
      this.setState(() => {
        return {
          windowWidth: window.innerWidth,
        };
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.path !== this.props.match.path) {
      this.getPendingNotifications()
    }
  }
  componentWillReceiveProps(nextProps) {
    this.lang = translator(nextProps.lang);
  }

  render() {
    const menuMobile = (
      <Menu
        onClick={this.handleClick}
        selectedKeys={[this.state.current]}
        mode="horizontal"
        className="navbar-menu"
        inlineCollapsed
      >
        <Menu.Item key="landlord">
          <p>
            <Link className="tenantive-navbar-link" to="/Home">
              {this.lang.landlord}
            </Link>
          </p>
        </Menu.Item>
        {this.props.user &&
          (this.props.user.type === "admin" ||
            this.props.user.userPermissions.includes(
              "Add transfers"
            )) && (
            <Menu.Item key="Transfers">
              <p>
                <Link className="tenantive-navbar-link" to="/Transfers">
                  {this.lang.Transfers}
                </Link>
              </p>
            </Menu.Item>
          )}
        {(this.props.user.type === "admin" ||
          this.props.user.userPermissions.includes("Add transfers")) && (
            <Menu.Item key="Rentals">
              <p>
                <Link className="tenantive-navbar-link" to="/Rentals">
                  {this.lang.Rentals}
                </Link>
              </p>
            </Menu.Item>
          )}
        {(this.props.user.type === "admin" ||
          this.props.user.userPermissions.includes(
            "Accept bank account"
          )) && (
            <Menu.Item key="PendingBanks">
              <p>
                <Link className="tenantive-navbar-link" to="/PendingBanks">
                  {this.lang.PendingBanks}
                </Link>
              </p>
              {
                this.props.auth.totalNotificationPendingBanks && this.props.auth.totalNotificationPendingBanks > 0 ?
                  <span className="notif_bank">
                    {this.props.auth.totalNotificationPendingBanks}
                  </span>
                  :
                  null
              }
            </Menu.Item>
          )}
        {(this.props.user.type === "admin" ||
          this.props.user.userPermissions.includes(
            "Accept bank account"
          )) && (
            <Menu.Item key="PendingProperties">
              <p>
                <Link className="tenantive-navbar-link" to="/PendingProperties">
                  {this.lang.PendingProperties}
                </Link>
              </p>
            </Menu.Item>
          )}
        <Menu.Item
          key="setting"
        >
          {this.props.user && this.props.user.type !== "admin" ? (
            <span
              className="sub_user_logout"
              onClick={() => {
                this.props.logoutUser()
                this.props.history.push('/login')
              }}
            >
              Log out
            </span>
          ) : (
            <Link
              className="tenantive-navbar-link"
              to={
                (this.props.lists && this.props.lists.pendingSubusers && this.props.lists.pendingSubusers.length !== 0 || this.props.lists && this.props.lists.activeSubusers && this.props.lists.activeSubusers.length !== 0)
                  ? "/settings/subUserList"
                  : "/settings/subUser"
              }
            >

              <p>
                Settings
              </p>
            </Link>
          )}
        </Menu.Item>
      </Menu>
    )
    const mobileMenuDropdown = (
      <>
        <Link className="tenantive-navbar-link" to="/dashboardHome">
          <img
            style={{ verticalAlign: "sub" }}
            src={this.props.lang === "en" ? logo : arabicLogo}
            alt=""
          />
        </Link>
        <Dropdown overlay={menuMobile} overlayClassName="dropDownMenu--container" >
          <img src={menuIcon} className={`${this.props.lang}_navBar--menuIcon ${this.props.lang}_navBar--menuIcon--margin`} />
        </Dropdown>
      </>
    )
    return (
      <div className={this.props.lang + "_containerDashboard"}>
        <Row
          style={{ paddingTop: 20 }}
          className={this.props.lang + "_containerNavBar"}
        >
          {this.state.windowWidth >= 500 ?
            <Col span={24}>
              <Menu
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}
                mode="horizontal"
                className="navbar-menu"
                overflowedIndicator={(<img src={menuIcon} className={this.props.lang + "_navBar--menuIcon"} />)}
              >
                <Menu.Item key="img">
                  <Link className="tenantive-navbar-link" to="/Home">
                    <img
                      style={{ verticalAlign: "sub" }}
                      src={this.props.lang === "en" ? logo : arabicLogo}
                      alt=""
                    />
                  </Link>
                </Menu.Item>
                <Menu.Item key="landlord">
                  <p>
                    <Link className="tenantive-navbar-link" to="/Home">
                      {this.lang.landlord}
                    </Link>
                  </p>
                </Menu.Item>
                {this.props.user &&
                  (this.props.user.type === "admin" ||
                    this.props.user.userPermissions.includes(
                      "Add transfers"
                    )) && (
                    <Menu.Item key="Transfers">
                      <p>
                        <Link className="tenantive-navbar-link" to="/Transfers">
                          {this.lang.Transfers}
                        </Link>
                      </p>
                    </Menu.Item>
                  )}
                {(this.props.user.type === "admin" ||
                  this.props.user.userPermissions.includes("Add transfers")) && (
                    <Menu.Item key="Rentals">
                      <p>
                        <Link className="tenantive-navbar-link" to="/Rentals">
                          {this.lang.Rentals}
                        </Link>
                      </p>
                    </Menu.Item>
                  )}
                {(this.props.user.type === "admin" ||
                  this.props.user.userPermissions.includes(
                    "Accept bank account"
                  )) && (
                    <Menu.Item key="PendingBanks">
                      <p>
                        <Link className="tenantive-navbar-link" to="/PendingBanks">
                          {this.lang.PendingBanks}
                        </Link>
                      </p>
                      {
                        this.props.auth.totalNotificationPendingBanks && this.props.auth.totalNotificationPendingBanks > 0 ?
                          <span className="notif_bank">
                            {this.props.auth.totalNotificationPendingBanks}
                          </span>
                          :
                          null
                      }
                    </Menu.Item>
                  )}
                {(this.props.user.type === "admin" ||
                  this.props.user.userPermissions.includes(
                    "Accept bank account"
                  )) && (
                    <Menu.Item key="PendingProperties">
                      <p>
                        <Link className="tenantive-navbar-link" to="/PendingProperties">
                          {this.lang.PendingProperties}
                        </Link>
                      </p>
                      {
                        this.props.auth.totalNotificationPendingProperties && this.props.auth.totalNotificationPendingProperties > 0 ?
                          <span className="notif_bank">
                            {this.props.auth.totalNotificationPendingProperties}
                          </span>
                          :
                          null
                      }
                    </Menu.Item>
                  )}
                {(this.props.user.type === "admin" ||
                  this.props.user.userPermissions.includes(
                    "Claims"
                  )) && (
                    <Menu.Item key="claims">
                      <p>
                        <Link className="tenantive-navbar-link" to="/claims">
                          {this.lang.claims}
                        </Link>
                      </p>
                      {
                        this.props.auth.totalNotificationClaims && this.props.auth.totalNotificationClaims > 0 ?
                          <span className="notif_bank">
                            {this.props.auth.totalNotificationClaims}
                          </span>
                          :
                          null
                      }
                    </Menu.Item>
                  )}
                {this.props.user.type !== "admin" && (
                  <Menu.Item key="multiFactorAuth">
                    <p>
                      <Link className="tenantive-navbar-link" to="/multiFactorAuth">
                        {this.lang.multiFactorAuth}
                      </Link>
                    </p>
                  </Menu.Item>
                )}
                <Menu.Item
                  key="setting"
                  className={this.props.lang + "_dashbord_msg_setting"}
                >
                  {this.props.user && this.props.user.type !== "admin" ? (
                    <span
                      className="sub_user_logout"
                      onClick={() => {
                        this.props.logoutUser()
                        this.props.history.push('/login')
                      }}
                    >
                      Log out
                    </span>
                  ) : (
                    <Link
                      className="tenantive-navbar-link"
                      to={"/settings/superAdminAccount"}
                    >

                      <img
                        src={
                          window.location.pathname
                            .toString()
                            .includes("/settings")
                            ? blackSetting
                            : setting
                        }
                        alt=""
                      />
                    </Link>
                  )}
                </Menu.Item>
                {(this.props.user.type === "admin" ||
                  this.props.user.userPermissions.includes(
                    "Messenger"
                  )) && (<Menu.Item
                    key="messenger"
                    className={this.props.lang + "_dashbord_msg_setting"}
                  >

                    <div className='message_logo_container'>
                      <span className={(this.props.messenger.tenantConversationsList && this.props.messenger.tenantConversationsList.filter(el =>
                        el.lu === false).length > 0) || (this.props.messenger.landlordConversationsList && this.props.messenger.landlordConversationsList.filter(el =>
                          el.lu === false).length > 0) ?
                        this.props.lang + '_message_logo_not' : 'none'}>
                        {this.props.messenger.tenantConversationsList && this.props.messenger.landlordConversationsList &&
                          this.props.messenger.tenantConversationsList.filter(el =>
                            el.lu === false).length + this.props.messenger.landlordConversationsList.filter(el =>
                              el.lu === false).length}
                      </span>
                      <Link
                        className="tenantive-navbar-link"
                        to={"/Messenger"}
                      >
                        <img
                          src={
                            window.location.pathname
                              .toString()
                              .includes("/Messenger")
                              ? blackMessenger
                              : messenger
                          }
                          alt=""
                        />
                      </Link>
                    </div>

                  </Menu.Item>)}
              </Menu>
            </Col> :
            <Col span={24} className="mobileMenuDropdownContainer">
              {mobileMenuDropdown}
            </Col>
          }
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
    auth: state.auth,
    user: state.user,
    lists: state.lists,
    messenger: state.messenger
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
    logoutUser: () => dispatch(logoutUser()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter((NavBar)))
