import React, { Component } from "react";
import { Select, DatePicker, Input, Col, Row } from "antd";
import translator from "../../lang/translator";
import FilterDate from "../../assets/images/icon/Filters_Date.png";
import FiltersSearch from "../../assets/images/icon/Filters - search.png";
import FilterDateAr from "../../assets/images/icon/FilterDateAr.png";
import FilterSearchAr from "../../assets/images/icon/FilterSearchAr.png";
import FIltersDropdown from "../../assets/images/icon/FIlters - dropdown.png";
import { connect } from "react-redux";
const { Option } = Select;
const { RangePicker } = DatePicker;

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.lang = translator(this.props.lang);
  }
  changeRangeRentalDate = (rangeDate) => {
    if (rangeDate) {
      this.props.setRentalsDate({
        startDate: rangeDate[0],
        endDate: rangeDate[1],
      })
    } else {
      this.props.setRentalsDate({
        startDate: '',
        endDate: '',
      })

    }
  }
  render() {
    return (
      <div className={this.props.lang + "_Rental-filter1"}>
        <Row className={this.props.lang + "_rental-input-con"} >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 14 }}
            xl={{ span: 16 }}
            className={this.props.lang + "_rental-input-col"}
          >
            <Row
              gutter={{
                xs: 22,
                sm: 22,
                md: 24,
                lg: this.props.lang === "ar" ? 24 : 20,
                xl: this.props.lang === "ar" ? 24 : 20,
              }}
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 10 }}
                lg={{ span: 10 }}
                xl={{
                  span: this.props.lang === "ar" ? 4 : 7,
                  order: 1
                }}
              >
                <div>
                  {this.props.selectedTab === "Rentals" || this.props.selectedTab === "Deposit" ?
                    <div className={this.props.lang + "_input-filter"}>
                      <Input
                        allowClear={true}
                        placeholder={this.lang.ARV_Search}
                        className={this.props.lang + "_ind"}
                        onKeyPress={(e) =>
                          e.key === "Enter" &&
                          this.props.updatedRentals(this.props.id, 1, 10)
                        }
                        onChange={(e) => this.props.setRentalsSearch(e.target.value)}
                      ></Input>
                      <img
                        src={
                          this.props.lang === "ar"
                            ? FilterSearchAr
                            : FiltersSearch
                        }
                        onClick={() => this.props.updatedRentals(this.props.id, 1, 10)}
                        className={this.props.lang + "_icon-input"}
                      />
                    </div>
                    : this.props.selectedTab === "Invoices" ?
                      <div className={this.props.lang + "_input-filter"}>
                        <Input
                          allowClear={true}
                          placeholder={this.lang.ARV_Search}
                          className={this.props.lang + "_ind"}
                          onKeyPress={(e) =>
                            e.key === "Enter" &&
                            this.props.updatedInvoices(this.props.id, 1, 10)
                          }
                          onChange={(e) => this.props.setInvoicesSearch(e.target.value)}
                        ></Input>
                        <img
                          src={
                            this.props.lang === "ar"
                              ? FilterSearchAr
                              : FiltersSearch
                          }
                          onClick={() => this.props.updatedInvoices(this.props.id, 1, 10)}
                          className={this.props.lang + "_icon-input"}
                        />
                      </div>
                      :
                      <div className={this.props.lang + "_input-filter"}>
                        <Input
                          allowClear={true}
                          placeholder={this.lang.ARV_Search}
                          className={this.props.lang + "_ind"}
                          onKeyPress={(e) =>
                            e.key === "Enter" &&
                              this.props.selectedTab === "Rentals"
                              ? this.props.updatedRentalsProp()
                              : console.log()
                          }
                          onChange={(e) => this.props.setPropertySearch(e.target.value)}
                        ></Input>
                        <img
                          src={
                            this.props.lang === "ar"
                              ? FilterSearchAr
                              : FiltersSearch
                          }
                          onClick={this.props.updatedRentalsProp}
                          className={this.props.lang + "_icon-input"}
                        />
                      </div>}
                </div>
              </Col>
              {this.props.selectedTab === "Rentals" || this.props.selectedTab === "Deposit" ?
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 10, order: 2 }}
                  md={{ span: 8, order: 2 }}
                  lg={{
                    span: 7,
                    order: 2,
                  }}
                  xl={{
                    span: this.props.lang === "ar" ? 4 : 7,
                    order: 2,
                  }}
                  className={this.props.lang + "_input-filter"}
                >
                  <RangePicker
                    style={{ width: '100%' }}
                    className={this.props.lang + "_transfers-date-picker"}
                    allowEmpty={true}
                    allowClear={true}
                    format="YYYY-MM-DD"
                    suffixIcon={false}
                    separator=""
                    placeholder={[
                      this.props.selectedTab === "Properties"
                        ? this.lang.ContractPeriode
                        : this.lang.Date,
                    ]}
                    onChange={this.changeRangeRentalDate}
                  />

                  <img
                    src={this.props.lang === "ar" ? FilterDateAr : FilterDate}
                    className={this.props.lang + "_img-date-ico"}
                    onClick={() => this.props.updatedRentals(this.props.id, 1, 10)}
                  />
                </Col>
                : this.props.selectedTab === "Invoices" ?
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 10, order: 2 }}
                    md={{ span: 8, order: 2 }}
                    lg={{
                      span: 7,
                      order: 2,
                    }}
                    xl={{
                      span: this.props.lang === "ar" ? 4 : 7,
                      order: 2,
                    }}
                    className={this.props.lang + "_input-filter"}
                  >
                    <RangePicker
                      style={{ width: '100%' }}
                      className={this.props.lang + "_transfers-date-picker"}
                      allowEmpty={true}
                      allowClear={true}
                      separator=""
                      format="YYYY-MM-DD"
                      suffixIcon={false}
                      placeholder={[
                        this.props.selectedTab === "Properties"
                          ? this.lang.ContractPeriode
                          : this.lang.Date,
                      ]}
                      onChange={(rangeDate) =>
                        this.props.setInvoicesSearch({
                          startDate: rangeDate[0],
                          endDate: rangeDate[1],
                        })
                      }
                    />

                    <img
                      src={this.props.lang === "ar" ? FilterDateAr : FilterDate}
                      className={this.props.lang + "_img-date-ico"}
                      onClick={() => this.props.updatedInvoices(this.props.id, 1, 10)}
                    />
                  </Col>
                  :



                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 6, order: 2 }}
                    md={{ span: 8, order: 2 }}
                    lg={{
                      span: 7,
                      order: 2,
                    }}
                    xl={{
                      span: this.props.lang === "ar" ? 4 : 7,
                      order: 2,
                    }}
                  >
                    <div className={this.props.lang + "_input-filter"}>
                      <RangePicker
                        className={this.props.lang + "_ind2"}
                        allowEmpty={true}
                        separator=""
                        allowClear={true}
                        placeholder={[
                          this.props.selectedTab === "Properties"
                            ? this.lang.ContractPeriode
                            : this.lang.Date,
                        ]}
                        onChange={(rangeDate) =>
                          this.props.setPropertyDate({
                            startDate: rangeDate[0],
                            endDate: rangeDate[1],
                          })
                        }
                        format="YYYY-MM-DD"
                        suffixIcon={false}
                      />

                      <img
                        src={this.props.lang === "ar" ? FilterDateAr : FilterDate}
                        className={this.props.lang + "_img-date-ico"}
                        onClick={this.FilterWdate}
                      />
                    </div>
                  </Col>}
              {
                this.props.selectedTab === "Properties" && (
                  <Col
                    xs={{ span: 12 }}
                    sm={{ span: 10, order: 3 }}
                    md={{ span: 6, order: 3 }}
                    lg={{
                      span: 7,
                      order: 3,
                    }}
                    xl={{
                      span: this.props.lang === "ar" ? 4 : 6,
                      order: 3,
                    }}
                    className={this.props.lang + "_col-inputrender2"}
                  >
                    <div>
                      <div className={this.props.lang + "_input-filter"}>
                        <Select
                          dropdownStyle={{ borderRadius: "5px" }}
                          showArrow={false}
                          placeholder={this.lang.ARV_Status}
                          onChange={
                            (value) => this.props.setPropertyStatus(value)
                          }
                          className={this.props.lang + "_ind3"}
                        >
                          <Option
                            value={

                              this.lang.ContractStatus
                            }
                          >
                            {
                              this.lang.ContractStatus}
                          </Option>

                          <Option
                            value={
                              this.lang.ContractStatus2
                            }
                          >
                            {this.lang.ContractStatus2}
                          </Option>
                        </Select>
                        <img
                          src={FIltersDropdown}
                          className={this.props.lang + "_acount-rentl-img-select"}
                        />{" "}
                      </div>
                    </div>
                  </Col>
                )}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
    selectedTab: state.selectedTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPropertySearch: (e) =>
      dispatch({ type: "SET_PROPERTY_SEARCH", payload: e }),
    setRentalsSearch: (e) =>
      dispatch({ type: "SET_RENTALAS_SEARCH", payload: e }),
    setInvoicesSearch: (e) =>
      dispatch({ type: "SET_INVOICES_SEARCH", payload: e }),
    setConnectedBankSearch: (e) =>
      dispatch({ type: "SET_CONNECTEDBANK_SEARCH", payload: e }),
    setPropertyDate: (e) => dispatch({ type: "SET_PROPERTY_DATE", payload: e }),
    setInvoicesyDate: (e) => dispatch({ type: "SET_INVOICES_DATE", payload: e }),
    setRentalsDate: (e) => dispatch({ type: "SET_RENTALS_DATE", payload: e }),
    setPropertyStatus: (e) =>
      dispatch({ type: "SET_PROPERTY_STATUS", payload: e }),
    setConnectedBankStatus: (e) =>
      dispatch({ type: "SET_CONNECTED_BANK_STATUS", payload: e }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
