import React, { Component, Fragment } from 'react'
import { Col, Row, Input, Pagination, DatePicker, Select, Button, Modal } from "antd";
import FilterDate from "../../assets/images/icon/Filters_Date.png";
import FiltersSearch from "../../assets/images/icon/Filters - search.png";
import FilterDateAr from "../../assets/images/icon/FilterDateAr.png";
import FilterSearchAr from "../../assets/images/icon/FilterSearchAr.png";
import FIltersDropdown from "../../assets/images/icon/FIlters - dropdown.png";
import translator from '../../lang/translator';
import { connect } from 'react-redux';
import LoaderTenantive from "../../UIKIT/LoaderTenantive"
import Empty from "antd/es/empty";
import Arrow from "../../assets/icons/arrowdown.svg";
import ViewClaim from "./ViewClaim";
import moment from 'moment'
import { apiUrl } from '../../config/config';
import { downloadFile } from '../../Api';
const { RangePicker } = DatePicker;
const { Option } = Select;
var PAGES = 10;

class Claims extends Component {
    constructor(props) {
        super(props);
        this.state = {
            claimsData: [],
            LoadingTableData: false,
            activekey: [],
            nbrElements: 0,
            currentPage: 1,
            search: '',
            type: '',
            profile: '',
            startDate: '',
            endDate: '',
            warningModalVisible: false,
            selectedClaim: {},
            windowWidth: window.innerWidth,
        };
        this.lang = translator(this.props.lang);
    }
    handleCancel = () => {
        this.setState({
            warningModalVisible: false
        })
    }
    showWarningModal = (el) => {
        this.setState({
            warningModalVisible: true,
            selectedClaim: el
        })
    }
    onShow = (i) => {
        !this.state.activekey.includes(i) &&
            this.setState({
                activekey: [i],
            });
    };
    hide = (i) => {
        this.state.activekey.includes(i) &&
            this.setState({
                activekey: this.state.activekey.filter((el) => el !== i),
            });
    };
    GetClaimsList(page) {
        this.setState({
            LoadingTableData: true
        })
        fetch(apiUrl + "api/admin/getClaimsList?text=" + this.state.search + "&type=" + this.state.type +
            "&profile=" + this.state.profile + "&startDate=" + this.state.startDate + "&endDate=" + this.state.endDate + "&page=" + page + "&per_page=10", {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.auth.token,
            },
        })
            .then((response) => {
                response.json().then((data) => {
                    let action = {
                        type: "SET_PENDING_NOTIF",
                        totalPendingBanks: this.props.auth.totalNotificationPendingBanks,
                        totalPendingClaims: 0,
                        totalPendingProperties: this.props.auth.totalNotificationPendingProperties
                    };
                    this.props.dispatch(action);
                    if (data.status === 200) {
                        this.setState({
                            claimsData: data.claims,
                            nbrElements: data.nbrElements,
                            LoadingTableData: false
                        })
                    }
                });
            })
            .catch((err) => {
                console.log("error ", err);
            });
    }

    renderDcoumentName(document) {
        if (this.state.windowWidth < 1600) {
            return document.name.length > 7 ? document.name.substr(0, 3) + "..." + document.name.substr(document.name.length - 3) : document.name
        } else {
            return document.name.length > 15 ? document.name.substr(0, 10) + "..." + document.name.substr(document.name.length - 3) : document.name
        }
    }
    componentDidMount() {
        this.GetClaimsList(1)
        window.addEventListener("resize", () => {
            this.setState(() => {
                return {
                    windowWidth: window.innerWidth,
                };
            });
        });
    }
    pagination = (page, numberpage) => {
        this.GetClaimsList(page)
        this.setState({
            currentPage: page,
            activekey: []
        })
    }
    showFile = (path) => {
        let newWindow = window.open()
        downloadFile({ path: path, type: "pdf", window: newWindow })
    }
    downloadFile = (path) => {
        downloadFile({ path })
    }
    acceptClaim = (el) => {
        fetch(apiUrl + "api/admin/acceptClaim/" + el._id, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.auth.token,
            },
        })
            .then((response) => {
                response.json().then((data) => {
                    if (data.status === 200) {
                        this.GetClaimsList(this.state.currentPage)
                    }
                });
            })
            .catch((err) => {
                console.log("error ", err);
            });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.search !== this.state.search || prevState.type !== this.state.type || prevState.profile !== this.state.profile
            || prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) {
            this.GetClaimsList(1)
        }
    }
    closeClaim = (el) => {
        fetch(apiUrl + "api/admin/closeClaim/" + this.state.selectedClaim._id, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.auth.token,
            },
        })
            .then((response) => {
                response.json().then((data) => {
                    if (data.status === 200) {
                        this.GetClaimsList(this.state.currentPage)
                        this.setState({
                            selectedClaim: {},
                            warningModalVisible: false
                        })
                    }
                });
            })
            .catch((err) => {
                console.log("error ", err);
            });
    }
    onSelectTypeChange = (e) => {
        this.setState({
            type: e === this.lang.claimsGeneral.toLowerCase() ? "general" : e === this.lang.claimsPayment.toLowerCase() ? "payment" : ''
        })
    }
    onSelectProfileChange = (e) => {
        this.setState({
            profile: e
        })
    }
    FilterDate = (range) => {
        if (range) {
            this.setState({
                startDate: moment(range[0]).format("YYYY-MM-DD"),
                endDate: moment(range[1]).format("YYYY-MM-DD")
            })
        } else {
            this.setState({
                startDate: '',
                endDate: ''
            })
        }
    }
    render() {
        return (
            <div className={this.props.lang + "_accounting_transfer_bigContainer"}>
                <Row>
                    <Col className={this.props.lang + "_claims_search"}>
                        <Input
                            allowClear={true}
                            placeholder={this.lang.ARV_Search}
                            className={this.props.lang + "_ind"}
                            onKeyPress={(e) =>
                                e.key === "Enter" &&
                                this.setState({ search: e.target.value })
                            }
                            onChange={(e) => { this.setState({ search: e.target.value }) }}
                        ></Input>
                        <img
                            src={
                                this.props.lang === "ar"
                                    ? FilterSearchAr
                                    : FiltersSearch
                            }
                            onClick={(e) => { this.setState({ search: e.target.value }) }}
                            className={this.props.lang + "_icon-input"}
                        />
                    </Col>
                    <Col className={this.props.lang + "_claims_date"}>
                        <RangePicker
                            style={{ width: '100%' }}
                            className={this.props.lang + "_transfers-date-picker"}
                            allowEmpty={true, true}
                            allowClear={true, true}
                            separator=""
                            format="YYYY-MM-DD"
                            suffixIcon={false}
                            className={this.props.lang + "_ind2"}
                            separator=""
                            placeholder={[
                                this.lang.Date,
                            ]}
                            onChange={(value) => this.FilterDate(value)}
                        />

                        <img
                            src={this.props.lang === "ar" ? FilterDateAr : FilterDate}
                            className={this.props.lang + "_img-date-ico"}
                            onClick={() => console.log()}
                        />
                    </Col>
                    <Col className={this.props.lang + "_claims_select"}>
                        <Select
                            showArrow={false}
                            placeholder={this.lang.claimsSelectType}
                            onChange={this.onSelectTypeChange}
                            className={this.props.lang + "_ind3"}
                        >
                            <Option value=''>
                                {this.lang.all}
                            </Option>
                            <Option value={this.lang.claimsGeneral.toLowerCase()}>
                                {this.lang.claimsGeneral}
                            </Option>
                            <Option value={this.lang.claimsPayment.toLowerCase()}>
                                {this.lang.claimsPayment}
                            </Option>
                        </Select>
                        <img
                            src={FIltersDropdown}
                            className={this.props.lang + "_acount-rentl-img-select"}
                        />
                    </Col>
                    <Col className={this.props.lang + "_claims_select"}>
                        <Select
                            showArrow={false}
                            placeholder={this.lang.claimsSelectProfile}
                            onChange={this.onSelectProfileChange}
                            className={this.props.lang + "_ind3"}
                        >
                            <Option value=''>
                                {this.lang.all}
                            </Option>
                            <Option value="landlords">
                                {this.lang.landlord}
                            </Option>
                            <Option value="tenants">
                                {this.lang.Tenant}
                            </Option>
                        </Select>
                        <img
                            src={FIltersDropdown}
                            className={this.props.lang + "_acount-rentl-img-select"}
                        />
                    </Col>
                </Row>
                {/* **************************************************************************************************************************************** */}
                <div className={this.props.lang + "_pending_banks_bigContainer"}>
                    <Row className={this.props.lang + "_claims_list"}>
                        <Col
                            span={5}
                            xs={{ span: 5 }}
                            sm={{ span: 5 }}
                            md={{ span: 5 }}>
                            {this.lang.claimsList}
                        </Col>
                        <Col
                            span={4}
                            xs={{ span: 4 }}
                            sm={{ span: 4 }}
                            md={{ span: 4 }}>
                            {this.lang.User}
                        </Col>
                        <Col
                            span={3}
                            xs={{ span: 3 }}
                            sm={{ span: 3 }}
                            md={{ span: 3 }}>
                            {this.lang.propertyAndUnit}
                        </Col>
                        <Col
                            span={3}
                            xs={{ span: 3 }}
                            sm={{ span: 3 }}
                            md={{ span: 3 }}>
                            {this.lang.dateandtime}
                        </Col>
                        <Col
                            span={6}
                            xs={{ span: 6 }}
                            sm={{ span: 6 }}
                            md={{ span: 6 }}>
                            {this.lang.BanklistAction}
                        </Col>
                        <Col
                            span={3}
                            xs={{ span: 3 }}
                            sm={{ span: 3 }}
                            md={{ span: 3 }}>
                            {this.lang.status}
                        </Col>
                    </Row>
                    {
                        this.state.LoadingTableData ?
                            <LoaderTenantive />
                            :
                            this.state.claimsData && this.state.claimsData.length == 0 ?
                                <div className="noData_Container_">
                                    <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    <p className='noData'>
                                        No Data !
                                    </p>
                                </div>
                                :
                                this.state.claimsData && this.state.claimsData.map((el, i) => {
                                    return (
                                        <Fragment>
                                            <Row className={this.props.lang + "_claims_list_row"}>
                                                <Col
                                                    id='start'
                                                    span={1}
                                                    offset={1}
                                                >
                                                    <div className="downProp">
                                                        {
                                                            el.documents && el.documents.length > 0 ?
                                                                <div className="arrow-down"
                                                                    onClick={
                                                                        !this.state.activekey.includes(i)
                                                                            ? () => this.onShow(i)
                                                                            : () => this.hide(i)
                                                                    }
                                                                >
                                                                    <img className={!this.state.activekey.includes(i) ? 'arroww' : "arrowUP"}

                                                                        src={Arrow}
                                                                        alt="arrow"

                                                                    />
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </Col>
                                                <Col
                                                    span={4}
                                                    xs={{ span: 4 }}
                                                    sm={{ span: 4 }}
                                                    md={{ span: 4 }}
                                                    className={this.props.lang + "_claims_list_column"}>
                                                    <span className={this.props.lang + "_claims_list_type " + this.props.lang + "_claims_list_type_position"}>
                                                        {el.type === "general" ? this.lang.general : this.lang.Payment}
                                                    </span>
                                                    <span className={this.props.lang + "_claims_list_type_description " + this.props.lang + "_claims_list_type_position"}>
                                                        {
                                                            el.complaint && el.complaint.length > 40 ?
                                                                el.complaint.slice(0, 37) + "..."
                                                                :
                                                                el.complaint
                                                        }
                                                    </span>
                                                </Col>
                                                <Col
                                                    span={4}
                                                    xs={{ span: 3 }}
                                                    sm={{ span: 3 }}
                                                    md={{ span: 3 }}
                                                    className={this.props.lang + "_claims_list_column"}>
                                                    <span className={this.props.lang + "_claims_list_type"}>{el.user.full_name}</span>
                                                    <span className={this.props.lang + "_claims_user_type"}>
                                                        {el.user.type === "Landlords" ?
                                                            this.lang.landlord
                                                            :
                                                            this.lang.Tenant
                                                        }
                                                    </span>
                                                </Col>
                                                <Col
                                                    span={3}
                                                    xs={{ span: 3 }}
                                                    sm={{ span: 3 }}
                                                    md={{ span: 3 }}
                                                    className={this.props.lang + "_claims_list_column"}>
                                                    {
                                                        el.property ?
                                                            <>
                                                                <span className={this.props.lang + "_claims_list_type"}>{el.property.name}</span>
                                                                <span className={this.props.lang + "_claims_user_type"}>{el.unit?.unit_number}</span>
                                                            </>
                                                            :
                                                            null
                                                    }
                                                </Col>
                                                <Col
                                                    span={3}
                                                    xs={{ span: 3 }}
                                                    sm={{ span: 3 }}
                                                    md={{ span: 3 }}
                                                    className={this.props.lang + "_claims_list_column"}>
                                                    <span className={this.props.lang + "_claims_list_type"}>{moment(el.createdAt).format("DD MMM YYYY")}</span>
                                                    <span className={this.props.lang + "_claims_user_type"}>{moment(el.createdAt).format("hh : mm A")}</span>
                                                </Col>
                                                <Col
                                                    span={6}
                                                    xs={{ span: 6 }}
                                                    sm={{ span: 6 }}
                                                    md={{ span: 6 }}>
                                                    <div className={`${this.props.lang}_tableButton table_header_items_name`} >
                                                        <ViewClaim viewFile={(path) => this.showFile(path)} type={el.type} userType={el.user.type} id={el._id} />
                                                        {
                                                            el.status === "resolved" ?
                                                                <Button
                                                                    className={this.props.lang + "_accept_claim_btn"}
                                                                    disabled={this.props.user.view_only ? true : false}
                                                                >
                                                                    {this.lang.closeClame}
                                                                </Button>
                                                                :
                                                                el.status === "in progress" ?
                                                                    <Button
                                                                        onClick={() => this.showWarningModal(el)}
                                                                        className={this.props.lang + "_accept_claim_btn"}
                                                                        disabled={this.props.user.view_only ? true : false}
                                                                    >
                                                                        {this.lang.closeClame}
                                                                    </Button>
                                                                    :
                                                                    <Button
                                                                        onClick={() => this.acceptClaim(el)}
                                                                        className={this.props.lang + "_accept_claim_btn"}
                                                                        disabled={this.props.user.view_only ? true : false}
                                                                    >
                                                                        {this.lang.acceptClaim}
                                                                    </Button>
                                                        }
                                                    </div>
                                                </Col>
                                                <Col
                                                    span={3}
                                                    xs={{ span: 3 }}
                                                    sm={{ span: 3 }}
                                                    md={{ span: 3 }}>
                                                    {
                                                        el.status === "in progress" ?
                                                            <div className={this.props.lang + "_claims_pending"}>
                                                                {this.lang.inProgress}
                                                            </div>
                                                            :
                                                            el.status === "resolved" ?
                                                                <div className={this.props.lang + "_claims_resolved"}>
                                                                    {this.lang.resolved}
                                                                </div>
                                                                :
                                                                null
                                                    }
                                                </Col>
                                                <Col
                                                    offset={1}
                                                    span={5}
                                                    className={
                                                        this.state.activekey.includes(i)
                                                            ? this.props.lang + "_display_content"
                                                            : this.props.lang + "_hide_content"
                                                    }
                                                >
                                                    {
                                                        el.documents && el.documents.map(doc => {
                                                            return (
                                                                <div className="all_files full_width">
                                                                    <span className="file_name">
                                                                        {this.renderDcoumentName(doc)}
                                                                    </span>
                                                                    <span
                                                                        className={this.props.lang + "_BankdocsColumn"}
                                                                        onClick={() => this.downloadFile(doc.path)}
                                                                    >
                                                                        {this.lang.Download}
                                                                    </span>
                                                                    <div
                                                                        onClick={() => this.showFile(doc.path)}
                                                                        className={this.props.lang + "_btn-action-View"}
                                                                    >
                                                                        {this.lang.View}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    )
                                })}
                    {
                        this.state.claimsData && this.state.claimsData.length !== 0 ?
                            <Pagination
                                current={this.state.currentPage}
                                defaultCurrent={1}
                                className="test"
                                pageSize={PAGES}
                                total={this.state.nbrElements}
                                showSizeChanger={false}
                                responsive={true}
                                onChange={this.pagination} /> : null
                    }
                </div>
                <Modal className={this.props.lang + "_modal_warning_close"}
                    centered
                    footer={null}
                    closable={false}
                    width="347px"
                    visible={this.state.warningModalVisible} >
                    <p>{this.lang.closeClaimWarning}</p>
                    <div className={this.props.lang + "_btnEndContP"}>
                        <div
                            className={this.props.lang + "_btnendModulCi2"}
                            onClick={() => this.closeClaim()}
                        >
                            {this.lang.closeClame}
                            
                        </div>
                        <div
                            className={this.props.lang + "_btnCancelContract"}
                            onClick={this.handleCancel}
                        >
                            {this.lang.CancelApr}
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        auth: state.auth,
        user: state.user,
    };
};
export default connect(mapStateToProps, null)(Claims);