import React, { Component } from "react";
import translator from "../../../lang/translator";
import { connect } from "react-redux";
import logo from "../../../assets/icons/logo.svg";
import arabicLogo from "../../../assets/images/arabicLogo.svg";
import UIKIT_INPUT_PASSWORD from "../../../UIKIT/InputPassword";
import { Form, Input, Button, Popover } from "antd";
import { registerUser } from "../../../Redux/Actions";
import publicIp from "public-ip";

class SubUserRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      errors: null,
      user: {},
      validatePasswordStatus: "",
      CPUpper_successful: "_CPUpper_successful_false",
      CPLower_successful: "_CPLower_successful_false",
      CPNumber_successful: "_CPNumber_successful_false",
      CPMinCar_successful: "_CPMinCar_successful_false",
      CPSpecialChar_successful: "_CPSpecialChar_successful_false",
      errorName: "",
    };
    this.lang = translator(this.props.lang);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.token) {
      this.props.history.push("/");
    }

    if (nextProps.errors) {
      this.setState({
        errors: "Login Error",
      });
    }
  }
  handleSubmit = async () => {
    let ip = await publicIp.v4()
    const formData = new FormData();
    formData.append("phone_number", this.props.auth.phone_number);
    formData.append("user_name", this.state.userName);
    formData.append("password", this.state.password);
    const formDataLogin = new FormData();
    formDataLogin.append("phone_number", this.props.auth.phone_number);
    formDataLogin.append("ip", ip);
    formDataLogin.append("password", this.state.password);
    this.state.validatePasswordStatus === "success" ?
      this.props.registerUser(formData, formDataLogin) : this.setState({
        errorName: "Invalid password"
      })
  };
  handelChangePassword(event) {
    let password = event.target.value;
    this.Password_requirements_True(password);
    if (
      this.hasUperCase(password) &&
      this.hasSpecialCaracter(password) &&
      this.hasNumber(password) &&
      this.hasLowerCase(password) &&
      password.length >= 8
    ) {
      this.setState({ validatePasswordStatus: "success" });
    } else {
      this.setState({ validatePasswordStatus: "error" });
    }
  }
  hasUperCase(str) {
    return /[A-Z]/.test(str);
  }
  hasLowerCase(str) {
    return /[a-z]/.test(str);
  }
  hasSpecialCaracter(str) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(str);
  }
  hasNumber(str) {
    return /[0-9]/.test(str);
  }
  Password_requirements_True(str) {
    if (this.hasUperCase(str)) {
      this.setState({ CPUpper_successful: "_CPUpper_successful_true" });
    } else {
      this.setState({ CPUpper_successful: "_CPUpper_successful_false" });
    }
    if (this.hasLowerCase(str)) {
      this.setState({ CPLower_successful: "_CPLower_successful_true" });
    } else {
      this.setState({ CPLower_successful: "_CPLower_successful_false" });
    }
    if (this.hasNumber(str)) {
      this.setState({ CPNumber_successful: "_CPNumber_successful_true" });
    } else {
      this.setState({ CPNumber_successful: "_CPNumber_successful_false" });
    }
    if (str.length >= 8) {
      this.setState({ CPMinCar_successful: "_CPMinCar_successful_true" });
    } else {
      this.setState({ CPMinCar_successful: "_CPMinCar_successful_false" });
    }
    if (this.hasSpecialCaracter(str)) {
      this.setState({
        CPSpecialChar_successful: "_CPSpecialChar_successful_true",
      });
    } else {
      this.setState({
        CPSpecialChar_successful: "_CPSpecialChar_successful_false",
      });
    }
  }

  render() {
    const Passwordcontent = (
      <div style={{ fontSize: 12 }}>
        <h4 style={{ color: "black" }}>
          {this.lang.forgotPasswordPasswordGuidance}{" "}
        </h4>
        {/*Password requirements must turn green when the user meets the specific requirement and they must be written as followed */}
        <p className={this.props.lang + this.state.CPUpper_successful}>
          {this.lang.forgotPasswordUperCase}
        </p>
        <p className={this.props.lang + this.state.CPLower_successful}>
          {this.lang.forgotPasswordLowerCaracter}
        </p>
        <p className={this.props.lang + this.state.CPNumber_successful}>
          {this.lang.forgotPasswordNumbers}
        </p>
        <p className={this.props.lang + this.state.CPSpecialChar_successful}>
          {this.lang.forgotPasswordSpeicalChar}
        </p>
        <p className={this.props.lang + this.state.CPMinCar_successful}>
          {this.lang.forgotPasswordMinCaracter}
        </p>

      </div>
    );
    return (
      <div>
        <img
          className={this.props.lang + "_loginLogo"}
          src={this.props.lang === "en" ? logo : arabicLogo}
          alt=""
        />
        <div className={this.props.lang + "_login-Container"}>
          <div className={this.props.lang + "_item_subuser"}>
            <h1 style={{ textAlign: "center" }}>{this.lang.Please_create_username_and_password}</h1>
            <Form className={this.props.lang + "_login-form"}>
              <label className={this.props.lang + "_labelForm"}>
                {this.lang.username}
              </label>
              <Form.Item>
                <Input
                  label={false}
                  value={this.state.phoneNumber}
                  placeholder={this.lang.enterUsername}
                  onChange={(event) => {
                    this.setState({ userName: event.target.value });
                  }}
                />
              </Form.Item>
              <label className={this.props.lang + "_labelForm"}>
                {this.lang.password}
              </label>
              <Popover
                placement="topRight"
                content={Passwordcontent}
                trigger={("click", "focus")}
              >
                <Form.Item>
                  <UIKIT_INPUT_PASSWORD
                    style={{ height: "38px", borderRadius: "5px" }}
                    label={this.lang.PasswordLogin}
                    placeholder={this.lang.enterPassword}
                    onChange={(event) => {
                      this.handelChangePassword(event);
                      this.setState({ password: event.target.value });
                    }}
                  />
                </Form.Item>
              </Popover>
              <Button
                onClick={() => this.handleSubmit()}
                type="primary"
                htmlType="submit"
                className="loginFormButton"
              >
                <span style={{ fontSize: 16, fontWeight: 500 }}>
                  {this.lang.forgotPasswordConfirm}
                </span>
              </Button>
              {this.state.errorName && <p
                style={{
                  color: "#CB3D3D",
                  fontFamily: "SF-Pro-Display-Medium",
                  fontSize: "14px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {this.state.errorName}
              </p>}
            </Form>

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
    errors: state.errors,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (user, history) => dispatch(registerUser(user, history)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubUserRegister);
