import React, { Component } from "react";
import { Col, Row, Avatar, Upload, Divider } from "antd";
import persone from "../../../assets/images/avatarImage.svg";
import translator from "../../../lang/translator";
import { connect } from "react-redux";
import Input from "../../../UIKIT/InputText";
import { beforeUpload, getBase64 } from "./UtilFunctions";
import { apiUrl, imagepath } from "../../../config/config";
import PhoneNumberUpdateModal from "./Utils/PhoneNumberUpdateModal";
import ValidatePhoneNumber from "./Utils/ValidatePhoneNumber";
import { convertArabicNumbers } from "./Utils/ArabicConverter";
import File from "../../Shared/File";


const { Dragger } = Upload;

class EditProfil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            imageUrl: "",
            PhoneNumberError: "",
            updatePhoneClicked: false,
            ValidPhone: null,
            veriffNumbVisible: false,
            validateNumbVisible: false,
            errorMsg: '',
            btnCancelClicked: false,
            oldAdminNumber: '',
            newAdminNumber: '',
            adminPhoneNumber: '',
            errorMessage: '',
            phoneObject: '+965',
            dialCode: 3
        };
        this.lang = translator(this.props.lang);
    }

    verifNumber = () => {
        if (this.state.oldAdminNumber.length >= 11) {
            const formData = new FormData();

            formData.append("phone_number", this.state.oldAdminNumber);
            fetch(apiUrl + "api/admin/updatePhoneNumber", {
                method: "POST",
                mode: "cors",
                headers: { Authorization: "Bearer " + this.props.auth.token },
                body: formData,
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status == 400) {
                        this.setState({ errorMsg: this.lang.phone_number_error });
                    }
                    else if (responseJson.status == 200) {
                        this.setState({ errorMsg: '' })
                        this.update()
                        this.onShowValidateModal()
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            this.setState({ errorMsg: this.lang.forgotPasswordMessageWarning });
        }
    }
    updateNewPhoneNumber = () => {
        if (this.state.newAdminNumber.length >= 11) {
            const formData = new FormData();
            formData.append("phone_number", this.state.newAdminNumber);
            fetch(apiUrl + "api/admin/updatePhoneVerifCode", {
                method: "POST",
                mode: "cors",
                headers: { Authorization: "Bearer " + this.props.auth.token },
                body: formData,
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status === 400) {
                        if (responseJson.message.trim() === "Phone number still the same") {
                            this.setState({ errorMsg: this.lang.newNumberErr });
                        }
                        else if (responseJson.message.trim() === "Phone number already in use!") {
                            this.setState({ errorMsg: this.lang.numberUsed });
                        }
                    }
                    else if (responseJson.status == 200) {
                        this.setState({
                            adminPhoneNumber: '+' + this.state.newAdminNumber,
                            errorMsg: '',
                            isNewNumberVisible: !this.state.isNewNumberVisible,
                        })
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            this.setState({ errorMsg: this.lang.forgotPasswordMessageWarning });
        }
    }
    HandleChangeInput = (e) => {
        this.setState({
            [e.target.placeholder]: e.target.value,
        });
    };
    onShowValidateModal = () => {
        this.setState({
            validateNumbVisible: !this.state.validateNumbVisible
        })
    }
    onShowNewNumberModal = () => {
        this.setState({
            isNewNumberVisible: !this.state.isNewNumberVisible
        })
    }

    componentDidMount() {
        this.setState({ adminPhoneNumber: this.props.data.adminPhoneNumber })
    }

    onChangeOldPhoneNumber = (phone_number, a, b, c) => {
        if (a.dialCode.length !== this.state.dialCode) {
            this.setState({
                dialCode: a.dialCode.length
            })
        }
        if (b.nativeEvent && b.nativeEvent.data) {
            let num = convertArabicNumbers(b.nativeEvent.data)
            let regex = /[0-9]/g
            this.setState({
                oldAdminNumber: num.toString().match(regex) && !b.nativeEvent.data.toString().match(regex) ? "+" + phone_number + num : "+" + phone_number,
            })
        } else {
            this.setState({
                oldAdminNumber: "+" + phone_number,
            })
        }
    }
    onChangeNewPhoneNumber = (phone_number, a, b, c) => {
        if (b.nativeEvent && b.nativeEvent.data) {
            let num = convertArabicNumbers(b.nativeEvent.data)
            let regex = /[0-9]/g
            this.setState({
                newAdminNumber: num.toString().match(regex) && !b.nativeEvent.data.toString().match(regex) ? "+" + phone_number + num : "+" + phone_number,
            })
        } else {
            this.setState({
                newAdminNumber: "+" + phone_number,
            })
        }
    }
    handleChangeImage = (info) => {
        this.setState({ image: info });
        getBase64(info.file.originFileObj, (imageUrl) =>
            this.setState({
                imageUrl: imageUrl,
                loading: false,
            })
        );
    };
    handleNameInput = (e) => {
        this.setState({
            name: e.target.value,
        });
    };
    handleEmailInput = (e) => {
        this.setState({
            email: e,
        });
    };

    handleNumberInput = (e) => {
        if (!isNaN(e) || e === "+") {
            this.setState({ phone: e });
        }
    };
    update = () => {
        this.setState({
            veriffNumbVisible: !this.state.veriffNumbVisible,
            oldAdminNumber: this.state.oldAdminNumber.substr(0, this.state.dialCode + 1),
            errorMsg: '',
        });
    };
    newPhoneUpdated = () => {
        this.setState({
            isNewNumberVisible: !this.state.isNewNumberVisible,
            newAdminNumber: this.state.newAdminNumber.substr(0, 3),
            errorMsg: '',
        });
    };
    render() {
        if (this.props.data.adminPhoto) {
            var image = imagepath + this.props.data.adminPhoto.substring(9, this.props.data.adminPhoto.length)
        }
        return (
            <div className="info_container">
                <Row >
                    <Col
                    >
                        <Row className="edit_avatar">
                            <Col>
                                {this.props.data.uploadedPhoto ?
                                    <Avatar size={122} src={this.props.data.uploadedPhoto} />
                                    : this.props.data.adminPhoto ?
                                        <File size={122} src={image} isAvatar />
                                        : persone}
                            </Col>

                        </Row>
                        <Row className="upload_button_container"
                        >
                            <Col><Upload
                                showUploadList={false}
                                accept="image/*"
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                onChange={this.props.handleUploadImage}
                                beforeUpload={beforeUpload}
                            >
                                <span
                                    type="link"
                                    className="upload_photo_button"
                                    onChange
                                >
                                    {this.lang.uploadPhoto}
                                </span>
                            </Upload></Col>

                        </Row>
                    </Col>
                    <Col
                        className="edit_full_name"
                    >
                        <span htmlFor="name" className="full_name_label">
                            {this.lang.fullName}
                        </span>
                        <Input
                            valid={this.props.data.fullNameError ? false : true}
                            placeholder={this.lang.full_name}
                            value={this.props.data.adminFullName}
                            onChange={(e) => this.props.onChangeFullName(e)}
                        />
                        <div className="update_error_message">
                            {this.props.data.fullNameError}
                        </div>
                    </Col>
                    <Col
                        className="edit_full_name"
                    >
                        <span htmlFor="email" className="full_name_label">
                            {this.lang.emailAdress}
                        </span>
                        <Input
                            valid={this.props.data.emailError ? false : true}
                            placeholder={this.lang.emailAdress}
                            value={this.props.data.adminEmail}
                            onChange={(e) => this.props.onChangeEmail(e.target.value)}
                        />
                        <div className="update_error_message">
                            {this.props.emailerreurMessage && this.props.emailerreurMessage}
                        </div>
                    </Col>
                    <Col xl={2} className="save_cancel_container" >
                        <span
                            type="link"
                            onClick={this.props.handleCancelEdit}
                            className="edit_profil_cancel_button"
                        >
                            {this.lang.cancel}
                        </span>
                        <span
                            type="link"
                            onClick={() => this.props.editProfile()}
                            className="edit_profil_save_button"
                        >
                            {this.lang.save}
                        </span>

                    </Col>
                </Row>
                <Divider />

                <Row >
                    <Col className="profile_name">
                        {this.lang.adminContact}
                    </Col>
                </Row>
                <Row>
                    <Col className="profile_email" >
                        {this.lang.phoneNumber}
                    </Col>
                </Row>
                <Row className="number_update_container">
                    <Col >
                        <span className="profile_phone_number">{this.state.adminPhoneNumber}</span>
                        <span onClick={this.update} className="edit_profile_update_phone_number">{this.lang.update}</span>
                    </Col>
                </Row>
                <PhoneNumberUpdateModal
                    ChangePhoneVerif={(phone_number, a, b, c) => this.onChangeOldPhoneNumber(phone_number, a, b, c)}
                    verifNumber={this.verifNumber}
                    landlordNumber={this.state.oldAdminNumber}
                    onShowValidateModal={this.onShowValidateModal}
                    update={this.update}
                    isUpdateModal={this.state.veriffNumbVisible}
                    errorMsg={this.state.errorMsg}
                    errorMessage={this.state.errorMessage}
                    description={this.lang.update_current_phone}
                    buttonLabel={this.lang.continue}
                />
                <PhoneNumberUpdateModal
                    ChangePhoneVerif={(phone_number, a, b, c) => this.onChangeNewPhoneNumber(phone_number, a, b, c)}
                    verifNumber={this.updateNewPhoneNumber}
                    landlordNumber={this.state.newAdminNumber}
                    onShowNewNumberModal={this.onShowNewNumberModal}
                    update={this.newPhoneUpdated}
                    isUpdateModal={this.state.isNewNumberVisible}
                    errorMsg={this.state.errorMsg}
                    errorMessage={this.state.errorMessage}
                    description={this.lang.update_number_txt}
                    buttonLabel={this.lang.update}
                />
                <ValidatePhoneNumber
                    handleGetProfileInfo={this.props.handleGetProfileInfo}
                    onShowValidateModal={this.onShowValidateModal}
                    validateNumbVisible={this.state.validateNumbVisible}
                    onShowNewNumberModal={this.onShowNewNumberModal}
                    phoneNumber={this.props.data.adminPhoneNumber}
                />
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        auth: state.auth,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfil);