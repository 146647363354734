import React, { Component } from "react";
import { Modal, Button, Row, Col } from "antd";
import PropTypes from 'prop-types';
class ModalUikit extends Component {
  constructor(props) {
    super(props);
  }
  static defaultProps = {
    titleModal: null,
    contentTextStyle: null,
    contentText: null,
    VisibleModal: false,
    styleModal: null,
    BtnOkPressed: void null,
    btnCancelPressed: void null,
    closableButtonModal: null,
    deleteButton: null,
    styleButtonDelete: null,
    styleButtonCancel: null,
    buttonModalStyle: null,
    valueDeletedSimilarUnit: null,
    styleIconDeletedSimliarUnit: null,
    negatif: null,
    positif: null,
    onDecriment: void null,
    onIncriment: void null,
    confirmDeletButton: void null,
    unitNumber: null,
    unitNumberStyle: null,
    confirmButton: null,
    cancelButton: null,
    modalStyle: null
  };
  render() {
    return (
      <div>
        <Modal
          title={this.props.titleModal}
          className={this.props.modalStyle}
          centered
          closable={this.props.closableButtonModal}
          visible={this.props.VisibleModal}
          style={this.props.styleModal}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          footer={null}
        >
          <p className={this.props.contentTextStyle}>
            {this.props.contentText}
            <span style={this.props.unitNumberStyle}>
              {this.props.unitNumber}
            </span>
          </p>
          <div className={this.props.buttonModalStyle}>
            <Row className={this.props.styleIconDeletedSimliarUnit}>
              <Col offset={8}>
                <span onClick={() => this.props.buttonDecriment()}>
                  <img src={this.props.negatif} />
                </span>
              </Col>
              <Col
                offset={2}
                className={this.props.styleValueDeletedSimliarUnit}
              >
                {this.props.valueDeletedSimilarUnit}
              </Col>
              <Col offset={2}>
                <span onClick={() => this.props.buttonIncriment()}>
                  <img src={this.props.positif} />
                </span>
              </Col>
            </Row>
            <div className="heelo">
              {this.props.confirmButton}
              {this.props.cancelButton}
            </div>

          </div>
        </Modal>
      </div>
    );
  }
}

ModalUikit.propTypes = {
  confirmButton: PropTypes.elementType,
  cancelButton: PropTypes.elementType
}
export default ModalUikit;
