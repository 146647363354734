import React from "react";
import { connect } from "react-redux";
import { apiUrl } from "../../config/config";
import Personne from '../../assets/images/defaultAvatar.png'
import File from "../Shared/File";

function Toolbar(props) {
    const onShowToolbarTittle = (
        <div className="toolbar-title">
            {
                props.messenger.Broadcast ?
                    <div>
                        {props.messenger.photo && props.messenger.photo.map(el =>
                            el ? <File className={props.auth.lang + "_conversation-photo-history"} src={el.replace('./public/', apiUrl)} alt="" width="72"
                            height= "72"/>
                                :
                                <img className={props.auth.lang + "_conversation-photo-history"} src={Personne} alt="" />
                        )}

                    </div>
                    :
                    props.messenger.photo && props.messenger.photo.length > 0 ?
                        <File className={props.auth.lang + "_conversation-photo-history"}
                            src={props.messenger.photo.replace('./public/', apiUrl)} alt="" width="72"
                            height= "72"/>
                        :
                        <img className={props.auth.lang + "_conversation-photo-history"}
                            src={Personne} alt="" />
            }
            <p className="toolbar-fullName"> {props.messenger.full_name} </p>
        </div>
    )
    return (
        <div className="toolbar">

            {
                props.conversationDeleted || props.messenger.Broadcast ? onShowToolbarTittle : null
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        auth: state.auth,
        messenger: state.messenger
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
