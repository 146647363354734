import React, { Component } from 'react';
import {
    Select,
    DatePicker,
    Input,
    Col, Row
} from "antd";
import translator from "../../../lang/translator";
import FiltersSearch from "../../../assets/images/icon/Filters - search.png";
import FilterSearchAr from "../../../assets/images/icon/FilterSearchAr.png";
import FIltersDropdown from "../../../assets/images/icon/FIlters - dropdown.png";
import { connect } from 'react-redux'
const { Option } = Select;
const { RangePicker } = DatePicker;

class Filters extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.lang = translator(this.props.lang);

    }
    render() {
        return (
            <div style={{ width: "80%", marginLeft: "12.5%" }} className={this.props.lang + "_Rental-filter1"}>
                <Row className={this.props.lang + "_rental-input-con"} offset={2}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 18 }}
                        md={{ span: 16 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                        className={this.props.lang + "_rental-input-col"}
                    >
                        <Row
                            gutter={{
                                xs: 22,
                                sm: 24,
                                md: 24,
                                lg: 25,
                                xl: 25,
                            }}
                        >
                            {" "}
                            {/* justify="space-between" */}
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                md={{ span: 10 }}
                                lg={{ span: 7 }}
                                xl={{
                                    span: 7,
                                    order: 1,
                                }}
                            >
                                <div>
                                    <div className={this.props.lang + "_input-filter"}>
                                        <Input
                                            allowClear
                                            placeholder={this.lang.ARV_Search}
                                            className={this.props.lang + "_ind"}
                                            onChange={
                                                this.props.selectedTab === "Properties" ?
                                                    (e) => this.props.setPropertySearch(e.target.value) :
                                                    this.props.selectedTab === "Rentals" ?
                                                        (e) => this.props.setRentalsSearch(e.target.value) :
                                                        (e) => this.props.setConnectedBankSearch(e.target.value)

                                            }
                                        ></Input>
                                        <img
                                            src={
                                                this.props.lang === "ar"
                                                    ? FilterSearchAr
                                                    : FiltersSearch
                                            }
                                            className={this.props.lang + "_icon-input"}
                                        />
                                    </div>
                                </div>
                            </Col>

                            {(this.props.selectedTab === "Connected_Bank" || this.props.selectedTab === "Properties") && <Col
                                xs={{ span: 24, order: 2 }}
                                sm={{ span: 12, order: 3 }}
                                md={{
                                    span: 10,
                                    order: 3,
                                }}
                                lg={{
                                    span: 7,
                                    order: 3,
                                }}
                                xl={{
                                    span: 7,
                                    order: 3,
                                }}
                                className={this.props.lang + "_col-inputrender2"}
                            >
                                <div>
                                    <div className={this.props.lang + "_input-filter"}>
                                        <Select
                                            showArrow={false}
                                            placeholder={this.lang.ARV_Status}
                                            onChange={
                                                this.props.selectedTab === "Properties" ?
                                                    (value) => this.props.setPropertyStatus(value) :

                                                    (value) => this.props.setConnectedBankStatus(value)
                                            }
                                            className={this.props.lang + "_ind3"}
                                        >
                                            <Option value={''}> {this.lang.all}</Option>
                                            <Option value={this.lang.RTVStApproved.toLowerCase()}> {this.lang.RTVStApproved}</Option>
                                            <Option value={this.lang.RTVStRejected.toLowerCase()} > {this.lang.RTVStRejected}</Option>
                                            <Option value={this.lang.RTVStPending.toLowerCase()}> {this.lang.RTVStPending}</Option>
                                        </Select>
                                        <img
                                            src={FIltersDropdown}
                                            className={this.props.lang + "_acount-rentl-img-select"}
                                        />{" "}
                                    </div>
                                </div>
                            </Col>}
                        </Row>
                    </Col>
                </Row>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        selectedTab: state.selectedTab,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPropertySearch: (e) => dispatch({ type: "SET_PROPERTY_SEARCH", payload: e }),
        setRentalsSearch: (e) => dispatch({ type: "SET_RENTALAS_SEARCH", payload: e }),
        setConnectedBankSearch: (e) => dispatch({ type: "SET_CONNECTEDBANK_SEARCH", payload: e }),
        setPropertyDate: (e) => dispatch({ type: "SET_PROPERTY_DATE", payload: e }),
        setRentalsDate: (e) => dispatch({ type: "SET_RENTALS_DATE", payload: e }),
        setPropertyStatus: (e) => dispatch({ type: "SET_PROPERTY_STATUS", payload: e }),
        setConnectedBankStatus: (e) => dispatch({ type: "SET_CONNECTED_BANK_STATUS", payload: e }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);