import React, { Component } from "react";
import {Row, Col } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import translator from "../../lang/translator";
import pageNotFound from "../../assets/404/pageNotFound.svg";
import logo from "../../assets/404/logo.svg";
import ArabicLogo from "../../assets/images/ArabicLogo2.svg";
class ErrorNotFound extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.lang = translator(this.props.lang);
  }
  render() {

    return (
      <div className={this.props.lang + "_containerPageNotFound"}>
        <Row>
          <Col
            offset={3}
            style={
              this.props.lang === "ar"
                ? { display: "none" }
                : { paddingTop: "30px" }
            }
          >
            <Link to="/">
              <img src={logo} alt="aa" />
            </Link>
          </Col>
          <Col
            style={
              this.props.lang === "en"
                ? { display: "none" }
                : { paddingTop: "30px", marginRight: "12%" }
            }
          >
            <Link to="/">
              <img src={ArabicLogo} alt="aa" />
            </Link>
          </Col>
        </Row>

        <div className={this.props.lang + "_notFoundPageContainer"}>
          <img src={pageNotFound} alt="aa" />
        </div>
        <div className={this.props.lang + "_notFoundPageTitle"}>
          <span style={{ width: "250px", fontSize: "26px" }}>
            {this.lang.PageNotFound}
          </span>
        </div>
        <div className={this.props.lang + "_notFoundPageTitle"}>
          <span>{this.lang.sorry}</span>
        </div>
        <div className={this.props.lang + "_notFoundPageTitle"}>
          <Link to="/">
            <button className={this.props.lang + "_btnReturn"}>
              {this.lang.btnReturn}
            </button>
          </Link>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
  };
};
export default connect(mapStateToProps, null)(ErrorNotFound);
