import React, { Component } from 'react';
import { connect } from 'react-redux';
import translator from "../../lang/translator";
import { Input, Checkbox } from "antd";
import persone from '../../assets/images/defaultAvatar.png'
import searchInput from '../../assets/icons/searchInput.svg'
import backMsg from '../../assets/message/backMsg.svg'
import { apiUrl } from '../../config/config';
import Compose from "./Compose";
import close from '../../assets/icons/close.svg';
import online from "../../assets/images/online.svg";
import _ from 'lodash';
import File from '../Shared/File';
class BrodcastList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tenantsList: [],
            landlordsList: [],
            value: 'tenant',
            searchUser: '',
            tenantId: [],
            landlordId: [],
            checkAll: false,
        }
        this.lang = translator(this.props.lang);
    }
    componentDidMount() {
        this.getLandlordsTenantsList()
        const action = { type: "SET_BROADCAST_CATEGORY", value: this.state.value }
        this.props.dispatch(action)
    }
    onChangeCheckboxTenants = (e) => {
        let index = this.state.tenantId.indexOf(e.target.value)
        if (index !== -1 && !e.target.checked) {
            _.pullAt(this.state.tenantId, index)
            const action = {
                type: "SET_BROADCAST_ID",
                value: this.state.tenantId.length != 0 ? this.state.tenantId : '',
                photo: this.state.tenantsList.filter(el => this.state.tenantId.includes(el._id)).map(item => item.photo)
            };
            this.props.dispatch(action)
            this.setState({
                tenantId: this.state.tenantId,
                checkAll: false
            })
        } else {
            const action = {
                type: "SET_BROADCAST_ID",
                value: [...this.state.tenantId, e.target.value],
                photo: this.state.tenantsList.filter(el => [...this.state.tenantId, e.target.value].includes(el._id)).map(item => item.photo)
            };
            this.props.dispatch(action)
            if (this.state.tenantsList.length === this.state.tenantId.length + 1) {
                this.setState({
                    tenantId: [...this.state.tenantId, e.target.value],
                    checkAll: true
                })
            }
            else
                this.setState({
                    tenantId: [...this.state.tenantId, e.target.value]
                })
        }
    }
    onChangeCheckboxLandlords = (e) => {
        let index = this.state.landlordId.indexOf(e.target.value)
        if (index !== -1 && !e.target.checked) {
            _.pullAt(this.state.landlordId, index)
            const action = {
                type: "SET_BROADCAST_ID",
                value: this.state.landlordId.length != 0 ? this.state.landlordId : '',
                photo: this.state.landlordsList.filter(el => this.state.landlordId.includes(el._id)).map(item => item.photo)
            };
            this.props.dispatch(action)
            this.setState({
                landlordId: this.state.landlordId,
                checkAll: false
            })
        } else {
            const action = {
                type: "SET_BROADCAST_ID",
                value: [...this.state.landlordId, e.target.value],
                photo: this.state.landlordsList.filter(el => [...this.state.landlordId, e.target.value].includes(el._id)).map(item => item.photo)
            };
            this.props.dispatch(action)
            if (this.state.landlordsList.length === this.state.landlordId.length + 1) {
                this.setState({
                    landlordId: [...this.state.landlordId, e.target.value],
                    checkAll: true
                })
            }
            else
                this.setState({
                    landlordId: [...this.state.landlordId, e.target.value]
                })
        }
    }
    getLandlordsTenantsList = () => {
        fetch(apiUrl + "api/admin/getListOfLandlordAndTenant", {
            method: "GET",
            mode: "cors",
            headers: { Authorization: "Bearer " + this.props.auth.token },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 200) {
                    let action = {
                        type: "SET_LANDLORDS_TENANTS_LIST",
                        landlords: responseJson.landlords,
                        tenants: responseJson.tenants,
                    };
                    this.props.dispatch(action);
                    this.setState({
                        tenantsList: responseJson.tenants,
                        landlordsList: responseJson.landlords,
                    })
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    onSearchUsers = (e) => {
        this.setState({
            searchUser: e.target.value
        })
    }
    clearInput = () => {
        this.setState({
            searchUser: ''
        })
    }
    chooseUserType = (userType) => {
        const action = { type: "SET_BROADCAST_CATEGORY", value: userType }
        this.props.dispatch(action)
        this.setState({
            value: userType,
            landlordId: [],
            tenantId: [],
            checkAll: false
        });
    }
    renderOptions = (type) => {
        let options = []
        if (type === "tenant") {
            this.props.messenger.tenants.filter(e => e.full_name && e.full_name.toUpperCase().includes(this.state.searchUser.toUpperCase())).map(el => {
                options.push({ value: el._id, label: '' })
                return options
            }
            )
        } else {
            this.props.messenger.landlords.filter(e => e.full_name && e.full_name.toUpperCase().includes(this.state.searchUser.toUpperCase())).map(el => {
                options.push({ value: el._id, label: '' })
                return options
            }
            )
        }
        return options
    }
    onCheckAllChange = (e) => {
        if (this.props.messenger.Broadcast_category === "landlord") {
            const action = {
                type: "SET_BROADCAST_ID",
                value: e.target.checked ? this.state.landlordsList.map((el) => el._id) : [],
                photo: e.target.checked ? this.state.landlordsList.map(el => el.photo) : []
            };
            this.props.dispatch(action)
            this.setState({
                landlordId: e.target.checked
                    ? this.state.landlordsList.map((el) => el._id)
                    : [],
                checkAll: e.target.checked,
            });
        } else {
            const action = {
                type: "SET_BROADCAST_ID",
                value: e.target.checked ? this.state.tenantsList.map((el) => el._id) : [],
                photo: e.target.checked ? this.state.tenantsList.map(el => el.photo) : []
            };
            this.props.dispatch(action)
            this.setState({
                tenantId: e.target.checked
                    ? this.state.tenantsList.map((el) => el._id)
                    : [],
                checkAll: e.target.checked,
            });
        }
    };
    render() {
        return (
            <div>
                <p className={this.props.lang + '_broadcast_messageBack'} onClick={this.props.onBroadcast} >
                    <img className={this.props.lang + '_broadcast_messageBackLogo'} src={backMsg} alt="" />
                    {this.lang.messages}
                </p>
                <p className={this.props.lang + '_broadcast_to'}>
                    {this.lang.broadcast_to}
                </p>
                <div className={"broadcast_to_btn_container" + " " + this.props.lang + "_brodcast_buttons"}>
                    <button
                        onClick={() => this.chooseUserType('tenant')}
                        className={
                            this.state.value === 'tenant'
                                ? this.props.lang + "_broadcast_to_btnSelected"
                                : this.props.lang + "_broadcast_to_btn"
                        }
                    >
                        {this.lang.tenants}
                    </button>
                    <button
                        onClick={() => this.chooseUserType('landlord')}
                        className={
                            this.state.value === 'landlord'
                                ? this.props.lang + "_broadcast_to_btnSelected-last"
                                : this.props.lang + "_broadcast_to_btn-last"
                        }
                    >
                        {this.lang.landlords}
                    </button>
                </div>
                <div className="conversation-search">
                    <Input
                        value={this.state.searchUser}
                        onChange={(e) => this.onSearchUsers(e)}
                        prefix={<img src={searchInput} alt="" />}
                        suffix={this.state.searchUser.length != 0 ? <img className='clearInput' onClick={this.clearInput} src={close} alt="" /> : null}
                        type="search"
                        className="conversation-search-input"
                        placeholder={this.lang.Search}
                    />
                </div>
                <div className={"broadcast_check_all_container"}>
                    <span className={"text"}>{this.lang.selectAll}</span>
                    <Checkbox
                        className={this.props.messenger.Broadcast_category === "landlord" ?
                            !this.state.checkAll && this.state.landlordId.length > 0 ? "broadcast_check_all__semi" : "broadcast_check_all"
                            :
                            !this.state.checkAll && this.state.tenantId.length > 0 ? "broadcast_check_all__semi" : "broadcast_check_all"}
                        onChange={(e) => this.onCheckAllChange(e)}
                        checked={
                            this.props.messenger.Broadcast_category === "landlord" ?
                                this.state.checkAll ||
                                (this.state.landlordId.length ===
                                    this.state.landlordsList.length
                                    && this.state.landlordsList && this.state.landlordsList.length > 0)
                                :
                                this.state.checkAll ||
                                (this.state.tenantId.length ===
                                    this.state.tenantsList.length
                                    && this.state.tenantsList && this.state.tenantsList.length > 0)
                        }
                    >
                    </Checkbox>
                </div>
                {this.state.value === 'tenant' ?
                    <div className={'broadcastTenant_check_container'}>
                        <div  >
                            {this.props.messenger.tenants.filter(e => e.full_name && e.full_name.toUpperCase().includes(this.state.searchUser.toUpperCase())).map(el =>
                                <div className='broadcastTenant_info'>
                                    {this.props.messenger.onlineTab.includes(el._id) ? <img className={this.props.lang + '_isOnline'} src={online} alt="" /> : null}
                                    {el.photo ?
                                        <File className={this.props.lang + '_broadcastTenant_photo'} src={el.photo.replace("./public/", apiUrl)} alt="" width="72"
                                        height= "72"/> :
                                        <img className={this.props.lang + '_broadcastTenant_photo'} src={persone} alt="" />}
                                    {el.full_name}
                                </div>
                            )}
                        </div>
                        <div className={this.props.lang + "_messenger_checkbox_design"}>
                            {
                                this.props.messenger.tenants.map(el => {
                                    return <Checkbox
                                        onChange={this.onChangeCheckboxTenants}
                                        checked={this.state.tenantId.includes(el._id)}
                                        defaultChecked={this.state.tenantId.includes(el._id)}
                                        value={el._id}
                                        style={el.full_name && el.full_name.toUpperCase().includes(this.state.searchUser.toUpperCase()) ? { 'display': '' } : { 'display': 'none' }}>
                                    </Checkbox>
                                }
                                )
                            }
                        </div>
                    </div>
                    :
                    <div className={'broadcastTenant_check_container'}>
                        <div  >
                            {this.props.messenger.landlords.filter(e => e.full_name && e.full_name.toUpperCase().includes(this.state.searchUser.toUpperCase())).map(el =>
                                <div className='broadcastTenant_info'>
                                    {this.props.messenger.onlineTab.includes(el._id) ? <img className={this.props.lang + '_isOnline'} src={online} alt="" /> : null}
                                    {el.photo ?
                                        <File className={this.props.lang + '_broadcastTenant_photo'} src={el.photo.replace("./public/", apiUrl)} alt="" width="72"
                                        height= "72"/> :
                                        <img className={this.props.lang + '_broadcastTenant_photo'} src={persone} alt="" />}
                                    {el.full_name}

                                </div>
                            )}
                        </div>
                        <div className={this.props.lang + "_messenger_checkbox_design"}>
                            {
                                this.props.messenger.landlords.map(el => {
                                    return <Checkbox
                                        onChange={this.onChangeCheckboxLandlords}
                                        checked={this.state.landlordId.includes(el._id)}
                                        defaultChecked={this.state.landlordId.includes(el._id)}
                                        value={el._id}
                                        style={el.full_name && el.full_name.toUpperCase().includes(this.state.searchUser.toUpperCase()) ? { 'display': '' } : { 'display': 'none' }}>
                                    </Checkbox>
                                }
                                )
                            }
                        </div>
                    </div>
                }
                {this.props.messenger.id_receiver === '' ? null : <Compose />}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        auth: state.auth,
        messenger: state.messenger
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => { dispatch(action) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BrodcastList);