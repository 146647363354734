import React, { Component } from 'react';
import translator from "../../../lang/translator";
import { connect } from 'react-redux'
import logo from "../../../assets/icons/logo.svg";
import arabicLogo from "../../../assets/images/arabicLogo.svg";
import { Form, Input, Button } from "antd";
import { apiUrl } from '../../../config/config'
import PhoneNumber from "../../../UIKIT/PhoneInput/index";
import { convertArabicNumbers } from '../../Setting/Profile/Utils/ArabicConverter';

class SubUserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone_number: "",
            errors: null,
            errorMessage: "",
            phoneObject: '+965'
        }
        this.lang = translator(this.props.lang);

    }

    handleSubmit = () => {
        this.props.phoneNumber(this.state.phone_number)
        const fomdata = new FormData()
        fomdata.append("phone_number", this.state.phone_number)
        fetch(apiUrl + `admin/enterSubuserPhoneNumber`, {
            method: "POST",
            mode: "cors",
            body: fomdata
        }).then(res => res.json())
            .then(data => {
                if (data.status === 400) {
                    this.setState({
                        errors: "Subuser already subscribed"
                    })
                    this.props.history.push("/")
                } else if (data.status === 404) {
                    this.setState({
                        errors: "Subuser not invited !"
                    })
                } else if (data.status === 200) {
                    this.props.history.push('/smsVerfication')
                }
            })
            .catch(err => {
                console.log(err)
            });

    }

    onChangePhoneNumber = (phone_number, a, b, c) => {
        if (b.nativeEvent && b.nativeEvent.data) {
            let num = convertArabicNumbers(b.nativeEvent.data)
            let regex = /[0-9]/g
            this.setState({
                phone_number: num.toString().match(regex) && !b.nativeEvent.data.toString().match(regex) ? "+" + phone_number + num : "+" + phone_number,
            })
        } else {
            this.setState({
                phone_number: "+" + phone_number,
            })
        }
    }
    render() {
        return (<div>
            <img
                className={this.props.lang + "_loginLogo"}
                src={this.props.lang === "en" ? logo : arabicLogo}
                alt=""
            />
            <div className={this.props.lang + "_login-sub-Container"}>
                <div className={this.props.lang + "_itemSub"}>
                    <h1 style={{ textAlign: "center" }}>{this.lang.welcome}</h1>
                    <span className={this.props.lang + "_pleasePhone"}>{this.lang.Please_enter_phone_number}</span>
                    <Form className={this.props.lang + "_login-form"}>
                        <Form.Item>
                            <PhoneNumber
                                label={false}
                                value={this.state.phone_number}
                                onChange={(phone_number, a, b, c) => this.onChangePhoneNumber(phone_number, a, b, c)}
                            />
                            <span style={{ color: "red", display: "flex", paddingTop: "10px" }}>
                                {this.state.errorMessage}
                            </span>
                        </Form.Item>
                        <Button
                            onClick={this.handleSubmit}
                            type="primary"
                            htmlType="submit"
                            className="loginFormButton"


                        >
                            <span style={{ fontSize: 16, fontWeight: 500 }}>
                                {this.lang.continue}
                            </span>
                        </Button>
                        <p style={{ textAlign: "center", color: "red" }}>{this.state.errors}</p>
                    </Form>
                </div>
            </div>
        </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,



    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        phoneNumber: (phone) => dispatch({ type: "SET_PHONE_NUMBER", payload: phone })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SubUserLogin);