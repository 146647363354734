import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import translator from "../../lang/translator";
import { Upload, Button, Input, Tooltip } from "antd";
import uploadIcon from "../../assets/icons/uploadIcon.svg";
import sendMessenger from "../../assets/icons/sendMessenger.svg";
import SingletonSocket from "../../singleton/socket" 
const { TextArea } = Input

function Compose(props) {
    let socket = new SingletonSocket();
    const [InputMessage, setInputMessage] = useState("");
    const [AttachementFile, setAttachementFile] = useState(null);
    const [uploadFile, setFile] = useState([])
    const lang = translator(props.lang);
    const [join, setJoin] = useState(true)
    const imageUpload = {

        listType: 'text',
        multiple: false,
        accept: '.pdf , .docx , .jpeg , .png , .jpg , .MP4 , .MOV , .WMV , .FLV , .AVI , .AVCHD , .WebM , .MKV , .GIF, .XLS , .XLSX',
        onChange({ file, fileList }) {
            setFile(fileList)
            setAttachementFile(file);
        }
    }
    setInterval(() => {
        setJoin(!join)
    }, 30000);
    useEffect(() => {
        socket.on('message', (data) => {
            let action = {}
            action = {
                type: "SET_MESSAGE",
                value: { text: data.lastMessgae.text },
                sender: data.lastMessgae.sender,
                attachments: data.lastMessgae.attachments,
                _id: data.lastMessgae._id,
                createdAt: data.lastMessgae.createdAt,
                id_conversation: data._idconverstaion,
                lu: props.user.type === "subuser" ? data.lastMessgae.sender === props.user.idSuperAdmin ? true : false
                    : data.lastMessgae.sender === props.auth.admin.adminId ? true : false
            };

            props.dispatch(action);
        });
        socket.on('receiveConversation', (data) => {
            const action = {
                type: "PUSH_NEW_CONVERSATION",
                conversation: {
                    id_conversation: data._id,
                    name: data.users[1].nameTenant,
                    text: data.messages[0].text,
                    createdAt: data.messages[0].createdAt,
                    discussion: data.messages,
                    id_receiver: data.users[1].user_id,
                    lu: props.user.type === "subuser" ? data.messages[0].sender === props.user.idSuperAdmin ? true : false
                        : data.messages[0].sender === props.auth.admin.adminId ? true : false
                }
            };
            props.dispatch(action);
        });

        socket.on("conversationDeleted", (data) => {
            const action = {
                type: "DELETE_CONVERSATION",
                id_conversation: data.id_conversation
            }
            props.dispatch(action)
        })
    }, [props.messenger.landlordConversationsList.length + props.messenger.tenantConversationsList.length, join])

    const onRemove = (file) => {
        setFile([]);
        setAttachementFile(null)
    }
    const sendBroadcastMessages = () => {
        if (InputMessage.length != 0 || AttachementFile !== null) {
            const action = {
                type: "SET_MY_NEW_BROADCAST", value: { text: InputMessage.length !== 0 ? InputMessage : 'You have sent a document' }
            };
            props.dispatch(action);
            const data = {
                message: InputMessage.length != 0 ? InputMessage : null, attachments: AttachementFile !== null ? AttachementFile : null,
                id_receiver: props.messenger.id_receiver, typeSender: 'Admin', sender: props.user.type === "subuser" ? props.user.idSuperAdmin : props.auth.admin.adminId,
                landlordName: 'Tenantive support',
                tenantName: props.messenger.full_name,
            }
            socket.emit('sendBroadcastAdmin', data, () => {
            });
            setFile([]);
            setAttachementFile(null)
            setInputMessage("");
        }
    };
    const sendMessages = () => {
        if (InputMessage.length != 0 || AttachementFile !== null) {
            const data = {
                message: InputMessage.length != 0 ? InputMessage : null, attachments: AttachementFile !== null ? AttachementFile : null,
                id_receiver: props.messenger.id_receiver, typeSender: 'Admin', id_conversation: props.messenger.id_conversation, sender: props.user.type === "subuser" ? props.user.idSuperAdmin : props.auth.admin.adminId
            }
            socket.emit('sendMessage', data, () => {
            });
            setFile([]);
            setAttachementFile(null)
            setInputMessage("");
        }
    };
    const onChangeInputValue = (InputMessage) => {
        setInputMessage(InputMessage);
    };

    const onClickBtnSend = () => {
        if (props.messenger.Broadcast) {
            sendBroadcastMessages()
        }
        else {
            sendMessages();
        }
        props.ScrollToBottom()
    };
    return (
        <div className={props.auth.lang + "_compose"} onClick={props.ScrollToBottom}>
            <Upload
                fileList={uploadFile}
                className={InputMessage.length != 0 ? 'none' : 'messenger_upload_button'} onRemove={onRemove} {...imageUpload}>
                <Button className={props.auth.lang + "_btnUploadMessenger"}>
                    {uploadFile.length != 0 ? null : <img id="uploadImageMessenger" src={uploadIcon} alt="" />}
                </Button>
            </Upload>
            <TextArea
                type="text"
                className={uploadFile.length != 0 ? 'none' : props.auth.lang + "_compose-input"}
                placeholder={lang.type_here}
                onChange={(e) => {
                    onChangeInputValue(e.target.value);
                }}
                value={InputMessage}

            />
            {
                props.auth.deactivated ?
                    <Tooltip title={lang.messengerDisabledTooltip} trigger={['hover', 'click']}>
                        <span>
                            <img src={sendMessenger} className={props.auth.lang + "_sendMessenger"} alt="" />
                        </span>
                    </Tooltip>
                    :
                    <span onClick={onClickBtnSend}>
                        <img src={sendMessenger} className={props.auth.lang + "_sendMessenger"} alt="" />
                    </span>
            }
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        auth: state.auth,
        user: state.user,
        messenger: state.messenger
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Compose);