
const admin = {
    id: "",
    full_name: " ",
    userPermissions: [],
    landlords: [],
    type: "",
    user_name: "",
    factorAuthentication: false,

}

const ConnectedUser = (state = admin, action) => {
    if (action.type === "GET_CONNECTED_USER") {
        return action.payload                                
    }
    else if (action.type === 'SET_MULTIFACTOR') {
        return {
            ...state,
            factorAuthentication: action.tow_factor 
        }
    }
    else if (action.type === 'LOGOUT') {
        return admin

    }
    else return state
}

export default ConnectedUser