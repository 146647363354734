import React, { Component } from "react";
import { Input } from "antd";
import moment from "moment";
import translator from "../../../lang/translator";
import { connect } from "react-redux";
import { Table, Pagination, Empty } from "antd";
import { apiUrl } from "../../../config/config";
import ActionView from "./actionView";

class ActivityLog extends Component {
  constructor(props) {
    super(props);
    this.lang = translator(this.props.lang);
    this.state = {
      data: [],
      elementnumber: 0,
      pagenumber: 1,

      columns: [
        {
          title: this.lang.User,
          dataIndex: "user",
          key: "user",

          render: (text, record) => <div>{record.user}</div>,
        },

        {
          title: this.lang.Action,

          dataIndex: "action",
          key: "action",

          render: (text, record) => {
            let BTQ = record.action.substring(0, record.action.indexOf(":"));

            let time = moment(record.date).format("lll");

            return (
              <div className={this.props.lang + "_"}>
                <ActionView
                  user={record.user}
                  action={record.action}
                  status={record.action.split(" ")[0]}
                  time1={time}
                ></ActionView>
              </div>
            );
          },
        },
        {
          title: this.lang.Time,

          dataIndex: "time",
          key: "time",

          render: (text, record) => {
            let timeHoraire = moment(record.date).format("LT");
            let timeDate = moment(record.date).format("ll");

            return (
              <div className={this.props.lang + "_time"}>
                <span> {timeDate}</span>
                <span> {timeHoraire}</span>
              </div>
            );
          },
        },

        /*   render: (text, record) => (
                    /**     "date": "2020-06-12T17:06:32.571Z" *
                      <div className={this.props.lang + "_time"}>
                    <span>  {record.timeDate}</span>
                    {record.date.format("ddd, M ,  YYYY, h:mm:ss a")}
                    <span>  {record.timeHoraire}</span>
        
                                      
                        </div>
          
               
                    ),
                    *
        
        
                  },*/
      ],
    };
  }
  getActivityLog(page) {
    /**http://localhost:8000/api/admin/getActivityLogsList?page=1&per_page=10 */
    fetch(
      apiUrl + "api/admin/getActivityLogsList?&page=" + page + "&per_page=10",
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + this.props.token,

          /* this.props.token, */
        },
      }
    )
      .then((response) => {
        response.json().then((data) => {
          this.setState({
            data: data.activityLog,
            elementnumber: data.nbrElements,
          });
        });
      })
      .catch((err) => {
        console.log("error ", err);
      });
  }
  componentDidMount() {
    this.getActivityLog(1, "");
  }
  pagination = (page, numberpage) => {
    this.getActivityLog(page);
    this.setState({
      pagenumber: page,
    });
  };

  render() {
    return (
      <section className={this.props.lang + "_Container-ActivtyLog"}>
        <div className={this.props.lang + "_tableActivity"}>
          <Table
            locale={{
              emptyText: (<div>
                <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                <p className='noData'>
                  No data !
                </p>
              </div>)
            }}
            columns={this.state.columns}
            dataSource={this.state.data}
            pagination={false}
            components={this.components}
          />

          {this.state.elementnumber !== 0 ? (
            <Pagination
              style={{ textAlign: "right", marginTop: "2%" }}
              defaultCurrent={1}
              current={this.state.pagenumber}
              pageSize={10}
              total={this.state.elementnumber}
              showSizeChanger={false}
              responsive={true}
              onChange={this.pagination}
            />
          ) : null}
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
    token: state.auth.token,
  };
};
export default connect(mapStateToProps, null)(ActivityLog);
