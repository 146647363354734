import React from "react";
import { connect } from "react-redux";
import { Button, Col, Row, Popover, Form } from "antd";
import UIKIT_INPUT_PASSWORD from "../../../UIKIT/InputPassword/index";
import {
    hasLowerCase,
    hasNumber,
    hasUperCase,
    hasSpecialCaracter,
} from "./UtilFunctions";
import translator from "../../../lang/translator";
class EditPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordUpperSuccessful: "change_password_profile",
            passwordLowerSuccessful: "change_password_profile",
            passwordNumberSuccessful: "change_password_profile",
            passwordMinCaractersSuccessful: "change_password_profile",
            passwordSpecialSuccessful: "change_password_profile",
        };
        this.lang = translator(this.props.lang)
    }
    newPasswordChanged = (str) => {
        if (hasUperCase(str)) {
            this.setState({ passwordUpperSuccessful: "change_password_profile_success" });
        } else {
            this.setState({ passwordUpperSuccessful: "change_password_profile" });
        }
        if (hasLowerCase(str)) {
            this.setState({ passwordLowerSuccessful: "change_password_profile_success" });
        } else {
            this.setState({ passwordLowerSuccessful: "change_password_profile" });
        }
        if (hasNumber(str)) {
            this.setState({ passwordNumberSuccessful: "change_password_profile_success" });
        } else {
            this.setState({ passwordNumberSuccessful: "change_password_profile" });
        }
        if (str.length >= 8) {
            this.setState({ passwordMinCaractersSuccessful: "change_password_profile_success" });
        } else {
            this.setState({ passwordMinCaractersSuccessful: "change_password_profile" });
        }
        if (hasSpecialCaracter(str)) {
            this.setState({ passwordSpecialSuccessful: "change_password_profile_success" });
        } else {
            this.setState({ passwordSpecialSuccessful: "change_password_profile" });
        }
    }

    render() {
        const Passwordcontent = (
            <div style={{ fontSize: 12 }}>
                <h4 style={{ color: "black" }}>
                    {this.lang.forgotPasswordPasswordGuidance}
                </h4>
                <p className={this.state.passwordUpperSuccessful}>
                    {this.lang.forgotPasswordUperCase}
                </p>
                <p className={this.state.passwordLowerSuccessful}>
                    {this.lang.forgotPasswordLowerCaracter}
                </p>
                <p className={this.state.passwordNumberSuccessful}>
                    {this.lang.forgotPasswordNumbers}
                </p>
                <p className={this.state.passwordSpecialSuccessful}>
                    {this.lang.forgotPasswordSpeicalChar}
                </p>
                <p className={this.state.passwordMinCaractersSuccessful}>
                    {this.lang.forgotPasswordMinCaracter}
                </p>
            </div>
        );
        return (
            <div>
                <Row>
                    <Col className="change_password_label">
                        {this.lang.changePassword}
                    </Col>
                </Row>
                <Row
                    gutter={20}
                    className="setting_profile_editPassword"
                >

                    <Col xl={9} xxl={7} >
                        <Form autoComplete="off">
                            <div className="edit_profil_password_label">
                                {this.lang.currentPassword}
                            </div>
                            <Form.Item className="edit_password_input">
                                <UIKIT_INPUT_PASSWORD
                                    value={this.props.currentPassword}
                                    onChange={(e) => this.props.handleCurrentPassword(e.target.value)}
                                />
                            </Form.Item>
                            <div className="errPasswordStyle">
                                {this.props.data.passwordErreurMessage}
                            </div>
                        </Form>
                    </Col>

                    <Col xl={10} xxl={6}>
                        <Form autoComplete="off">
                            <div className="edit_profil_password_label">
                                {this.lang.newPassword}
                            </div>
                            <Form.Item className="edit_password_input">
                                <Popover
                                    placement="rightTop"
                                    content={Passwordcontent}
                                    trigger={("click", "focus")}
                                >
                                    <UIKIT_INPUT_PASSWORD
                                        value={this.props.changedPassword}
                                        className="profile_password_input"
                                        onChange={(e) => {
                                            this.props.handleChangedPassword(e.target.value)
                                            this.newPasswordChanged(e.target.value)
                                        }
                                        }
                                    />
                                    <div className="errPasswordStyle">
                                        {this.props.data.changedPasswordErreurMessage}
                                    </div>
                                </Popover>
                            </Form.Item>
                        </Form>
                    </Col>

                </Row>
                <Row></Row>
                <Row className="change_password_buttons_container">
                    <Col >
                        <Button
                            type="primary"
                            className="update_password_button"
                            onClick={this.props.updatePassword}
                        >
                            {this.lang.updatePassword}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="cancel_update_password_button"
                            onClick={this.props.handleCancelChangePassword}
                        >
                            {this.lang.cancel}
                        </Button>
                    </Col>
                </Row>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPassword);
