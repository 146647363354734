import React, { Component } from "react";
import { connect } from "react-redux";
import spin from "../../assets/Property/LOGO.svg";
class LoaderTenantive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
        
    }
    

    render() {
        return (
        <div className={"dashboard_spinContainer"}>
        {" "}
        <div className="boxLoading">
          <div className={"stepLoading"}></div>
          <img
            style={{ width: "200px", position: "relative" }}
            src={spin}
            alt=""
          />
        </div>{" "}
      </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        token: state.auth.token,
  

    };
};
export default connect(mapStateToProps, null)(LoaderTenantive);





