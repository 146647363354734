const LandLordSelectReducer = (state = 'Properties', action) => {
    if (action.type === "PROPERTIES_SELECTED") {
        return action.payload
    } else if (action.type === "RENTALS_SELECTED") {
        return action.payload
    } else if (action.type === "CONNECTED_BANK_SELECTED") {
        return action.payload
    } else if (action.type === "INVOICE_SELECTED") {
        return action.payload
    } else if (action.type === "DEPOSIT_SELECTED") {
        return action.payload
    }
    else return state
}

export default LandLordSelectReducer