import React, { Component, Fragment } from "react";
import { Input, Row, Col, notification } from "antd";
import translator from "../../lang/translator";
import { connect } from "react-redux";
import { Pagination } from "antd";
import Approve from "../Landlords/ListBanks/Apporove";
import Reject from "../Landlords/ListBanks/Reject";
import { apiUrl } from "../../config/config"
import LoaderTenantive from "../../UIKIT/LoaderTenantive"
import Empty from "antd/es/empty";
import Arrow from "../../assets/icons/arrowdown.svg";
import redFlag from "../../assets/icons/redFlag.png";
import orangeFlag from "../../assets/icons/orangeFlag.png";
import greenFlag from "../../assets/icons/greenFlag.png";
import moment from 'moment'
import { downloadFile as download } from "../../Api";

const { Search } = Input;
var PAGES = 10;
class PendingBanks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            banksData: [],
            LoadingTableData: false,
            activekey: [],
            nbrElements: 0,
            currentPage: 1,
            windowWidth: window.innerWidth,
        };
        this.lang = translator(this.props.lang);
    }
    renderDcoumentName(document) {
        if (this.state.windowWidth < 1600) {
            return document.name.length > 8 ? document.name.substr(0, 3) + "..." + document.name.substr(document.name.length - 3) : document.name
        } else {
            return document.name.length > 15 ? document.name.substr(0, 10) + "..." + document.name.substr(document.name.length - 3) : document.name
        }
    }
    onShow = (i) => {
        !this.state.activekey.includes(i) &&
            this.setState({
                activekey: [i],
            });
    };
    hide = (i) => {
        this.state.activekey.includes(i) &&
            this.setState({
                activekey: this.state.activekey.filter((el) => el !== i),
            });
    };
    GetBankList(page, search) {
        this.setState({
            LoadingTableData: true
        })
        fetch(apiUrl + "api/admin/searchPendingBanks?searchedLandlord=" + search + "&page=" + page + "&per_page=10", {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.token,
            },
        })
            .then((response) => {
                response.json().then((data) => {
                    let action = {
                        type: "SET_PENDING_NOTIF",
                        totalPendingBanks: 0,
                        totalPendingClaims: this.props.auth.totalNotificationClaims,
                        totalPendingProperties: this.props.auth.totalNotificationPendingProperties
                    };
                    this.props.dispatch(action);
                    this.setState({
                        banksData: data.bankPaginate,
                        nbrElements: data.nbrElements,
                        LoadingTableData: false
                    })
                });
            })
            .catch((err) => {
                console.log("error ", err);
            });
    }
    componentDidMount() {
        this.GetBankList(1, '')
        window.addEventListener("resize", () => {
            this.setState(() => {
                return {
                    windowWidth: window.innerWidth,
                };
            });
        });
    }
    pagination = (page, numberpage) => {
        this.GetBankList(page, this.state.search)
        this.setState({
            currentPage: page,
            activekey: []
        })
    }
    Action() {
        if (this.state.banksData.length == 1 && this.state.currentPage > 1) {
            this.state.currentPage = this.state.currentPage - 1
            this.GetBankList(this.state.currentPage, this.state.search)
            this.openNotificationWithIcon('success')
        }
        else {
            this.GetBankList(this.state.currentPage, this.state.search)
            this.openNotificationWithIcon('success')
        }
    }
    openNotificationWithIcon = type => {
        notification[type]({
            message: 'Pending Banks Updated',
            duration: null,
            top: 125,
            className: this.props.lang + '_PendingBanknotification',
            placement: this.props.lang === "en" ? "topRight" : "topLeft"
        })
    };

    DownloadId = (x) => {
        fetch(apiUrl + "api/admin/downloadBankDocs/" + x, {
            method: "Get",
            mode: "cors",
            headers: {
                'Authorization': 'Bearer ' + this.props.token
            },
        })
            .then((response) => {
                if (response.status == 200) {
                    window.open(response.url)
                }
            })
            .catch(err => console.log("err", err));
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.search !== this.state.search) {
            this.GetBankList(this.state.currentPage, this.state.search)
        }
    }
    SearchLandlord(value) {
        this.setState({ currentPage: 1, search: value })
    }
    showFile = (path) => {
        let newWindow = window.open()
        let link = path.replace("./public/", "")
        download({ path: link, type: "pdf", window: newWindow })
    }
    downloadFile = (path) => {
        download({ path })
    }
    calculateTotalDocs = (el) => {
        let total = 0;
        if (el.document_from_bank && el.document_from_bank.length > 0) {
            total += el.document_from_bank.length
        }
        if (el.title_deed && el.title_deed.length > 0) {
            total += el.title_deed.length
        }
        if (el.civil_id && el.civil_id.length > 0) {
            total += el.civil_id.length
        }
        if (el.signature_authorization && el.signature_authorization != undefined && el.signature_authorization.length > 0) {
            total += el.signature_authorization.length
        }
        if (el.company_license && el.company_license != undefined && el.company_license.length > 0) {
            total += el.company_license.length
        }
        if (el.civil_ID_for_authorized_signatory && el.civil_ID_for_authorized_signatory != undefined && el.civil_ID_for_authorized_signatory.length > 0) {
            total += el.civil_ID_for_authorized_signatory.length
        }
        if (el.property_management_company_license && el.property_management_company_license != undefined && el.property_management_company_license.length > 0) {
            total += el.property_management_company_license.length
        }
        if (el.property_management_company_signature_authorization && el.property_management_company_signature_authorization != undefined && el.property_management_company_signature_authorization.length > 0) {
            total += el.property_management_company_signature_authorization.length
        }
        if (el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company && el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company != undefined && el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company.length > 0) {
            total += el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company.length
        }
        if (el.Power_of_attorney && el.Power_of_attorney != undefined && el.Power_of_attorney.length > 0) {
            total += el.Power_of_attorney.length
        }
        if (el.Company_signature_authorization && el.Company_signature_authorization != undefined && el.Company_signature_authorization.length > 0) {
            total += el.Company_signature_authorization.length
        }
        if (el.Civil_ID_for_authorized_signatory_in_the_Company && el.Civil_ID_for_authorized_signatory_in_the_Company != undefined && el.Civil_ID_for_authorized_signatory_in_the_Company.length > 0) {
            total += el.Civil_ID_for_authorized_signatory_in_the_Company.length
        }
        if (el.Property_manager_civil_ID && el.Property_manager_civil_ID != undefined && el.Property_manager_civil_ID.length > 0) {
            total += el.Property_manager_civil_ID.length
        }
        if (el.Landlord_Civil_ID && el.Landlord_Civil_ID != undefined && el.Landlord_Civil_ID.length > 0) {
            total += el.Landlord_Civil_ID.length
        }
        if (el.more_files && el.more_files != undefined && el.more_files.length > 0) {
            total += el.more_files.length
        }
        return total
    }
    landlordtype = (type) => {
        switch (type.toUpperCase()) {
            case 'individual landlord'.toUpperCase():
                return <span className="connected_bank_name type-landlord-scroll">Landlord Individual</span>
            case 'CMMOP'.toUpperCase():
                return <span className="connected_bank_name type-landlord-scroll">Landlord Company</span>
            case 'IPMWWOBOAIL'.toUpperCase():
                return <span className="connected_bank_name type-landlord-scroll">Property Manager Individual works on behalf of an Individual Landlord</span>
            case 'IPMWWOBOAC'.toUpperCase():
                return <span className="connected_bank_name type-landlord-scroll">Property Manager Individual works on behalf of a Company</span>
            case 'PMCTWOBOAIL'.toUpperCase():
                return <span className="connected_bank_name type-landlord-scroll">Property Manager Company works on behalf of an Individual Landlord</span>
            case 'PMCTWOBOAC'.toUpperCase():
                return <span className="connected_bank_name type-landlord-scroll">Property Manager Company works on behalf of a Company</span>
            default:
                return ""
        }
    }
    render() {
        return (
            <div className={this.props.lang + "_accounting_transfer_bigContainer"}>
                <Row className={this.props.lang + "_row-transfers-btn"}>
                    <Col
                        span={5}
                        xs={24}
                        sm={24}
                        md={8}
                        lg={5}
                        xl={5}
                        style={{
                            height: "40px",
                        }}
                    >
                        <Search
                            allowClear={true}
                            style={{
                                height: "100% ",
                                direction: "ltr",
                            }}
                            placeholder={this.lang.Search}
                            onSearch={(value) => this.SearchLandlord(value)}
                            enterButton
                            className='search-input'
                        />
                    </Col>
                </Row>
                <div className={this.props.lang + "_pending_banks_bigContainer"}>
                    <Row className={this.props.lang + "_connected_bank"}>
                        <Col
                            span={5}>
                            {this.lang.Bankdocs}
                        </Col>
                        <Col
                            span={3}>
                            {this.lang.name_as_in_bank}
                        </Col>
                        <Col
                            span={3}>
                            {this.lang.landlord}
                        </Col>
                        <Col
                            span={2}>
                            {this.lang.properties}
                        </Col>
                        <Col
                            span={6}>
                            {this.lang.iban_bank_name}
                        </Col>
                        <Col
                            md={{ span: 4 }}>
                            {this.lang.BanklistAction}
                        </Col>
                        <Col
                            span={1}>
                            {this.lang.kyc}
                        </Col>
                    </Row>
                    {
                        this.state.LoadingTableData ?
                            <LoaderTenantive />
                            :
                            this.state.banksData.length == 0 ?
                                <div className="noData_Container_">
                                    <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    <p className='noData'>
                                        No Data !
                                    </p>
                                </div>
                                :
                                this.state.banksData && this.state.banksData.map((el, i) => {
                                    return (
                                        <Fragment>
                                            <Row className={this.props.lang + "_connected_bank_row"}>
                                                <Col id='start'
                                                    xs={{ span: 1, offset: 1 }}
                                                    sm={{ span: 1, offset: 1 }}
                                                    md={{ span: 1, offset: 0 }}
                                                >
                                                    <div className="downProp">
                                                        <div className="arrow-down ml-5" onClick={
                                                            !this.state.activekey.includes(i)
                                                                ? () => this.onShow(i)
                                                                : () => this.hide(i)
                                                        }>
                                                            <img className={!this.state.activekey.includes(i) ? 'arroww' : "arrowUP"}

                                                                src={Arrow}
                                                                alt="arrow"

                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col
                                                    span={4} className="totalDocs">
                                                    <div className="pending_banks_days_container">
                                                        <div>
                                                            <span>{this.calculateTotalDocs(el)}{this.lang.documents}</span>
                                                            <span
                                                                className={this.props.lang + "_BankdocsColumn"}
                                                                onClick={() => this.DownloadId(el.id)}
                                                            >
                                                                {this.lang.downloadAll}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="pending_banks_days">{el.pending_date ? moment(el.pending_date).format("DD MMM YYYY") : null}</span>
                                                            <span className="pending_banks_days ml-10">{el.pending_date ? moment(el.pending_date).fromNow() : null}</span>
                                                        </div>
                                                    </div>

                                                </Col>
                                                <Col
                                                    span={3}>
                                                    <div className='column_propertyName'>
                                                        <span>{el.name_as_bank}</span>
                                                    </div>
                                                </Col>
                                                <Col
                                                    span={3}>
                                                    <div className='bank_documents_date'>
                                                        <span>{el.landlord}</span>
                                                        {
                                                            this.landlordtype(el.type)
                                                        }
                                                    </div>
                                                </Col>

                                                <Col
                                                    span={2}>
                                                    <div className='column_propertyName'>
                                                        <span>{el.property_name.length} {this.lang.properties}</span>
                                                    </div>
                                                </Col>
                                                <Col
                                                    span={6}>
                                                    <div className="bank_documents_date">
                                                        {el.iban}
                                                        <span className="connected_bank_name">{el.bank_name}</span>
                                                    </div>
                                                </Col>
                                                <Col
                                                    span={4}>
                                                    <div className={this.props.lang + "_btnAppReject pr-0"}>
                                                        <div className={this.props.lang + "_btn_column_action1 w-48"}>
                                                            <div className={this.props.lang + "_btn_column_action"}>
                                                                <Approve getBankTable={this.Action.bind(this)} type="banks" id={el.id} />
                                                            </div>
                                                        </div>

                                                        <div
                                                            className={this.props.lang + "_btn_column_action1 w-48"}>
                                                            <div className={this.props.lang + "_btn_column_action"}>
                                                                <Reject getBankTable={this.Action.bind(this)} type="banks" id={el.id} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col
                                                    xs={{ span: 1 }}
                                                    sm={{ span: 1 }}
                                                    md={{ span: 1 }}
                                                >
                                                    <img alt="" src={el.risk === 'high' ? redFlag : el.risk === 'medium' ? orangeFlag : greenFlag} />
                                                </Col>
                                                <Col
                                                    span={5} offset={1}
                                                    className={
                                                        this.state.activekey.includes(i)
                                                            ? this.props.lang + "_display_content"
                                                            : this.props.lang + "_hide_content"
                                                    }>
                                                    {
                                                        el.document_from_bank && el.document_from_bank.map(doc => {
                                                            return (
                                                                <div className="all_files full_width">
                                                                    <span className="file_name">
                                                                        {this.renderDcoumentName(doc)}
                                                                    </span>
                                                                    <span
                                                                        className={this.props.lang + "_BankdocsColumn"}
                                                                        onClick={() => this.downloadFile(doc.path)}
                                                                    >
                                                                        {this.lang.Download}
                                                                    </span>
                                                                    <div
                                                                        onClick={() => this.showFile(doc.path)}
                                                                        className={this.props.lang + "_btn-action-View"}
                                                                    >
                                                                        {this.lang.View}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        el.civil_id && el.civil_id.map(civil => {
                                                            return (
                                                                <div className="all_files full_width">
                                                                    <span className="file_name">
                                                                        {this.renderDcoumentName(civil)}
                                                                    </span>
                                                                    <span
                                                                        className={this.props.lang + "_BankdocsColumn"}
                                                                        onClick={() => this.downloadFile(civil.path)}
                                                                    >
                                                                        {this.lang.Download}
                                                                    </span>
                                                                    <div
                                                                        onClick={() => this.showFile(civil.path)}
                                                                        className={this.props.lang + "_btn-action-View"}
                                                                    >
                                                                        {this.lang.View}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        el.title_deed && el.title_deed.map(title_deed => {
                                                            return (
                                                                <div className="all_files full_width">
                                                                    <span className="file_name">
                                                                        {this.renderDcoumentName(title_deed)}
                                                                    </span>
                                                                    <span
                                                                        className={this.props.lang + "_BankdocsColumn"}
                                                                        onClick={() => this.downloadFile(title_deed.path)}
                                                                    >
                                                                        {this.lang.Download}
                                                                    </span>
                                                                    <div
                                                                        onClick={() => this.showFile(title_deed.path)}
                                                                        className={this.props.lang + "_btn-action-View"}
                                                                    >
                                                                        {this.lang.View}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        el.signature_authorization && el.signature_authorization.map(signature_authorization =>
                                                            <div className="all_files full_width">
                                                                <span className="file_name">
                                                                    {this.renderDcoumentName(signature_authorization)}
                                                                </span>
                                                                <span
                                                                    className={this.props.lang + "_BankdocsColumn"}
                                                                    onClick={() => this.downloadFile(signature_authorization.path)}
                                                                >
                                                                    {this.lang.Download}
                                                                </span>
                                                                <div
                                                                    onClick={() => this.showFile(signature_authorization.path)}
                                                                    className={this.props.lang + "_btn-action-View"}
                                                                >
                                                                    {this.lang.View}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        el.company_license && el.company_license.map(company_license =>
                                                            <div className="all_files full_width">
                                                                <span className="file_name">
                                                                    {this.renderDcoumentName(company_license)}
                                                                </span>
                                                                <span
                                                                    className={this.props.lang + "_BankdocsColumn"}
                                                                    onClick={() => this.downloadFile(company_license.path)}
                                                                >
                                                                    {this.lang.Download}
                                                                </span>
                                                                <div
                                                                    onClick={() => this.showFile(company_license.path)}
                                                                    className={this.props.lang + "_btn-action-View"}
                                                                >
                                                                    {this.lang.View}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        el.civil_ID_for_authorized_signatory && el.civil_ID_for_authorized_signatory.map(civil_ID_for_authorized_signatory =>
                                                            <div className="all_files full_width">
                                                                <span className="file_name">
                                                                    {this.renderDcoumentName(civil_ID_for_authorized_signatory)}
                                                                </span>
                                                                <span
                                                                    className={this.props.lang + "_BankdocsColumn"}
                                                                    onClick={() => this.downloadFile(civil_ID_for_authorized_signatory.path)}
                                                                >
                                                                    {this.lang.Download}
                                                                </span>
                                                                <div
                                                                    onClick={() => this.showFile(civil_ID_for_authorized_signatory.path)}
                                                                    className={this.props.lang + "_btn-action-View"}
                                                                >
                                                                    {this.lang.View}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        el.property_management_company_license && el.property_management_company_license.map(property_management_company_license => {
                                                            return (
                                                                <div className="all_files full_width">
                                                                    <span className="file_name">
                                                                        {this.renderDcoumentName(property_management_company_license)}
                                                                    </span>
                                                                    <span
                                                                        className={this.props.lang + "_BankdocsColumn"}
                                                                        onClick={() => this.downloadFile(property_management_company_license.path)}
                                                                    >
                                                                        {this.lang.Download}
                                                                    </span>
                                                                    <div
                                                                        onClick={() => this.showFile(property_management_company_license.path)}
                                                                        className={this.props.lang + "_btn-action-View"}
                                                                    >
                                                                        {this.lang.View}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        el.property_management_company_signature_authorization && el.property_management_company_signature_authorization.map(property_management_company_signature_authorization => {
                                                            return (
                                                                <div className="all_files full_width">
                                                                    <span className="file_name">
                                                                        {this.renderDcoumentName(property_management_company_signature_authorization)}
                                                                    </span>
                                                                    <span
                                                                        className={this.props.lang + "_BankdocsColumn"}
                                                                        onClick={() => this.downloadFile(property_management_company_signature_authorization.path)}
                                                                    >
                                                                        {this.lang.Download}
                                                                    </span>
                                                                    <div
                                                                        onClick={() => this.showFile(property_management_company_signature_authorization.path)}
                                                                        className={this.props.lang + "_btn-action-View"}
                                                                    >
                                                                        {this.lang.View}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company && el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company.map(Civil_ID_for_authorized_signatory_in_the_Property_Management_Company => {
                                                            return (
                                                                <div className="all_files full_width">
                                                                    <span className="file_name">
                                                                        {this.renderDcoumentName(Civil_ID_for_authorized_signatory_in_the_Property_Management_Company)}
                                                                    </span>
                                                                    <span
                                                                        className={this.props.lang + "_BankdocsColumn"}
                                                                        onClick={() => this.downloadFile(Civil_ID_for_authorized_signatory_in_the_Property_Management_Company.path)}
                                                                    >
                                                                        {this.lang.Download}
                                                                    </span>
                                                                    <div
                                                                        onClick={() => this.showFile(Civil_ID_for_authorized_signatory_in_the_Property_Management_Company.path)}
                                                                        className={this.props.lang + "_btn-action-View"}
                                                                    >
                                                                        {this.lang.View}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        el.Power_of_attorney && el.Power_of_attorney.map(Power_of_attorney => {
                                                            return (
                                                                <div className="all_files full_width">
                                                                    <span className="file_name">
                                                                        {this.renderDcoumentName(Power_of_attorney)}
                                                                    </span>
                                                                    <span
                                                                        className={this.props.lang + "_BankdocsColumn"}
                                                                        onClick={() => this.downloadFile(Power_of_attorney.path)}
                                                                    >
                                                                        {this.lang.Download}
                                                                    </span>
                                                                    <div
                                                                        onClick={() => this.showFile(Power_of_attorney.path)}
                                                                        className={this.props.lang + "_btn-action-View"}
                                                                    >
                                                                        {this.lang.View}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        el.Company_signature_authorization && el.Company_signature_authorization.map(Company_signature_authorization => {
                                                            return (
                                                                <div className="all_files full_width">
                                                                    <span className="file_name">
                                                                        {this.renderDcoumentName(Company_signature_authorization)}
                                                                    </span>
                                                                    <span
                                                                        className={this.props.lang + "_BankdocsColumn"}
                                                                        onClick={() => this.downloadFile(Company_signature_authorization.path)}
                                                                    >
                                                                        {this.lang.Download}
                                                                    </span>
                                                                    <div
                                                                        onClick={() => this.showFile(Company_signature_authorization.path)}
                                                                        className={this.props.lang + "_btn-action-View"}
                                                                    >
                                                                        {this.lang.View}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        el.Civil_ID_for_authorized_signatory_in_the_Company && el.Civil_ID_for_authorized_signatory_in_the_Company.map(Civil_ID_for_authorized_signatory_in_the_Company => {
                                                            return (
                                                                <div className="all_files full_width">
                                                                    <span className="file_name">
                                                                        {this.renderDcoumentName(Civil_ID_for_authorized_signatory_in_the_Company)}
                                                                    </span>
                                                                    <span
                                                                        className={this.props.lang + "_BankdocsColumn"}
                                                                        onClick={() => this.downloadFile(Civil_ID_for_authorized_signatory_in_the_Company.path)}
                                                                    >
                                                                        {this.lang.Download}
                                                                    </span>
                                                                    <div
                                                                        onClick={() => this.showFile(Civil_ID_for_authorized_signatory_in_the_Company.path)}
                                                                        className={this.props.lang + "_btn-action-View"}
                                                                    >
                                                                        {this.lang.View}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        el.Property_manager_civil_ID && el.Property_manager_civil_ID.map(Property_manager_civil_ID => {
                                                            return (
                                                                <div className="all_files full_width">
                                                                    <span className="file_name">
                                                                        {this.renderDcoumentName(Property_manager_civil_ID)}
                                                                    </span>
                                                                    <span
                                                                        className={this.props.lang + "_BankdocsColumn"}
                                                                        onClick={() => this.downloadFile(Property_manager_civil_ID.path)}
                                                                    >
                                                                        {this.lang.Download}
                                                                    </span>
                                                                    <div
                                                                        onClick={() => this.showFile(Property_manager_civil_ID.path)}
                                                                        className={this.props.lang + "_btn-action-View"}
                                                                    >
                                                                        {this.lang.View}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        el.Landlord_Civil_ID && el.Landlord_Civil_ID.map(Landlord_Civil_ID => {
                                                            return (
                                                                <div className="all_files full_width">
                                                                    <span className="file_name">
                                                                        {this.renderDcoumentName(Landlord_Civil_ID)}
                                                                    </span>
                                                                    <span
                                                                        className={this.props.lang + "_BankdocsColumn"}
                                                                        onClick={() => this.downloadFile(Landlord_Civil_ID.path)}
                                                                    >
                                                                        {this.lang.Download}
                                                                    </span>
                                                                    <div
                                                                        onClick={() => this.showFile(Landlord_Civil_ID.path)}
                                                                        className={this.props.lang + "_btn-action-View"}
                                                                    >
                                                                        {this.lang.View}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {el.more_files && el.more_files.map((file, k) => {
                                                        return (
                                                            <div className="all_files full_width">
                                                                <span className="file_name">
                                                                    {this.renderDcoumentName(file)}
                                                                </span>
                                                                <span
                                                                    className={this.props.lang + "_BankdocsColumn"}
                                                                    onClick={() => this.downloadFile(file.path)}
                                                                >
                                                                    {this.lang.Download}
                                                                </span>
                                                                <div
                                                                    onClick={() => this.showFile(file.path)}
                                                                    className={this.props.lang + "_btn-action-View"}
                                                                >
                                                                    {this.lang.View}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </Col>
                                                <Col span={4} offset={4}
                                                    className={
                                                        this.state.activekey.includes(i)
                                                            ? this.props.lang + "_display_content"
                                                            : this.props.lang + "_hide_content"
                                                    }
                                                >
                                                    {
                                                        el.property_name?.map(el => <div className="full_width connected_bank_properties"> {el} </div>)
                                                    }
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    )
                                })}
                    {
                        this.state.banksData != 0 ?
                            <Pagination
                                current={this.state.currentPage}
                                defaultCurrent={1}
                                className="test"
                                pageSize={PAGES}
                                total={this.state.nbrElements}
                                showSizeChanger={false}
                                responsive={true}
                                onChange={this.pagination} /> : null
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        token: state.auth.token,
        auth: state.auth

    };
};
export default connect(mapStateToProps, null)(PendingBanks);





