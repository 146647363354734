import React, { Component } from "react";
import { Modal, Button, Row, Col, Input, Checkbox } from "antd";
import translator from "../../lang/translator";
import { connect } from "react-redux";
import search from "../../assets/icons/baseline-keyboard_arrow_down-24px.svg";
import { apiUrl } from "../../config/config";

const CheckboxGroup = Checkbox.Group;
class Receipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      checkAll: false,
      landlords: [],
      //checkedList: [],
    };
    this.lang = translator(this.props.lang);
  }
  onChange = (el) => {
    if (this.props.checkedList.indexOf(el) === -1) {
      this.props.onChangeCheckedList([...this.props.checkedList, el])
      // this.setState({
      //   checkedList: [...this.props.checkedList, el],
      // });
    } else {
      this.props.onChangeCheckedList(this.props.checkedList.filter((e) => e !== el))
      this.setState({
        //checkedList: this.state.checkedList.filter((e) => e !== el),
        checkAll: false,
      });
    }
    if (this.props.landlords.length === this.props.checkedList.length) {
      this.setState({
        checkAll: true,
      });
    } else {
      this.setState({
        checkAll: false,
      });
    }
  };
  onCheckAllChange = (e) => {
    this.props.onChangeCheckedList(
      e.target.checked
        ? this.props.landlords.map((el) => el._id)
        : []
    )
    this.setState({
      // checkedList: e.target.checked
      //   ? this.props.landlords.map((el) => el._id)
      //   : [],
      checkAll: e.target.checked,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.checkedList.length !== this.props.checkedList.length) {
      this.props.landlordsNumber(
        this.props.checkedList,
        this.props.landlords.length
      );
      this.setState({
        checkAll: true
      })
    }
    if (prevProps.checkedList.length !== this.props.checkedList.length && this.props.checkedList.length !== this.props.landlords.length) {
      this.props.landlordsNumber(
        this.state.checkedList,
        this.props.landlords.length
      );
      this.setState({
        checkAll: false
      })
    }
  }
  render() {
    return (
      <div>
        <Modal
          className={this.props.lang + "_EditLandlords"}
          visible={this.props.visible}
          footer={null}
          closable={false}
          onCancel={() => this.props.onShow(false)}
        >
          <Row>
            <Col span={24}>
              <div className={this.props.lang + "_EditLandlords_entete"}>
                <span>{this.lang.landlords}</span>
                <Checkbox
                  className={!this.state.checkAll && this.props.checkedList.length > 0 ? "EditLandlords_semi" : "EditLandlords_Check"}
                  onChange={this.onCheckAllChange}
                  checked={
                    this.state.checkAll ||
                    this.props?.landlords?.length ===
                    this.props.checkedList.length
                  }
                >
                  {" "}
                  {this.lang.All_landlords}
                </Checkbox>
              </div>
            </Col>
            <Col span={22}>
              <Input
                className={this.props.lang + "_EditLandlords_searchInput"}
                prefix={<img src={search} />}
                placeholder={this.lang.Search}
                onChange={(e) => this.setState({ search: e.target.value })}
              />
            </Col>
            <Col span={24} className='editLandlordContainer' >
              {this.props?.landlords?.filter((el) =>
                el?.full_name?.toUpperCase()
                  .includes(this.state.search?.toUpperCase().trim())
              )
                .map((el, i) => (
                  <div key={i}>
                    <div className={this.props.lang + "_EditLandlords_list"}>
                      <Checkbox
                        onChange={() => this.onChange(el._id)}
                        checked={this.props.checkedList.indexOf(el._id) !== -1}
                        className="EditLandlords_Check"
                      >
                        {el.full_name}
                      </Checkbox>
                      <span>{el.phone_number}</span>
                    </div>
                  </div>
                ))}
            </Col>
            <Col span={12} offset={6}>
              <button
                onClick={() => {
                  this.props.onShow(false);
                }}
                className={this.props.lang + "_EditLandlords-btn-edit"}
              >
                {this.lang.forgotPasswordConfirm}
              </button>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
    auth: state.auth,
  };
};
export default connect(mapStateToProps, null)(Receipt);
