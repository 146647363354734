import React from 'react';
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import './assets/ar.css'
import './assets/en.css'
import MainRoute from './Routes/MainRoute';


function App() {
  return (
    <div>
     <MainRoute/>
    </div>
  );
}

export default App;
