import io from "socket.io-client";
import { apiUrl } from "../config/config";
const ENDPOINT = apiUrl ;


export default class SingletonSocket {
    _socket;
    constructor() {
        if (!SingletonSocket._socket) {
            SingletonSocket._socket = io(ENDPOINT);
        }
        return SingletonSocket._socket;
    }
}