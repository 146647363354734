import React, { Component } from "react";
import translator from "../../lang/translator";
import { connect } from "react-redux";
import logo from "../../assets/icons/logo.svg";
import arabicLogo from "../../assets/images/arabicLogo.svg";
import { Form, Input, Button } from "antd";
import { loginUser } from "../../Redux/Actions";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import PhoneNumber from "../../UIKIT/PhoneInput/index";
import publicIp from 'public-ip'
import { convertArabicNumbers } from "../Setting/Profile/Utils/ArabicConverter";
import UIKIT_INPUT_PASSWORD from "../../UIKIT/InputPassword";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      password: "",
      errors: null,
      errorMessage: "",
      user: {},
      phoneObject: '+965'
    };
    this.lang = translator(this.props.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.token) {
      this.props.history.push("/");
    }

    // if (nextProps.errors) {
    //   this.setState({
    //     errors: nextProps.errors,
    //   });
    // }
  }

  handleSubmit = async () => {
    let ip = await publicIp.v4()
    const formData = new FormData();
    formData.append("phone_number", this.state.phoneNumber);
    formData.append("password", this.state.password);
    formData.append("ip", ip);
    this.props.loginUser(formData);

    let action = { type: "SET_PHONE_NUMBER", payload: this.state.phoneNumber };
    this.props.dispatch(action);
  };
  onChangePhoneNumber = (phone_number, a, b, c) => {
    if (b.nativeEvent && b.nativeEvent.data) {
      let num = convertArabicNumbers(b.nativeEvent.data)
      let regex = /[0-9]/g
      this.setState({
        phoneNumber: num.toString().match(regex) && !b.nativeEvent.data.toString().match(regex) ? "+" + phone_number + num : "+" + phone_number,
      })
    } else {
      this.setState({
        phoneNumber: "+" + phone_number,
      })
    }
  }
  render() {
    return (
      <div>
        <img
          className={this.props.lang + "_loginLogo"}
          src={this.props.lang === "en" ? logo : arabicLogo}
          alt=""
        />
        <div className={this.props.lang + "_login-Container"}>
          <div className={this.props.lang + "_item"}>
            <h1 style={{ textAlign: "center" }}>{this.lang.welcome}</h1>
            <span className={this.props.lang + "_pleaseLogIn"}>
              {this.lang.pleaseLogIn}
            </span>
            <Form className={this.props.lang + "_login-form"}>
              <label className={this.props.lang + "_labelForm"}>
                {this.lang.phoneNumber}
              </label>
              <Form.Item>
                <PhoneNumber
                  label={false}
                  value={this.state.phoneNumber}
                  onChange={(phone_number, a, b, c) => this.onChangePhoneNumber(phone_number, a, b, c)}
                />
                <span style={{color: "red",display: "flex",paddingTop: "10px"}}>
                  {this.state.errorMessage}
                </span>

              </Form.Item>
              <label className={this.props.lang + "_labelForm"}>
                {this.lang.password}
              </label>

              <Form.Item>
                <UIKIT_INPUT_PASSWORD
                  label={this.lang.PasswordLogin}
                  placeholder={this.lang.enterPassword}
                  onChange={(event) => {
                    this.setState({ password: event.target.value });
                  }}
                  inputClassName={this.props.lang + "_input_password"}
                />
              </Form.Item>
              <Button
                onClick={() => this.handleSubmit()}
                type="primary"
                htmlType="submit"
                className="loginFormButton"
              >
                <span style={{ fontSize: 16, fontWeight: 500 }}>
                  {this.lang.LogIn}
                </span>
              </Button>
            </Form>
            {this.props.errors.LoginError === "erreur" ? (
              <span
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "10px",
                }}
              >
                {this.lang.loginUserNotFound}
              </span>
            )
              :
              this.props.errors.LoginError === "bruteForce" ?
                (
                  <span
                    style={{
                      color: "red",
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "10px",
                    }}
                  >
                    {this.lang.BruteForceError}
                  </span>
                )
                :
                null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
    errors: state.errors,
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (user) => dispatch(loginUser(user)),
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
