import React, { Component } from 'react';
import { connect } from "react-redux";
import translator from "../../../lang/translator";
import closeNotificationIcon from '../../../assets/icons/closeNotificationIcon.svg'
import expiryIcon from '../../../assets/icons/expiryIcon.svg'
import moment from "moment";
class ExpiryToast extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.lang = translator(this.props.lang);
    }

    getDays(expiry_Date) {
        let dateNow = moment(new Date()).startOf('day')
        let expiryDate = moment(new Date(expiry_Date)).startOf('day')
        let diffrence = expiryDate.diff(dateNow, 'days')
        if (diffrence < 0) {
            return 0
        }
        return diffrence
    }

    addLeadingZeros(value) {
        value = String(value);
        while (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }
    renderExpiredTenantToast = (counter) => {
        return (
            <div className='expiry_toast_container' style={{ top: this.props.top }}>
                <div className="expiry_toast">
                    <div className='expiry_toast_text'>
                        <img className='mr-10' height='24px' width='24px' src={expiryIcon} alt="expiry" />
                        <div className="pr-5">{this.props.tenant ? this.lang.the_tenant : this.lang.the_landlord}</div>
                        <div className="expiry_bold_text_toast"> {this.props.tenant ? this.props.tenant : this.props.landlord} {this.props.documentType}</div>
                        {this.props.tenant ? <div>{this.lang.of_the_lanlord}</div> : null}
                        {this.props.tenant ? <div className="pl-5 expiry_bold_text_toast">{this.props.landlord}</div> : null}
                        <div className="pl-5">{this.lang.will_be_expired_in}</div>
                        <div className="pl-5">{counter}</div>
                        <div className="pl-5">{this.lang.days}</div>
                    </div>
                    <img onClick={this.props.closeExpiryToast} className='close_expiry_toast' src={closeNotificationIcon} alt="close" />
                </div>
            </div>
        )
    }
    renderExpiredPropertyToast = (counter) => {
        return (
            <div className='expiry_toast_container' style={{ top: this.props.top }}>
                <div className="expiry_toast">
                    <div className='expiry_toast_text'>
                        <img className='mr-10' height='24px' width='24px' src={expiryIcon} alt="expiry" />
                        <div className="pr-5">{this.lang.The}</div>
                        <div className="expiry_bold_text_toast pr-5"> {this.props.document}</div>
                        <div>{this.lang.of_the_property}</div>
                        <div className="pl-5 expiry_bold_text_toast">{this.props.property}</div>
                        <div className="pl-5">{this.lang.of_the_lanlord}</div>
                        <div className="pl-5 expiry_bold_text_toast">{this.props.landlord}</div>
                        <div className="pl-5">{this.lang.will_be_expired_in}</div>
                        <div className="pl-5">{counter}</div>
                        <div className="pl-5">{this.lang.days}</div>
                    </div>
                    <img onClick={this.props.closeExpiryToast} className='close_expiry_toast' src={closeNotificationIcon} alt="close" />
                </div>
            </div>
        )
    }

    render() {
        return (<div> {this.props.isProperty ?
            this.renderExpiredPropertyToast(this.addLeadingZeros(this.getDays(this.props.date)))
            :
            this.renderExpiredTenantToast(this.addLeadingZeros(this.getDays(this.props.date)))} </div>);
    }
}
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang
    };
};
export default connect(mapStateToProps, null)(ExpiryToast);