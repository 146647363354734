import React, { Component } from "react";
import { Modal, Row, Col, Button, Divider, Card } from "antd";
import moment from "moment";
import translator from "../../lang/translator";
import { connect } from "react-redux";
import { apiUrl } from "../../config/config";
import logo from "../../assets/images/LogoTenEn.svg";
import { formateNumber } from "../helpers/Helper";
import { downloadFile } from "../../Api";
const ref = React.createRef();
class Receipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      elementnumber: 0,
      pagenumber: 1,
    };
    this.lang = translator(this.props.lang);
  }

  renderPaymentFor = () => {
    if (this.props.selectedTab == 'Rentals' || this.props.category == 'Rentals') {
      if (this.props.receipt.payment_for && this.props.receipt.payment_for.length === 1) {
        return moment(this.props.receipt.payment_for, 'DD/MM/YYYY').format('MMM,YYYY')
      } else {
        return (this.props.receipt.payment_for &&
          <div>
            From {moment(this.props.receipt.payment_for[0], 'DD/MM/YYYY').format('MMM,YYYY')} to {moment(this.props.receipt.payment_for[1], 'DD/MM/YYYY').format('MMM,YYYY')}
          </div>)
      }
    }
    else if (this.props.selectedTab == 'Deposit' || this.props.category == 'Deposit') {
      return moment(this.props.receipt.payment_for).format('MMM, YYYY')
    }
    else {
      return moment(this.props.receipt.payment_for).format('DD/MM/YYYY')
    }

  }

  exportToPdf = () => {
    let newWindow = window.open()
    fetch(apiUrl + `api/contract/exportPdf/${this.props.receipt._id}?lang=${'EN'}`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          downloadFile({ path: data.link, type: "pdf", window: newWindow })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  openFile = (path) => {

    let link = path.replace("./public/", apiUrl)
    window.open(link)
  }

  render() {
    let type_receipt = this.props.receipt && this.props.receipt.type
    return (
      <Modal
        centered
        visible={this.props.visible}
        onCancel={this.props.hide}
        footer={null}
        className='receipt_admin_rental'
      >
        <div style={{ paddingLeft: "20px" }}>
          <div ref={ref}>
            <Row style={{ width: "100%" }}>
              <Col span={14} >
                <span
                  style={{
                    fontFamily: "SF-Pro-Display-Bold",
                    fontSize: 18,
                    color: "#212121",
                  }}
                >
                  {
                    this.props.selectedTab == 'Rentals' || this.props.category == 'Rentals' ? this.lang.Rental_receipt :
                      this.props.selectedTab == 'Deposit' || this.props.category == 'Deposit' ?
                        this.lang.depositReceipt : this.lang.Invoice_receipt
                  }
                </span>

              </Col>
              <Col span={10} >
                <img
                  src={logo}
                  alt="Logo"
                  className='logo_receipt'
                />
              </Col>
            </Row>
            <p
              style={{
                fontFamily: "SF-Pro-Display-Medium",
                fontSize: "16px",
                color: "#B3B3B3",
                marginTop: -10
              }}
            >
              {moment(this.props.receipt.payment_date).format("DD/MM/YYYY")}
            </p>
            <Row style={{ width: "100%" }}>
              <p className="ReceiptLabel_">Receipt</p>
              <p className="Receiptfont1_" style={{ paddingLeft: "16px" }}>
                {this.props.receipt.receipt_number}
              </p>
            </Row>
            <div className='receipt_modal_block' >
              {this.props.receipt.payment_method === "Knet" && (
                <Row style={{ width: "100%" }}>
                  <p className="ReceiptLabel">Merchant track ID</p>
                  <p className="Receiptfont1" style={{ paddingLeft: "16px" }}>
                    {this.props.receipt.merchant_track_id}
                  </p>
                </Row>
              )}
              {this.props.receipt.payment_method === "Knet" && (
                <Row style={{ width: "100%" }}>
                  <p className="ReceiptLabel">Transaction ID</p>
                  <p className="Receiptfont1" style={{ paddingLeft: "16px" }}>
                    {this.props.receipt.transaction_id}
                  </p>
                </Row>
              )}
              {this.props.receipt.payment_method === "Knet" && (
                <Row style={{ width: "100%" }}>
                  <p className="ReceiptLabel">Payment ID</p>
                  <p className="Receiptfont1" style={{ paddingLeft: "16px" }}>
                    {this.props.receipt.payment_id}
                  </p>
                </Row>
              )}
              {this.props.receipt.payment_method === "Knet" && (
                <Row style={{ width: "100%" }}>
                  <p className="ReceiptLabel">Reference ID</p>
                  <p className="Receiptfont1" style={{ paddingLeft: "16px" }}>
                    {this.props.receipt.reference_id}
                  </p>
                </Row>
              )}
              <Divider style={{ width: "109%", marginLeft: "-5%" }} />
              <Row style={{ width: "100%" }}>
                <Col span={5} xs={24} sm={24} md={8} lg={5} xl={10}>
                  <p className="ReceiptLabel">From</p>
                  <p className="Receiptfont1">{this.props.receipt.tenant}</p>
                </Col>
                <Col xs={0} sm={0} md={1} lg={1} xl={4}></Col>
                <Col span={5} xs={24} sm={24} md={8} lg={5} xl={10}>
                  <p className={type_receipt && type_receipt.toUpperCase() !== 'DEPOSIT' ? "ReceiptLabel" : 'depositReceiptLabel'}>To</p>
                  <p className={type_receipt && type_receipt.toUpperCase() !== 'DEPOSIT' ? "Receiptfont1" : 'depositReceiptfont1'}>{this.props.receipt.landlord}</p>
                </Col>
              </Row>
              <Divider style={{ width: "109%", marginLeft: "-5%" }} />
              <Row style={{ width: "100%" }}>
                <Col span={8}>
                  <p className="ReceiptLabel">Landlord name</p>
                </Col>
                <Col span={16}>
                  <p className="Receiptfont1" style={{ paddingLeft: "16px" }}>
                    {this.props.receipt.landlord}
                  </p>
                </Col>
              </Row>
              <Row style={{ width: "100%" }}>
                <Col span={8}>
                  <p className="ReceiptLabel">Property name</p>
                </Col>
                <Col span={16}>
                  <p className="Receiptfont1" style={{ paddingLeft: "16px" }}>
                    {this.props.receipt.property_name}
                  </p>
                </Col>
              </Row>
              <Row style={{ width: "100%" }}>
                <Col span={8}>
                  <p className="ReceiptLabel">Floor number</p>
                </Col>
                <Col span={16}>
                  <p className="Receiptfont1" style={{ paddingLeft: "16px" }}>
                    {this.props.receipt.floor_number}
                  </p>
                </Col>
              </Row>
              <Row style={{ width: "100%" }}>
                <Col span={8}>
                  <p className="ReceiptLabel">Unit number</p>
                </Col>
                <Col span={16}>
                  <p className="Receiptfont1" style={{ paddingLeft: "16px" }}>
                    {this.props.receipt.unit_number}
                  </p>
                </Col>
              </Row>
              <Row style={{ width: "100%" }}>
                <Col span={8}>
                  <p className="ReceiptLabel">Property address</p>
                </Col>
                <Col span={16}>
                  <p className="Receiptfont1" style={{ paddingLeft: "16px" }}>
                    {this.props.receipt.property_street}
                  </p>
                </Col>
              </Row>
              <Card style={{ marginTop: 24 }}>
                {type_receipt && type_receipt.toUpperCase() == 'DEPOSIT' ?
                  <Row style={{ width: "100%" }}>
                    <Col span={11} >
                      <p className="Receipt_Label">
                        Amount paid
                      </p>
                      <p className="Receipt_Label" >
                        Security deposit for
                      </p>
                      <p className="Receipt_Label" >
                        Payment method
                      </p>
                    </Col>
                    <Col span={13} >
                      <p className="ReceiptfontDown">
                        {this.props.receipt.amount != undefined ? (
                          <span
                            style={{
                              fontFamily: "SF-Pro-Display-Bold",
                              fontSize: "18px",
                              color: "#5FB563",
                            }}
                          >
                            {formateNumber(this.props.receipt.amount.replace('KWD', ''))}
                          </span>) : null}
                        <span
                          style={{
                            fontFamily: "SF-Pro-Display-Regular",
                            fontSize: "16px",
                            color: "#B3B3B3",
                            paddingLeft: "3px",
                          }}
                        >
                          KWD
                        </span>
                      </p>
                      <p className="ReceiptfontDown" style={{ paddingTop: "5px" }}>
                        {this.renderPaymentFor()}
                      </p>
                      <p className="ReceiptfontDown" >
                        {this.props.receipt.payment_method}
                      </p>
                    </Col>
                  </Row>
                  :
                  <Row style={{ width: "100%" }}>
                    <Col span={11} >
                      <p className="Receipt_Label">
                        Payment for
                      </p>
                      <p className="Receipt_Label" >
                        Amount paid
                        <span className={this.props.lang + '_discount_label'} >
                          {this.props.receipt.discount && ` (${this.lang.discount} ${this.props.receipt.discount}%)`}
                        </span>
                      </p>
                      <p className="Receipt_Label" >
                        Payment method
                      </p>
                      {
                        this.props.receipt.reference_number && this.props.receipt.reference_number.length > 0 ?
                          <p className="Receipt_Label" >
                            Reference number
                          </p>
                          :
                          null
                      }

                    </Col>
                    <Col span={13} >
                      <p className="ReceiptfontDown" style={{ paddingTop: "5px" }}>
                        {this.renderPaymentFor()}
                      </p>
                      <p className="ReceiptfontDown">
                        {this.props.receipt.amount != undefined ? (
                          <span
                            style={{
                              fontFamily: "SF-Pro-Display-Bold",
                              fontSize: "18px",
                              color: "#5FB563",
                            }}
                          >
                            {formateNumber(this.props.receipt.amount.replace('KWD', ''))}
                          </span>) : null}
                        <span
                          style={{
                            fontFamily: "SF-Pro-Display-Regular",
                            fontSize: "16px",
                            color: "#B3B3B3",
                            paddingLeft: "3px",
                          }}
                        >
                          KWD
                        </span>
                      </p>
                      <p className="ReceiptfontDown" >
                        {this.props.receipt.payment_method}
                      </p>
                      <p className="ReceiptfontDown" >
                        {this.props.receipt.reference_number}
                      </p>
                    </Col>
                  </Row>
                }
              </Card>
              <span className={this.props.lang + "_record_payment_view"} onClick={() => this.openFile(this.props.receipt.documents && this.props.receipt.documents.length > 0 && this.props.receipt.documents[0].path)}>
                {this.props.receipt.documents && this.props.receipt.documents.length > 0 && this.props.receipt.documents[0].name}
              </span>
            </div>

          </div>
          <div style={{ marginTop: 30, marginBottom: 30 }}>
            <Row style={{ width: "100%" }}>
              <Col span={5} xs={24} sm={24} md={8} lg={5} xl={10}>
                <div>
                  <Button
                    className='export_pdf_btn'
                    onClick={this.exportToPdf}
                  >

                    Export PDF
                  </Button>
                </div>
              </Col>
              <Col xs={0} sm={0} md={1} lg={1} xl={4}></Col>
            </Row>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
    token: state.auth.token,
    receipt: state.receipt,
    selectedTab: state.selectedTab
  };
};
export default connect(mapStateToProps, null)(Receipt);
