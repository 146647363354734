import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from 'react-redux'


const RestrictedRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth.phone_number ?
          <Component {...props} />
         :
         <Redirect to="/subUserLogin" />
  
    }
  />
);

RestrictedRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(RestrictedRoute);