import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import inputTextcss from "../../UIKIT/InputText/inputText.module.css";
import React from "react";
import { Typography } from "antd";

class PhoneNumber extends React.Component {
  constructor(props) {
    super(props);
  }
  static defaultProps = {
    label: true,
    disabled: false,
    handleKeyPress: null,
    onEnterPress: null,
  };
  handleKeyPress = (event) => {
    if (event.key === "Enter" && this.props.onEnterPress != null) {
      this.props.onEnterPress();
    }
  };
  render() {
    return (
      <div className="inputContainerPhoneNumber" style={{ display: "relative" }}>
        {this.props.label ? (
          <Typography className={inputTextcss.labelForm}>
            Phone number
          </Typography>
        ) : null}

        {this.props.value && this.props.value.length < 4 ? (
          <Typography
            style={{
              position: "absolute",
              top: this.props.label ? "38px" : "34px",
              left: "110px",
              color: "#CCCCCC",
            }}
          >
            {this.props.placeholder}
          </Typography>
        ) : null}
        <PhoneInput
          disabled={this.props.disabled}
          country={"kw"}
          value={this.props.value}
          onChange={(phone_number,a,b,c) => this.props.onChange(phone_number,a,b,c)}
          enableSearch={true}
          disableSearchIcon={true}
          countryCodeEditable={false}
          searchPlaceholder='Search'
          placeholder=""
          inputStyle={{
            height: "0.5px",
            backgroundColor: "white",
            width: "100%",
            color: "#000",
          }}
          inputClass="Flaginput"
          onKeyDown={this.handleKeyPress}
        />
      </div>
    );
  }
}

export default PhoneNumber;
