import { LoadingOutlined } from '@ant-design/icons';
import { Avatar, Spin } from 'antd'
import React, { useState, useEffect } from 'react'
import { apiGetFile } from '../../Api'
import { imagepath } from '../../config/config'
const spinner = <LoadingOutlined style={{ fontSize: 24 }} spin />;
function File(props) {
    const [img, setImg] = useState(null)
    const [numberOfCalls, setNumberOfCalls] = useState(0)
    const getImg = async () => {
        const src = props.src.replace(imagepath, imagepath + "api/files/downloadFile/")
        const response = await apiGetFile({ url: src })
        if (response.status === 200) {
            setImg(response.url)
            setNumberOfCalls(0)
        } else {
            if (numberOfCalls < 5) {
                setNumberOfCalls(numberOfCalls => numberOfCalls + 1)
            } else {
                setNumberOfCalls(0)
            }
        }
    }
    useEffect(() => {
        if (props.src) {
            if (typeof props.src === "string" && props.src?.indexOf("http") !== -1) {
                getImg()
            } else {
                setImg(null)
            }
        } else {
            setImg(null)
        }
    }, [props.src])
    useEffect(() => {
        if (numberOfCalls) {
            setTimeout(() => {
                getImg()
            }, 1000)
        }
    }, [numberOfCalls])
    const className = ['flex-center']
    if (props.className) {
        className.push(props.className)
    }
    if (img) {
        if (props.isAvatar) {
            return (<Avatar size={props.size} src={img} />)
        }
        return (
            <img {...props} src={img} />
        )
    }
    return <div className={className.join(' ')} style={{ height: props.height, width: props.width }}><Spin indicator={spinner} /></div>
}

File.defaultProps = {
    className: '',
    src: '',
    onClick: undefined,
    alt: '',
    width: 'auto',
    height: 'auto',
    isAvatar: false,
    size: 99
}

export default File