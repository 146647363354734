import detectBrowserLanguage from "detect-browser-language";
import arDesign from "antd/es/locale/ar_EG";
import enDesign from "antd/es/locale/en_US";
import translator from "../lang/translator";

// export const apiUrl = "https://back-tenantive.azurewebsites.net/"
// export const apiUrl = "https://pre-prod-backend-tenantive.azurewebsites.net/";
// export const apiUrl = "https://back.tenantive.com/";
// export const apiUrl = process.env.REACT_APP_API_URL
// export const apiUrl = "http://localhost:8000/";
export const apiUrl = "https://tenantive.bk.dev.piximind.com/";

// export const imagepath = "https://pre-prod-backend-tenantive.azurewebsites.net/";
//export const imagepath = "http://localhost:8000/";
// export const imagepath = "https://back.tenantive.com/";
//export const imagepath = "https://dev.back.tenantive.com/";
export const imagepath = process.env.REACT_APP_IMAGE_PATH


export function getlang(propsLang, dispatch) {
  let ln = propsLang;
  if (!propsLang) {
    let lang = detectBrowserLanguage();
    lang = lang.substring(0, 2);
    if (lang != "ar") {
      lang = "en";
    }
    let action = { type: "setLang", lang: lang };
    dispatch(action);
    ln = lang;
  }
  require("./../assets/" + ln + ".css");
  return translator(ln);
}
