import React, { Component } from 'react'
import { Button, Modal, Row, Col, Divider, Checkbox } from "antd";
import translator from '../../lang/translator';
import { connect } from 'react-redux';
import moment from 'moment'
import logo from "../../assets/images/LogoTenEn.svg";
import { apiUrl } from '../../config/config';
import { formateNumber } from '../helpers/Helper';
import { downloadFile } from '../../Api';

class ViewClaim extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            acknowledge: true,
            claim: {},
        };
        this.lang = translator(this.props.lang);
    }
    showModal = () => {
        this.setState({
            showModal: true
        })
    }
    closeModal = () => {
        this.setState({
            showModal: false
        })
    }
    onCheckAcknowledge = (e) => {
        this.setState({
            acknowledge: e.target.checked
        })
    }
    getClaimById = () => {
        fetch(apiUrl + "api/admin/viewClaim/" + this.props.id, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.auth.token,
            },
        })
            .then((response) => {
                response.json().then((data) => {
                    if (data.status === 200) {
                        this.setState({
                            claim: data.claimView
                        })
                    }
                });
            })
            .catch((err) => {
                console.log("error ", err);
            });
    }
    componentDidMount() {
        this.getClaimById()
    }
    exportClaim = () => {
        let newWindow = window.open()
        fetch(apiUrl + `api/claimExportPdf/${this.state.claim._id}?lang=EN`, {
            method: "GET",
            mode: "cors",
            headers: { Authorization: "Bearer " + this.props.auth.token }
        }).then(response => {
            response.json().then(data => {
                if (data.status === 200) {
                    downloadFile({ path: data.link, type: "pdf", window: newWindow })
                }
            }
            );
        }).catch(err => { });
    }
    render() {

        return (
            <div>
                <Button
                    onClick={() => this.showModal()}
                    className={this.props.lang + "_view_claims_btn"}
                >
                    {this.lang.viewClaim}
                </Button>
                {this.state.showModal && <Modal
                    visible={this.state.showModal}
                    centered
                    onCancel={() => this.closeModal()}
                    footer={null}
                    closable={false}
                    className={this.props.lang + "_view_claim_modal"}>
                    <Row>
                        <Col span={14} >
                            <span className={this.props.lang + "_claims_receipt_title"}>
                                {
                                    this.props.type && this.props.type === "general" ?
                                        this.lang.claimsGeneral
                                        :
                                        this.lang.claimsPayment
                                }
                            </span>
                            <p className={this.props.lang + "_claims_receipt_date"}>
                                {moment(this.state.claim.createdAt).format("DD/MM/YYYY")}
                            </p>
                        </Col>
                        <Col span={10} >
                            <img
                                className={this.props.lang + "_claims_receipt_logo"}
                                src={logo}
                                alt="Logo"
                            />
                        </Col>
                    </Row>
                    <Row className={this.props.lang + "_claims_receipt_content"}>
                        <Col span={24}>
                            <span className={this.props.lang + "_claims_receipt_header"}>
                                {this.lang.CustomerInformation}
                            </span>
                        </Col>
                        <Col span={24}>
                            <Divider className={this.props.lang + "_claims_receipt_divider"} />
                        </Col>
                        <Col span={10} className={this.props.lang + "_claims_receipt_key"}>
                            {this.lang.Name}
                        </Col>
                        <Col span={14} className={this.props.lang + "_claims_receipt_value"}>
                            {this.props.userType && this.props.userType === "Landlords" ?
                                this.state.claim.landlord && this.state.claim.landlord !== "null" ?
                                    this.state.claim.landlord.full_name
                                    :
                                    this.state.claim.subuser.full_name
                                :
                                this.state.claim.tenant.full_name
                            }
                        </Col>
                        <Col span={10} className={this.props.lang + "_claims_receipt_key"}>
                            {this.lang.mobileNumber}
                        </Col>
                        <Col span={14} className={this.props.lang + "_claims_receipt_value"}>
                            {this.props.userType && this.props.userType === "Landlords" ?
                                this.state.claim.landlord && this.state.claim.landlord !== "null" ?
                                    this.state.claim.landlord.phone_number
                                    :
                                    this.state.claim.subuser.phone_number
                                :
                                this.state.claim.tenant.phone_number
                            }
                        </Col>
                        <Col span={10} className={this.props.lang + "_claims_receipt_key"}>
                            {this.lang.email}
                        </Col>
                        <Col span={14} className={this.props.lang + "_claims_receipt_value"}>
                            {this.props.userType && this.props.userType === "Landlords" ?
                                this.state.claim.landlord && this.state.claim.landlord !== "null" ?
                                    this.state.claim.landlord.email
                                    :
                                    this.state.claim.subuser.email
                                :
                                this.state.claim.tenant.email
                            }
                        </Col>
                        {
                            this.props.userType && this.props.userType === "Landlords" &&
                            this.state.claim.subuser &&
                            <>
                                <Col span={10} className={this.props.lang + "_claims_receipt_key"}>
                                    {this.lang.onBehalfLandlord}
                                </Col>
                                <Col span={14} className={this.props.lang + "_claims_receipt_value"}>
                                    {this.state.claim.subuser.onBehalf}
                                </Col>
                            </>
                        }
                        <Col span={24}>
                            <span className={this.props.lang + "_claims_receipt_header"}>
                                {this.lang.issueInfo}
                            </span>
                        </Col>
                        <Col span={24}>
                            <Divider className={this.props.lang + "_claims_receipt_divider"} />
                        </Col>
                        {
                            this.props.userType && this.props.userType === "Landlords" ?
                                <>
                                    <Col span={10} className={this.props.lang + "_claims_receipt_key"}>
                                        {
                                            this.props.type && this.props.type === "payment" ?
                                                this.lang.Tenant
                                                :
                                                null
                                        }
                                    </Col>
                                    <Col span={14} className={this.props.lang + "_claims_receipt_value"}>
                                        {
                                            this.props.type && this.props.type === "payment" ?
                                                this.state.claim.tenant.full_name
                                                :
                                                null
                                        }
                                    </Col>
                                </>
                                :
                                <>
                                    <Col span={10} className={this.props.lang + "_claims_receipt_key"}>
                                        {
                                            this.state.claim.subuser ?
                                                this.lang.claimsSubUser
                                                :
                                                this.lang.landlord
                                        }
                                    </Col>
                                    <Col span={14} className={this.props.lang + "_claims_receipt_value"}>
                                        {
                                            this.state.claim.subuser ?
                                                this.state.claim.subuser.full_name
                                                :
                                                this.state.claim.landlord.full_name
                                        }
                                    </Col>
                                </>
                        }
                        {
                            this.props.userType && this.props.userType === "Tenants" && this.state.claim.subuser &&
                            <>
                                <Col span={10} className={this.props.lang + "_claims_receipt_key"}>
                                    {this.lang.onBehalfLandlord}
                                </Col>
                                <Col span={14} className={this.props.lang + "_claims_receipt_value"}>
                                    {this.state.claim.subuser.onBehalf}
                                </Col>
                            </>
                        }
                        {
                            this.state.claim.property ?
                                <>
                                    <Col span={10} className={this.props.lang + "_claims_receipt_key"}>
                                        {this.lang.Property}
                                    </Col>
                                    <Col span={14} className={this.props.lang + "_claims_receipt_value"}>
                                        {this.state.claim.property.name}
                                    </Col>
                                </>
                                :
                                null
                        }
                        {
                            this.state.claim.unit ?
                                <>
                                    <Col span={10} className={this.props.lang + "_claims_receipt_key"}>
                                        {this.lang.Unit}
                                    </Col>
                                    <Col span={14} className={this.props.lang + "_claims_receipt_value"}>
                                        {this.state.claim.unit.unit_number}
                                    </Col>
                                </>
                                :
                                null
                        }
                        {
                            this.props.type && this.props.type === "payment" &&
                            <>
                                <Col span={10} className={this.props.lang + "_claims_receipt_key"}>
                                    {this.lang.receiptNumber}
                                </Col>
                                <Col span={14} className={this.props.lang + "_claims_receipt_value"}>
                                    {this.state.claim.receipt.receipt_number}
                                </Col>
                                <Col span={10} className={this.props.lang + "_claims_receipt_key"}>
                                    {this.lang.transactionId}
                                </Col>
                                <Col span={14} className={this.props.lang + "_claims_receipt_value"}>
                                    {this.state.claim.receipt.transaction_id}
                                </Col>
                                <Col span={10} className={this.props.lang + "_claims_receipt_key"}>
                                    {this.lang.transactionDate}
                                </Col>
                                <Col span={14} className={this.props.lang + "_claims_receipt_value"}>
                                    {moment(this.state.claim.receipt.transaction_date).format("DD MMM, YYYY")}
                                </Col>
                                <Col span={10} className={this.props.lang + "_claims_receipt_key"}>
                                    {this.lang.amountRequested}
                                </Col>
                                <Col span={14} className={this.props.lang + "_claims_receipt_value"}>
                                    {formateNumber(this.state.claim.refund) + this.lang.kwd}
                                </Col>
                            </>
                        }
                        <Col span={24} className={this.props.lang + "_claims_receipt_key"}>
                            {this.lang.complaint}
                        </Col>
                        <Col span={24} className={this.props.lang + "_claims_receipt_complaint"}>
                            {this.state.claim && this.state.claim.complaint}
                        </Col>
                        <Col span={24} className={this.props.lang + "_claims_receipt_key"}>
                            {this.state.claim && this.state.claim.documents.length > 0 ? this.lang.attachedDocs : null}
                        </Col>
                        <Col span={24} className={this.props.lang + "_claims_receipt_files"}>
                            {
                                this.state.claim && this.state.claim.documents.map(doc => {
                                    return (<span className={this.props.lang + "_claims_receipt_file_name"} onClick={() => this.props.viewFile(doc.path)}>
                                        {doc.name && doc.name.length > 17 ? "..." + doc.name.substr(doc.name.length - 14) : doc.name}
                                    </span>)
                                })
                            }
                        </Col>
                    </Row>
                    <Row className={this.props.lang + "_claims_receipt_footer"}>
                        <Col span={2}>
                            <Checkbox disabled checked={true}></Checkbox>
                        </Col>
                        <Col span={22} className={this.props.lang + "_claims_receipt_acknowledge"}>
                            {this.lang.acknowledge}
                        </Col>
                        <div className={this.props.lang + "_claims_receipt_btn_container"}>
                            <Button
                                className={this.props.lang + "_claims_receipt_export"}
                                onClick={() => this.exportClaim()}
                            >
                                {this.lang.exportPDF}
                            </Button>
                            <Button
                                className={this.props.lang + "_claims_receipt_cancel"}
                                onClick={() => this.closeModal()}
                            >
                                {this.lang.cancel}
                            </Button>
                        </div>
                    </Row>
                </Modal>
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        auth: state.auth
    };
};
export default connect(mapStateToProps, null)(ViewClaim);