import React, { Component, Fragment } from "react";
import { Input, Row, Col, notification, Select } from "antd";
import translator from "../../lang/translator";
import { connect } from "react-redux";
import { Pagination } from "antd";
import Approve from "../Landlords/ListBanks/Apporove";
import Reject from "../Landlords/ListBanks/Reject";
import { apiUrl } from "../../config/config"
import LoaderTenantive from "../../UIKIT/LoaderTenantive"
import Empty from "antd/es/empty";
import Arrow from "../../assets/icons/arrowdown.svg";
import redFlag from "../../assets/icons/redFlag.png";
import orangeFlag from "../../assets/icons/orangeFlag.png";
import greenFlag from "../../assets/icons/greenFlag.png";
import moment from 'moment'
import { downloadFile as download } from "../../Api";

var PAGES = 10;
const { Option } = Select;
const { Search } = Input;
class PendingProperties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            propertyList: [],
            LoadingTableData: false,
            activekey: [],
            nbrElements: 0,
            currentPage: 1,
            windowWidth: window.innerWidth,
        };
        this.lang = translator(this.props.lang);
    }
    onShow = (i) => {
        !this.state.activekey.includes(i) &&
            this.setState({
                activekey: [i],
            });
    };
    hide = (i) => {
        this.state.activekey.includes(i) &&
            this.setState({
                activekey: this.state.activekey.filter((el) => el !== i),
            });
    };
    GetPropertyList(page, search) {
        this.setState({
            LoadingTableData: true
        })
        fetch(apiUrl + "api/admin/searchPendingProperties?searchedProperty=" + search + "&page=" + page + "&per_page=10", {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.token,
            },
        })
            .then((response) => {
                response.json().then((data) => {
                    let action = {
                        type: "SET_PENDING_NOTIF",
                        totalPendingProperties: 0,
                        totalPendingClaims: this.props.auth.totalNotificationClaims,
                        totalPendingBanks: this.props.auth.totalNotificationPendingBanks
                    };
                    this.props.dispatch(action);
                    if (data.status === 200) {
                        this.setState({
                            propertyList: data.propertiesPaginate,
                            nbrElements: data.nbrElements,
                            LoadingTableData: false
                        })
                    } else {
                        this.setState({ LoadingTableData: false })
                    }
                });
            })
            .catch((err) => {
                console.log("error ", err);
            });
    }
    componentDidMount() {
        this.GetPropertyList(1, '')
        window.addEventListener("resize", () => {
            this.setState(() => {
                return {
                    windowWidth: window.innerWidth,
                };
            });
        });
    }
    pagination = (page, numberpage) => {
        this.GetPropertyList(page, this.state.search)
        this.setState({
            currentPage: page,
            activekey: []
        })
    }
    Action() {
        if (this.state.propertyList.length == 1 && this.state.currentPage > 1) {
            this.state.currentPage = this.state.currentPage - 1
            this.GetPropertyList(this.state.currentPage, this.state.search)
            this.openNotificationWithIcon('success')
        }
        else {
            this.GetPropertyList(this.state.currentPage, this.state.search)
            this.openNotificationWithIcon('success')
        }
    }
    openNotificationWithIcon = type => {
        notification[type]({
            message: 'Pending properties updated',
            duration: null,
            top: 125,
            className: this.props.lang + '_PendingBanknotification',
            placement: this.props.lang === "en" ? "topRight" : "topLeft"
        })
    };

    DownloadPropertyFiles = (x) => {

        fetch(apiUrl + "api/admin/downloadPropertyDocs/" + x, {
            method: "Get",
            mode: "cors",
            headers: {
                'Authorization': 'Bearer ' + this.props.token
            },
        })
            .then((response) => {
                if (response.status == 200) {
                    window.open(response.url)
                }
            })
            .catch(err => console.log("err", err));
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.search !== this.state.search) {
            this.GetPropertyList(this.state.currentPage, this.state.search)
        }
    }
    SearchProperties(value) {
        this.setState({ currentPage: 1, search: value })
    }
    calculateTotalDocs = (el) => {
        let total = 0;
        if (el.title_deed) {
            total++
        }
        if (el.civil_id) {
            total += el.civil_id.length
        }
        return total
    }
    showFile = (path) => {
        let newWindow = window.open()
        let link = path.replace("./public/", "")
        download({ path: link, type: "pdf", window: newWindow })
    }
    downloadFile = (path) => {
        download({ path })
    }

    renderDcoumentName(document) {
        if (this.state.windowWidth < 1600) {
            return document.name.length > 7 ? document.name.substr(0, 3) + "..." + document.name.substr(document.name.length - 3) : document.name
        } else {
            return document.name.length > 15 ? document.name.substr(0, 10) + "..." + document.name.substr(document.name.length - 3) : document.name
        }
    }
    render() {
        return (
            <div className={this.props.lang + "_accounting_transfer_bigContainer"}>
                <Row className={this.props.lang + "_row-transfers-btn"}>
                    <Col
                        span={5}
                        xs={24}
                        sm={24}
                        md={8}
                        lg={5}
                        xl={5}
                        style={{
                            height: "40px",
                            marginRight: 16
                        }}
                    >
                        <Search
                            allowClear={true}
                            style={{
                                height: "100% ",
                                direction: "ltr",
                            }}
                            placeholder={this.lang.Search}
                            onSearch={(value) => this.SearchProperties(value)}
                            enterButton
                            className='search-input'
                        />
                    </Col>
                </Row>
                <div className={this.props.lang + "_pending_banks_bigContainer"}>
                    <Row className={this.props.lang + "_connected_bank"}>
                        <Col
                            span={6}
                            xs={{ span: 6 }}
                            sm={{ span: 6 }}
                            md={{ span: 6 }}>
                            {this.lang.CivilID_TitleDeed}
                        </Col>
                        <Col
                            span={3}
                            xs={{ span: 3 }}
                            sm={{ span: 3 }}
                            md={{ span: 3 }}>
                            {this.lang.Property}
                        </Col>
                        <Col
                            span={4}
                            xs={{ span: 4 }}
                            sm={{ span: 4 }}
                            md={{ span: 4 }}>
                            {this.lang.landlord}
                        </Col>
                        <Col
                            span={5}
                            xs={{ span: 5 }}
                            sm={{ span: 5 }}
                            md={{ span: 5 }}>
                            {this.lang.iban_bank_name}
                        </Col>
                        <Col
                            span={5}
                            xs={{ span: 5 }}
                            sm={{ span: 5 }}
                            md={{ span: 5 }}>
                            {this.lang.BanklistAction}
                        </Col>
                        <Col span={1}>
                            {this.lang.kyc}
                        </Col>
                    </Row>
                    {
                        this.state.LoadingTableData ?
                            <LoaderTenantive />
                            :
                            this.state.propertyList.length == 0 ?
                                <div className="noData_Container_">
                                    <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    <p className='noData'>
                                        No Data !
                                    </p>
                                </div>
                                :
                                this.state.propertyList && this.state.propertyList.map((el, i) => {
                                    return (
                                        <Fragment>
                                            <Row className={this.props.lang + "_connected_bank_row"}>
                                                <Col id='start'
                                                    xs={{ span: 1, offset: 1 }}
                                                    sm={{ span: 1, offset: 1 }}
                                                    md={{ span: 1, offset: 1 }}
                                                >
                                                    <div className="downProp">
                                                        <div className="arrow-down" onClick={
                                                            !this.state.activekey.includes(i)
                                                                ? () => this.onShow(i)
                                                                : () => this.hide(i)
                                                        }>
                                                            <img className={!this.state.activekey.includes(i) ? 'arroww' : "arrowUP"}

                                                                src={Arrow}
                                                                alt="arrow"

                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col
                                                    span={4}
                                                    xs={{ span: 4 }}
                                                    sm={{ span: 4 }}
                                                    md={{ span: 4 }} className="totalDocs">
                                                    <div className="pending_banks_days_container">
                                                        <div>
                                                            <span>{this.calculateTotalDocs(el)}{this.lang.documents}</span>
                                                            <span
                                                                className={this.props.lang + "_BankdocsColumn"}
                                                                onClick={() => this.DownloadPropertyFiles(el.id)}
                                                            >
                                                                {this.lang.downloadAll}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="pending_banks_days">{el.pending_Property_date ? moment(el.pending_Property_date).format("DD MMM YYYY") : null}</span>
                                                            <span className="pending_banks_days ml-10">{el.pending_Property_date ? moment(el.pending_Property_date).fromNow() : null}</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col
                                                    span={3}
                                                    xs={{ span: 3 }}
                                                    sm={{ span: 3 }}
                                                    md={{ span: 3 }}>
                                                    <div className='column_propertyName'>
                                                        <span>{el.property}</span>
                                                    </div>
                                                </Col>
                                                <Col
                                                    span={4}
                                                    xs={{ span: 4 }}
                                                    sm={{ span: 4 }}
                                                    md={{ span: 4 }}>
                                                    <div className='column_propertyName'>
                                                        <span>{el.landlord}</span>
                                                    </div>
                                                </Col>
                                                <Col
                                                    span={5}
                                                    xs={{ span: 5 }}
                                                    sm={{ span: 5 }}
                                                    md={{ span: 5 }}>
                                                    <div className="bank_documents_date">
                                                        {el.iban}
                                                        <span className="connected_bank_name">{el.bank_name}</span>
                                                    </div>
                                                </Col>
                                                <Col
                                                    span={5}
                                                    xs={{ span: 5 }}
                                                    sm={{ span: 5 }}
                                                    md={{ span: 5 }}>
                                                    <div className={this.props.lang + "_btnAppReject"}>
                                                        <div className={this.props.lang + "_btn_column_action1"}>
                                                            <div className={this.props.lang + "_btn_column_action"}>
                                                                <Approve getBankTable={this.Action.bind(this)} type="properties" id={el.id} />
                                                            </div>
                                                        </div>

                                                        <div
                                                            className={this.props.lang + "_btn_column_action1"}>
                                                            <div className={this.props.lang + "_btn_column_action"}>
                                                                <Reject getBankTable={this.Action.bind(this)} type="properties" id={el.id} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col span={1}>
                                                    <img alt="" src={el.risk === 'high' ? redFlag : el.risk === 'medium' ? orangeFlag : greenFlag} />
                                                </Col>
                                                <Col
                                                    xs={{ span: 5, offset: 1 }}
                                                    sm={{ span: 5, offset: 1 }}
                                                    md={{ span: 5, offset: 1 }}
                                                    className={
                                                        this.state.activekey.includes(i)
                                                            ? this.props.lang + "_display_content"
                                                            : this.props.lang + "_hide_content"
                                                    }>
                                                    {
                                                        el.civil_id && el.civil_id.map(civil => {
                                                            return (
                                                                <div className="all_files full_width">
                                                                    <span className="file_name">
                                                                        {this.renderDcoumentName(civil)}
                                                                    </span>
                                                                    <span
                                                                        className={this.props.lang + "_BankdocsColumn"}
                                                                        onClick={() => this.downloadFile(civil.path)}
                                                                    >
                                                                        {this.lang.Download}
                                                                    </span>
                                                                    <div
                                                                        onClick={() => this.showFile(civil.path)}
                                                                        className={this.props.lang + "_btn-action-View"}
                                                                    >
                                                                        {this.lang.View}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        el.title_deed ?
                                                            <div className="all_files full_width">
                                                                <span className="file_name">
                                                                    {this.renderDcoumentName(el.title_deed)}
                                                                </span>
                                                                <span
                                                                    className={this.props.lang + "_BankdocsColumn"}
                                                                    onClick={() => this.downloadFile(el.title_deed.path)}
                                                                >
                                                                    {this.lang.Download}
                                                                </span>
                                                                <div
                                                                    onClick={() => this.showFile(el.title_deed.path)}
                                                                    className={this.props.lang + "_btn-action-View"}
                                                                >
                                                                    {this.lang.View}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </Col>
                                            </Row>
                                        </Fragment>
                                    )
                                })}
                    {
                        this.state.propertyList != 0 ?
                            <Pagination
                                current={this.state.currentPage}
                                defaultCurrent={1}
                                className="test"
                                pageSize={PAGES}
                                total={this.state.nbrElements}
                                showSizeChanger={false}
                                responsive={true}
                                onChange={this.pagination} /> : null
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        token: state.auth.token,
        auth: state.auth


    };
};
export default connect(mapStateToProps, null)(PendingProperties);





