import translator from "../../../lang/translator";
import { Col, Row, Select, DatePicker, Input } from "antd";
import React, { Component } from "react";
import FilterDate from "../../../assets/images/icon/Filters_Date.png";
import FilterDateAr from "../../../assets/images/icon/FilterDateAr.png";
import FilterSearchAr from "../../../assets/images/icon/FilterSearchAr.png";
import FIltersDropdown from "../../../assets/images/icon/FIlters - dropdown.png";
import { connect } from "react-redux";
import { PercentageOutlined } from "@ant-design/icons";
import moment from "moment";
import { apiUrl } from "../../../config/config";
const { RangePicker } = DatePicker;
const { Option } = Select;
class Discount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProperty: [],
            discountFor: "",
            percent: "",
            startDate: "",
            endDate: "",
            disabled: false,
            autoDate: false,
            errorMsg: "",
            dateError: "",
            clear: false,
            idSub: "",
            api: false,
            discountInf: "",
            update: false
        };
        this.lang = translator(this.props.lang);
        this.dateFormat = 'DD-MM-YYYY';
    }
    handleSelectChange = (value) => {
        if ((value.indexOf("All") > 0 && value.length > 1) || (value.indexOf("All") === 0 && value.length === 1) || !value.length) {
            this.setState({
                selectedProperty: ["All Propreties"],
            });
        } else {
            let propertyList = value.filter(el => el !== 'All Propreties')
            this.setState({
                selectedProperty: propertyList,
            });
        }
    };
    changeRangeDate = (rangeDate) => {
        this.setState({
            autoDate: false
        })
        if (rangeDate) {
            if (rangeDate[0] && !rangeDate[1]) {
                this.setState({
                    startDate: moment(rangeDate[0]).format("DD/MM/YYYY"),
                })
            }
            if ((!rangeDate[0] && rangeDate[1])) {
                this.setState({
                    endDate: moment(rangeDate[1]).format("DD/MM/YYYY"),
                })
            }
            if ((rangeDate[0] && rangeDate[1])) {
                this.setState({
                    startDate: moment(rangeDate[0]).format("DD/MM/YYYY"),
                    endDate: moment(rangeDate[1]).format("DD/MM/YYYY"),
                })
            }
        } else {
            this.setState({
                startDate: '',
                endDate: '',
                dateError: ''
            })

        }
    }
    handleSelectDiscountChange = (value) => {
        this.setState({
            discountFor: value,
            disabled: false,
            startDate: '',
            endDate: '',
            percent: ""
        });
        if (value === this.lang.Gold_subscription) {
            this.setState({
                disabled: true,
                selectedProperty: ["All Propreties"],
                autoDate: true,
                startDate: moment(new Date()).startOf('day').format("DD/MM/YYYY"),
                endDate: moment(new Date()).startOf('day').add(30, 'days').format("DD/MM/YYYY")
            })
        }
        if (value === this.lang.transaction_fees) {
            this.setState({
                disabled: false,
                selectedProperty: ["All Propreties"],
                autoDate: false,
            })
        }
    }
    disabledDate = current => {
        return current && current < moment().add(-1, 'days');
    };
    dateOfGoldSub = () => {
        const currentDate = moment(new Date()).startOf('day').format("DD/MM/YYYY")
        const afterMM = moment(new Date()).startOf('day').add(30, 'days').format("DD/MM/YYYY")
        return [currentDate, afterMM]
    }
    updateSubscriptionUser = () => {
        let validStartDate = this.state.startDate.substring(6, this.state.startDate.length) + "-" +
            this.state.startDate.substring(3, 5) + "-" +
            this.state.startDate.substring(0, 2)
        let validEndtDate = this.state.endDate.substring(6, this.state.endDate.length) + "-" +
            this.state.endDate.substring(3, 5) + "-" +
            this.state.endDate.substring(0, 2)
        const formData = new FormData();
        formData.append("percentage", this.state.percent)
        formData.append("period_from", validStartDate)
        formData.append("period_to", validEndtDate)
        formData.append("idUser", this.props.idLandlord);
        formData.append('idSubscription', this.state.idSub);
        if (this.state.percent === "100") {
            this.setState({
                discountInf: ""
            })
        }
        else {
            this.setState({
                discountInf: "true"
            })
        }
        fetch(apiUrl + `api/admin/updateDiscountSubscription`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.auth.token
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 200) {
                    this.setState({
                        clear: true,
                        update: false,
                        idSub: responseJson.result?.subscription.at(-1)._id
                    })
                }
            })
    }
    onSubmit = () => {
        let validStartDate = this.state.startDate.substring(6, this.state.startDate.length) + "-" +
            this.state.startDate.substring(3, 5) + "-" +
            this.state.startDate.substring(0, 2)
        let validEndtDate = this.state.endDate.substring(6, this.state.endDate.length) + "-" +
            this.state.endDate.substring(3, 5) + "-" +
            this.state.endDate.substring(0, 2)
        let date1 = moment(this.state.startDate, 'DD/MM/YYYY');
        let date2 = moment(this.state.endDate, 'DD/MM/YYYY');
        let daysDiff = date2.diff(date1, 'days');
        if (parseInt(this.state.percent) < 0 || parseInt(this.state.percent) > 100) {
            this.setState({
                errorMsg: "Percent should be between 0 and 100"
            })
        }
        else {
            const formData = new FormData();
            formData.append("idUser", this.props.idLandlord);
            formData.append("type", this.state.discountFor)
            if (this.state.selectedProperty[0] === "All Propreties") {
                this.props.properties.forEach(element => {
                    return formData.append("properties", element.property_id)
                });
            } else {
                this.state.selectedProperty.forEach(element => {
                    return formData.append("properties", element)
                });
            }
            formData.append("period_from", validStartDate)
            formData.append("period_to", validEndtDate)
            formData.append("percentage", this.state.percent)
            formData.append('idSubscription', this.state.idSub);
            if (this.state.percent === "100") {
                this.setState({
                    discountInf: ""
                })
            }
            else {
                this.setState({
                    discountInf: "true"
                })
            }
            if (daysDiff < 30 && this.lang.Gold_subscription === this.state.discountFor) {
                this.setState({
                    dateError: "The default statuts is one month at least"
                })
            }
            else {
                if (this.lang.Gold_subscription === this.state.discountFor) {
                    this.apiDiscount(formData, "addDiscountUserSubscription")
                }
            }
            if (this.lang.transaction_fees === this.state.discountFor) {
                this.apiDiscount(formData, "addDiscountToUser")
            }
        }
    }

    apiDiscount = (formData, url) => {
        fetch(apiUrl + `api/admin/${url}`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.auth.token
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 200) {
                    this.setState({
                        clear: true,
                        api: true
                    })
                    if (this.lang.Gold_subscription === this.state.discountFor) {
                        let arr = responseJson.result.subscription[responseJson.result.subscription?.length - 1]
                        if (responseJson.result) {
                            this.setState({
                                idSub: arr._id
                            })
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    onChangePercent = (e) => {
        if (parseInt(e.target.value) > 100) {
            this.setState({ percent: "100" })
            return
        }
        if (parseInt(e.target.value) < 0) {
            this.setState({ percent: "0" })
            return
        }
        if (0 <= parseInt(e.target.value) <= 100) {
            this.setState({ percent: e.target.value })
        }
    }
    getDiscount = () => {
        fetch(apiUrl + `api/admin/getDiscountUser?idLandlord=${this.props.idLandlord}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.auth.token
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 200) {
                    if (responseJson.discountType === "subscription") {
                        let subscription = responseJson.user[responseJson.user.length - 1]
                        if (responseJson.discountPercentSubscription) {
                            this.setState({
                                clear: true,
                                discountFor: this.lang.Gold_subscription,
                                percent: responseJson.discountPercentSubscription,
                                startDate: moment(new Date(responseJson.createdAt)).startOf('day').format("DD/MM/YYYY"),
                                endDate: moment(new Date(responseJson.expiry_Date)).startOf('day').format("DD/MM/YYYY"),
                                selectedProperty: ["All Propreties"],
                                disabled: true,
                                discountInf: "true",
                                idSub: subscription._id
                            })
                        }
                        else {
                            this.setState({
                                clear: true,
                                discountFor: this.lang.Gold_subscription,
                                percent: "100",
                                startDate: moment(new Date(subscription.createdAt)).startOf('day').format("DD/MM/YYYY"),
                                endDate: moment(new Date(subscription.expiry_Date)).startOf('day').format("DD/MM/YYYY"),
                                selectedProperty: ["All Propreties"],
                                disabled: true,
                                idSub: subscription._id,
                                discountInf: ""
                            })
                        }
                    }
                    else {
                        this.setState({
                            clear: true,
                            discountFor: responseJson.user[0].type,
                            percent: responseJson.user[0].percentage,
                            startDate: moment(new Date(responseJson.user[0].period_from)).startOf('day').format("DD/MM/YYYY"),
                            endDate: moment(new Date(responseJson.user[0].period_to)).startOf('day').format("DD/MM/YYYY"),
                            disabled: false,
                        })
                        responseJson.properties === responseJson.user[0].properties.length ?
                            this.handleSelectChange(["All"]) :
                            this.handleSelectChange(responseJson.user[0].properties)
                    }
                    this.setState({
                        api: true
                    })
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    deleteDiscount = () => {
        let api = ""
        if (this.lang.Gold_subscription === this.state.discountFor) {
            api = "deleteDiscountSubscription"
        }
        else {
            api = "deleteDiscountUser"
        }
        fetch(apiUrl + `api/admin/${api}?idLandlord=${this.props.idLandlord}&idSub=${this.state.idSub}&typeSubscription=${this.state.discountInf}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.auth.token
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 200) {
                    this.setState({
                        discountFor: "",
                        percent: "",
                        selectedProperty: [],
                        startDate: "",
                        endDate: "",
                        clear: false,
                        disabled: false,
                        autoDate: false
                    })
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    componentDidMount() {
        this.getDiscount()
    }
    /**
     * selectedProperty: [],
            discountFor: "",
            percent: "",
            startDate: "",
            endDate: "",
   */
    componentDidUpdate(prevProps, prevState) {
        if ((this.state.selectedProperty != prevState.selectedProperty || this.state.discountFor != prevState.discountFor ||
            this.state.percent != prevState.percent || this.state.startDate != prevState.startDate ||
            this.state.endDate != prevState.endDate) &&
            this.state.clear === true && this.state.api === true) {
            this.setState({
                clear: false,
                update: true
            })
        }
    }
    render() {
        console.log(this.state.idSub);
        return (
            <div className="add-discoutn-wrap">
                <Row>
                    <div class="discount">{this.lang.addDiscount}</div>
                </Row>
                <div className={`inputs-add-discount`}>
                    <Row className="discount-inputs">
                        <Col
                            md={{ span: 6, order: 1 }}
                            lg={{
                                span: 7,
                                order: 1,
                            }}
                            xl={{
                                span: 4,
                                order: 1,
                            }}
                            className="col-discount"
                        >
                            <div className={this.props.lang + "_discount-span"}><span>{this.lang.discount_for}</span></div>
                            <div className={this.props.lang + "_input-filter"}>
                                <Select
                                    dropdownStyle={{ borderRadius: "5px" }}
                                    showArrow={false}
                                    placeholder={this.lang.select}
                                    onChange={this.handleSelectDiscountChange}
                                    className={this.props.lang + "_ind"}
                                    value={this.state.discountFor}
                                >
                                    <Option
                                        value={

                                            this.lang.transaction_fees
                                        }
                                    >
                                        {" "}
                                        {
                                            this.lang.transaction_fees}
                                    </Option>
                                    <Option
                                        value={

                                            this.lang.Gold_subscription
                                        }
                                    >
                                        {" "}
                                        {
                                            this.lang.Gold_subscription}
                                    </Option>
                                </Select>
                                <img
                                    src={FIltersDropdown}
                                    className={this.props.lang + "_acount-rentl-img-select"}
                                />{" "}
                            </div>
                        </Col>

                        <Col
                            md={{ span: 6, order: 2 }}
                            lg={{
                                span: 7,
                                order: 2,
                            }}
                            xl={{
                                span: 6,
                                order: 2,
                            }}
                            className="col-discount"
                        >
                            <div className={this.props.lang + "_discount-span"}><span>{this.lang.Properties}</span></div>
                            <div className={this.props.lang + "_input-filter"}>
                                <Select
                                    disabled={this.state.disabled}
                                    required
                                    size="large"
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    placeholder={this.lang.select_property}
                                    onChange={this.handleSelectChange}
                                    value={this.state.selectedProperty}
                                    className={this.props.lang + "_ind"}
                                >
                                    <Option value={"All"}>{this.lang.all_properties}</Option>
                                    {this.props.properties && this.props.properties.map((el, index) => {
                                        return (
                                            <Option value={el.property_id} key={index}>
                                                {el.property_name}
                                            </Option>
                                        )
                                    }
                                    )}
                                </Select>
                                <img
                                    src={FIltersDropdown}
                                    className={this.props.lang + "_acount-rentl-img-select"}
                                />{" "}
                            </div>
                        </Col>

                        <Col
                            md={{ span: 8, order: 3 }}
                            lg={{
                                span: 6,
                                order: 3,
                            }}
                            xl={{
                                span: 6,
                                order: 3,
                            }}
                            className="col-discount"
                        >
                            <div className={this.props.lang + "_discount-span"}><span>{this.lang.period}</span></div>
                            <div className="disc-date-img">
                                <RangePicker
                                    style={{ width: '100%' }}
                                    allowEmpty={[true, true]}
                                    allowClear={[true, true]}
                                    separator="-"
                                    format="DD/MM/YYYY"
                                    suffixIcon={false}
                                    className={this.props.lang + "_ind2"}
                                    placeholder={[this.lang.select_period,]}
                                    onChange={this.changeRangeDate}
                                    value={
                                        this.state.autoDate ?
                                            [moment(this.dateOfGoldSub()[0], this.dateFormat), moment(this.dateOfGoldSub()[1], this.dateFormat)] :
                                            this.state.startDate === "" && this.state.endDate === "" ?
                                                [,] :
                                                this.state.startDate !== "" && this.state.endDate === "" ?
                                                    [moment(this.state.startDate, this.dateFormat),] :
                                                    this.state.startDate === "" && this.state.endDate !== "" ?
                                                        [, moment(this.state.endDate, this.dateFormat)] :
                                                        [moment(this.state.startDate, this.dateFormat), moment(this.state.endDate, this.dateFormat)]
                                    }
                                    disabledDate={this.disabledDate}
                                />

                                <img
                                    src={this.props.lang === "ar" ? FilterDateAr : FilterDate}
                                    className={this.props.lang + "_img-date-ico"}
                                />
                            </div>
                            {(this.state.dateError !== "" && this.state.discountFor === this.lang.Gold_subscription) &&
                                <div className="error-msg-percent">{this.state.dateError}</div>}
                        </Col>

                        <Col
                            lg={{ span: 4 }}
                            xl={{
                                span: 4,
                                order: 4,
                            }}
                            className="col-discount"
                        >
                            <div className={this.props.lang + "_discount-span"}><span>{this.lang.percentage}</span></div>
                            <div className="">
                                <Input
                                    allowClear={true}
                                    min={0}
                                    max={100}
                                    inputMode="numeric"
                                    maxLength={2}
                                    type="number"
                                    className={this.props.lang + "_ind discount-percent"}
                                    onChange={this.onChangePercent}
                                    suffix={<PercentageOutlined className="percent-icon" />}
                                    value={this.state.percent}
                                ></Input>

                            </div>
                        </Col>
                    </Row>
                    <div className="discount-btn">
                        {
                            this.state.clear ?
                                <button className="delete-discount" onClick={this.deleteDiscount}>Clear</button> :
                                <button className="delete-discount-disabled">Clear</button>
                        }
                        {
                            (this.state.selectedProperty.length !== 0 && this.state.discountFor !== "" && this.state.percent !== "" &&
                                this.state.endDate && this.state.startDate && this.state.clear !== true) ?
                                <div
                                    onClick={this.state.update === true && this.state.discountFor === this.lang.Gold_subscription ?
                                        this.updateSubscriptionUser : this.onSubmit}
                                    className={this.props.lang + "_btn-rental"}
                                >
                                    {this.lang.validate_discount}
                                </div> :
                                <div
                                    className="discount-disable-btn"
                                >
                                    {this.lang.validate_discount}
                                </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(Discount);