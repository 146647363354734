// import React from "react";
// import { Route, Redirect } from "react-router-dom";
// import { connect } from 'react-redux'
// class PrivateRoute extends React.Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             loaded: false,
//             verified: true
//         }

//     }

//     componentDidMount() {

//         this.setState({ loaded: true })

//         if (this.props.state.user) {
//             this.setState({
//                 loaded: true,
//                 //verified:this.props.state.user.verified
//             })
//         } else {
//             this.setState({
//                 loaded: true,
//                 //verified:false
//             })
//         }

//     }

//     render() {

//         if (this.state.loaded) {
//             if (this.props.auth.token !== null && this.props.auth.user && this.props.auth.user.verified) {
//                 return this.rendered()
//             } else if (this.props.auth.token !== null && !this.props.auth.user) {
//                 return this.verifivationRedirect()
//             } else {
//                 return this.notauthorizedRedirect()
//             }
//         } else {
//             return null
//         }
//     }

//     verifivationRedirect() {
//         return (
//             <Redirect to='/verification' />
//         )
//     }
//     notauthorizedRedirect() {
//         return (
//             <Redirect to='/NotAutherized' />
//         )
//     }

//     rendered() {

//         return (
//             <Route
//                 component={this.props.component}

//             />
//         );
//     }

// };

// const mapStateToProps = (state) => {
//     return {
//         auth: state.auth
//     }
// }

// export default connect(mapStateToProps, null)(PrivateRoute);

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import NavBar from "../Components/dashboard/NavBar";

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth.token && auth.admin ? (
        <div>
          <NavBar />
          <Component {...props} />
        </div>
      ) : auth.token && !auth.admin ? (
        <Redirect to="/login" />
      ) : !auth.token ? (
        <Redirect to="/login" />
      ) : (
        <Redirect to="/NotAutherized" />
      )
    }
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
