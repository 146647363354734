import React from "react";
import { Row, Col, Modal, Checkbox, Input, notification, Switch } from "antd";
import ButtonUtil from "./utils/Button";
import checkEnabled from "../../../assets/Property/checkEnabled.svg";
import phoneIcon from "../../../assets/icons/phoneIcon.png";
import tickIcon from "../../../assets/icons/tickIcon.png";
import profileIcon from "../../../assets/icons/profileIcon.png";
import { apiUrl } from "../../../config/config";
import ModalUikit from "./utils/modal/ModalUikit";
import Search from "../../../assets/icons/Search.svg";
import { connect } from "react-redux";
import TextInpu from "../../../UIKIT/TextInp/index";
import translator from "../../../lang/translator";
import _ from 'lodash';
class SubusersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ActiveUser: true,
      PendingUser: false,
      visible2: false,
      visible1: false,
      alllandloard: [],
      access: ["Accept bank account", "Add transfers"],
      test: false,
      activeSubuserData: [],
      pendingSubuserData: [],
      objectEditselected: null,
      dataselected: [],
      landloards: [],
      visible: false,
      searchword: "",
      pendingUserid: "",
      load: false,
      load2: false,
      message: "",
      selectedLanlordsId: [],
      isSubAdmin: ""
    };
    this.lang = translator(this.props.lang);
  }

  componentDidMount() {
    var url1 = new URL(apiUrl + "api/admin/getListLandlords");
    var url2 = new URL(apiUrl + "api/admin/getActiveSubList");
    var url3 = new URL(apiUrl + "api/admin/getPendingSubList");
    Promise.all([
      fetch(url1, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + this.props.auth.token,
        },
      }),
      fetch(url2, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + this.props.auth.token,
        },
      }),
      fetch(url3, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + this.props.auth.token,
        },
      }),
    ])
      .then(([res1, res2, res3]) => {
        Promise.all([res1.json(), res2.json(), res3.json()])
          .then(([data1, data2, data3]) => {
            if (
              data1.status === 200 &&
              data2.status === 200 &&
              data3.status === 200
            ) {

              this.setState({
                landloards: data1.users,
                activeSubuserData: data2.activeSubusers,
                pendingSubuserData: data3.pendingSubusers,
              });
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log("erreur fetch", err));
  }

  editUserClicked(object) {
    fetch(apiUrl + 'api/admin/getSubuserById/' + object.id, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            if (data.status === 200) {
              this.setState({
                isSubAdmin: object.id,
                selectedLanlordsId: [...this.state.selectedLanlordsId, ...data.subuser.landlords.map(element => element._id)],
                visible: true,
                objectEditselected: data.subuser,
                dataselected: this.state.landloards
                  .filter((el) => {
                    return el.full_name.toUpperCase().includes(this.state.searchword.trim());
                  })
                  .map((object) => object.id),
              });
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log("erreur fetch", err));

  }
  checkboxChanged = (checkedValue) => {
    this.setState({
      access: checkedValue,
    });
  };
  searchButton = (value) => {
    this.setState({
      searchword: value.toUpperCase(),
      alllandloard: [],
      dataselected: [],
      test: false,
    });
  };
  openNotificationWithIcon = (type, text) => {
    notification[type]({
      message: <p className={this.props.lang + "_bank-note-txt"}>{text}</p>,
      duration: null,
      top: 125,
      className: this.props.lang + "_bank-added-notification",
      icon: (
        <img
          className={this.props.lang + "_bank-added-icon"}
          src={checkEnabled}
          alt=""
        />
      ),
      placement: this.props.lang === "en" ? "topRight" : "topLeft",
    });
  };
  saveclicked = () => {
    if (this.state.access.length === 0) {
      this.setState({
        message: "One permission should at least be selected ",
      });
    } else {
      var url = new URL(apiUrl + "api/admin/editSubuser");
      const form = new FormData();
      form.append("subuserId", this.state.objectEditselected._id);
      form.append("full_name", this.state.objectEditselected.full_name);
      this.state.access &&
        this.state.access.forEach((el) => {
          form.append("user_permissions", el);
        });
      this.state.selectedLanlordsId &&
        this.state.selectedLanlordsId.forEach((el) => {
          form.append("landlords", el);
        });
      form.append("view_only", this.state.objectEditselected.view_only);
      form.append("phone_number", this.state.objectEditselected.phone_number);
      fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + this.props.auth.token,
        },
        body: form,
      })
        .then((response) => {
          response
            .json()
            .then((data) => {
              if (data.status === 200) {
                this.setState({
                  visible: false,
                  searchword: "",
                  load2: true,
                  dataselected: [],
                  selectedLanlordsId: []
                });
              }
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log("erreur fetch", err));
    }
  };
  onChangeViewOnly = (checked) => {
    this.setState({
      objectEditselected: {
        ...this.state.objectEditselected,
        view_only: checked,
      }
    })
  }

  componentDidUpdate() {
    if (this.state.load || this.state.load2) {
      var url1 = new URL(apiUrl + "api/admin/getListLandlords");
      var url2 = new URL(apiUrl + "api/admin/getActiveSubList");
      var url3 = new URL(apiUrl + "api/admin/getPendingSubList");
      Promise.all([
        fetch(url1, {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + this.props.auth.token,
          },
        }),
        fetch(url2, {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + this.props.auth.token,
          },
        }),
        fetch(url3, {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + this.props.auth.token,
          },
        }),
      ])
        .then(([res1, res2, res3]) => {
          Promise.all([res1.json(), res2.json(), res3.json()])
            .then(([data1, data2, data3]) => {
              if (
                data1.status === 200 &&
                data2.status === 200 &&
                data3.status === 200
              ) {
                this.setState({
                  landloards: data1.users,
                  activeSubuserData: data2.activeSubusers,
                  pendingSubuserData: data3.pendingSubusers,
                  load: false,
                  load2: false,
                });
              }
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log("erreur fetch", err));
    }
  }
  checkboxlandloardChanged = (value) => {
    console.log('ffffffffff',value);
    let seachedLandlord = this.state.landloards.filter((el) => {
      return el.full_name.toUpperCase().includes(this.state.searchword.trim())
    })
    console.log('seachedLandlord',seachedLandlord);
    if (value.length > 0) {
      return this.setState({
        alllandloard: value,
        dataselected: seachedLandlord.map(el => el._id),
        selectedLanlordsId: seachedLandlord.map(el => el._id)
      })
    } else {
      return this.setState({
        alllandloard: value,
        selectedLanlordsId: [],
        dataselected: [],
      })
    }

  };
  landloardschange = (id) => {
    let index = this.state.selectedLanlordsId.indexOf(id)
    if (index !== -1) {
      _.pullAt(this.state.selectedLanlordsId, index)
      this.setState({
        selectedLanlordsId: this.state.selectedLanlordsId

      })
    } else {
      this.setState({
        selectedLanlordsId: [...this.state.selectedLanlordsId, id]
      })
    }
  }
  confirmCancel = () => {
    var url = new URL(apiUrl + "api/admin/SubusersCancelInvitation");
    const form = new FormData();
    form.append("id_subuser", this.state.pendingUserid);
    this.state.pendingUserid
      ? fetch(url, {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + this.props.auth.token,
        },
        body: form,
      })
        .then((response) => {
          response
            .json()
            .then((data) => {
              if (data.status === 200) {
                this.setState({
                  pendingUserid: "",
                  visible1: false,
                  load: true,
                });
                this.openNotificationWithIcon(
                  "success",
                  "Invitation has been canceled"
                );
              }
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log("erreur fetch", err))
      : this.setState({ visible1: false });
  };
  deleteuserClicked = () => {
    var url = new URL(
      apiUrl + "api/admin/deleteSubuser/" + this.state.isSubAdmin
    );

    fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            if (data.status === 200) {
              this.setState({ visible2: false, load: true });
              this.openNotificationWithIcon("success", "User has been deleted");
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log("erreur fetch", err));
  };
  cancelEditUserModal = () => {
    this.setState({
      visible: false,
      searchword: "",
      dataselected: [],
      selectedLanlordsId: []
    })
  }
  renderLabel = (name, number) => {
    return (
      <div className="edit_subuser_checkbox">
        <div className="subuserList_left0">
          {name}
        </div>
        <div className="subuserList_right0">
          {number}
        </div>
      </div>
    )
  }
  render() {
    let boxClassSubUerActive = ["SubusersList_activeUer"];
    let boxClassSubUerPending = ["SubusersList_pendingUer"];
    if (this.state.ActiveUser) {
      boxClassSubUerActive.push("SubusersListActive");
    }
    if (this.state.PendingUser) {
      boxClassSubUerPending.push("SubusersListActive");
    }
    return (
      <>
        <div className="coverSusUserList">
          <div className="subUserListContainer">
            <ButtonUtil
              text={this.lang.invite_a_user}
              backgroundColor="#E6E6E6"
              textColor="#4D4D4D"
              width="30%"
              onClick={() => (this.props.history.push(`/settings/subUserInvite`))}
            />
            <div className="subUserSelection">
              <div
                className={boxClassSubUerActive.join(" ")}
                onClick={() =>
                  this.setState({ ActiveUser: true, PendingUser: false })
                }
              >
                {this.lang.active_users}
              </div>
              <div
                className={boxClassSubUerPending.join(" ")}
                onClick={() =>
                  this.setState({ ActiveUser: false, PendingUser: true })
                }
              >
                {this.lang.pending_users}
              </div>
            </div>
            {this.state.ActiveUser
              ? this.state.activeSubuserData &&
              this.state.activeSubuserData.map((el, index) => {
                return (
                  <Row
                    className="FontSubs"
                    key={index}
                    style={{ width: "100%", backgroundColor: "#fff" }}
                  >
                    <Col
                      xs={24}
                      md={24}
                      lg={12}
                      xl={7}
                      className="subUserList_datacontainer"
                    >
                      <div style={{ width: "100%" }}>
                        <img
                          src={profileIcon}
                          className="SubuserList_icons"
                        />
                        <span className="SubusersList_info">
                          {el.full_name}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={24}
                      md={24}
                      lg={12}
                      xl={6}
                      className="subUserList_datacontainer"
                    >
                      <div style={{ width: "100%" }}>
                        <img src={phoneIcon} className="SubuserList_icons" />
                        <span className="SubusersList_info">
                          {el.phone_number}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={24}
                      md={24}
                      lg={12}
                      xl={6}
                      className="subUserList_datacontainer"
                    >
                      <div style={{ width: "100%" }}>
                        <img src={tickIcon} className="SubuserList_icons" />
                        <span className="SubusersList_info">
                          {el.user_permissions.length === 5
                            ? "Full access"
                            : el.user_permissions[0]}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={24}
                      md={24}
                      lg={12}
                      xl={4}
                      className="subUserList_datacontainer"
                    >
                      <div>
                        <div
                          style={{ justifySelf: "center", width: "130px" }}
                        >
                          <ButtonUtil
                            text={this.lang.edit_user}
                            style={{
                              fontFamily: "SF-Pro-Display-Semibold",
                              fontSize: "14px",
                            }}
                            backgroundColor="#E6E6E6"
                            textColor="#4D4D4D"
                            width="100%"
                            onClick={() => this.editUserClicked(el)}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                );
              })
              : this.state.pendingSubuserData &&
              this.state.pendingSubuserData.map((el, index) => {
                return (
                  <Row className="FontSubs" key={index}>
                    <Col
                      xs={24}
                      md={24}
                      lg={12}
                      xl={6}
                      className="subUserList_datacontainer"
                    >
                      <div
                        style={{
                          width: "100%",
                          fontFamily: "SF-Pro-Display-Medium",
                          fontSize: "16px",
                        }}
                      >
                        <img
                          src={profileIcon}
                          className="SubuserList_icons"
                        />
                        <span className="SubusersList_info">
                          {el.full_name}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={24}
                      md={24}
                      lg={12}
                      xl={6}
                      className="subUserList_datacontainer"
                    >
                      <div style={{ width: "100%" }}>
                        <img src={phoneIcon} className="SubuserList_icons" />
                        <span className="SubusersList_info">
                          {el.phone_number}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={24}
                      md={24}
                      lg={12}
                      xl={6}
                      className="subUserList_datacontainer"
                    >
                      <div style={{ width: "100%" }}>
                        <img src={tickIcon} className="SubuserList_icons" />
                        <span className="SubusersList_info">
                          {el.user_permissions.length === 5
                            ? "full access"
                            : el.user_permissions[0]}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={24}
                      md={24}
                      lg={12}
                      xl={6}
                      className="subUserList_datacontainer"
                    >
                      <div>
                        <div
                          style={{
                            justifySelf: "end",
                            width: "80%",
                            fontFamily: "SF-Pro-Display-Semibold",
                            fontSize: "14px",
                          }}
                        >
                          <ButtonUtil
                            text="Cancel invitation"
                            backgroundColor="#E6E6E6"
                            textColor="#4D4D4D"
                            width="100%"
                            onClick={() => {
                              this.setState({
                                visible1: true,
                                pendingUserid: el.id,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                );
              })}
          </div>
          <ModalUikit
            VisibleModal={this.state.visible2}
            modalStyle="en_SubUserListModel"
            closableButtonModal={false}
            contentText="Sure you want delete user?"
            unitNumberStyle={{ paddingLeft: "4px" }}
            contentTextStyle={"en" + "_contentTextStyle"}
            buttonModalStyle={"en" + "_buttonModalStyle"}
            confirmButton={
              <ButtonUtil
                text="Delete"
                backgroundColor="#CB3D3D"
                textColor="#FFFFFF"
                width="45%"
                onClick={this.deleteuserClicked}
              />
            }
            cancelButton={
              <ButtonUtil
                text="Cancel"
                backgroundColor="#E6E6E6"
                textColor="#4D4D4D"
                width="45%"
                onClick={() => this.setState({ visible2: false })}
              />
            }
          />
          <ModalUikit
            VisibleModal={this.state.visible1}
            modalStyle="en_SubUserListModel"
            closableButtonModal={false}
            contentText="Sure you want to cancel invitation?"
            unitNumberStyle={{ paddingLeft: "4px" }}
            contentTextStyle={"en" + "_contentTextStyle"}
            buttonModalStyle={"en" + "_buttonModalStyle"}
            confirmButton={
              <ButtonUtil
                text="Cancel invitation"
                backgroundColor="#27AAD8"
                textColor="#FFFFFF"
                fontSizeText="13px"
                width="45%"
                onClick={this.confirmCancel}
              />
            }
            cancelButton={
              <ButtonUtil
                text="Cancel"
                backgroundColor="#E6E6E6"
                textColor="#4D4D4D"
                width="45%"
                onClick={() => this.setState({ visible1: false })}
              />
            }
          />
          <Modal
            centered
            footer={null}
            closable={false}
            visible={this.state.visible}
            width="807px"
            className='modal_edit_subUser'
          >
            <div
              style={{
                position: "relative",
                height: "22px",
                marginBottom: "30px",
              }}
            >
              <div className="subuserList_left0 subuserList_edituserinfo">
                Edit User
              </div>
              <div
                className="subuserList_right0 subuserList_deleteuserinfo"
                onClick={() => {
                  this.setState({ visible: false, visible2: true });
                }}
              >
                Delete user
              </div>
            </div>
            <Row gutter={70}>
              <Col span={10}>
                <div style={{ marginBottom: "20px" }}>
                  <TextInpu
                    className={this.props.lang + "_EditPropertyInputText"}
                    onChange={(e) =>
                      this.setState({
                        objectEditselected: {
                          ...this.state.objectEditselected,
                          full_name: e.target.value,
                        },
                      })
                    }
                    placeholder={null}
                    label="Full name"
                    errorMessage={null}
                    valid={null}
                    value={
                      this.state.objectEditselected &&
                      this.state.objectEditselected.full_name
                    }
                    type="text"
                  />
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <TextInpu
                    className={this.props.lang + "_EditPropertyInputText"}
                    onChange={(e) =>
                      this.setState({
                        objectEditselected: {
                          ...this.state.objectEditselected,
                          phone_number:
                            e.target.value && e.target.value[0] === "+"
                              ? e.target.value
                              : `+${e.target.value}`,
                        },
                      })
                    }
                    placeholder={null}
                    label="Phone Number"
                    errorMessage={null}
                    valid={null}
                    value={
                      this.state.objectEditselected &&
                      this.state.objectEditselected.phone_number
                    }
                    type="text"
                  />
                </div>

                <hr
                  style={{ border: "1px solid #CCC", marginBottom: "20px" }}
                />
                <div className="subuserList_userpermissionsinfo">
                  User permissions
                </div>
                <div className="subuser_view_only">
                  <span>{this.lang.viewOnly}</span>
                  <Switch checked={this.state.objectEditselected && this.state.objectEditselected.view_only} onChange={this.onChangeViewOnly} />
                </div>
                <Checkbox.Group
                  className={"en_EditUser_checkbox"}
                  onChange={this.checkboxChanged}
                  style={{ width: "100%" }}
                  defaultValue={
                    this.state.objectEditselected &&
                    this.state.objectEditselected.user_permissions
                  }
                >
                  <Row>
                    <Checkbox value="Accept bank account">
                      Accept bank account
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="Add transfers">Add transfers</Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="Messenger">{this.lang.messenger}</Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="Claims">{this.lang.claims}</Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="KYC">{this.lang.kyc}</Checkbox>
                  </Row>
                </Checkbox.Group>
              </Col>
              <Col span={14}>
                <div style={{ position: "relative", height: "22px" }}>
                  <div className="subuserList_left0 subuserList_landloardinfo ">
                    Landlords
                  </div>
                  <div>
                    <Checkbox.Group
                      onChange={this.checkboxlandloardChanged}
                      value={this.state.alllandloard}
                      className={this.state.selectedLanlordsId.length > 0 && this.state.selectedLanlordsId.length < this.state.landloards.length ? "subuserList_right0 EditLandlords_semi" : "subuserList_right0"}
                    >
                      <Row>
                        <Checkbox value="all">All landlords</Checkbox>
                      </Row>
                    </Checkbox.Group>
                  </div>
                </div>
                <div className="subuserList_searchinput">
                  <Input
                    allowClear={true}
                    type="textarea"
                    placeholder="Search landloards"
                    onChange={(value) => this.searchButton(value.target.value)}
                    addonBefore={
                      <div style={{ cursor: "pointer" }}>
                        <img src={Search} />
                      </div>
                    }
                    className={"en_subuserListaddProp_search"}
                  />
                </div>

                <div className="subuserList_containerCheckbox">
                  <div className="subuserList_landloardcheckbox">
                    {
                      this.state.landloards.map(el => {
                        return <Checkbox onChange={() => this.landloardschange(el._id)} checked={this.state.selectedLanlordsId.includes(el._id)} defaultChecked={this.state.selectedLanlordsId.includes(el._id)} value={el.id} style={el.full_name.toUpperCase().includes(this.state.searchword) ? { 'display': '' } : { 'display': 'none' }}>
                          {this.renderLabel(el.full_name, el.phone_number)}
                        </Checkbox>
                      }
                      )
                    }
                  </div>
                </div>
              </Col>
            </Row>
            {
              <span
                style={{
                  color: "#CB3D3D",
                  fontFamily: "SF-Pro-Display-Medium",
                  fontSize: "14px",
                }}
              >
                {this.state.message}
              </span>
            }
            <div
              style={{
                position: "relative",
                height: "50px",
                marginTop: "30px",
              }}
            >
              <div className="subuserList_saveButton">
                <ButtonUtil
                  text="Save"
                  backgroundColor="#27AAD8"
                  textColor="#FFFFFF"
                  width="100%"
                  onClick={this.saveclicked}
                />
              </div>
              <div className="subuserList_cancelButton">
                <ButtonUtil
                  text="Cancel"
                  backgroundColor="#E6E6E6"
                  textColor="#4D4D4D"
                  width="100%"
                  onClick={() =>
                    this.cancelEditUserModal()
                  }
                />
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,

    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubusersList);
