import { Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from "react-redux";
import LOgOEn from "../../../assets/images/LogoTenEn.svg";
import LOgOAr from "../../../assets/images/logoArabic.svg";
import translator from '../../../lang/translator';
import { Row, Col, Divider } from "antd";

import moment from "moment"
class PaymentReceipt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userNbr: 3,
            status: 'success'
        }

        this.lang = translator(this.props.lang);
    }
    detectSubscriptionOrUser = () => {
        if (this.props.receiptDetails.type === 'subuser') {
            return (
                <span>
                    <span className={this.props.lang + "_TeantReceipView_landlord"} >
                        {this.props.userNbr}
                    </span>
                    <span>
                        {this.props.userNbr > 1 ? this.lang.users : this.lang.user}
                    </span>
                </span>
            )
        } else {
            return this.lang.subscription_fees
        }
    }
    translatePaymentMethod = (data) => {
        if (this.props.lang === "ar") {
            if (data && data.toUpperCase() === 'KNET') {
                return 'كي - نت'
            }
            if (data && data.toUpperCase() === 'CASH') {
                return 'نقدي'
            }
            if (data && data.toUpperCase() === 'BANK TRANSFER') {
                return 'تحويل مصرفي'
            }
            if (data && data.toUpperCase() === 'CREDIT CARD') {
                return 'بطاقة الائتمان'
            }
            if (data && data.toUpperCase() === 'CHEQUE') {
                return "شيك مصرفي"
            }
        }
        else { return data }
    }
    render() {
        return (
            <Modal
                className={this.props.lang + "_TeantReceipView receipt_subuser"}
                visible={this.props.visibleReceiptModal}
                footer={null}
                closable={false}
                centered
                onCancel={this.props.onShowReceiptModal}>
                <Row>
                    <Col span={12}>
                        <h1 className={this.props.lang + "_TeantReceipView_title"}>
                            {this.lang.ARVRReceipt}
                        </h1>
                    </Col>
                    <Col span={12}>
                        <img
                            alt=""
                            className={this.props.lang + "_TeantReceipView_logo"}
                            src={this.props.lang === "ar" ? LOgOAr : LOgOEn} />

                    </Col>
                    <Col
                        span={24}
                        className={this.props.lang + "_TeantReceipView_date_sub"}
                    >
                        {this.props.lang === "en" ? moment(this.props.receiptDetails.payment_date).format('DD/MM/YYYY') : moment(this.props.receiptDetails.payment_date).format('YYYY/MM/DD')}
                    </Col>
                    <p className={this.props.receiptDetails.status === 'failed' ? this.props.lang + "_receiptFailed_pay_message" : 'none'}>
                        {this.lang.not_captured}
                    </p>
                </Row>
                <Row>
                </Row>

                <div className='payment_receipt_block' >
                    <div className="tenantViewRecipt__container">
                        <Row>
                            <Col
                                className={this.props.lang + "_TeantReceipView_receip"}
                                span={12}
                            >
                                {this.lang.ARVRReceipt}
                                <span className={this.props.lang + '_receipt_invoice_details'}>
                                    {this.props.receiptDetails.receipt_number}
                                </span>
                            </Col>
                        </Row>

                        <Row>
                            <Col
                                span={24}
                                className={this.props.lang + "_TeantReceipView_receip"}
                            >
                                {this.lang.transaction_id}
                                <span className={this.props.lang + '_receipt_invoice_details'}>
                                    {this.props.receiptDetails.transaction_id}
                                </span>
                            </Col>
                            <Col
                                className={this.props.lang + "_TeantReceipView_receip"}
                                span={24}
                            >
                                {this.lang.merchant_id}
                                <span className={this.props.lang + '_receipt_invoice_details'}>
                                    {this.props.receiptDetails.merchant_track_id}
                                </span>
                            </Col>
                            <Col
                                span={24}
                                className={this.props.lang + "_TeantReceipView_receip"}
                            >
                                {this.lang.payment_id}
                                <span className={this.props.lang + '_receipt_invoice_details'}>
                                    {this.props.receiptDetails.payment_id}
                                </span>
                            </Col>
                            <Col
                                span={24}
                                className={this.props.lang + "_TeantReceipView_receip"}
                            >
                                {this.lang.reference_id}
                                <span className={this.props.lang + '_receipt_invoice_details'}>
                                    {this.props.receiptDetails.reference_id}
                                </span>
                            </Col>
                        </Row>
                        <Divider />
                    </div>
                    <div className={this.props.lang + "_TeantReceipView_body2"}>
                        <div className={this.props.lang + "_TeantReceipView_receipL"}>
                            <span className={this.props.lang + "_TeantReceipView_receip"}>
                                {this.lang.ARVRt}
                            </span>
                            <p className={this.props.lang + "_TeantReceipView_landlord"}>
                                {this.props.receiptDetails.landlord}
                            </p>
                        </div>
                        <div className={this.props.lang + "_TeantReceipView_receipR"}>
                            <span className={this.props.lang + "_TeantReceipView_receip"}>
                                {this.lang.ARTRTo}
                            </span>
                            <p className={this.props.lang + "_TeantReceipView_landlord"}>
                                {this.lang.tenantive}
                            </p>
                        </div>
                        <div
                            className={this.props.lang + "_TeantReceipView_moneyContainer"}
                        >
                            <div
                                style={{ marginBottom: "25px" }}
                                className={
                                    this.props.lang + "_TeantReceipView_moneyContainerDiv"
                                }
                            >
                                <p className={this.props.lang + "_TeantReceipView_receip_Down"}>

                                    {this.detectSubscriptionOrUser()}
                                </p>
                                <p
                                    className={this.props.lang + "_TeantReceipView_receipAmount"}
                                >
                                    {this.props.receiptDetails.amount}
                                    <span className={this.props.lang + '_receipt_money'}>
                                        {this.lang.UnitRented_KWD}
                                    </span>
                                </p>
                            </div>
                            <div
                                className={
                                    this.props.lang + "_TeantReceipView_moneyContainerDiv"
                                }
                            >
                                <p className={this.props.lang + "_TeantReceipView_receip_Down"}>

                                    {this.lang.ARTPyment}
                                </p>
                                <p className={this.props.lang + "_TeantReceipView_cash"}>
                                    {this.translatePaymentMethod(this.props.receiptDetails.payment_method)}
                                    {this.props.receiptDetails.payment_method === 'cash' ? <img
                                        alt=""
                                        className={this.props.lang + "_TeantReceipView_cashLogo"}
                                        src="../../../images/money.png"
                                    /> : null}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <button onClick={this.props.exportReceipt}
                    className={this.props.lang + '_TeantReceipView_btnModal'}>
                    {this.lang.UnitRented_export} PDF
                </button>
            </Modal>
        );
    }
}
const mapStateToProps = state => {
    return {
        lang: state.auth.lang
    }
}
export default connect(mapStateToProps, null)(PaymentReceipt);