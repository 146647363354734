import React, { Component } from 'react';
import { Modal, Button, Row, Col, Input } from "antd";
import translator from "../../lang/translator";
import { connect } from "react-redux";
import attachementPic from '../../assets/icons/attachementPic.svg'
import search from '../../assets/icons/baseline-keyboard_arrow_down-24px.svg'
import { apiUrl } from '../../config/config';
import { formateNumber } from '../helpers/Helper';
import { downloadFile } from '../../Api';
class Receipt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            object: null,
            tenant: [
                {
                    name: 'zied jaziri',
                    money: 1200
                },
                {
                    name: 'hatem trabelsi',
                    money: 300
                },
                {
                    name: 'amine chermiti',
                    money: 900
                },
                {
                    name: 'andrea pirlo',
                    money: 700
                },
                {
                    name: 'genaro gattuso',
                    money: 900
                },
                {
                    name: 'lionel messi',
                    money: 300
                },
                {
                    name: 'cristiano ronaldo',
                    money: 1500
                },
                {
                    name: 'bilel ben hlima',
                    money: 500
                },
                {
                    name: 'yessine chakroun',
                    money: 700
                }
            ]
        }
        this.lang = translator(this.props.lang);
    }

    showFile = (path) => {
        let newWindow = window.open()
        downloadFile({ path: path, type: "pdf", window: newWindow })
    }
    render() {

        let tenantList = this.props.transferView.tenants && this.props.transferView.tenants.filter(e => e.name
            .toLowerCase()
            .includes(this.state.search.toLowerCase().trim()))

        return (
            <div>
                <Modal
                    className={this.props.lang + '_adminReceiptView'}
                    visible={this.props.visible}
                    footer={null}
                    closable={false}
                    onCancel={this.props.handleOk}
                    centered
                >
                    <Row>
                        <Col className={this.props.lang + '_adminReceipView_container'} span={12} >
                            <h1 className={this.props.lang + '_adminReceipView_title'}>{this.props.transferView.landlord && this.props.transferView.landlord.name}</h1>
                            <div style={{ display: 'flex' }}>
                                <Col style={{ marginBottom: '10px' }} span={12}>
                                    <span className={this.props.lang + '_adminReceipView_leftLabel'} > {this.lang.amount} </span>
                                </Col>
                                <Col style={{ marginBottom: '10px' }} span={12}>
                                    <span className={this.props.lang + '_adminReceipView_amountValue'}>{this.props.transferView && formateNumber(this.props.transferView.amount)}</span>
                                    <span className={this.props.lang + '_adminReceipView_moneyUnit'}> {this.lang.kwd} </span>
                                </Col>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Col style={{ marginBottom: '10px' }} span={12}>
                                    <span className={this.props.lang + '_adminReceipView_leftLabel'}> {this.lang.transactionFees} </span>
                                </Col>
                                <Col style={{ marginBottom: '10px' }} span={12}>
                                    < span className={this.props.lang + '_adminReceipView_filsValue'}> {this.props.transferView && formateNumber(this.props.transferView.transaction_fees)/1000} </span>
                                    <span className={this.props.lang + '_adminReceipView_moneyUnit'}> {this.lang.kwd} </span>
                                </Col>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Col style={{ marginBottom: '10px' }} span={12}>
                                    <span className={this.props.lang + '_adminReceipView_leftLabel'}> {this.lang.Actual_transfer} </span>
                                </Col>
                                <Col style={{ marginBottom: '10px' }} span={12}>
                                    <span className={this.props.lang + '_adminReceipView_trasfertValue'}> {this.props.transferView && formateNumber(this.props.transferView.actual_transfer)} </span>
                                    <span className={this.props.lang + '_adminReceipView_moneyUnit'}> {this.lang.kwd} </span>
                                </Col>
                            </div>
                            <hr />
                            <div style={{ display: 'flex' }}>
                                <Col style={{ marginBottom: '10px', marginTop: '10px' }} span={12}>
                                    <img src={attachementPic} alt="" />
                                    <span style={this.props.lang === 'en' ? { marginLeft: '5px' } : { marginRight: '5px' }} className={this.props.lang + '_adminReceipView_leftLabel'}> {this.lang.Attachments} </span>
                                </Col>
                                <Col style={{ marginBottom: '10px', marginTop: '10px' }} span={12}>
                                    <span className={this.props.lang + '_adminReceipView_docName'}> {this.props.transferView.attachments && this.props.transferView.attachments.map(e =>
                                        <p style={{ marginBottom: '0px' }} onClick={() => this.showFile(e.path)} > {e.name} </p>)} </span>
                                </Col>
                            </div>
                            <hr />
                            <Col style={{ marginTop: '15px' }} span={24}>
                                <p className={this.props.lang + '_adminReceipView_leftLabel'}> {this.lang.iban} </p>
                            </Col>
                            <Col span={24}>
                                <p className={this.props.lang + '_adminReceipView_bAccountName'}> {this.props.transferView.bank_account?.iban} </p>
                            </Col>
                            <Col style={{ marginTop: '15px' }} span={24}>
                                <p className={this.props.lang + '_adminReceipView_leftLabel'}> {this.lang.bank_name} </p>
                            </Col>
                            <Col span={24}>
                                <p className={this.props.lang + '_adminReceipView_bAccountName'}> {this.props.transferView.bank_account?.bank_name} </p>
                            </Col>
                        </Col>

                        <Col className={this.props.lang + '_adminReceipView_container'} span={12}>
                            <p style={{ marginTop: '1em' }} className={this.props.lang + '_adminReceipView_rightLabel'}> {this.lang.tenant_rental_list} </p>
                            <Col span={24}>
                                <Input allowClear={true} className={this.props.lang + '_adminReceipView_searchInput'} prefix={<img src={search} />} placeholder={this.lang.Search}
                                    onChange={(e) => this.setState({ search: e.target.value })} />
                            </Col>
                            <Col className={this.props.lang + '_adminReceipView_tenantListcontainer'} span={24}>
                                {tenantList && tenantList.map(item =>
                                    <div style={{ display: 'flex' }}>

                                        <Col span={15}>

                                            <p className={this.props.lang + '_adminReceipView_rightLabel'}> {item.name} </p>
                                        </Col>
                                        <Col span={8} style={{ display: 'flex' }}>
                                                <p className={this.props.lang + '_adminReceipView_trasfertValue'}> {formateNumber(item.amount)} </p>
                                                <p className={this.props.lang + '_adminReceipView_moneyUnit'} style={this.props.lang === 'en' ? { marginLeft: '5px' } : { marginRight: '5px' }}>{this.lang.kwd} </p>
                                        </Col>
                                    </div>)}
                            </Col>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
    };
};
export default connect(mapStateToProps, null)(Receipt);