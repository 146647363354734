import React, { useEffect, useRef, useState } from "react";
import { Route, BrowserRouter as Router, Switch, useHistory, useLocation } from "react-router-dom";
import Login from "../Components/Login/Login";
import PrivateRoute from "./PrivateRoute";
import ErrorNotFound from "../Components/Pages/ErrorNotFound";
import NotAuthorized from "../Components/Pages/NotAutherized";
import Home from "../Components/dashboard/Home";
import Setting from "../Components/Setting/Setting";
import Landing from "../Components/Landing/Landing";
import LandLordsMenu from "../Components/Landlords/LandLordsMenu";
import Transfers from "../Components/transfers/transfers";
import PendingBanks from "../Components/PendingBanks";
import PendingProperties from "../Components/PendingProperties";
import SubUserLogin from "../Components/SubUser/SubLogin/SubUserNumber";
import smsVerifiaction from "../Components/SubUser/SubLogin/smsVerifiaction";
import subUserRegister from "../Components/SubUser/SubLogin/subUserRegister";
import Rentals from "../Components/Rentals";
import RestrictedRoute from "./RestrictedRoute";
import Claims from "../Components/Claims/Claims";
import Messenger from "../Components/Messenger/Messenger";
import MultiFactorAuth from '../Components/Setting/MultiFactor/MultiFactor'
import { apiUrl } from "../config/config";
import { connect, useDispatch, useSelector } from "react-redux";
import EmailVerification from "../Components/Setting/EmailVerification";
import SingletonSocket from "../singleton/socket";

let socket = new SingletonSocket()
const MainRoute = (props) => {
  const [landlords, setLandlords] = useState([])
  const [adminInfo, setAdminInfo] = useState({})
  const messenger = useSelector(state => state.messenger)
  const auth = useSelector(state => state.auth)
  const location = useLocation();
  const dispatch = useDispatch()
  const getLandlordConversations = () => {
    fetch(apiUrl + "api/admin/getConversationAdminLandlord", {
      method: "GET",
      mode: "cors",
      headers: { Authorization: "Bearer " + auth.token },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 200) {
          let newConversations = responseJson.conversations.map((result) => {
            return {
              id_conversation: result.id_conversation,
              photo: result.photo,
              name: result.full_name,
              text: result.message,
              createdAt: result.createdAt,
              discussion: result.discussion,
              id_receiver: result.landlord,
              lu: result.lu
            };
          });
          newConversations.sort((prevMessage, nextMessage) => {
            return (
              new Date(nextMessage.createdAt) - new Date(prevMessage.createdAt)
            );
          });
          const data = { userId: auth.admin?.adminId, typeSender: "Admin", conversations: newConversations.map(el => el.id_conversation) }
          socket.emit('join', data, (error) => { });
          socket.emit("online", {
            userId: auth.admin?.adminId,
            conversations: newConversations.map(el => el.id_conversation),
            id_receiver: newConversations.map(el => el.id_receiver)
          })
          let action = {
            type: "SET_LANDLORD_CONVERSATION_LIST",
            value: [...newConversations],
          };
          dispatch(action);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getTenantsConversations = () => {
    fetch(apiUrl + "api/admin/getConversationAdminTenant", {
      method: "GET",
      mode: "cors",
      headers: { Authorization: "Bearer " + auth.token },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 200) {
          let newConversations = responseJson.conversations.map((result) => {
            return {
              id_conversation: result.id_conversation,
              photo: result.photo,
              name: result.full_name,
              text: result.message,
              createdAt: result.createdAt,
              discussion: result.discussion,
              id_receiver: result.tenant,
              lu: result.lu
            };
          });
          newConversations.sort((prevMessage, nextMessage) => {
            return (
              new Date(nextMessage.createdAt) - new Date(prevMessage.createdAt)
            );
          });
          const data = { userId: auth.admin?.adminId, typeSender: "Admin", conversations: newConversations.map(el => el.id_conversation) }
          socket.emit('join', data, (error) => { });
          socket.emit("online", {
            userId: auth.admin?.adminId,
            conversations: newConversations.map(el => el.id_conversation),
            id_receiver: newConversations.map(el => el.id_receiver)
          })
          let action = {
            type: "SET_TENANT_CONVERSATION_LIST",
            value: [...newConversations],
          };
          dispatch(action);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getLandlordsTenantsList = () => {
    fetch(apiUrl + "api/admin/getListOfLandlordAndTenant", {
      method: "GET",
      mode: "cors",
      headers: { Authorization: "Bearer " + auth.token },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 200) {
          let action = {
            type: "SET_LANDLORDS_TENANTS_LIST",
            landlords: responseJson.landlords,
            tenants: responseJson.tenants,
          };
          dispatch(action);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const logout = () => {
    let allConversations = messenger.landlordConversationsList.map(el => el.id_conversation)
    socket.emit("logout", { userId: auth.admin?.adminId, conversations: allConversations.concat(messenger.tenantConversationsList.map(el => el.id_conversation)) });
    localStorage.removeItem("jwtToken");
    dispatch({ type: "LOGOUT" });
  };

  async function getSocketDetails() {
    let promise1 = new Promise((resolve, reject) => {
      socket.on("sendOnlineTab", (data) => {
        const action = {
          type: "ADD_ONLINE_Tab",
          online: data
        }
        resolve(action)
      })
    })
    let promise2 = new Promise((resolve, reject) => {
      let action
      socket.on("userId", (data) => {
        if (auth.admin?.adminId !== data) {
          action = {
            type: "ADD_ONLINE_USER",
            userId: data,
            myId: auth.admin?.adminId
          }
        }
        resolve(action)
      })
    })
    Promise.all([promise1, promise2])
      .then((values) => {
        for (let index = 0; index < values.length - 1; index++) {
          const element = values[index];
          typeof element === Object && dispatch(element)
        }
      })
  }

  const getLandlords = () => {
    fetch(apiUrl + `api/admin/getListLandlords`, {
      method: "GET",
      mode: "cors",
      headers: { Authorization: "Bearer " + auth.token },
    })
      .then((res) => res.json())
      .then((data) => {
        setLandlords(data.users)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(async () => {
    getLandlords()
    socket.on("removeUserId", (data) => {
      const action = {
        type: "REMOVE_USER",
        userId: data,
      }
      dispatch(action)
    })
    await getSocketDetails()
  }, [])

  useEffect(() => {
    if (auth.admin?.adminId) {
      let allConversations = messenger.landlordConversationsList.map(el => el.id_conversation)
      const data = { userId: auth.admin?.adminId, typeSender: "Admin", conversations: allConversations.concat(messenger.tenantConversationsList.map(el => el.id_conversation)) }
      socket.emit('join', data, (error) => { });
    }
  })

  useEffect(() => {
    if (!location.pathname.includes('/settings')) {
      getLandlordsTenantsList()
      getLandlordConversations()
      getTenantsConversations()
    }
  }, [auth.admin?.adminId])

  useEffect(() => {
    let allConversations = messenger.landlordConversationsList.map(el => el.id_conversation)
    const data = { userId: auth.admin?.adminId, typeSender: "Admin", conversations: allConversations.concat(messenger.tenantConversationsList.map(el => el.id_conversation)) }
    socket.emit('join', data, (error) => { });
  }, [messenger.landlordConversationsList.length, messenger.tenantConversationsList.length])
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route path="/verification/email/:token" component={EmailVerification} />
          <PrivateRoute path="/Home" component={Home} />
          <PrivateRoute path="/Rentals" component={Rentals} />
          <PrivateRoute path="/PendingProperties" component={PendingProperties} />
          <PrivateRoute path="/PendingBanks" component={PendingBanks} />
          <PrivateRoute path="/Landlords/:id" component={LandLordsMenu} />
          <PrivateRoute path="/claims" component={Claims} />
          <PrivateRoute path="/Transfers" component={Transfers} />
          <PrivateRoute path="/Messenger" component={Messenger} />
          {
            props.user.type !== 'subuser' ?
              <PrivateRoute path="/Settings" component={(props) => <Setting logout={logout} {...props} setLandlords={setLandlords} landlords={landlords} />} />
              :
              <Route path="/Settings" component={ErrorNotFound} />
          }
          {props.user.type === 'subuser' &&
            <PrivateRoute path="/multiFactorAuth" component={MultiFactorAuth} />}
          <Route path="/subUserLogin" component={SubUserLogin} />
          <Route path="/smsVerfication" component={smsVerifiaction} />
          <RestrictedRoute path="/subUserRegister" component={subUserRegister} />
          <Route path="/Login" component={Login} />
          <Route path="/NotAuthorized" component={NotAuthorized} />
          <Route path="*" exact={true} component={ErrorNotFound} />
        </Switch>
      </Router>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(React.memo((MainRoute)));
