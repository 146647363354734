import React, { useState, useEffect } from "react";
import ConversationSearch from "./ConversationSearch";
import ConversationListItem from "./ConversationListItem";
import { connect } from "react-redux";
import BrodcastList from './BroadcastList'
import { apiUrl } from "../../config/config";

function ConversationList(props) {

    const [conversationSearch, setConversationSearch] = useState('')
    const [Broadcast, setBroadcast] = useState(false)

    const chooseUserType = (val) => {
        const action = { type: "SET_BROADCAST_CATEGORY", value: val }
        props.dispatch(action)
    }

    const setConversation = (conversation, type) => {
        if (type === "tenant") {
            const action = {
                type: "TENANT_CONVERSATION_INFO", value: conversation, id_conversation: conversation.id_conversation,
                lu: true
            };
            props.dispatch(action);
        } else {
            const action = {
                type: "LANDLORD_CONVERSATION_INFO", value: conversation, id_conversation: conversation.id_conversation,
                lu: true
            };
            props.dispatch(action);
        }
        fetch(apiUrl + "api/admin/markConversationAsRead/" + conversation.id_conversation, {
            method: "GET",
            mode: "cors",
            headers: { Authorization: "Bearer " + props.auth.token },
        })
            .then((response) => response.json())
            .catch((error) => {
                console.error(error);
            });
    }
    useEffect(() => {
        const action = { type: "SET_BROADCAST", value: Broadcast };
        props.dispatch(action)
    }, [])
    const onSearch = (search) => {
        setConversationSearch(search)

    }
    const clearInput = () => {
        setConversationSearch('')
    }
    const onBroadcast = () => {

        const action = { type: "SET_BROADCAST", value: !Broadcast };
        props.dispatch(action);
        setBroadcast(!Broadcast)
    }
    return (
        <div className={props.auth.lang + "_conversation-list"}>
            {
                Broadcast ?
                    <BrodcastList onBroadcast={onBroadcast} />
                    :
                    <div>
                        <ConversationSearch onSearch={(search) => onSearch(search)} onBroadcast={onBroadcast}
                            conversationSearch={conversationSearch} clearInput={clearInput}
                            chooseUserType={(val) => chooseUserType(val)} />
                        {
                            props.messenger.Broadcast_category === "landlord" ?
                                props.messenger.landlordConversationsList && props.messenger.landlordConversationsList.filter(el => el.name && el.name.toUpperCase().includes(conversationSearch.toUpperCase())).map((conversation, i) => (
                                    <ConversationListItem index={i} data={conversation} onClick={() => setConversation(conversation, "landlord")} />
                                ))
                                :
                                props.messenger.tenantConversationsList && props.messenger.tenantConversationsList.filter(el => el.name && el.name.toUpperCase().includes(conversationSearch.toUpperCase())).map((conversation, i) => (
                                    <ConversationListItem index={i} data={conversation} onClick={() => setConversation(conversation, "tenant")} />
                                ))
                        }
                    </div>
            }
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        messenger: state.messenger
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ConversationList);