import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getConnectedAdmin, getPendingSubusers, getActiveSubusers } from "../../Redux/Actions";

const Landing = ({ history }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const [Loaded, setLoaded] = useState(false);
  useEffect(() => {
    dispatch(getPendingSubusers(auth.token))
    dispatch(getActiveSubusers(auth.token))
    dispatch(getConnectedAdmin(auth.token));

  }, []);

  useEffect(() => {
    if (user) {
      history.push("/Home");
    }
  }, []);

  return <div></div>;
};

export default Landing;
