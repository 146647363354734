import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "antd";
import translator from "../../lang/translator";
import searchInput from '../../assets/icons/searchInput.svg'
import close from '../../assets/icons/close.svg';
function ConversationSearch(props) {

    const language = useSelector(state => state.auth.lang)
    const messenger = useSelector(state => state.messenger)
    const lang = translator(language);
    const [Value, setValue] = useState("tenant")

    useEffect(() => {
        setValue(messenger.Broadcast_category)
    }, [messenger.Broadcast_category])

    return (
        <div>
            <div className="conversation-message-option">
                <p className={language + "_conversation-message-bodcast"}
                    onClick={props.onBroadcast}>
                    {lang.bodcastMsg}
                </p>
            </div>
            <div className={"broadcast_to_btn_container " + language + "_brodcast_buttons"}>
                <button
                    onClick={() => props.chooseUserType('tenant')}
                    className={
                        Value === 'tenant'
                            ? language + "_broadcast_to_btnSelected"
                            : language + "_broadcast_to_btn"
                    }
                >
                    {lang.tenants}
                </button>
                <button
                    onClick={() => props.chooseUserType('landlord')}
                    className={
                        Value === 'landlord'
                            ? language + "_broadcast_to_btnSelected-last"
                            : language + "_broadcast_to_btn-last"
                    }
                >
                    {lang.landlords}
                </button>
            </div>
            <div className="conversation-search">
                <Input
                    value={props.conversationSearch}
                    onChange={(e) => props.onSearch(e.target.value)}
                    prefix={<img src={searchInput} alt="" />}
                    suffix={props.conversationSearch.length !== 0 ? <img className='clearInput' onClick={props.clearInput} src={close} alt="" /> : null}
                    type="search"
                    className="conversation-search-input"
                    placeholder={lang.Search}
                />
            </div>
        </div>
    );
}



export default ConversationSearch;
