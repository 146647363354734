import React, { Component } from 'react'
import { Modal, Divider, Row, Col } from "antd";
import defaultImage from "../../../assets/images/defaultProperty.svg"
import close from "../../../assets/images/icon/closeIcon.svg"
import { connect } from 'react-redux';
import translator from '../../../lang/translator';
import ExpiryDatePicker from '../../PendingBanks/ExpiryDatePicker';
import { apiUrl, imagepath } from '../../../config/config';
import File from '../../Shared/File';
import { downloadFile } from '../../../Api';
const moment = require("moment");
class PropertyDetailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };

        this.lang = translator(this.props.lang);
    }
    showFile = (path) => {
        let newWindow = window.open()
        let link = path.replace("./public/", "")
        downloadFile({ path: link, type: "pdf", window: newWindow })
    }
    render() {
        return (
            <Modal
                maxHeight={505}
                className="relative property-detail-modal"
                footer={null}
                closable={false}
                visible={this.props.isPropertyDetail}
                centered>
                <div className='property-modal-header-container'>
                    {this.props.propertyDetails?.photos ?
                        <File src={this.props.propertyDetails?.photos.replace("./public/", apiUrl)} height="93px" width="114px" />
                        : <img src={defaultImage} height="93px" width="114px" />}
                    <div className='ml-12'>
                        <div className='property-name'>{this.props.propertyDetails?.name}</div>
                        <div className='property-units-number'>{this.props.propertyDetails?.units?.length} {this.lang.Units}</div>
                    </div>
                </div>
                <div className='close-icon-container'><img src={close} width="32px" height="32px" onClick={this.props.onCloseModal} className="pointer" /></div>
                <Divider />
                <div className='property-detail-body-container'>
                    <Row className='mb-21'>
                        <Col span={10} className='property-units-number'>{this.lang.Area}</Col>
                        <Col span={14} className="property-detail-body-item">{this.props.propertyDetails.area}</Col>
                    </Row>
                    <Row className='mb-21'>
                        <Col span={10} className='property-units-number'>{this.lang.Street}</Col>
                        <Col span={14} className="property-detail-body-item">{this.props.propertyDetails.street}</Col>
                    </Row>
                    <Row className='mb-21'>
                        <Col span={10} className='property-units-number'>{this.lang.Block_number}</Col>
                        <Col span={14} className="property-detail-body-item">{this.props.propertyDetails.block}</Col>
                    </Row>
                    <Row className='mb-21'>
                        <Col span={10} className='property-units-number'>{this.lang.Bulding_number}</Col>
                        <Col span={14} className="property-detail-body-item">{this.props.propertyDetails.building}</Col>
                    </Row>
                    {this.props.propertyDetails?.civil_id?.length !== 0 && <Row className='mb-21'>
                        <Col span={10} className='property-units-number'>{this.lang.owner_civil_id}</Col>
                        <Col span={14} className="property-detail-body-item">
                            {this.props.propertyDetails?.civil_id?.length !== 0 && this.props.propertyDetails?.civil_id?.map((civil, index) => {
                                return (
                                    <div className="tenant_civil_id_container">
                                        <span onClick={() => this.showFile(civil.path)} className="pointer">
                                            {civil.name.length > 12 ? "..." + civil.name.slice(civil.name.length - 9) : civil.name}
                                        </span>
                                        {index === 0 && <ExpiryDatePicker
                                            expiryDate={this.props.ownerCivilIdExpiryDate && moment(this.props.ownerCivilIdExpiryDate)}
                                            onChangeExpiryDate={(value, dataString) => this.props.onChangeOwnerCivilIDExpiryDate(value, dataString)}
                                            className={this.props.ownerCivilIdExpiryDate && this.props.calculateExpiryDateDifference(this.props.ownerCivilIdExpiryDate) ? 'expired_document_date' : 'expiry_date_picker '}
                                            buttonClassName={'modal_date_picker_button'}
                                            container={"date_picker_container"} />}
                                    </div>
                                )
                            })}</Col>
                    </Row>}
                    {this.props?.propertyDetails?.title_deed && <Row >
                        <Col span={10} className='property-units-number'>{this.lang.title_deed}</Col>
                        <Col span={14} className="property-detail-body-item">

                            <div className="tenant_civil_id_container">
                                <span onClick={() => this.showFile(this.props?.propertyDetails?.title_deed.path)}>
                                    {this.props?.propertyDetails?.title_deed?.name.length > 12 ? "..." + this.props?.propertyDetails?.title_deed?.name.slice(this.props?.propertyDetails?.title_deed?.name.length - 9) : this.props?.propertyDetails?.title_deed?.name}
                                </span>
                                <ExpiryDatePicker
                                    expiryDate={this.props.titleDeedExpiryDate && moment(this.props.titleDeedExpiryDate)}
                                    onChangeExpiryDate={(value, dataString) => this.props.onChangeTitleDeedExpiryDate(value, dataString)}
                                    className={this.props.titleDeedExpiryDate && this.props.calculateExpiryDateDifference(this.props.titleDeedExpiryDate) ? 'expired_document_date' : 'expiry_date_picker '}
                                    buttonClassName={'modal_date_picker_button'}
                                    container={"date_picker_container"} />
                            </div>
                        </Col>
                    </Row>}
                </div>
            </Modal>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
    };
};

export default connect(mapStateToProps, null)(PropertyDetailModal);