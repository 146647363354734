import React, { Component } from 'react';
import translator from "../../../lang/translator";
import { connect } from "react-redux";
import { Table, Pagination, Empty } from "antd";
import { apiUrl } from '../../../config/config';
import { createBrowserHistory } from "history";
import moment from 'moment';
import PaymentReceipt from './paymentReceipt';
import { downloadFile } from '../../../Api';
class Subscriptions extends Component {
    constructor(props) {
        super(props);
        this.lang = translator(this.props.lang);
        this.state = {
            receipt_id: '',
            ModalInfoReceipt: false,
            receiptDetails: {},
            data: [],
            show: true,
            elementnumber: 0,
            pagenumber: 1,
            columns: [
                {
                    title: this.lang.ARV_Status,
                    dataIndex: "status",
                    key: "status",
                    render: (text, record) =>
                        record.statusSub &&
                        <div className={"sub_" + record.statusSub}>{record.statusSub}</div>,
                },
                {
                    title: this.lang.ARV_DueDate,
                    dataIndex: "dueDate",
                    key: "dueDate",
                    render: (text, record) =>
                        (record.subscription) &&
                        <div>
                            {this.props.lang === "ar" ? moment(record.subscription.expiry_Date).format('YYYY/MM/DD') : moment(record.subscription.expiry_Date).format('DD/MM/YYYY')}
                        </div>,
                },
                {
                    title: this.lang.landlords,
                    dataIndex: "landlords",
                    key: "landlords",
                    render: (text, record) => <div>{record.full_name}</div>,
                },
                {
                    title: this.lang.Subscription_model,
                    dataIndex: "model",
                    key: "model",
                    render: (text, record) =>
                        //(record.subscription) &&
                        <div>{record?.subscription?.type === "gold" ? this.lang.gold + " (" + (record?.subscription?.payment_Period) + ")": this.lang.basic}</div>,
                },
                {
                    title: this.lang.amount_kwd,
                    dataIndex: "amount",
                    key: "amount",
                    render: (text, record) =>
                        //(record.subscription) &&
                        <div>{record?.subscription?.type === "gold"? record?.subscription?.sum: 0}</div>,
                },
                {
                    title: this.lang.Action,
                    dataIndex: "action",
                    key: "action",
                    render: (text, record) => {
                        return (
                            record.subscription && (record.statusSub !== "Free" && record.subscription.sum !== 0) &&
                            <div className={`${this.props.lang}_tableButton table_header_items_name`} >
                                <div
                                    onClick={() => record.subscription !== null && this.getReceiptPayInfo(record.subscription.receipt)}
                                    className={this.props.lang + "_btn-rental"}
                                >
                                    {" "}
                                    {this.lang.ViewReceipt}
                                </div>
                            </div>
                        );
                    },
                },
            ],
        }

    }
    getSubscriptions(page) {
        fetch(
            apiUrl + "api/admin/getSubscription?page=" + page + "&per_page=10",
            {
                method: "GET",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + this.props.auth.token,
                },
            }
        )
            .then((response) => {
                response.json().then((data) => {
                    this.setState({
                        data: data.subsciptions,
                        elementnumber: data.nbrElements[0].total,
                    });
                });
            })
            .catch((err) => {
                console.log("error ", err);
            });
    }
    pagination = (page, numberpage) => {
        this.getSubscriptions(page);
        this.setState({
            pagenumber: page,
        });
    };
    getReceiptPayInfo = (id) => {
        fetch(apiUrl + "api/contract/getReceiptByID/" + id, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.auth.token
            }
        }).then(response => {
            response.json().then(data => {
                if (data.status === 200) {
                    this.setState({
                        receiptDetails: data.receipt,
                        receipt_id: id,
                        ///itemSlected: item,
                        ModalInfoReceipt: true
                    })
                }

            }).catch(err => console.log(err))

        }).catch((err) => console.log("erreur fetch", err))
    }
    componentDidMount() {
        this.getSubscriptions(1)
    }
    handleCloseModalReceipt = () => {
        this.setState({
            ModalInfoReceipt: false
        })
    }
    exportReceipt = () => {
        let newWindow = window.open()
        fetch(apiUrl + `api/contract/exportPdf/${this.state.receipt_id}?lang=${this.props.lang === 'en' ? 'EN' : 'AR'}`, {
            method: "Post",
            mode: "cors",
        }).then(response => {
            response.json().then(data => {
                if (data.status === 200) {
                    downloadFile({ path: data.link, type: "pdf", window: newWindow })
                }
            }
            );
        }).catch(err => console.log());
    }
    render() {
        return (
            <section className={this.props.lang + "_Container-ActivtyLog"}>
                <div className={this.props.lang + "_tableActivity"}>
                    <Table
                        locale={{
                            emptyText: (<div>
                                <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                <p className='noData'>
                                    No data !
                                </p>
                            </div>)
                        }}
                        columns={this.state.columns}
                        dataSource={this.state.data}
                        pagination={false}
                        components={this.components}
                    />

                    {this.state.elementnumber !== 0 ? (
                        <Pagination
                            style={{ textAlign: "right", marginTop: "2%" }}
                            defaultCurrent={1}
                            current={this.state.pagenumber}
                            pageSize={10}
                            total={this.state.elementnumber}
                            showSizeChanger={false}
                            responsive={true}
                            onChange={this.pagination}
                        />
                    ) : null}
                </div>
                {this.state.receiptDetails !== {} ? <PaymentReceipt onShowReceiptModal={this.handleCloseModalReceipt} exportReceipt={this.exportReceipt}
                    visibleReceiptModal={this.state.ModalInfoReceipt} receiptDetails={this.state.receiptDetails} userNbr={this.state.itemSlected && this.state.itemSlected.number} />
                    : null}
            </section>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        auth: state.auth,
        user: state.user
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);