import React, { Component } from "react";
import { Modal, Divider, Row, Col, Avatar, Input } from "antd";
import { connect } from "react-redux";
import translator from "../../..//lang/translator";
import { apiUrl } from "../../../config/config";
import Groupe from "../../../assets/images/Groupe 1731.png";
import CheckCercle from "../../../assets/images/icon/check_circle.png";
import ExpiryDatePicker from "../../PendingBanks/ExpiryDatePicker";
import persone from "../../../assets/images/defaultAvatar.png";
import File from "../../Shared/File";
import { downloadFile } from "../../../Api";
const moment = require("moment");
class ActionView extends Component {
  translator = null;
  constructor(props) {
    super(props);
    this.lang = translator(this.props.lang);
    this.state = {
      photo: "",
      visible: false,
      imageUrl: null,
      propTenant: "Property A",
      full_name: "",
      birthdate: "",
      email: "",
      phone_number: "",
      id_number: "",
      civil_id: [],
      rentalLeaseAgreement: [],
      civilIDExpiryDate: "",
      rentalLeaseAgreementExpiryDate: "",
      editIdNumber: false,
      errorIdNumber: false,
      tenantId: "",
    };
  }
  showFile = (path) => {
    let newWindow = window.open()
    let link = path.replace("./public/", "")
    downloadFile({ path: link, type: "pdf", window: newWindow })
  }

  ActionMeth = () => {
    const formData = new FormData()
    formData.append("idContract", this.props.el.contract_id)
    fetch(apiUrl + "api/admin/getTenantDetails/" + this.props.el.tenant_id, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          this.setState({
            tenantId: data.tenant._id,
            full_name: data.tenant.full_name,
            birthdate: data.tenant.birthdate ? moment(data.tenant.birthdate).format("DD/MM/YYYY") : null,
            email: data.tenant.email,
            phone_number: data.tenant.phone_number
              ? data.tenant.phone_number.replace("./public/", apiUrl)
              : { CheckCercle },
            id_number: data.tenant.id_number,
            civil_id: data.tenant.civil_id,
            imageUrl: data.tenant.photo,
            civilIDExpiryDate: data.contract.civil_id_expiry_date,
            rentalLeaseAgreement: data.contract.rental_lease_agreement,
            rentalLeaseAgreementExpiryDate: data.contract.rental_lease_agreement_expiry_date
          });
        }
      })
      .catch((err) => console.log());
  };
  calculateExpiryDateDifference = (expiryDate) => {
    let dateofvisit = moment(moment(expiryDate), 'DD-MM-YYYY');
    let today = moment();
    let difference = dateofvisit.diff(today, 'days');
    let isInExpiryPeriod = false
    if (difference <= 30) {
      isInExpiryPeriod = true
    } else {
      isInExpiryPeriod = false
    }
    return isInExpiryPeriod
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
    this.ActionMeth();
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };


  onChangeRentalLeaseAgreementExpiryDate = (value, dataString) => {
    this.setState({ rentalLeaseAgreementExpiryDate: value })
    const formData = new FormData()
    formData.append("idContract", this.props.el.contract_id)
    formData.append("rental_lease_agreement_expiry_date", value)
    this.addTenantExpiryDate(formData)
  }

  onChangeCivilIdExpiryDate = (value, contractID) => {
    this.setState({ civilIDExpiryDate: value })
    const formData = new FormData()
    formData.append("idTenant", this.state.tenantId)
    formData.append("civil_id_expiry_date", value)
    this.addTenantExpiryDate(formData)
  }
  addTenantExpiryDate = (formData) => {
    fetch(apiUrl + "api/admin/addExpiryDateToTenantsDocuments", {
      method: "POST",
      mode: "cors",
      headers: { Authorization: "Bearer " + this.props.auth.token },
      body: formData,
    }).then(response => response.json())
      .then((response) => {
        if (response.status == 200) {
          this.ActionMeth();
          this.props.getExpiredTenantToasts()
          this.props.refreshPropertiesList()
        }
      })

      .catch((err) => console.log("err", err));
  }
  onChangeIdNumber = (e) => {
    let regex = '^[0-9]*$'
    if (e.target.value.toString().match(regex)) {
      if (e.target.value.length === 12) {
        this.setState({ errorIdNumber: false, id_number: e.target.value })
      } else {
        this.setState({ errorIdNumber: true, id_number: e.target.value })
      }
    }
  }
  editTenantIdNumber = () => {
    if (this.state.id_number.toString().length < 12) {
      this.setState({ errorIdNumber: true })
    } else {
      this.setState({ editIdNumber: !this.state.editIdNumber })
      const formData = new FormData()
      formData.append("tenantId", this.props.el.tenant_id)
      formData.append("idNumber", this.state.id_number)
      fetch(apiUrl + "api/admin/editTenantIdNumber", {
        method: "POST",
        mode: "cors",
        headers: { Authorization: "Bearer " + this.props.auth.token },
        body: formData,
      }).then(response => response.json())
        .then((response) => {
          if (response.status == 200) {
            this.ActionMeth();
            this.props.getExpiredTenantToasts()
          }
        })
        .catch((err) => console.log("err", err));
    }
  }
  render() {
    return (
      <div className={this.props.lang + "_UModalContainer"}>
        <div onClick={this.showModal} className={this.props.lang + "_icon-input"}>
          {this.props.el.tenant}
        </div>
        {this.state.visible && <Modal
          // className={this.props.lang + "_LogAction"}
          className={this.props.lang + "_UmodalDetail"}
          footer={null}
          closable={false}
          visible={this.state.visible}
          centered
          maxHeight={505}
        >
          <Row className={'pl-40'}>
            <Col>
              {this.state.imageUrl ?
                <File isAvatar size={99} src={this.state.imageUrl.replace("./public/", apiUrl)} />
                : <Avatar size={99} src={persone} />}
            </Col>

            <Col className={this.props.lang + "_ModalHeaderChild" + " " + "column-justify-content-space-between"}>
              <div className={this.props.lang + "_Modal_Name_Ten"}>
                {this.state.full_name}
              </div>
              <div className={this.props.lang + "_Modal_prop_Ten"}>
                {this.lang.Property} {this.props.property}
              </div>
              <div className={this.props.lang + "_Modal_prop_Ten"}>
                {this.lang.Unit} {this.props.el.unit_number}
              </div>
            </Col>
          </Row>
          {/**
 * 
 *  full_name:data.tenant.full_name,
            birthdate:data.tenant.birthdate,
            email:data.tenant.email,
            phone_number:data.tenant.phone_number,
            id_number:data.tenant.id_number,
            civil_id:data.tenant.civil_id
 * 
 * 
 */}
          <img
            onClick={this.handleOk}
            className={this.props.lang + "_imgModalDel"}
            src={Groupe}
          ></img>
          <Divider type="horizontal" style={{ height: "100%" }} />

          <div >
            {this.state.birthdate && <Row
              className={'pl-40' + ' ' + 'pr-32'}
              gutter={[0, 12]}
            >
              <Col sm={12} xl={12} className={this.props.lang + "_TenantInfo"}>
                Birthdate
              </Col>
              <Col sm={12} xl={12} className={this.props.lang + "_TenantInfo"}>
                {this.state.birthdate}
              </Col>
            </Row>}
            {this.state.email && <Row
              className={'pl-40' + ' ' + 'pr-32'}
              gutter={[0, 12]}
            >
              <Col sm={12} xl={12} className={this.props.lang + "_TenantInfo"}>
                Email address
              </Col>
              <Col sm={12} xl={12} className={this.props.lang + "_TenantInfo"}>
                {this.state.email}
              </Col>
            </Row>}
            <Row
              className={'pl-40' + ' ' + 'pr-32'}
              gutter={[0, 12]}
            >
              <Col sm={12} xl={12} className={this.props.lang + "_TenantInfo"}>
                Phone number
              </Col>
              <Col sm={12} xl={12} className={this.props.lang + "_TenantInfo"}>
                {this.state.phone_number}
              </Col>
            </Row>

            <Row
              className={'pl-40' + ' ' + 'pr-32'}
              gutter={[0, 12]}
            >
              <Col sm={12} xl={12} className={this.props.lang + "_TenantInfo"}>
                ID number
              </Col>
              {this.state.editIdNumber ?
                <>
                  <Col sm={10} xl={10} className={this.props.lang + "_TenantInfo"}>
                    <Input className={this.state.errorIdNumber ? this.props.lang + "_TenantInfo " + this.props.lang + "_error_input" : this.props.lang + "_TenantInfo"} type="text" maxLength={12} value={this.state.id_number} onChange={this.onChangeIdNumber} />
                  </Col>
                  <Col sm={2} xl={2} className="edit_profile_button_col">
                    <span onClick={() => this.editTenantIdNumber()} className="edit_button_profile" >
                      {this.lang.save}
                    </span>
                  </Col>
                </>
                :
                <>
                  <Col sm={10} xl={10} className={this.props.lang + "_TenantInfo"}>
                    {this.state.id_number}
                  </Col>
                  <Col sm={2} xl={2} className="edit_profile_button_col">
                    <span onClick={() => { this.setState({ editIdNumber: !this.state.editIdNumber }) }} className="edit_button_profile" >
                      {this.lang.edit}
                    </span>
                  </Col>
                </>
              }
            </Row>

            {this.state.civil_id.length !== 0 && <Row
              className={'pl-40' + ' ' + 'pr-32'}
              gutter={[0, 12]}
            >
              <Col sm={12} xl={12} className={this.props.lang + "_TenantInfo"}>
                Civil ID 
              </Col>
              <Col
                sm={12}
                xl={12}
                className={this.props.lang + "_TenantInfoLast"}
              >
                {this.state.civil_id.length !== 0 && this.state.civil_id.map((civil, index) => {
                  return (
                    <div className="tenant_civil_id_container">
                      <span onClick={() => this.showFile(civil.path)}>
                        {civil?.name?.length > 12 ? "..." + civil?.name?.slice(civil?.name?.length - 9) : civil?.name}
                      </span>
                      {index === 0 && <ExpiryDatePicker
                        expiryDate={this.state.civilIDExpiryDate && moment(this.state.civilIDExpiryDate)}
                        onChangeExpiryDate={(value, dataString) => this.onChangeCivilIdExpiryDate(value, dataString)}
                        className={this.state.civilIDExpiryDate && this.calculateExpiryDateDifference(this.state.civilIDExpiryDate) ? 'expired_document_date' : 'expiry_date_picker '}
                        buttonClassName={'modal_date_picker_button'}
                        container={"date_picker_container"} />}
                    </div>
                  )
                })}

              </Col>
            </Row>}
            {this.state.rentalLeaseAgreement.length !== 0 &&
              <Row
                className={'pl-40' + ' ' + 'pr-32'}
                gutter={[0, 12]}
              >
                <Col sm={12} xl={12} className={this.props.lang + "_TenantInfo"}>
                  {this.lang.rental_lease_agreement}
                </Col>
                <Col
                  sm={12}
                  xl={12}
                  className={this.props.lang + "_TenantInfoLast"}
                >
                  {this.state.rentalLeaseAgreement.length !== 0 && this.state.rentalLeaseAgreement.map((rental, index) => {
                    return (
                      <div className="tenant_civil_id_container">
                        <span onClick={() => this.showFile(rental.path)}>
                          {rental.name.length > 12 ? "..." + rental.name.slice(rental.name.length - 9) : rental.name}
                        </span>
                        {index === 0 && <ExpiryDatePicker
                          expiryDate={this.state.rentalLeaseAgreementExpiryDate && moment(this.state.rentalLeaseAgreementExpiryDate)}
                          onChangeExpiryDate={(value, dataString) => this.onChangeRentalLeaseAgreementExpiryDate(value, dataString)}
                          className={this.state.rentalLeaseAgreementExpiryDate && this.calculateExpiryDateDifference(this.state.rentalLeaseAgreementExpiryDate) ? 'expired_document_date' : 'expiry_date_picker '}
                          buttonClassName={'modal_date_picker_button'}
                          container={"date_picker_container"} />}
                      </div>
                    )
                  })}

                </Col>
              </Row>}
          </div>
        </Modal>}
      </div >
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    //lang: state.auth.lang
    lang: state.auth.lang,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionView);
