import React, { Component } from "react";
import { Modal } from 'antd';
import { connect } from "react-redux";
import translator from "../../..//lang/translator";
import { apiUrl } from "../../../config/config";
import Groupe from "../../../assets/images/Groupe 1731.png"
import CheckCercle from "../../../assets/images/icon/check_circle.png";

class UnitDetails extends Component {
  translator = null;
  constructor(props) {
    super(props);
    this.lang = translator(this.props.lang);
    this.state = {
      visible: false,
      tpye: "Apartment",
      floor_number: "4",
      amenities: [
        { name: "Furnished", value: true, xx: "is_furnished", },
        { name: "Parking", value: true },
        { name: "TV", value: true },
        { name: "Wifi", value: true },
        { name: "Gym", value: false },
        { name: "conditioning", value: false },
        { name: "Elevator", value: false },
      ],
      bedroom: 3, bathroom: 2, area_size: 220, area_size_unit: "Sq.ft",
    };
  }

  ActionMeth = () => {

    /**http://localhost:8000/api/admin/getUnitDetails/5ed61f06d841e32a880f591b */
    fetch(apiUrl + "api/admin/getUnitDetails/" + this.props.el.unit_id, {
      method: "GET",
      mode: "cors",
      headers: {
        'Authorization': 'Bearer ' + this.props.auth.token
      },
    }).then(response => {
      response.json().then(data => {

        if (data.status == '200') {
          this.setState({
            tpye: data.unit.type,
            floor_number: data.unit.floor_number,
            bedroom: data.unit.bedroom, bathroom: data.unit.bathroom, area_size: data.unit.area_size, area_size_unit: data.unit.area_size_unit,
            amenities: [
              {
                name: "Furnished",
                value: data.unit.is_furnished,
              },
              {
                name: "Wifi",
                value: data.unit.amenities[0],
              },
              {
                name: "Parking",
                value: data.unit.amenities[1],
              },
              {
                name: "TV",
                value: data.unit.amenities[2],
              },
              {
                name: "Gym",
                value: data.unit.amenities[3],
              },
              {
                name: "conditioning",
                value: data.unit.amenities[4],
              },
              {
                name: "Elevator",
                value: data.unit.amenities[5],
              },
            ],
          });
        }
      }
      );
    }).catch(err => console.log(err));
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {

    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };



  render() {
    return (
      <div className={this.props.lang + "_UModalContainer"}>
        <div onClick={this.showModal} className={this.props.lang + "_LogActionTitle"}>
          Unit{this.props.el.unit_number}
        </div>
        <Modal
          // className={this.props.lang + "_LogAction"}
          className={this.props.lang + "_UnitMod"}
          footer={null}
          closable={false}
          visible={this.state.visible} >
          <p className={this.props.lang + "_ModalTitle"} > Unit{this.props.el.unit_number}</p>
          <img onClick={this.handleOk} className={this.props.lang + "_imgModalDel"} src={Groupe}></img>
          <div className={this.props.lang + "_LogDescri"} >
            <div className={this.props.lang + "_type"}> {this.state.tpye}</div>
            <div className={this.props.lang + "_floor"}>  Floor  {this.state.floor_number}</div>
            <div className={this.props.lang + "_Hroom-rental"}>
              <p>
                <span>{this.state.bedroom}</span>
                <span>{this.lang.UnitRented_Beds}</span>
              </p>
              <p>
                <span>{this.state.bathroom}</span>
                <span>{this.lang.UnitRented_Bathrooms} </span>
              </p>
              <p>
                <span>{this.state.area_size}</span>
                <span>{this.lang.UnitRented_SQft} </span>
              </p>
            </div>

            <div className={this.props.lang + "_Hroom-rental2"}>
              {this.state.amenities
                .filter((el, i) => el.value === true)
                .map((el) => (
                  <p>
                    <img src={CheckCercle} alt="" />
                    {el.name}
                  </p>
                ))}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    lang: state.auth.lang,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitDetails);
