const lang = {
  welcome: "Welcome there !",
  pleaseLogIn: "Please log in",
  username: "Username",
  password: "Password",
  LogIn: "Login ",
  landlord: "Landlord",
  landlords: "Landlords",
  Transfers: "Transfers",
  enterUsername: "Enter username",
  enterPassword: "Enter your password",
  PageNotFound: "Page Not Found",
  sorry: "Sorry, The Page You Are Looking For Is Currently Unavailable.",
  btnReturn: "Return to the home page",
  PageNotAuthorized: "Page Not authorized",
  serch: "Search",
  findLandLord: "Find landlord",
  AddTransfert: "Add Transfer",
  View: "View",
  Name: "Name",
  Properties: "Properties",
  Units: "Units",
  TransferAmount: "Transfer amount",
  Action: "Action",
  confirmation1: "Add transfer",
  amountModaltransfer: "Amount",
  Civil_ID: "Civil ID",
  CivilID_TitleDeed: "Civil ID & Title deed",
  browse: "Browse",
  or: "OR",
  drag_and_drop: "DRAG & DROP FILES",
  d_and_d: "DRAG & DROP FILES",
  d_and_ds: "DRAG & DROP FILE",
  Attachement: "Attachement",
  units: "units",

  /**************************************LandLordProperties ************************/

  properties: "Properties",
  Rentals: "Rentals",
  ConnectedBank: "Connected banks",
  Account_subscription: "Account subscription",
  Basic_account: "Basic account",
  ARV_Search: "Search",
  ARV_Status: "Status",
  ARV_DueDate: "Due date",
  Date: "Date",
  ContractPeriode: "Contract period",
  ContractStatus: "Active",
  Unit: "Unit",
  TenantName: "Tenant name",
  MonthlyRate: "Monthly rent (KWD)",
  status: "Status",
  ViewReceipt: "View Receipt",
  Tenant: "Tenant",
  Amount: "Amount",
  Property: "Property",
  ContractStatus2: "Expired",
  loginUserNotFound: "Phone number or password are wrong !",
  ArabicNumberError: "Please use english numbers only.",

  /***********Bank connect*****/

  /****************************** */
  Bankaccount: "Bank account",

  RTVStRejected: "Rejected",
  RTVStApproved: "Approved",
  Bankdocs: "Bank docs",
  BanklistAction: "Actions",
  pendingPropertiesAction: "Action",
  AETCancel: "Cancel",
  AETSubmit: "Submit",
  RTVStsend: "Unpaid",
  RTVStpaid: "Paid",

  /*------trasfers------*/
  Search: "Search",
  dateSearch: "Transfer date",
  collections: "Collections",
  bank_account: "Bank account",
  transfert_to: "Transfert to",
  Actual_transfer: "Actual transfer",
  Actual_transfer_kwd: "Actual transfer (KWD)",
  amount: "Amount",
  kwd: "KWD",
  transactionFees: "Transaction fees",
  fils: "fils",
  Attachments: "Attachments",
  tenant_rental_list: "Tenants rentals",

  /***********Bank connect*****/
  Approved: "Approved",
  Approve: "Approve",
  Rejected: "Rejected",
  Reject: "Reject",
  CancelApr: "Cancel",
  msgforApprv: "Sure you want to approve ? ",
  msgforReject: "Sure you want to reject ? ",
  PendingBanks: 'Pending banks',
  PendingProperties: 'Pending properties',
  Download: "Download",
  RTVStPending: "Pending",
  /********************************************SubUsers */
  Enter_number: "Enter phone number",
  Enter_name: "Enter name",
  full_name: "Full name",
  phoneNumber: " Phone number",
  cancel: "Cancel",
  invite_user: "Invite User",
  accept_bank_account: "Accept properties / bank account",
  User_permissions: "User permissions",
  All_landlords: "All landlords",
  All_tenants: "All tenants",
  all_properties: "all properties",
  edit: "Edit",
  Invite_subusers: "Invite subusers",
  user_info: "Please enter the user’s info.",
  /****************************LOGINSUB */
  Please_enter_phone_number: "Please enter your phone number",
  continue: "Continue",
  Done: "Done",
  Please_create_username_and_password: "Please create username and password",
  /********************SMSVERIF */
  pswTenantgreat: "Great !",
  pswTenantcreatepsw: "Please create a password",
  pswTenantPassword: "Password",
  pswTenantConfirmPassword: "Confirm password",
  cnxTeantwelcomeBack: "Welcome back !",
  cnxTenantEnterPassword: "Enter your password",
  cnxTenantContinue: "Continue",
  phoneVerificationButtonBackTenant: "Back",
  smsverificationTenantContinue: "Continue",
  newPasswordTenant: "New password",
  /*----F Pswd -----*/
  ResetPassword: "Reset Password",
  messagePhoneVerification: "Please enter your phone number to reset password.",
  phoneVericationPlaceHolder: "Add phone number",
  phoneVerificationButtonBack: "Back to log in",
  forgotPasswordThankyou: "Thank you!",
  forgotPasswordMessage:
    "We’ve sent you an sms with code, please enter the code to verify your number.",
  forgotPasswordWarning: "This code is invalid",
  forgotPasswordResend: "Resend code again",
  forgotPasswordResetPassword: "ResetPassword",
  forgotPasswordNewPassword: "New Password",
  forgotPasswordConfirmPassword: "Confirm password",
  forgotPasswordButtonConfirmation: "Submit",
  forgotPasswordMessageWarning: "please make sure your number is valid",
  forgotPasswordPasswordGuidance: "Password Guidance",
  forgotPasswordNumbers: "numbers",
  forgotPasswordLowerCaracter: "lower case",
  forgotPasswordUperCase: "upper case",
  forgotPasswordMinCaracter: "min 8 characters",
  forgotPasswordConfirm: "Confirm",
  forgotPasswordSpeicalChar: "special Character",


  /***********Setting************/
  subUser: "SubUsers",
  logout: "Log out",
  InviteSubUser: "Invite subusers",
  PleaseEnterTheUserInfo: "Please enter the user's info",
  fullName: "Full name",
  enterName: "enter name",
  UserPermission: "User permission",
  acceptBankAccount: "Accept bank account",
  AllLandlords: "All Landlords",
  inviteUser: "Invite user",
  addTransfers: "Add transfer",
  dateandtime: "Date & time",
  Subscription_model: "Subscription model",
  /***********Activity Log*****/
  User: "User",
  Time: "Time",
  activityLog: "Activity Log",
  /************************ */
  UnitRented_floor: "Floor",
  UnitRented_Beds: "Beds",
  UnitRented_Bathrooms: "Bathrooms",
  UnitRented_SQft: "Sq.ft",
  Rental_receipt: 'Rental receipt',
  Invoice_receipt: 'Invoice receipt',
  Deposit: "Security deposit",
  depositReceipt: 'Security deposit receipt',
  noData: 'No Data !',
  selectBank: "Select bank account",
  successTransfer: "Transfer has been made !",
  tenantTransfer: 'Transfers from tenants',
  Rent: 'Rent',
  Invoices: 'Invoices',
  totalAmount: 'Total amount',
  extract: 'Extract Report',
  all: "All",
  documents: " Documents ",
  downloadAll: "Download All",
  superAdminAccount: "Super admin account",
  superAdminContact: "Super admin contact",
  changeYourPassword: "Change your password anytime",
  changePassword: "Change password",
  currentPassword: "Current password",
  newPassword: "New password",
  updatePassword: "Update Password",
  uploadPhoto: "Upload Photo",
  emailAdress: " Email adress",
  save: "Save",
  adminContact: "Admin contact",
  update: "Update",
  update_current_phone: 'Please enter the current phone number here',
  update_number: 'Update phone number',
  update_number_txt: 'Please enter the new phone number here',
  please_input_your_name: "Name is required",
  please_input_your_email: "Email is required",
  name_not_valid: 'Your name must contain at least 5 character',
  email_Error: "Please Enter valid Email",
  email_exist: "Email already exist",
  full_name: "Full name",
  phone_number_error: "please verify your phone number",
  newNumberErr: 'Phone number still the same',
  numberUsed: 'Phone number already in use!',
  please_input_a_password: "please input a password",
  password_not_valid: "password not valid",
  this_password_is_incorrect: "this password is incorrect",
  wrong_password: "you have entered a wrong password",
  viewOnly: "View Only",
  amount_kwd: "Amount (KWD)",
  kyc: "KYC",
  lowRisk: "Low Risk",
  mediumRisk: "Medium Risk",
  highRisk: "High Risk",
  addedSince: "Added since ",
  claims: "Claims",
  claimsGeneral: "General claim",
  claimsPayment: "Payment claim",
  claimsSelectType: "Select claims type",
  claimsSelectProfile: "Select user profile",
  claimsList: "Claims list",
  propertyAndUnit: "Property & Unit",
  tenants: "Tenants",
  general: "General",
  Payment: "Payment",
  viewClaim: "View Claim",
  acceptClaim: "Accept Claim",
  resolved: "Resolved",
  inProgress: "In progress",
  closeClame: "Close Claim",
  CustomerInformation: "Customer information",
  mobileNumber: "Mobile number",
  email: "Email",
  complaint: "Complaint",
  attachedDocs: "Attached documents",
  issueInfo: "Issue information",
  exportPDF: "Export PDF",
  acknowledge: "I hereby acknowledge that all the information mentioned above are true and correct, and I bear full responsibility for the incorrectness of such information.",
  receiptNumber: "Receipt number",
  transactionId: "Transaction ID",
  transactionDate: "Transaction date",
  amountRequested: "Amount requested for refund",
  onBehalfLandlord: "On behalf the Landlord ",
  claimsSubUser: "Sub-User",
  loadingHistory: 'Loading...',
  noMore_message: 'No more messages',
  bodcastMsg: "Broadcast message",
  tenants: "Tenants",
  messages: 'Messages',
  type_here: "Type here",
  selectAll: "Select all",
  messenger: "Messenger",
  changeColor: "Change color",
  changeColorWarning: "Sure you want to change the Customer flag color ?",
  closeClaimWarning: "Sure you want to close the Claim ?",
  multiFactorAuth: "Security ",
  subscriptions: "Subscriptions",
  multiFactorAuth1: "Log in using two-factor authentication",
  off: 'Off',
  on: 'On',
  BruteForceError: "You have been blocked for 5 minutes. Please retry again later.",
  rental_lease_agreement: "Rental lease agreement",
  expiryDate: "Expiry date",
  the_landlord: "The landlord",
  will_be_expired_in: "will be expired in",
  days: "days",
  surtodeactivate: 'Sure to Deactivate landlord account ?',
  surtoreactivate: 'Sure to Reactivate landlord account ?',
  iban_bank_name: "IBAN & Bank name",
  iban: "IBAN",
  bank_name: "Bank name",
  MailVerifSuccessfully: 'Thank you!',
  MailVerifFailed: 'Email verification failed',
  MailVerifDashboard: 'Go to Homepage',
  goToDashoardSuccess: 'Your email was successfully verified.',
  goToDashoardFailed: 'Either the link has already expired or you did not copy the URL properly.',
  discount: 'Discount',
  the_tenant: "The tenant",
  of_the_lanlord: ", of the landlord",
  export_excel: "Export Excel",
  active_users: "Active Users",
  pending_users: "Pending Users",
  invite_a_user: "Invite a User",
  edit_user: "Edit User",
  wallet_accounting: 'Wallet',
  name_as_in_bank: "Name as in bank",
  Area: "Area",
  Street: "Street",
  Block_number: "Block number",
  Bulding_number: "Bulding number",
  owner_civil_id: "Owner civil ID",
  title_deed: "Title deed",
  The: "The",
  of_the_property: "of the property",
  ARVRReceipt: "Receipt",
  transaction_id: "Transaction ID",
  merchant_id: "Merchant track ID",
  payment_id: 'Payment ID',
  reference_id: 'Reference ID',
  ARVRt: "From",
  ARTRTo: "to",
  UnitRented_KWD: "KWD",
  ARTPyment: "Payment method",
  subscription_fees: 'Subscription fees',
  tenantive: "Tenantive",
  name_as_in_bank: "Name as in bank",
  basic: "Basic",
  gold: "Gold",
  addDiscount: "Add discount",
  discount_for: "Discount for",
  period: "Period",
  percentage: "Percentage",
  select: "Select",
  select_property: "Select properties",
  select_period: "Select a period",
  Gold_subscription: "Gold subscription",
  transaction_fees: "Transaction fees",
  validate_discount: "Validate discount",
  All_landlords_discount: "All tenants with discount"
};

export default lang;
