import React, { Component } from 'react';
import EditPassword from './EditPassword';
import EditProfile from './EditProfile';
import PasswordDetails from './PasswordDetails';
import ProfileDetails from './ProfileDetails'
import { apiUrl } from "../../../config/config"
import { connect } from 'react-redux';
import { validateEmail, getBase64, hasLowerCase, hasNumber, hasSpecialCaracter, hasUperCase } from "./UtilFunctions"
import translator from "../../../lang/translator";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditProfile: false,
            isChangePassword: false,
            currentPassword: "",
            changedPassword: "",
            adminFullName: "",
            adminEmail: "",
            mail: "",
            adminPhoneNumber: "",
            adminPhoto: "",
            uploadedPhoto: null,
            fullNameError: "",
            emailError: "",
            changedPasswordErreurMessage: "",
            passwordErreurMessage: "",
            clicked: false,
            emailerreurMessage: ""
        };
        this.lang = translator(this.props.lang);
    }
    onChangePassword = () => {
        this.setState({ isChangePassword: !this.state.isChangePassword })
    }
    handleCancelChangePassword = () => {
        this.setState({
            changedPasswordErreurMessage: "",
            passwordErreurMessage: "",
            isChangePassword: !this.state.isChangePassword,
        })
    }
    handleChangedPassword = (e) => {
        this.setState({
            changedPassword: e
        });
    }
    handleCurrentPassword = (e) => {
        this.setState({
            currentPassword: e,
        });
    }
    handleEditProfil = () => {
        this.setState({
            isEditProfile: !this.state.isEditProfile
        })
    }
    handleCancelEdit = () => {
        this.getAdminProfileInfo()
        this.setState({
            isEditProfile: !this.state.isEditProfile,
            emailError: "",
            fullNameError: "",
        })
    }
    onChangeFullName = (e) => {
        this.setState({ adminFullName: e.target.value })
    }
    onChangeEmail = (e) => {
        this.setState({ adminEmail: e })
    }
    getAdminProfileInfo() {
        fetch(apiUrl + "api/admin/getAdminProfileInfo", {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.auth.token,
            },
        })
            .then((response) => {
                response.json().then((data) => {
                    if (data.status === 200) {
                        this.setState({
                            adminFullName: data.Details.user_name,
                            adminEmail: data.Details.email,
                            mail: data.Details.email,
                            adminPhoneNumber: data.Details.phone_number,
                            adminPhoto: data.Details.photo
                        })
                    }
                });
            })
            .catch((err) => {
                console.log("err", err);
            });
    }
    componentDidMount() {
        this.getAdminProfileInfo()
    }
    editProfile = () => {
        this.setState({
            clicked: true
        })
        const formData = new FormData();
        if (!this.state.adminFullName || this.state.adminFullName.trim() === null || this.state.adminFullName.trim() == '') {
            this.setState({
                fullNameError: this.lang.please_input_your_name,
                clicked: false
            });
            return;
        }
        if (this.state.adminFullName.length < 5) {
            this.setState({
                fullNameError: this.lang.name_not_valid,
                clicked: false
            });
            return;
        }
        if (!this.state.adminEmail) {
            this.setState({
                emailError: this.lang.please_input_your_email,
                fullNameError: "",
                clicked: false
            });
            return;
        }
        else {
            if (!validateEmail(this.state.adminEmail)) {
                this.setState({
                    emailError: this.lang.email_Error,
                    fullNameError: "",
                    clicked: false
                });
                return;
            }
            if (this.state.adminEmail?.toLowerCase() !== this.state.mail?.toLowerCase()) {
                formData.append("email", this.state.adminEmail);
            }

        }
        formData.append("full_name", this.state.adminFullName);

        if (this.state.uploadedPhoto) {
            formData.append("profile_picture", this.state.uploadedPhoto);
        }

        if (this.state.clicked === false) {
            fetch(apiUrl + "api/admin/editProfileInfo", {
                method: "POST",
                mode: "cors",
                headers: {
                    Authorization: "Bearer " + this.props.auth.token,
                },
                body: formData,
            }).then((res) => {
                if (res.status === 400) {
                    this.setState({
                        emailerreurMessage: this.lang.email_exist
                    });
                }
                if (res.status === 200) {
                    this.getAdminProfileInfo()
                    this.setState({
                        isEditProfile: !this.state.isEditProfile,
                        emailError: "",
                        fullNameError: '',
                        emailerreurMessage:""
                    })
                }
                this.setState({
                    clicked: false
                })
            });
        }
    };
    handleUploadImage = (info) => {
        getBase64(info.file.originFileObj, (imageUrl) =>
            this.setState({
                uploadedPhoto: imageUrl,
                loading: false,
            })
        );
    }
    updatePassword = () => {
        if (!this.state.currentPassword) {
            this.setState({
                passwordErreurMessage: this.lang.please_input_a_password,
                changedPasswordErreurMessage: ""
            });
            return;
        }
        if (!this.state.changedPassword) {
            this.setState({
                changedPasswordErreurMessage: this.lang.please_input_a_password,
                passwordErreurMessage: "",
            });
            return;
        }
        if (
            !hasUperCase(this.state.changedPassword) ||
            !hasLowerCase(this.state.changedPassword) ||
            !hasNumber(this.state.changedPassword) ||
            !hasSpecialCaracter(this.state.changedPassword) ||
            this.state.changedPassword.length < 8
        ) {
            this.setState({
                changedPasswordErreurMessage: this.lang.password_not_valid,
                passwordErreurMessage: "",
            });
            return;
        }
        const formData = new FormData();
        formData.append("current_password", this.state.currentPassword);
        formData.append("new_password", this.state.changedPassword);

        fetch(apiUrl + "api/admin/changePassword", {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.auth.token,
            },
            body: formData,
        }).then((res) => {
            if (res.status === 200) {
                this.setState({
                    changedPasswordErreurMessage: "",
                    passwordErreurMessage: "",
                    isChangePassword: !this.state.isChangePassword,
                    currentPassword: "",
                    changedPassword: "",
                });
            } else {
                this.setState({
                    passwordErreurMessage: this.lang.wrong_password,
                    changedPasswordErreurMessage: "",
                });
            }
        });
    }
    render() {
        return (
            <div>
                <div className="profile_container">
                    {!this.state.isEditProfile ?
                        <ProfileDetails data={this.state} handleEditProfil={this.handleEditProfil}></ProfileDetails>
                        :
                        <EditProfile
                            data={this.state}
                            handleCancelEdit={this.handleCancelEdit}
                            editProfile={this.editProfile}
                            onChangeFullName={this.onChangeFullName}
                            onChangeEmail={this.onChangeEmail}
                            handleUploadImage={this.handleUploadImage}
                            emailerreurMessage={this.state.emailerreurMessage}
                        ></EditProfile>}
                </div>
                <div className="password_container">
                    {!this.state.isChangePassword ?
                        <PasswordDetails onChangePassword={this.onChangePassword}></PasswordDetails> :
                        <EditPassword
                            handleCancelChangePassword={this.handleCancelChangePassword}
                            handleChangedPassword={this.handleChangedPassword}
                            handleCurrentPassword={this.handleCurrentPassword}
                            currentPassword={this.state.currentPassword}
                            changedPassword={this.state.changedPassword}
                            updatePassword={this.updatePassword}
                            data={this.state}>

                        </EditPassword>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        lang: state.auth.lang,
        auth: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
