import React, { useEffect } from "react";
import { connect } from "react-redux";
import shave from "shave";
import { apiUrl } from "../../config/config";
import moment from "moment"
import "moment/locale/ar-ly";
import "moment/locale/en-gb";
import Personne from "../../assets/images/defaultAvatar.png";
import online from "../../assets/images/online.svg";
import greenPoint from '../../assets/images/greenPoint.svg';
import File from "../Shared/File";
function ConversationListItem(props) {

    useEffect(() => {
        shave(".conversation-snippet", 20);
    });
    const translateDate = () => {
        if (props.lang === "ar") {
            moment.locale("ar-ly");
            let dating = moment(createdAt).startOf("minut").fromNow()
            return dating;
        } else {
            moment.locale("en-gb");
            let dating = moment(createdAt).startOf("minut").fromNow()
            return dating;
        }
    };
    const { photo, name, text, createdAt, lu } = props.data;
    return (
        <div onClick={props.onClick} className={lu ? props.auth.lang + "_conversation-list-item" : 'conversation-list-item-msg'}>
            {props.messenger.id_conversation === props.data.id_conversation ? <img className={props.lang + '_isOnline'} src={online} alt="" /> : null}
            {photo ?
                <File className={props.auth.lang + "_conversation-photo"} src={photo.replace('./public/', apiUrl)} alt="" width="72"
                height= "72"/>
                :
                <img className={props.auth.lang + "_conversation-photo"} src={Personne} alt="" />
            }
            <div className={props.auth.lang + "_conversation-info"}>
                <h1 className={props.auth.lang + "_conversation-title"}>{name}</h1>
                <p className={props.auth.lang + "_conversation-snippet"}>
                    {text && text.length > 40 ? text.slice(0, 37) + "..." : text}
                </p>

            </div>
            <p className={props.auth.lang + "_conversation-time-info"} >
                {translateDate()}
                {props.messenger.onlineTab.includes(props.data.id_receiver) ? <img className={props.lang + '_msgNo_readt'} src={greenPoint} alt="" /> : null}
            </p>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        lang: state.auth.lang,
        messenger: state.messenger,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConversationListItem);
