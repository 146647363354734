import React, { Component, Fragment } from "react";
import Building from "../../../assets/icons/building.svg";
import Units from "../../../assets/icons/units.svg";
import Arrow from "../../../assets/icons/arrowdown.svg";
import { Col, Row, Pagination } from "antd";
import { connect } from "react-redux";
import translator from "../../../lang/translator";
import ColorBar from "../../../UIKIT/react-color-bar/lib/index";
import { apiUrl } from "../../../config/config";
import Filters from "../Filters";
import UnitDetail from "./UnitDetails";
import TenantDetail from "./TenantDetails";
import Empty from "antd/es/empty";
import { formateNumber } from "../../helpers/Helper";
import PropertyDetailModal from "./PropertyDetailModal";
const VARR = 3;
const moment = require("moment");
class Properties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            properties: [],
            id: this.props.match.params.id,
            visible: false,
            activekey: [],
            page: 1,
            SearchResults: [],
            pageSize: VARR,
            isPropertyDetail: false,
            propertyDetails: {},
            ownerCivilIdExpiryDate: "",
            selectedPropertyId: "",
            titleDeedExpiryDate: ""
        };

        this.lang = translator(this.props.lang);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.search.StartPropertyDate !== this.props.search.StartPropertyDate && prevProps.search.EndPropertyDate !== this.props.search.EndPropertyDate ||
            prevProps.search.PropertyStatus !== this.props.search.PropertyStatus) {
            this.updatedRentalsProp()
        }
    }
    getAllProperties = () => {
        fetch(apiUrl + "api/admin/profileView/" + this.state.id, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${this.props.auth.token}`,
            },
        })
            .then((res) => {
                res.json().then((data) => {
                    if (data.status === 200) {
                        data.properties.map(proper => {
                            proper.page = 1
                        })
                        this.setState({
                            properties: data.properties,
                        });
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }
    componentDidMount() {
        this.getAllProperties()
    }

    onShow = (i) => {
        !this.state.activekey.includes(i) &&
            this.setState({
                activekey: [i],
                activeProp: this.state.properties[i]
            });
    };
    hide = (i) => {
        this.state.activekey.includes(i) &&
            this.setState({
                activekey: this.state.activekey.filter((el) => el !== i),
            });
    };

    updatedRentalsProp = () => {
        fetch(
            apiUrl +
            `api/admin/searchProperty/${this.state.id}?startDate=${this.props.search.StartPropertyDate}&endDate=${this.props.search.EndPropertyDate}&contractStatus=${this.props.search.PropertyStatus}&searchedTenant=${this.props.search.SearchProperty}`,
            {
                method: "GET",
                mode: "cors",
                headers: { Authorization: "Bearer " + this.props.auth.token },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 200) {

                    if (data.properties.length !== 0) {
                        data.properties.map(proper => {
                            proper.page = 1
                        })
                        this.setState({
                            properties: data.properties,
                        });
                    } else {
                        this.setState({
                            properties: [],
                        });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    paginate = (page, pageSize) => {
        let newProperties = this.state.properties
        let activePr;
        newProperties.filter(pr => {
            if (pr.property_id === this.state.activeProp.property_id) {
                activePr = pr
                activePr.page = page
            }
        })
        this.setState({
            page: page,
            pageSize: pageSize,
            activeProp: activePr,
            properties: newProperties
        })
    };
    onCloseModal = () => {
        this.setState({
            isPropertyDetail: false,
            selectedPropertyId: ""
        })
    }
    onHandleModal = (id) => {
        this.getPropertyDetails(id)
        this.setState({
            isPropertyDetail: true,
            selectedPropertyId: id
        })
    }
    getPropertyDetails = (id) => {
        fetch(`${apiUrl}api/admin/getPropertyDetails/${id}`,
            {
                method: "GET",
                mode: "cors",
                headers: { Authorization: "Bearer " + this.props.auth.token },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 200) {
                    console.log(data);
                    this.setState({
                        propertyDetails: data.property[0],
                        ownerCivilIdExpiryDate: data.property[0].civil_id_expiry_date,
                        titleDeedExpiryDate: data.property[0].title_deed_expiry_date
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    onChangeOwnerCivilIDExpiryDate = (value, dataString) => {
        this.setState({ ownerCivilIdExpiryDate: value })
        const formData = new FormData()
        formData.append("propertyId", this.state.selectedPropertyId)
        formData.append("civil_id_expiry_date", value.format("YYYY-MM-DD"))
        this.addPropertyExpiryDate(formData)
    }
    onChangeTitleDeedExpiryDate = (value, dataString) => {
        this.setState({ titleDeedExpiryDate: value })
        const formData = new FormData()
        formData.append("propertyId", this.state.selectedPropertyId)
        formData.append("title_deed_expiry_date", value.format("YYYY-MM-DD"))
        this.addPropertyExpiryDate(formData)
    }
    addPropertyExpiryDate = (formData) => {
        fetch(apiUrl + "api/admin/PropertyAddExpiryDate", {
            method: "POST",
            mode: "cors",
            headers: { Authorization: "Bearer " + this.props.auth.token },
            body: formData,
        }).then(response => response.json())
            .then((response) => {
                if (response.status == 200) {
                    this.getPropertyDetails(this.state.selectedPropertyId)
                }
            })
            .catch((err) => console.log("err", err));
    }
    calculateExpiryDateDifference = (expiryDate) => {
        let dateofvisit = moment(moment(expiryDate), 'DD-MM-YYYY');
        let today = moment();
        let difference = dateofvisit.diff(today, 'days');
        let isInExpiryPeriod = false
        if (difference <= 30) {
            isInExpiryPeriod = true
        } else {
            isInExpiryPeriod = false
        }
        return isInExpiryPeriod
    }
    render() {
        return (
            <div style={{ width: "75%", marginLeft: "12.5%" }}>
                <Filters updatedRentalsProp={this.updatedRentalsProp} />

                <div className='properties_land_container'>
                    {this.state.properties && this.state.properties.length == 0 ?
                        <div className="noData_Container_ mt-51">
                            <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            <p className='noData'>
                                No Data !
                            </p>
                        </div>
                        :
                        this.state.properties && this.state.properties.map((el, i) => (
                            <Fragment  >
                                <Row key={i} className={`${this.props.lang}_PropertyRow ${i === 0 && 'mt-0'}`}>
                                    <Col span={1}>
                                        {el.expiredPropery && <div className='connected_banks_red_point'></div>}
                                    </Col>
                                    <Col span={7}>
                                        <div className={this.props.lang + "_PropertyCol"}>
                                            <img
                                                src={Building}
                                                alt="building"
                                                style={
                                                    this.props.lang == "en"
                                                        ? { paddingRight: "16px" }
                                                        : { paddingLeft: "16px" }
                                                }
                                            />
                                            <div onClick={() => this.onHandleModal(el.property_id)} className={el.expiredPropertyDetails ? "expired_property" : "valid_property"}>
                                                {el.property_name}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col
                                        span={12}
                                    // xs={{ span: 22 }}
                                    // sm={{ span: 10, offset: 0 }}
                                    // md={{ span: 14, offset: 0 }}
                                    >
                                        <div className={this.props.lang + "_PropertyCol"}>
                                            <img
                                                src={Units}
                                                alt="units"
                                                style={
                                                    this.props.lang == "en"
                                                        ? { paddingRight: "16px" }
                                                        : { paddingLeft: "16px" }
                                                }
                                            />
                                            <span className={this.props.lang + "_unitsNumber"}>
                                                {el.rented_unit_number}
                                            </span>
                                            <span className="units">{this.lang.units}</span>
                                        </div>
                                    </Col>
                                    <Col
                                        span={4}
                                    // xs={{ span: 2 }}
                                    // sm={{ span: 2 }}
                                    // md={{ span: 2 }}

                                    >
                                        <div className="drop-downProp">
                                            {el.rented_unit_number !== 0 && (
                                                <span className={this.props.search.PropertyStatus === "Active" ? this.props.lang + "_ActiveNumbers" : this.props.search.PropertyStatus === "Expired" ? this.props.lang + "_ExpiredNumber" : "none"}>
                                                    {el.rented_unit_number}
                                                </span>
                                            )}
                                            <div className="arrow-down" onClick={
                                                !this.state.activekey.includes(i)
                                                    ? () => this.onShow(i)
                                                    : () => this.hide(i)
                                            }>
                                                <img className={!this.state.activekey.includes(i) ? 'arroww' : "arrowUP"}

                                                    src={Arrow}
                                                    alt="arrow"

                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col span={24}>
                                        <div
                                            className={
                                                this.state.activekey.includes(i)
                                                    ? this.props.lang + "_display"
                                                    : "content"
                                            }
                                        >
                                            <div
                                                className={this.props.lang + "_table_header_Property "}
                                            >
                                                <span>{this.lang.Unit}</span>
                                                <span>{this.lang.TenantName}</span>
                                                <span>{this.lang.ContractPeriode}</span>
                                                <span>{this.lang.MonthlyRate}</span>
                                                <span>{this.lang.status}</span>
                                            </div>

                                            <div className="_table properties-sub-container" >
                                                {el.rented_units && el.rented_units
                                                    .slice((el.page - 1) * VARR, el.page * VARR)
                                                    .map((item, i) => {
                                                        let end_date = new Date(item.contract_end).valueOf()
                                                        let start_date = new Date(item.contract_start).valueOf()
                                                        let difference = end_date - start_date
                                                        let now = Date.now().valueOf()
                                                        let position = (now - start_date) / difference * 100

                                                        return (

                                                            <div key={i} className={i % 2 == 0 ? "row_table_Propperty" : "row_table_Propperty2"}>
                                                                <span className="tableItemProp">
                                                                    {" "}
                                                                    <UnitDetail el={item} />
                                                                </span>
                                                                <span className={item.expiredTenantDocs ? "expired_tenant_label" : "tableItemProp"}>
                                                                    {" "}
                                                                    <TenantDetail
                                                                        getExpiredTenantToasts={this.props.getExpiredTenantToasts}
                                                                        el={item}
                                                                        property={el.property_name}
                                                                        refreshPropertiesList={this.getAllProperties}
                                                                    />
                                                                </span>
                                                                <div
                                                                    className={
                                                                        this.props.lang + "_mybardaate_container"
                                                                    }
                                                                >
                                                                    <div className={this.props.lang + "_mybardaate"}>
                                                                        <div style={{ position: "relative" }}>
                                                                            <ColorBar
                                                                                data={[
                                                                                    {
                                                                                        legendLabel: "Start Date",
                                                                                    },
                                                                                    {
                                                                                        legendLabel: "End Date",
                                                                                    },
                                                                                ]}
                                                                            />
                                                                            <span className="whitePoint" style={{ left: position < 0 ? "0%" : position >= 100 ? "100%" : `${position}%` }}></span>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            this.props.lang + "_mybardaate_child"
                                                                        }
                                                                    >

                                                                        <p>{`${new Date(item.contract_start).getDay()} ${new Date(item.contract_start).toLocaleString('en', { month: 'short' })}, ${(new Date(item.contract_start).getYear() - 100)} `}</p>
                                                                        <p>{`${new Date(item.contract_end).getDay()} ${new Date(item.contract_end).toLocaleString('en', { month: 'short' })}, ${(new Date(item.contract_end).getYear() - 100)} `}</p>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <span className="Amount_Property">
                                                                        {formateNumber(item.monthly_rent)}
                                                                    </span>
                                                                </div>
                                                                <span
                                                                    className={
                                                                        item.status === "Active"
                                                                            ? "status_Active"
                                                                            : "status_Expired"
                                                                    }
                                                                >
                                                                    {item.status === "Active" ? "Active" : "Expired"}
                                                                </span>
                                                            </div>
                                                        )
                                                    }

                                                    )}
                                            </div>
                                            {el.rented_units && el.rented_units.length != 0 && (
                                                <Pagination
                                                    className="test"
                                                    defaultCurrent={1}
                                                    pageSize={VARR}
                                                    total={el.rented_units && el.rented_units.length}
                                                    showSizeChanger={false}
                                                    responsive={true}
                                                    onChange={
                                                        this.paginate
                                                    }
                                                />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Fragment>
                        ))}
                </div>
                {this.state.isPropertyDetail && <PropertyDetailModal {...this.state}
                    onCloseModal={this.onCloseModal}
                    onChangeOwnerCivilIDExpiryDate={this.onChangeOwnerCivilIDExpiryDate}
                    calculateExpiryDateDifference={this.calculateExpiryDateDifference}
                    onChangeTitleDeedExpiryDate={this.onChangeTitleDeedExpiryDate} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        auth: state.auth,
        search: state.search,
    };
};

export default connect(mapStateToProps, null)(Properties);
