import React, { Component } from "react";
import { Row, Col, Input, Form, Divider, ConfigProvider, Checkbox } from "antd";
import FormItem from "antd/lib/form/FormItem";
import translator from "../../lang/translator";
import { connect } from "react-redux";
import Checked from "../../assets/icons/IconCheckcircle.svg";
import EditLandlords from "./EditLandlords";
import { apiUrl } from "../../config/config";

class SubUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: "",
      phone_number: "",
      addTransfert: false,
      messenger: false,
      claims: false,
      kyc: false,
      acceptBank: false,
      visible: false,
      landlords: [],
      length: 0,
      permission: [],
    };
    this.lang = translator(this.props.lang);
  }
  onShow = (visibe) => {
    this.setState({
      visible: visibe,
    });
  };
  landlordsNumber = (number, length) => {
    this.setState({
      landlords: number,
      length: length,
    });
  };

  onSubmit = () => {
    const fomdata = new FormData();
    fomdata.append("full_name", this.state.full_name);
    fomdata.append("phone_number", this.state.phone_number);
    this.state.acceptBank &&
      fomdata.append("user_permissions", "Accept bank account");
    this.state.addTransfert &&
      fomdata.append("user_permissions", "Add transfers");
    this.state.messenger &&
      fomdata.append("user_permissions", "Messenger");
    this.state.claims &&
      fomdata.append("user_permissions", "claims");
    this.state.kyc &&
      fomdata.append("user_permissions", "kyc");
    this.state.landlords.forEach(el => {
      return fomdata.append("landlord", el)
    });
    fetch(apiUrl + `api/admin/subUserInvite`, {
      method: "POST",
      headers: { Authorization: "Bearer " + this.props.auth.token },
      body: fomdata,
    })
      .then((res) => res.json())
      .then((data) => {
        window.location = "/settings/subUserList";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <ConfigProvider direction={this.props.lang === "en" ? "ltr" : "rtl"}>
        <div style={{ marginLeft: "6%", marginTop: "4px" }}>
          <p className="SubuserTitle">{this.lang.Invite_subusers}</p>
          <p className="SubUserlabel">{this.lang.user_info}</p>
          <Row>
            <Col xs={24} sm={15} lg={14} xl={7}>
              <Form>
                <label className="sub_input_label">{this.lang.full_name}</label>
                <FormItem>
                  <Input
                    style={{ borderRadius: "5px" }}
                    value={this.state.full_name}
                    required
                    onChange={(event) => {
                      this.setState({ full_name: event.target.value });
                    }}
                    placeholder={this.lang.Enter_name}
                  />
                </FormItem>
                <label className="sub_input_label">
                  {this.lang.phoneNumber}
                </label>
                <FormItem>
                  <Input
                    style={{ borderRadius: "5px" }}
                    value={this.state.phone_number}
                    required
                    onChange={(event) => {
                      this.setState({ phone_number: event.target.value });
                    }}
                    placeholder={this.lang.Enter_number}
                  />
                </FormItem>
                <Divider style={{ width: "50%" }} orientation="left" />
                <label className="SubUserlabel">
                  {this.lang.User_permissions}
                </label>
                <Col>
                  <div className="user_permission">
                    <Checkbox
                      onChange={() =>
                        this.setState({ acceptBank: !this.state.acceptBank })
                      }
                      className="checkBoxEdit"
                    >
                      <span className="checkbox_text">
                        {this.lang.accept_bank_account}
                      </span>
                    </Checkbox>
                    <Checkbox
                      onChange={() =>
                        this.setState({
                          addTransfert: !this.state.addTransfert,
                        })
                      }
                      className="checkBoxEdit"
                    >
                      <span className="checkbox_text">
                        {this.lang.AddTransfert}
                      </span>
                    </Checkbox>
                    <Checkbox
                      onChange={() =>
                        this.setState({
                          messenger: !this.state.messenger,
                        })
                      }
                      className="checkBoxEdit"
                    >
                      <span className="checkbox_text">
                        {this.lang.messenger}
                      </span>
                    </Checkbox>
                    <Checkbox
                      onChange={() =>
                        this.setState({
                          claims: !this.state.claims,
                        })
                      }
                      className="checkBoxEdit"
                    >
                      <span className="checkbox_text">
                        {this.lang.claims}
                      </span>
                    </Checkbox>
                    <Checkbox
                      onChange={() =>
                        this.setState({
                          kyc: !this.state.kyc,
                        })
                      }
                      className="checkBoxEdit"
                    >
                      <span className="checkbox_text">
                        {this.lang.kyc}
                      </span>
                    </Checkbox>
                  </div>
                </Col>
                <Col>
                  <label className="SubUserlabel">{this.lang.landlords}</label>
                  <div className="SubUsersect" style={{ marginTop: "30px" }}>
                    <div>
                      <img
                        className={this.props.lang + "_checked"}
                        src={Checked}
                        alt="checked"
                      />
                      <span className="sub_landlord">
                        {this.state.landlords.length === 0
                          ? `${this.state.landlords.length} ${this.lang.landlords}`
                          : this.state.landlords.length === this.state.length
                            ? this.lang.All_landlords
                            : `${this.state.landlords.length} ${this.lang.landlords}`}
                      </span>
                    </div>
                    <div
                      className="sub_user_Edit"
                      onClick={() => this.onShow(true)}
                    >
                      {this.lang.edit}
                    </div>
                  </div>
                </Col>
                <Form.Item>
                  <button
                    onClick={this.onSubmit}
                    className={this.props.lang + "_tenantive-btn-edit"}
                  >
                    {this.lang.invite_user}
                  </button>
                  <p style={{ color: "red" }}> {this.state.errorMsg} </p>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
        <EditLandlords
          visible={this.state.visible}
          onShow={(visibe) => this.onShow(visibe)}
          landlordsNumber={(landlords, length) =>
            this.landlordsNumber(landlords, length)
          }
        />
      </ConfigProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, null)(SubUser);
