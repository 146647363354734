const lists = {
    pendingSubusers: [],
    activeSubusers: []

}



const SubsUserReducer = (state = lists, action) => {
    if (action.type === "GET_PENDING_SUBUSERS") {
        return {
            ...state,
            pendingSubusers: action.payload
        }
    } else if (action.type === "GET_ACTIVE_SUBUSRES") {
        return {
            ...state,
            activeSubusers: action.payload
        }
    }else return state
}


export default SubsUserReducer