import React, { Component } from 'react';
import { connect } from 'react-redux'
import translator from "../../../lang/translator";
import { Convert } from '../../dashboard/Util/UtilFunction'
import { Pagination, Row } from "antd";
import { apiUrl } from '../../../config/config'
import Filters from '../Filters';
import Receipt from "../../Rentals/Receipt"
import { getReceipt } from "../../../Redux/Actions"
import Empty from "antd/es/empty";
import { formateNumber } from '../../helpers/Helper';
import TenantInfo from '../../Rentals/TenantInfo';

const VARR = 10;

class Rentals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            rentals: [],
            nbrElements: 1,
            receipt: "",
            isTenantInfoVisible: false,
            tenant_id: '',
            property: '',
            unit: '',
        }
        this.lang = translator(this.props.lang);

    }

    getRentals = (id, page, per_page) => {
        fetch(apiUrl + `api/admin/getLandlordRentals?page=${page}&per_page=${per_page}`, {
            method: "POST",
            mode: "cors",
            headers: { Authorization: "Bearer " + this.props.auth.token },
            body: id
        }).then(res => res.json())
            .then(data => {
                this.setState({
                    rentals: data.rentalsPaginate,
                    nbrElements: data.nbrElements
                })
            })
            .catch(err => {
                console.log(err)
            }

            );
    }

    updatedRentals = (id, page, per_page) => {
        const fomdata = new FormData()
        fomdata.append("landlordId", id)
        fetch(apiUrl + `api/admin/searchRental?searchedRental=${this.props.search.SearchRentals}&page=${page}&per_page=${per_page}&dateStart=${this.props.search.StartRentalsDate}&dateEnd=${this.props.search.EndRentalsDate}`, {
            method: "POST",
            mode: "cors",
            headers: { Authorization: "Bearer " + this.props.auth.token },
            body: fomdata
        }).then(res => res.json())
            .then(data => {
                this.setState({
                    rentals: data.rentalsPaginate,
                    nbrElements: data.nbrElements

                })
            })
            .catch(err => {
                console.log(err)
            });
    }


    componentDidMount() {
        const id = new FormData();
        id.append("landlordId", this.state.id);

        this.getRentals(id, 1, VARR)

    }
    test = (page, pageSize) => {
        this.updatedRentals(this.state.id, page, pageSize)
    }

    // componentDidUpdate(prevProps, prevState) {
    //     const id = new FormData();
    //     id.append("landlordId", this.state.id);
    //     if (prevProps.search.SearchRentals !== this.props.search.SearchRentals) {
    //         this.updatedRentals(id, 1, VARR)
    //     }
    // }
    GetDateAndTime(str) {
        let date = str.split('T')
        let formdate = date[0].split('-')
        let time = date[1].split(':')
        let dayornigth = ''
        if (time[0] - '12' > '0') {
            dayornigth = 'PM'
            time[0] = time[0] - 12
        }
        else { dayornigth = 'AM' }
        return (
            <div style={{ height: 10, marginTop: -20 }}>
                <p style={{ color: '#212121' }}>{formdate[0] + '/' + formdate[1] + '/' + formdate[2]}</p>
                <Row style={{ marginLeft: 5, marginTop: -15 }}>
                    <p style={{ color: '#808080' }}>{time[0] + ':' + time[1]}</p>
                    <p style={{ color: '#808080', marginLeft: 2 }}>{dayornigth}</p>
                </Row>
            </div>
        )
    }

    ShowReceiptModal = (record) => {
        this.setState({
            ReceiptModal: true,
            receipt: record
        })
        this.props.getReceipt(record, this.props.auth.token)


    }
    HideReceitptModal = () => {
        this.setState({
            ReceiptModal: false
        })
    }


    onChangeTenantDetailsModalt = (record) => {
        this.setState({
            isTenantInfoVisible: !this.state.isTenantInfoVisible,
            tenant_id: record.tenant_id,
            property: record.property,
            unit: record.unit_number
        })
    }
    render() {
        return (
            <div style={{ width: "75%", marginLeft: "12.5%" }}>
                <Filters id={this.state.id}
                    updatedRentals={(id, page, per_page) => {
                        this.updatedRentals(id, page, per_page)
                    }}
                />

                <div className="table_Container">
                    <div className="table_header_Rentals">
                        <span>{this.lang.dateandtime}</span>
                        <span>{this.lang.Property}</span>
                        <span>{this.lang.Unit}</span>
                        <span>{this.lang.Tenant}</span>
                        <span>{this.lang.amount_kwd}</span>
                        <span>{this.lang.Action}</span>
                    </div>
                    <div className="_table">
                        {this.state.rentals && this.state.rentals.length == 0 ?
                            <div className="noData_Container_ mt-51">
                                <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                <p className='noData'>
                                    No Data !
                                </p>
                            </div>
                            : this.state.rentals.map((el, i) => {
                                return (
                                    <div key={i} className={`row_table_Rentals ${i === 0 ? 'mt-51' : ''}`}>
                                        <div>{this.GetDateAndTime(el.date)}</div>
                                        <div className="tableItem">{el.property}</div>
                                        <div className="tableItem">{this.lang.Unit + " " + el.unit_number}</div>
                                        <div className="tableItem tenant_rental_name" onClick={() => this.onChangeTenantDetailsModalt(el)}>{el.tenant}</div>
                                        <div>
                                            <span className="Amount">{formateNumber(el.amount)}</span>
                                            {el.discount && <div className={this.props.lang + '_discount_label'} >
                                                {` (Discount ${el.discount}%) `}
                                            </div>}
                                        </div>
                                        <div className={`${this.props.lang}_tableButton table_header_items_name`} >
                                            <div
                                                onClick={() => this.ShowReceiptModal(el.receipt_id)}
                                                className={this.props.lang + "_btn-rental"}
                                            >
                                                {" "}
                                                {this.lang.ViewReceipt}
                                            </div>
                                        </div>
                                    </div>)
                            })}
                    </div>
                </div>
                {this.state.rentals.length !== 0 &&
                    <div className='paginationStyle'>
                        <Pagination
                            className="test"
                            defaultCurrent={1}
                            pageSize={VARR}
                            total={this.state.nbrElements}
                            showSizeChanger={false}
                            responsive={true}
                            onChange={this.test} />
                    </div>

                }

                {this.state.isTenantInfoVisible && <TenantInfo tenant_id={this.state.tenant_id}
                    property={this.state.property}
                    unit_number={this.state.unit}
                    isTenantInfoVisible={this.state.isTenantInfoVisible}
                    onChangeTenantDetailsModalt={this.onChangeTenantDetailsModalt} />}
                <Receipt
                    visible={this.state.ReceiptModal}
                    hide={this.HideReceitptModal} />
            </div>);
    }
}

const mapStateToProps = state => {
    return {
        lang: state.auth.lang,
        auth: state.auth,
        search: state.search
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getReceipt: (id, token) => dispatch(getReceipt(id, token))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Rentals);
