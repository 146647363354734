import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Pagination } from "antd";
import { apiUrl } from '../../../config/config'
import Filters from '../Filters';
import Receipt from "../../Rentals/Receipt"
import { getReceiptByDepositID } from "../../../Redux/Actions"
import moment from 'moment'
import Empty from "antd/es/empty";
import translator from "../../../lang/translator";
import { formateNumber } from '../../helpers/Helper';
import TenantInfo from '../../Rentals/TenantInfo';

const VARR = 10;

class Deposit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            depositListPaginate: [],
            nbrElements: 1,
            receipt: "",
            isTenantInfoVisible: false,
            tenant_id: '',
            property: '',
            unit: '',
        }
        this.lang = translator(this.props.lang);
    }

    getDeposit = (page, per_page) => {
        const formData = new FormData();
        formData.append("landlordId", this.state.id);
        fetch(apiUrl + `api/admin/searchDeposit?page=${page}&per_page=${per_page}`, {
            method: "POST",
            mode: "cors",
            headers: { Authorization: "Bearer " + this.props.auth.token },
            body: formData
        }).then(res => res.json())
            .then(data => {
                if (data.status == 200) {
                    this.setState({
                        depositListPaginate: data.depositsPaginate,
                        nbrElements: data.nbrElements
                    })
                }
            })
            .catch(err => {
                console.log(err)
            }

            );
    }

    updatedRentals = (id, page, per_page) => {
        const fomdata = new FormData()
        fomdata.append("landlordId", id)
        fetch(apiUrl + `api/admin/searchDeposit?per_page=${per_page}&dateStart=${this.props.search.StartRentalsDate}&dateEnd=${this.props.search.EndRentalsDate}&searchedDeposit=${this.props.search.SearchRentals}&page=${page}`, {
            method: "POST",
            mode: "cors",
            headers: { Authorization: "Bearer " + this.props.auth.token },
            body: fomdata
        }).then(res => res.json())
            .then(data => {
                this.setState({
                    depositListPaginate: data.depositsPaginate,
                    nbrElements: data.nbrElements

                })
            })
            .catch(err => {
                console.log(err)
            })
    }


    componentDidMount() {
        this.getDeposit(1, VARR)
    }
    pagination = (page, pageSize) => {
        this.getDeposit(page, VARR)
    }

    GetDateAndTime(str) {
        let date = moment(str).format('YYYY/MM/DD')
        let time = moment(str).format('h:mm: a')
        return (
            <div className='deposite_date_container' >
                <p className='deposit_date'>{date}</p>
                <p className='deposit_time'>{time}</p>
            </div>
        )
    }

    ShowReceiptModal = (el) => {
        this.props.getReceiptByDepositID(el.contract_id, this.props.auth.token)
        this.setState({
            ReceiptModal: true,
            receipt: el._id
        })
    }

    HideReceitptModal = () => {
        this.setState({
            ReceiptModal: false
        })
    }

    onChangeTenantDetailsModalt = (record) => {
        this.setState({
            isTenantInfoVisible: !this.state.isTenantInfoVisible,
            tenant_id: record.tenant_id,
            property: record.property,
            unit: record.unit_number
        })
    }


    render() {
        return (
            <div className='deposit_container'>
                <Filters id={this.state.id}
                    updatedRentals={(id, page, per_page) => {
                        this.updatedRentals(id, page, per_page)
                    }}
                />
                <div className="table_Container">
                    <div className="table_header_Rentals">
                        <span>{this.lang.dateandtime}</span>
                        <span>{this.lang.Property}</span>
                        <span>{this.lang.Unit}</span>
                        <span>{this.lang.Tenant}</span>
                        <span>{this.lang.amount_kwd}</span>
                        <span>{this.lang.Action}</span>
                    </div>
                    <div className="_table">
                        {this.state.depositListPaginate.length == 0 ?
                            <div className="noData_Container_ mt-51">
                                <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                <p className='noData'>
                                    {this.lang.noData}
                                </p>
                            </div>
                            : this.state.depositListPaginate.map((el, i) => {
                                return (
                                    <div key={i} className={`row_table_Rentals ${i === 0 ? 'mt-51' : ''}`}>
                                        <div>{this.GetDateAndTime(el.date)}</div>
                                        <div className="tableItem">{el.property}</div>
                                        <div className="tableItem">{this.lang.Unit} {el.unit_number}</div>
                                        <div className="tableItem tenant_rental_name" onClick={() => this.onChangeTenantDetailsModalt(el)}>{el.tenant}</div>
                                        <div>
                                            <span className="Amount"> {formateNumber(el.amount)} </span>
                                        </div>
                                        <div className={`${this.props.lang}_tableButton table_header_items_name`} >
                                            <div
                                                onClick={() => this.ShowReceiptModal(el)}
                                                className={this.props.lang + "_btn-rental"}
                                            >
                                                {this.lang.ViewReceipt}
                                            </div>
                                        </div>
                                    </div>)
                            })}

                    </div>
                </div>
                {this.state.depositListPaginate.length !== 0 &&
                    <div className='paginationStyle'>
                        <Pagination
                            className="pagination"
                            defaultCurrent={1}
                            pageSize={VARR}
                            total={this.state.nbrElements}
                            showSizeChanger={false}
                            responsive={true}
                            onChange={this.pagination} />
                    </div>

                }


                {this.state.isTenantInfoVisible && <TenantInfo tenant_id={this.state.tenant_id}
                    property={this.state.property}
                    unit_number={this.state.unit}
                    isTenantInfoVisible={this.state.isTenantInfoVisible}
                    onChangeTenantDetailsModalt={this.onChangeTenantDetailsModalt} />}
                <Receipt
                    visible={this.state.ReceiptModal}
                    hide={this.HideReceitptModal} />
            </div>);
    }
}

const mapStateToProps = state => {
    return {
        lang: state.auth.lang,
        auth: state.auth,
        search: state.search
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getReceiptByDepositID: (deposit_id, token) => dispatch(getReceiptByDepositID(deposit_id, token))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Deposit);
