import React, { Component } from "react";
import { connect } from "react-redux";
import translator from "../../lang/translator";
import Search from "../../assets/icons/Search.svg";
import redFlag from "../../assets/icons/redFlag.png";
import orangeFlag from "../../assets/icons/orangeFlag.png";
import greenFlag from "../../assets/icons/greenFlag.png";
import { apiUrl } from "../../config/config";
import LoaderTenantive from "../../UIKIT/LoaderTenantive"
import date from "../../assets/Accounting/date.svg";
import Empty from "antd/es/empty";
import AddTransfer from "../AddTransfer/addTransferModal";
import moment from "moment";
import {
  Button,
  Input,
  Pagination,
  DatePicker,
  Modal,
  Radio
} from "antd";
import { withRouter } from "react-router-dom";
import { formateNumber } from "../helpers/Helper";
import ExpiryToast from "./Util/ExpiryToast";
import { downloadFile } from "../../Api";
const VARR = 10;

class Home extends Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.state = {
      isTransferModalVisible: false,
      amount: "",
      data: [],
      value: [],
      rapport_date: moment(new Date(), "MM-YYYY"),
      fetching: false,
      loading: false,
      hasMore: false,
      approuvedBank: [],
      search: "",
      tenants: [],
      tab: [],
      page: VARR,
      searchValue: "",
      nbrElements: 0,
      landlordId: "",
      landLordT: false,
      idBank: "Select bank account",
      checkedtedt: [],
      transaction_fees: "",
      actual_transfer: "",
      attachement: [],
      tenantId: [],
      rentalsId: [],
      collections: [],
      LoadingTableData: false,
      invoiceAmount: "",
      rentAmount: "",
      deposit: '',
      editModalVisible: false,
      radioFlagValue: '',
      selectedLandlord: {},
      expiryToastList: []
    };
    this.lang = translator(this.props.lang);
  }
  componentDidMount() {
    this.getPropertyToasts()
    this.getLandloardList();
  }
  showEditKYCModal = (landlord) => {
    this.setState({
      editModalVisible: true,
      selectedLandlord: landlord,
      radioFlagValue: landlord.risk
    })
  }
  hideEditKYCModal = () => {
    this.setState({
      editModalVisible: false,
      selectedLandlord: {}
    })
  }
  onRadioFlagsChange = (e) => {
    this.setState({
      radioFlagValue: e.target.value
    })
  }
  extractReport = () => {
    const formData = new FormData();
    formData.append("date", moment(this.state.rapport_date).format("YYYY-MM"));
    fetch(apiUrl + `api/admin/extractTenantsReport?searchedName=${this.state.searchValue}`, {
      method: "POST",
      mode: "cors",
      headers: { Authorization: "Bearer " + this.props.auth.token },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 200) { downloadFile({ path: responseJson.link, type: "excel" }) }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  disabledDate = (current) => {
    if (moment() < current) {
      return true
    } else {
      return false;
    }
  };
  getLandloardList = () => {
    var url = new URL(apiUrl + "api/admin/searchLandlord"),
      params = { searchedName: '', page: 1, per_page: VARR };
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            if (data.status === 200) {
              this.setState({
                nbrElements: data.nbrElements,
                tab: data.landlords,
                loading: true,
              });
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log("erreur fetch", err));
  }
  updateRisk = () => {
    const formData = new FormData();

    formData.append("landlord", this.state.selectedLandlord.id);
    formData.append("risk", this.state.radioFlagValue);

    fetch(apiUrl + "api/admin/editKycFlags", {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.status === 200) {
          this.getLandloardList()
          this.setState({
            warningModalVisible: false
          })
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleChange = (value) => {
    this.setState({
      value,
      data: [],
      fetching: false,
    });
  };

  showModal = (id) => {
    this.setState({
      landLordId: id,
      isTransferModalVisible: true,
    })
    const formData = new FormData();
    formData.append("landlordId", id);
    fetch(apiUrl + "api/admin/getApprovedBanks", {
      method: "POST",
      mode: "cors",
      headers: { Authorization: "Bearer " + this.props.auth.token },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 200) {
          this.setState({
            approuvedBank: responseJson.bankAccounts,
          });
        }

      })
      .catch((error) => {
        console.error(error);
      });
  };

  closeModalTransfer = () => {
    this.setState({
      isTransferModalVisible: false,
      landLordId: ''
    })
  }

  PaginationLandLoard = (page, pageSize) => {
    this.setState({
      LoadingTableData: true
    })
    var url = new URL(apiUrl + "api/admin/searchLandlord"),
      params = {
        searchedName: this.state.searchValue,
        page: page,
        per_page: pageSize,
      };
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {

            if (data.status === 200) {
              this.setState({
                nbrElements: data.nbrElements,
                tab: data.landlords,
                LoadingTableData: false
              });
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log("erreur fetch", err));
  };

  HandelSearchValue = (e) => {
    if (e === '') {
      this.getLandloardList()
    }
    this.setState({
      searchValue: e,
    });
  };
  enterKeyPress = (e) => {

    if (e.key === "Enter") {
      fetch(apiUrl + "api/admin/searchLandlord?searchedName=" + this.state.searchValue + "&page=1&per_page=10", {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + this.props.auth.token,
        },
      })
        .then((response) => {
          response
            .json()
            .then((data) => {

              if (data.status === 200) {
                this.setState({
                  nbrElements: data.nbrElements,
                  tab: data.landlords,

                });
              }
            })

        })
        .catch((err) => console.log("erreur fetch", err));
    }
  };
  loopPress = () => {
    var url = new URL(apiUrl + "api/admin/searchLandlord"),
      params = { searchedName: this.state.searchValue, page: 1, per_page: 10 };
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token,
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {

            if (data.status === 200) {
              this.setState({
                nbrElements: data.nbrElements,
                tab: data.landlords,
              });
            }
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log("erreur fetch", err));
  };
  showlandlord = (id, amount, risk, income) => {
    let action = { type: "SET_LANDLORD_RISK", risk: risk && risk !== 'undefined' ? risk : '', income: income && income !== 'undefined' ? income : '' }
    this.props.dispatch(action)
    if ((this.props.user.type == "admin" ||
      this.props.user.userPermissions.includes(
        "Add transfers"
      ))) {
      this.props.getAmount(amount)
    }
    if (this.props.user.userPermissions && this.props.user.userPermissions.includes(
      "Accept bank account"
    ) && this.props.user.userPermissions.length === 1) {
      this.props.history.push(`/Landlords/${id}/connectedbank`)
    } else {
      this.props.history.push(`/Landlords/${id}`)
    }

  };
  AddTransfertModal() {
    return (
      <AddTransfer
        closeModalTransfer={this.closeModalTransfer}
        data={this.state}
        isTransferModalVisible={this.state.isTransferModalVisible} />
    )
  }
  showWarning = () => {
    this.setState({
      warningModalVisible: true,
      editModalVisible: false
    })
  }
  handleCancel = () => {
    this.setState({
      warningModalVisible: false,
      editModalVisible: true
    })
  }
  getPropertyToasts = () => {
    fetch(apiUrl + "api/admin/adminGetExpiredPropertyToast", {
      method: "GET",
      mode: "cors",
      headers: { Authorization: "Bearer " + this.props.auth.token },

    }).then(response => response.json())
      .then((response) => {
        if (response.status == 200) {
          this.setState({
            expiryToastList: response.toast
          })
        }
      })

      .catch((err) => console.log("err", err));
  }
  closePropertyToast = (id, document) => {
    const formData = new FormData();
    formData.append("propertyId", id)
    formData.append("document", document)
    fetch(apiUrl + "api/admin/adminReadPropertyToast", {
      method: "POST",
      mode: "cors",
      headers: { Authorization: "Bearer " + this.props.auth.token },
      body: formData,

    }).then(response => response.json())
      .then((response) => {
        if (response.status == 200) {
          this.getPropertyToasts()
        }
      })
      .catch((err) => console.log("err", err));
  }
  renderPropertyToasts = () => {
    return this.state.expiryToastList?.map((el, i) => el.expiredDocument.map(toast =>
      !toast.lu && <ExpiryToast
        top={(i * 56) + 121}
        isProperty
        closeExpiryToast={() => this.closePropertyToast(el.propertyId, toast.type)}
        document={toast.type}
        property={el.Property}
        landlord={el.Landloard}
        date={toast.type === "Title deed" ? moment(el.title_deed_expiry_date)._d : moment(el.civil_id_expiry_date)._d}
      />
    ))
  }
  render() {

    return (
      <div>
        {this.renderPropertyToasts()}
        <div className="cover"  >

          <div className='filter-home-container'>
            <Input
              allowClear={true}
              type="textarea"
              placeholder={this.lang.findLandLord}
              onChange={(e) => {
                this.HandelSearchValue(e.target.value);
              }}
              onKeyPress={(e) => this.enterKeyPress(e)}
              addonBefore={
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => this.loopPress(e)}
                >
                  <img src={Search} value={this.state.searchValue} />
                </div>
              }
              className={this.props.lang + "_addProp_search"}
            />
            <div className='date-picker-container'>
              <DatePicker
                disabledDate={this.disabledDate}
                value={this.state.rapport_date}
                inputReadOnly={true}
                picker="month"
                suffixIcon={false}
                allowClear={true}
                format={"MM-YYYY"}
                className='date-picker'
                placeholder='Choose date'
                onChange={(value, dataString) =>
                  this.setState({ rapport_date: value })
                }
              />
              <Button
                className="date-btn"
              >
                <img src={date} alt="" />
              </Button>
              <Button className='upload-rapport-btn'
                onClick={this.extractReport}
              >
                {this.lang.extract}
              </Button>
            </div>
          </div>
          {this.state.loading ? <div className="table_ContainerHome">
            <div className='table_container_flow'>
              <div
                className={
                  this.props.user.type == "admin" ||
                    this.props.user.userPermissions.includes("Add transfers")
                    ? "lanlord_home_table_header"
                    : "table_header_noAddTrans"
                }
              >
                <span className="table_header_items_name">{this.lang.Name}</span>
                <span>{this.lang.Properties}</span>
                <span>{this.lang.Units}</span>
                {(this.props.user.type == "admin" ||
                  this.props.user.userPermissions.includes("Add transfers")) && (
                    <span className='center_text' >
                      <span className='center_text'>
                        {this.lang.amount_kwd}
                      </span>
                    </span>
                  )}
                <span className="table_header_items_name">
                  {this.lang.Action}
                </span>
                {(this.props.user.type == "admin" ||
                  this.props.user.userPermissions.includes("KYC")) && (
                    <span>{this.lang.kyc}</span>
                  )}
                {//(this.props.user.type == "admin" ||
                  //this.props.user.userPermissions.includes("subscriptions")) && (
                    <span>{this.lang.subscriptions}</span>
                  //)
                  }
              </div>
              <div>
                {this.state.LoadingTableData ? (
                  <LoaderTenantive />
                ) :
                  this.state.tab.length == 0 ?
                    <div className="noData_Container_">
                      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      <p className='noData'>
                        No Data !
                      </p>
                    </div>
                    :
                    this.state.tab.map((el, i) => {
                      return (
                        <div
                          key={i}
                          className={
                            this.props.user.type == "admin" ||
                              this.props.user.userPermissions.includes("Add transfers")
                              ? "home_page_lanlord_table"
                              : "row_table_noAddTrans"
                          }
                        >
                          <div className="tableItem table_header_items_name landlord_name_details ">
                            {el.expiredBankDocs && <div className="lanlord_red_point"></div>}
                            <span className='ml-20'>{el.name}</span>
                            <span className="added_since">{this.lang.addedSince + moment(el.createdAt).format("DD MMM YYYY")}</span>
                          </div>
                          <div className="tableItem">{el.properties}</div>
                          <div className="tableItem">{el.units}</div>
                          {(this.props.user.type == "admin" ||
                            this.props.user.userPermissions.includes(
                              "Add transfers"
                            )) && (
                              <div className="homeTransferAmount">
                                <span className="Amount">{formateNumber(el.transfer_amount)}</span>
                              </div>
                            )}
                          <div
                            className={`${this.props.lang}_tableButton table_header_items_name home_table`}
                          >
                            {(this.props.user.type == "admin" ||
                              this.props.user.userPermissions.includes(
                                "Add transfers"
                              )) && (
                                <Button
                                  disabled={this.props.user.view_only ? true : false}
                                  onClick={() => this.showModal(el.id)}
                                  className={this.props.user.view_only ? this.props.lang + "_btn-action-rental-disabled" : this.props.lang + "_btn-action-rental"}
                                >
                                  {this.lang.AddTransfert}
                                </Button>
                              )}
                            <div
                              onClick={() => this.showlandlord(el.id, el.transfer_amount, el.risk, el.income)}
                              className={this.props.lang + "_btn-action-View"}
                            >
                              {this.lang.View}
                            </div>
                          </div>
                          {(this.props.user.type == "admin" ||
                            this.props.user.userPermissions.includes("KYC")) && (
                              el.risk ?
                                <div>
                                  <img alt="" src={el.risk === 'high' ? redFlag : el.risk === 'medium' ? orangeFlag : greenFlag} />
                                  {!this.props.user.view_only &&
                                    <Button className="kyc_edit_btn" onClick={() => this.showEditKYCModal(el)}>{this.lang.edit}</Button>
                                  }
                                </div>
                                :
                                <div></div>
                            )}

                          {el.subscription ?
                            <div className="tableItem">
                              {el.subscription[el.subscription.length - 1]?.type === "basic" ? "Basic" : "Gold (" + (el.subscription[el.subscription.length - 1]?.payment_Period) + ")"}
                            </div>
                            :
                            <div className="tableItem">
                              {this.lang.basic}
                            </div>
                          }

                        </div>
                      );
                    })}
              </div>
            </div>
            {this.state.tab.length != 0 ? (
              <Pagination
                className={
                  this.props.user.type == "admin" ||
                    this.props.user.userPermissions.includes("Add transfers")
                    ? "test"
                    : "test_noAddTrans"
                }
                defaultCurrent={1}
                pageSize={VARR}
                total={this.state.nbrElements}
                showSizeChanger={false}
                responsive={true}
                onChange={this.PaginationLandLoard}
              />
            ) : null}
          </div> : <LoaderTenantive />}
          {this.AddTransfertModal()}
          <Modal className={this.props.lang + "_modal_edit_kyc"}
            footer={null}
            closable={false}
            visible={this.state.editModalVisible} >
            <Radio.Group onChange={this.onRadioFlagsChange} value={this.state.radioFlagValue} className="radio_flags">
              <Radio value="low">
                <img className="edit_KYC_modal_flags" alt="" src={greenFlag} />
                {this.lang.lowRisk}
              </Radio>
              <Radio value="medium">
                <img className="edit_KYC_modal_flags" alt="" src={orangeFlag} />
                {this.lang.mediumRisk}
              </Radio>
              <Radio value="high">
                <img className="edit_KYC_modal_flags" alt="" src={redFlag} />
                {this.lang.highRisk}
              </Radio>
            </Radio.Group>
            <div className={this.props.lang + "_edit_kyc_modal_footer"}>
              <Button onClick={() => this.showWarning()} className={this.props.lang + "_modal_edit_kyc_save"}>{this.lang.save}</Button>
              <Button
                className={this.props.lang + "_modal_edit_kyc_cancel"}
                onClick={this.hideEditKYCModal} >
                {this.lang.cancel}
              </Button>
            </div>
          </Modal>
          <Modal className={this.props.lang + "_modal_warning"}
            footer={null}
            closable={false}
            visible={this.state.warningModalVisible} >
            <p>{this.lang.changeColorWarning}</p>
            <div className={this.props.lang + "_btnEndContP"}>
              <div
                className={this.props.lang + "_btnendModulCi2"}
                onClick={() => this.updateRisk()}
              >
                {this.lang.changeColor}
              </div>
              <div
                className={this.props.lang + "_btnCancelContract"}
                onClick={this.handleCancel}
              >
                {this.lang.CancelApr}

              </div>
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
    errors: state.errors,
    auth: state.auth,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
    getAmount: (amount) => dispatch({ type: "GET_AMOUNT", payload: amount })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home));
