import React, { Component } from "react";
import Profil from "../../assets/icons/Profil.svg";
import Email from "../../assets/icons/Email.svg";
import Phone from "../../assets/icons/Phone.svg";
import { connect } from "react-redux";
import translator from "../../lang/translator";
import { Link, Switch, Route } from "react-router-dom";
import Checked from "../../assets/icons/IconCheckcircle.svg";
import Properties from "./LandlordsProperties/Properties";
import Rentals from "./LandlordsProperties/Rentals";
import Invoice from "./LandlordsProperties/Invoice";
import Deposit from "./LandlordsProperties/Deposit";
import ListBank from "./ListBanks/listBank";
import { apiUrl } from "../../config/config";
import AddTransfer from "../AddTransfer/addTransferModal";
import { Button } from "antd";
import { formateNumber } from "../helpers/Helper";
import ExpiryToast from "../dashboard/Util/ExpiryToast";
import moment from "moment";
import DeactivateModal from "./deactivateModal";
import Discount from "./LandlordsProperties/Discount";

class LandLordsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      name: "",
      email: "",
      phone: "",
      selected: "",
      attachement: [],
      tenantId: [],
      rentalsId: [],
      collections: [],
      search: "",
      checkedtedt: [],
      tenants: [],
      idBank: "Select bank account",
      amount: '',
      landLordId: '',
      invoiceAmount: "",
      rentAmount: "",
      deposit: '',
      risk: "",
      income: "",
      expiredToasts: [],
      isDeactivateVisible: false,
      isDeactivated: false,
      disDeactivate: true,
      tenantToasts: [],
      lanlordName: "",
      listProperties: [],
      windowWidth: window.innerWidth,
    };
    this.lang = translator(this.props.lang);
  }
  componentWillUnmount() {
    this.props.properties()
  }
  componentDidMount() {
    window.addEventListener("resize", () => {
      this.setState(() => {
        return {
          windowWidth: window.innerWidth,
        };
      });
    });
    this.getExpiredBanksToasts()
    this.getExpiredTenantToasts()
    this.setState({
      risk: this.props.match.params.risk,
      income: this.props.match.params.income,
    })
    fetch(apiUrl + "api/admin/profileView/" + this.props.match.params.id, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${this.props.auth.token}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          if (data.status === 200) {
            this.setState({
              name: data.landlord.name,
              email: data.landlord.email,
              phone: data.landlord.phone_number,
              accountType: data.landlord.subscription ? data.landlord.subscription : 'basic',
              isDeactivated: data.landlord.isDeactivatedByAdmin ? data.landlord.isDeactivatedByAdmin : false,
              disDeactivate: false,
              listProperties: data.properties
            });
          } else {
            return null;
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  openDeactivateModal = () => {
    this.setState({
      isDeactivateVisible: !this.state.isDeactivateVisible
    })
  }
  showModal = (id) => {
    this.setState({
      isTransferModalVisible: true,
      landLordId: id,
    })
    const formData = new FormData();
    formData.append("landlordId", id);
    fetch(apiUrl + "api/admin/getApprovedBanks", {
      method: "POST",
      mode: "cors",
      headers: { Authorization: "Bearer " + this.props.auth.token },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 200) {
          this.setState({
            approuvedBank: responseJson.bankAccounts,
          });
        }

      })
      .catch((error) => {
        console.error(error);
      });
  };
  closeModalTransfer = () => {
    this.setState({
      isTransferModalVisible: false,
      landLordId: ''
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedTab !== this.props.selectedTab) {
      this.setState({
        selected: this.props.selectedTab,
      });
    }
  }
  renderAccountType = (type) => {
    if (type === 'gold') {
      return 'Gold account'
    } else {
      return 'Basic account'
    }
  }
  getExpiredBanksToasts = () => {
    fetch(apiUrl + "api/admin/getToastByLandlord/" + this.props.match.params.id, {
      method: "GET",
      mode: "cors",
      headers: { Authorization: "Bearer " + this.props.auth.token },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 200) {
          this.setState({
            expiredToasts: responseJson.landlord
          });

        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  deactivateLandlord = () => {
    fetch(apiUrl + `api/admin/deactivateLandlordAccount/${this.props.match.params.id}`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.status === 200) {
          this.openDeactivateModal()
          this.setState({
            isDeactivated: true
          })

        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  closeBankToast = (toastID) => {
    fetch(apiUrl + "api/admin/neverShowToast/" + toastID, {
      method: "POST",
      mode: "cors",
      headers: { Authorization: "Bearer " + this.props.auth.token },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 200) {
          this.getExpiredBanksToasts()
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  reactivateLandlord = () => {
    fetch(apiUrl + `api/admin/activateLandlordAccount/${this.props.match.params.id}`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.status === 200) {
          this.openDeactivateModal()
          this.setState({
            isDeactivated: false
          })

        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  getExpiredTenantToasts = () => {
    fetch(apiUrl + "api/admin/getTenantToasts/" + this.props.match.params.id, {
      method: "GET",
      mode: "cors",
      headers: { Authorization: "Bearer " + this.props.auth.token },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 200) {
          this.setState({
            tenantToasts: responseJson.contractDetails,
            lanlordName: responseJson.landlordName
          });

        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  closeTenantToast = (toastID) => {
    fetch(apiUrl + "api/admin/neverShowTenantToast/" + toastID, {
      method: "POST",
      mode: "cors",
      headers: { Authorization: "Bearer " + this.props.auth.token },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status === 200) {
          this.getExpiredTenantToasts()
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  renderToasts() {
    let toast = []
    let toastNbr = []
    let number = 0
    let tenantToast = []
    let tenantToastsNumber = []
    let totalToastTab = []
    toast = this.state.expiredToasts.connectedBanks && this.state.expiredToasts.connectedBanks.map((el, index) =>
      el.expiredDocs).map(item => {
        return toastNbr.push(item.length)
      })
    tenantToast = this.state.tenantToasts.map((el, index) =>
      el.expiredDocs).map(item => {
        return tenantToastsNumber.push(item.length)
      })
    totalToastTab = toastNbr.concat(tenantToastsNumber)
    if (totalToastTab.length) {
      number = totalToastTab.reduce((accumulator, currentValue) => accumulator + currentValue)
    }
    return number
  }
  renderContainerClassName = () => {
    if (this.state.windowWidth < 1600) {
      return "coverProfil"
    }
    else {
      if (this.state.listProperties.length < 3) {
        return "cover-profile-big"
      }
      return "coverProfil"
    }
  }
  render() {

    return (
      <div className={` ${this.renderContainerClassName()} ${this.renderToasts() > 0 ? 'no_pTop minh-coverProfil' : ''}`}>
        {this.state.expiredToasts && this.state.expiredToasts.connectedBanks && this.state.expiredToasts.connectedBanks.map((el, index) =>
          el.expiredDocs.map((item, i) => {
            return < div key={index} className={this.state.expiredToasts.connectedBanks[this.state.expiredToasts.connectedBanks.length - 1].length - 1 === i ? 'mb-25' : ''} >
              <ExpiryToast top={(i * 56) + 121} key={i} closeExpiryToast={() => this.closeBankToast(item._id)} documentType={item.name} landlord={this.state.expiredToasts && this.state.expiredToasts.name} date={moment(item.date)._d} />
            </div>
          })
        )
        }
        {this.state.tenantToasts && this.state.tenantToasts.map((el, index) =>
          el.expiredDocs.map((item, i) => {
            return < div key={index} className={this.state.tenantToasts[this.state.tenantToasts.length - 1].length - 1 === i ? 'mb-25' : ''} >
              <ExpiryToast top={(i * 56) + 121} key={i}
                closeExpiryToast={() => this.closeTenantToast(item._id)}
                documentType={item.name}
                landlord={this.state?.lanlordName}
                tenant={el.TenantName}
                date={moment(item.date)._d} />
            </div>
          })
        )
        }
        <div className="row_profil_template pt-25">
          <div className="profil_template">
            <div className={this.props.lang + "_landlordProperty"}>
              <div>
                <img
                  className='profile_padding_element'
                  src={Profil}
                  alt="Profil"
                />
                <span>{this.state.name}</span>
              </div>
            </div>
            <div className={this.props.lang + "_landlordProperty"}>
              {this.state.email ? <div>
                <img className='profile_padding_element' src={Email} alt="Profil" />
                <span>{this.state.email}</span>
              </div> : null}
            </div>
            <div className={this.props.lang + "_landlordProperty"}>
              {this.state.phone ? <div>
                <img className='profile_padding_element' src={Phone} alt="Profil" />
                <span>{this.state.phone}</span>
              </div> : null}
            </div>
          </div>

          {(this.props.user.type == "admin" ||
            this.props.user.userPermissions.includes("Add transfers")) && (
              <div className={
                this.props.auth.risk === "high" ?
                  this.props.lang + "_landlordPropertyTransfert high_risk" : this.props.auth.risk === "medium" ?
                    this.props.lang + "_landlordPropertyTransfert medium_risk" : this.props.auth.risk === "low" ?
                      this.props.lang + "_landlordPropertyTransfert low_risk" : this.props.lang + "_landlordPropertyTransfert"}>
                <div className='propertyTransfer_container'>
                  <div>
                    <span className="transfet_Amount">
                      {this.lang.TransferAmount}
                    </span>
                    <div>
                      <span className="Amount">{formateNumber(this.props.amount)}</span>
                      <span className="devise">KWD</span>
                    </div>
                  </div>
                  <Button
                    disabled={this.props.user.view_only ? true : false}
                    onClick={() => this.showModal(this.props.match.params.id)}
                    className={this.props.user.view_only ? this.props.lang + "_btn-action-rental-disabled" : this.props.lang + "_btn-action-rental"}
                  >
                    {this.lang.AddTransfert}
                  </Button>
                </div>
                <div className={this.props.auth.risk === "high" ?
                  "landlord_risk high" : this.props.auth.risk === "medium" ?
                    "landlord_risk medium" : this.props.auth.risk === "low" ?
                      "landlord_risk low" : "none"}>
                  <span>{this.props.auth.risk === "high" ?
                    this.lang.highRisk : this.props.auth.risk === "medium" ?
                      this.lang.mediumRisk :
                      this.lang.lowRisk}</span>
                  <span>{this.props.auth.income}</span>
                </div>
              </div>
            )}
          <div
            className={
              this.props.user.type == "admin" ||
                this.props.user.userPermissions.includes("Add transfers")
                ? this.props.lang + "_accounting-btn-container"
                : this.props.lang + "_accounting-btn-container_noAddTrans"
            }
          >
            {this.props.user.type !== "admin" &&
              this.props.user.userPermissions.includes(
                "Accept bank account"
              ) && this.props.user.userPermissions.length === 1 && <p className="ConnectedBanksLabel">Connected Banks</p>}
            {(this.props.user.type == "admin" ||
              this.props.user.userPermissions.includes("Add transfers")) && (
                <div span={12} className="selectbutton">
                  <Link to={`/Landlords/${this.state.id}`}>
                    <div
                      onClick={this.props.properties}
                      className={
                        this.props.selectedTab === "Properties"
                          ? this.props.lang + "_accounting-btn-list-e"
                          : this.props.lang + "_accounting-btn-list"
                      }
                      id={this.props.lang + "_br-btn-list-first"}
                    >
                      {this.lang.properties}
                    </div>
                  </Link>
                  <Link to={`/Landlords/${this.state.id}/Rentals`}>
                    <div
                      onClick={this.props.rentals}
                      className={
                        this.props.selectedTab === "Rentals"
                          ? this.props.lang + "_accounting-btn-list-e"
                          : this.props.lang + "_accounting-btn-list"
                      }
                    >
                      {this.lang.Rentals}
                    </div>
                  </Link>
                  <Link to={`/Landlords/${this.state.id}/connectedbank`}>
                    <div
                      onClick={this.props.ConnectedBank}
                      className={
                        this.props.selectedTab === "Connected_Bank"
                          ? this.props.lang + "_accounting-btn-list-e"
                          : this.props.lang + "_accounting-btn-list"
                      }
                    >
                      {this.lang.ConnectedBank}
                    </div>
                  </Link>
                  <Link to={`/Landlords/${this.state.id}/Invoice`}>
                    <div
                      onClick={this.props.Invoice}
                      className={
                        this.props.selectedTab === "Invoices"
                          ? this.props.lang + "_accounting-btn-list-e"
                          : this.props.lang + "_accounting-btn-list"
                      }
                    >
                      Invoices
                    </div>
                  </Link>
                  <Link to={`/Landlords/${this.state.id}/Deposit`}>
                    <div
                      id={this.props.lang + "_br-btn-last"}
                      onClick={this.props.Deposit}
                      className={
                        this.props.selectedTab === "Deposit"
                          ? this.props.lang + "_accounting-btn-list-e"
                          : this.props.lang + "_accounting-btn-list"
                      }
                    >
                      {this.lang.Deposit}
                    </div>
                  </Link>
                </div>
              )}
          </div>
          <div
            className={
              this.props.user.type == "admin" ||
                this.props.user.userPermissions.includes("Add transfers")
                ? "Subs"
                : "Subs_noAddTrans"
            }
          >
            <div
              className={
                this.props.user.type == "admin" ||
                  this.props.user.userPermissions.includes("Add transfers")
                  ? this.props.lang + "_account_subscription"
                  : this.props.lang + "_account_subscription_noAddTrans"
              }
            >
              <span className="Account_sub_text">
                {this.lang.Account_subscription}
              </span>
              <div>
                <img
                  className={this.props.lang + "_checked"}
                  src={Checked}
                  alt="checked"
                />
                <span className="basic_account">{this.renderAccountType(this.state.accountType)}</span>
              </div>
            </div>
          </div>
        </div>
        <AddTransfer
          closeModalTransfer={this.closeModalTransfer}
          data={this.state}
          isTransferModalVisible={this.state.isTransferModalVisible} />
        <Switch>
          <Route exact path="/Landlords/:id"
            render={(props) => <Properties getExpiredTenantToasts={this.getExpiredTenantToasts} {...props} />} />
          <Route exact path="/Landlords/:id/Rentals" component={Rentals} />
          <Route
            exact
            path="/Landlords/:id/connectedbank"
            render={(props) => <ListBank getExpiredBanksToasts={this.getExpiredBanksToasts} {...props} />}
          />
          <Route exact path="/Landlords/:id/Invoice" component={Invoice} />
          <Route exact path="/Landlords/:id/Deposit" component={Deposit} />
        </Switch>
        <Discount properties={this.state.listProperties} idLandlord={this.props.match.params.id} />
        <Button onClick={this.openDeactivateModal} disabled={this.state.disDeactivate || this.props.user.type !== "admin"} className={`deactivate_landlord_btn ${this.renderToasts() > 0 ? 'relative' : ''}`} >
          {this.state.isDeactivated ? ' Reactivate Landlord ' : ' Deactivate Landlord'}
        </Button>
        {
          this.state.isDeactivateVisible && <DeactivateModal
            reactivateLandlord={this.reactivateLandlord}
            deactivateLandlord={this.deactivateLandlord}
            openDeactivateModal={this.openDeactivateModal}
            isDeactivated={this.state.isDeactivated}
            isDeactivateVisible={this.state.isDeactivateVisible} />
        }
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
    selectedTab: state.selectedTab,
    auth: state.auth,
    user: state.user,
    amount: state.amount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ConnectedBank: () =>
      dispatch({ type: "CONNECTED_BANK_SELECTED", payload: "Connected_Bank" }),
    rentals: () => dispatch({ type: "RENTALS_SELECTED", payload: "Rentals", startDate: '', endDate: '' }),
    properties: () =>
      dispatch({ type: "PROPERTIES_SELECTED", payload: "Properties" }),
    Invoice: () => dispatch({ type: "INVOICE_SELECTED", payload: "Invoices" }),
    Deposit: () => dispatch({ type: "DEPOSIT_SELECTED", payload: "Deposit", startDate: '', endDate: '' }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandLordsMenu);
