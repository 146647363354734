import React, { Component } from 'react';
import checked from "../../../../assets/images/Checkcircle.svg"
import UIKITBUTTON from "../../../../UIKIT/button/UIKITBUTTON";
import { connect } from "react-redux";
import { Modal, Row, Col, Button } from "antd";
import { apiUrl } from "../../../../config/config";
import translator from "../../../../lang/translator";
import { convertArabicNumbers } from './ArabicConverter';
class ValidateNewNumb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            num: 0,
            code: null,
            errorMsg: "",
            code1: "",
            code2: "",
            code3: "",
            code4: "",
            resendCodeMessage: "",
            isButtonDisabled: false,
            validate: false
        }
        this.lang = translator(this.props.lang);
    }
    handelClick = () => {
        let code1 = this.state.code1;
        let code2 = this.state.code2;
        let code3 = this.state.code3;
        let code4 = this.state.code4;
        let code = code1.concat(code2, code3, code4);

        let theCode = Number(code);

        const formData = new FormData();

        formData.append("phone_number", this.props.phoneNumber);
        formData.append("verificationCode", theCode);
        fetch(apiUrl + "api/admin/checkVerificationCode", {
            method: "POST",
            mode: "cors",
            headers: { Authorization: "Bearer " + this.props.auth.token },
            body: formData,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 400) {
                    this.setState({ errorMsg: this.lang.forgotPasswordWarning });
                } else if (responseJson.status === 200) {
                    this.setState({
                        validate: true,
                        code1: '',
                        code2: '',
                        code3: '',
                        code4: '',
                        errorMsg: '',
                    })
                    this.props.onShowValidateModal();
                    this.props.onShowNewNumberModal();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    handleInputChange = (e, num) => {
        const name = e.target.name;
        let inputValue = e.target.value
        const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
        inputValue = convertArabicNumbers(inputValue)
        if ((!Number.isNaN(inputValue) && reg.test(inputValue)) && inputValue !== '-' || inputValue === '') {
            document.getElementById("code" + num).focus();
            this.setState((state) => ({
                ...state,
                [name]: inputValue.slice(0, 1),
            }))
        }
    }
    render() {
        return (
            <Modal
                visible={this.props.validateNumbVisible}
                footer={null}
                closable={false}
            >
                <Row className="sms_input_container">
                    <Col>
                        <div
                            hoverable
                            className="verify_card_phone"
                        >
                            <div className="verify_card_phone_text">
                                <img
                                    alt="checkIcon"
                                    width={92} height={92}
                                    src={checked}
                                />
                                <p className="thank_you_text">
                                    {this.lang.forgotPasswordThankyou}
                                </p>
                                <p className="sms_verification_text">
                                    {this.lang.forgotPasswordMessage}
                                </p>
                            </div>
                            <div className="sms_input_container">
                                <div
                                    className="input_numbers_container"
                                >
                                    <input
                                        autoFocus
                                        className={
                                            this.state.code1 != ""
                                                ? "sms_verification_blue_phone"
                                                : "sms_verification_grey_reset"
                                        }
                                        type="text"
                                        inputMode="numeric"
                                        name="code1"
                                        value={this.state.code1}
                                        maxLength={1}
                                        onChange={(event) => {
                                            this.handleInputChange(event, 2)
                                        }}
                                    />
                                    <input
                                        id="code2"
                                        className={
                                            this.state.code2 != ""
                                                ? "sms_verification_blue_phone"
                                                : "sms_verification_grey_reset"
                                        }
                                        autoFocus={this.state.code1 != "" ? true : false}
                                        type="text"
                                        inputMode="numeric"
                                        name="code2"
                                        maxLength={1}
                                        value={this.state.code2}
                                        onChange={(event) => {
                                            this.handleInputChange(event, 3)
                                        }}
                                    />
                                    <input
                                        id="code3"
                                        className={
                                            this.state.code3 != ""
                                                ? "sms_verification_blue_phone"
                                                : "sms_verification_grey_reset"
                                        }
                                        type="text"
                                        inputMode="numeric"
                                        name="code3"
                                        value={this.state.code3}
                                        onChange={(event) => {
                                            this.handleInputChange(event, 4)
                                        }}
                                    />
                                    <input
                                        id="code4"
                                        className={
                                            this.state.code4 != ""
                                                ? "sms_verification_blue_phone"
                                                : "sms_verification_grey_reset"
                                        }
                                        type="text"
                                        inputMode="numeric"
                                        name="code4"
                                        maxLength={1}
                                        value={this.state.code4}
                                        onChange={(event) => {
                                            this.handleInputChange(event, 4)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="sms_verify_buttons_container">
                                <p className="errStyle"> {this.state.errorMsg} </p>
                                <div className="continue_button_container">
                                    <UIKITBUTTON
                                        className="verfiy_sms_continue_button"
                                        text={this.lang.continue}
                                        disabledbtn={
                                            this.state.code1 === "" ||
                                            this.state.code2 === "" ||
                                            this.state.code3 === "" ||
                                            this.state.code4 === ""
                                        }
                                        action={() => this.handelClick()}
                                    />
                                </div>
                                <div
                                    onClick={() => this.setState({
                                        code1: '',
                                        code2: '',
                                        code3: '',
                                        code4: '',
                                        errorMsg: ''
                                    }, this.props.onShowValidateModal)}
                                    className='cancel_sms_verification'>
                                    {this.lang.cancel}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        lang: state.auth.lang,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidateNewNumb);