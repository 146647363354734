import ar from "./ar";
import en from "./en";

const translator = (lang) => {
  if (lang == "ar") {
    return ar;
  }
  return en;
};

export default translator;
