import React, { Component } from "react";
import { DatePicker, Input, Row, Col, Button } from "antd";
import date from "../../assets/Accounting/date.svg";
import translator from "../../lang/translator";
import { connect } from "react-redux";
import { Pagination } from "antd";
import Receipt from "./receipt";
import { apiUrl } from "../../config/config";
import Empty from "antd/es/empty";
import moment from "moment";
import redFlag from "../../assets/icons/redFlag.png";
import orangeFlag from "../../assets/icons/orangeFlag.png";
import greenFlag from "../../assets/icons/greenFlag.png";
import { formateNumber } from "../helpers/Helper";
const VARR = 10;
const { Search } = Input;
const { RangePicker } = DatePicker;
class Transfers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property: [],
      search: "",
      date_search: "",
      date: "",
      startDate: "",
      endDate: "",
      index: null,
      transferValue: null,
      collectedValue: null,
      currencyMoney: "KWD",
      page: VARR,
      nbrElements: '',
      transferData: [],
      loading: false,
      visible: false,
      transferView: [],
      windowWidth: "",
    };
    this.lang = translator(this.props.lang);
  }
  showModal = (id) => {
    const formData = new FormData();
    formData.append("transferId", id);
    fetch(apiUrl + "api/admin/viewTransfer", {
      method: "POST",
      mode: "cors",
      headers: { Authorization: "Bearer " + this.props.auth.token },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {

          this.setState({
            transferView: responseJson.transfer
          })

        }
      })
      .catch((error) => {
        console.error(error);
      });
    this.setState({
      index: id,
      visible: true,
    })
  };
  handleOk = e => {

    this.setState({
      visible: false,
    });
  };
  filtration = (value) => {
    const formData = new FormData();
    formData.append("searchedLandlord", value);
    formData.append("dateStart", this.state.startDate);
    formData.append("dateEnd", this.state.endDate);
    fetch(apiUrl + `api/admin/searchTransfer?page=${1}&per_page=${VARR}`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token
      },
      body: formData,

    })

      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 201) {
          this.setState({
            transferData: responseJson.transfers,
            search: value,
            nbrElements:responseJson.nbrElements
          })

        } else {
          this.setState({
            search: value,
            nbrElements:responseJson.nbrElements
          })
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  pagination = (page, pageSize) => {
    const formData = new FormData();
    formData.append("searchedLandlord", this.state.search);
    formData.append("dateStart", this.state.startDate);
    formData.append("dateEnd", this.state.endDate);
    fetch(apiUrl + `api/admin/searchTransfer?page=${page}&per_page=${pageSize}`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token
      },
      body: formData,

    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == 201) {
          this.setState({
            transferData: responseJson.transfers,
            nbrElements:responseJson.nbrElements
          })
        }
      })
      .catch((error) => {
        console.error(error);
      });

  }
  change = (value, rangeDate) => {

    if (value === null) {
      this.getListTransfers()
    }
    this.setState({
      startDate: rangeDate[0],
      endDate: rangeDate[1],
    });
  };


  getListTransfers = () => {
    var url = new URL(apiUrl + "api/admin/getTransfersList"),
      params = { page: 1, per_page: VARR }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token
      },

    }).then(response => {
      response.json().then(data => {
        if (data.status === 200) {
          this.setState({
            nbrElements: data.nbrElements,
            transferData: data.transfers,
            transferValue: data.transfers_amount,
            collectedValue: data.collections_amount,
            wallet_amount: data.wallet_amount,
            loading: true
          })
        }

      }).catch(err => console.log(err))

    }).catch((err) => console.log("erreur fetch", err))
  }

  componentDidMount() {
    this.getListTransfers();
    window.addEventListener("resize", () => {
      this.setState(() => {
        return {
          windowWidth: window.innerWidth,
        };
      });
    });
  }
  resetTable = (e) => {

    if (e === '') {
      this.getListTransfers()
    }

  }


  render() {
    return (

      this.state.loading ?
        <div className={this.props.lang + "_accounting_transfer_bigContainer"}>
          <Row >
            <Col
              span={8}
              className={this.props.lang + "_transfers-collections-card"}
            >
              <h2 className={this.props.lang + "_collection-card-txt"}>
                {this.lang.collections}
              </h2>
              <h1 className={this.props.lang + "_collection-card-txt"}>
                {formateNumber(this.state.collectedValue)}{" "}
                {this.props.lang === "en" ? this.state.currencyMoney : " د.ك"}
              </h1>
            </Col>
            <Col
              className={this.props.lang + "_transfers-transferred-card"}
              span={8}
            >
              <h2 className={this.props.lang + "_transferred-card-txt"}>
                {this.lang.Transfers}
              </h2>
              <h1 className={this.props.lang + "_transferred-card-txt"}>
                {formateNumber(this.state.transferValue)}{" "}
                {this.props.lang === "en" ? this.state.currencyMoney : " د.ك"}
              </h1>
            </Col>
            <Col
              className={this.props.lang + "_transfers-wallet-card"}
              span={8}
            >
              <h2 className={this.props.lang + "_wallet-card-txt"}>
                {this.lang.wallet_accounting}
              </h2>
              <h1 className={this.props.lang + "_wallet-card-txt"}>
                {formateNumber(this.state.wallet_amount)}{" "}
                {this.props.lang === "en" ? this.state.currencyMoney : " د.ك"}
              </h1>
            </Col>
          </Row>
          <Row className={this.props.lang + "_row-transfers-btn"}>
            <Col
              span={5}
              xs={24}
              sm={24}
              md={8}
              lg={5}
              xl={4}
              style={this.state.windowWidth <= 500 ? {
                height: "40px", marginBottom: "20px"
              } : { height: "40px" }}
              className={this.props.lang + "_col-transfers-search"}
            >
              <Search
                allowClear={true}
                style={{
                  height: "100% ",
                  direction: "ltr",
                }}
                placeholder={this.lang.Search}
                onClick={() => this.pagination(1, 10)}
                onSearch={(value) => this.filtration(value)}
                enterButton
                loading={false}
                onChange={(value) => this.resetTable(value)}
                className='search-input'
              />
            </Col>
            <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
            <Col
              span={5}
              xs={24}
              sm={24}
              md={8}
              lg={5}
              xl={4}
              className={this.props.lang + "_transfers-picker-container"}
            >
              <RangePicker
                style={{ width: '100%', height: 40 }}
                className={this.props.lang + "_transfers-date-picker"}
                allowEmpty={true}
                separator=""
                placeholder={[this.lang.dateSearch, ""]}
                onChange={this.change}
                format="YYYY-MM-DD"
                suffixIcon={false}
              />
              <Button
                onClick={() => this.filtration(this.state.search,)}
                id={this.props.lang + "_date-btn-transfers"}
              >
                <img src={date} alt="" />{" "}
              </Button>
            </Col>

          </Row>
          <Row>
            <Col span={24}>

              <div style={{ marginTop: '25px', overflow: "auto", display: "grid" }}>
                <div className="table_header2">
                  <span className="table_header_items_name">{this.lang.Date}</span>
                  <span>{this.lang.transfert_to}</span>
                  <span>{this.lang.iban_bank_name}</span>
                  <span>{this.lang.Actual_transfer_kwd}</span>
                  <span>{this.lang.kyc}</span>
                </div>
                <div>
                  {this.state.transferData.length == 0 ?
                    <div className="noData_Container_">
                      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      <p className='noData'>
                        No Data !
                      </p>
                    </div>
                    : this.state.transferData.map((el, index) => {
                      return (
                        <div key={el._id} className="row_table2">
                          <div className={this.props.lang + "_transfer_date tableItem table_header_items_name"}>
                            <span className="first">{moment(el.transfer_date).format('DD/MM/YYYY')}</span>
                            <span className="second">{moment(el.transfer_date).format('hh:mm A')}</span>
                          </div>
                          <div onClick={() => this.showModal(el._id)} className="tableItem"> <p className={this.props.lang + '_transfers_toTxt'} > {el.transfer_to}
                          </p></div>
                          <div>
                            <div className="transfers_bank_name">{el.iban_number}</div>
                            <div className="transfers_bank_number">{el.bank_name}</div>
                          </div>
                          <div>
                            <span className="Amount">{formateNumber(el.actual_transfer)}</span>
                          </div>
                          <div>
                            <img alt="" src={el.risk === 'high' ? redFlag : el.risk === 'medium' ? orangeFlag : greenFlag} />
                          </div>


                        </div>
                      )
                    }
                    )
                  }
                  <Receipt handleOk={this.handleOk} index={this.state.index} visible={this.state.visible} transferView={this.state.transferView} />
                </div>
              </div>
              {
                this.state.nbrElements != 0 ?
                  <div className='paginationStyle'>
                    <Pagination
                      defaultCurrent={1}
                      className="test"
                      pageSize={VARR}
                      total={this.state.nbrElements}
                      showSizeChanger={false}
                      responsive={true}
                      onChange={this.pagination} />

                  </div> : null
              }
            </Col>
          </Row>
        </div> : null


    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
    auth: state.auth
  };
};
export default connect(mapStateToProps, null)(Transfers);
