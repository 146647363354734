const lang = {
  welcome: "مرحبًا بكم !",
  pleaseLogIn: "الرجاء تسجيل الدخول",
  username: "اسم المستخدم",
  password: "كلمه السر",
  LogIn: "تسجيل الدخول",
  landlord: "المالك",
  Transfers: "التحويلات",
  enterUsername: "ادخل اسم المستخدم",
  enterPassword: "ادخل كلمه السر",
  PageNotFound: "الصفحة غير موجودة",
  sorry: "عذرا ، الصفحة التي تبحث عنها غير متوفرة حاليا",
  btnReturn: "العودة الى الصفحة الرئيسية",
  PageNotAuthorized: "الصفحة غير مصرح بها",
  search: "ابحث",
  findLandLord: "البحث عن المالك",
  units: "وحدة",
  Unit: "وحدة",
  Units: "وحدات",
  Action: "عمل",
  transferAmount: "المبلغ",
  loginUserNotFound: "المستخدم غير موجود",
  forgotPasswordSpeicalChar: "حرف خاص",


  /*******************************LandLords Property View */
  properties: "المباني",
  Rentals: "الإيجارات",
  ConnectedBank: "البنك المتصل",
  Account_subscription: "اشتراك الحساب",
  Basic_account: "الحساب أساسي  ",
  ContractPeriode: "مدة العقد ",
  ContractStatus: "حالة التعاقد",
  ARV_Search: "ابحث",
  ARV_Status: "الحاله",
  ARV_DueDate: "تاريخ الاستحقاق",
  Date: "التاريخ",
  TenantName: "اسم المستأجر ",
  MonthlyRate: "المعدل الشهري",
  status: "الحالة",
  ViewReceipt: "استلام عرض",
  Tenant: "المستأجر",
  Amount: "كمية",
  Property: "ملكية",

  /*------trasfers------*/
  Search: "ابحث",
  dateSearch: "التاريخ",
  collections: "التحصيلات",
  bank_account: "الحساب البنكي",
  kwd: " د.ك",
  Approved: "Approved",
  Approve: "Approve",
  Rejected: "Rejected",
  Reject: "Reject",
  CancelApr: "Cancel",
  msgforApprv: "Sure you want to approve ? ",
  msgforReject: "Sure you want to reject ? ",
  PendingBanks: 'Pending banks',
  Download: "Download",
  RTVStPending: "Pending",
  RTVStRejected: "Rejected",
  RTVStApproved: "Approved",
  multiFactorAuth: "Security ",
  subscriptions:"الاشتراكات",
  multiFactorAuth1: "سجل الدخول باستخدام المصادقة الثنائية ",
  off: 'Off',
  on: 'On',
  BruteForceError: "You have been blocked for 5 minutes. Please retry again later.",
  discount: 'Discount',
  ARVRReceipt: "فاتورة",
  transaction_id: "رقم المعاملة",
  merchant_id: "معرّف التاجر",
  payment_id: 'معرف الدفع',
  reference_id: 'الرقم المرجعي',
  ARVRt: "من",
  ARTRTo: "الى",
  UnitRented_KWD: "د.ك",
  ARTPyment: "طريقة الدفع",
  subscription_fees: 'رسوم الاشتراك',
  addDiscount:"أضف الخصم",
  discount_for:"خصم ل",
  period:"فترة",
  percentage:"النسبة المئوية",
  select:"اختار",
  select_property:"اختار",
  select_period:"اختار",
  all_properties:"كل الممتلكات",
  transaction_fees:"رسوم التحويل",
  Gold_subscription:"الاشتراك الذهبي"
};

export default lang;
