import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import logger from "redux-logger-simple";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import AuthReducer from "./Reducers/AuthReducer";
import LandLordSelectReducer from "./Reducers/LanLordSelectReducer";
import SearchReducer from "./Reducers/SearchReducer";
import ErrorReducer from "./Reducers/ErrorReducer";
import ConnectedUser from "./Reducers/UserReducer";
import SubsUserReducer from "./Reducers/SubsReducer";
import AmountReducer from "./Reducers/AmountReducer";
import ReceiptReducer from "./Reducers/ReceiptReducer";
import MessengerReducer from "./Reducers/MessengerReducer";

const persistConfig = {
  key: "User",
  timeout: null,
  storage,
  whitelist: ["auth", "user", "amount", "lists", 'selectedTab'],
  blacklist: []
};
const middleware = [thunkMiddleware];
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware, logger)
  // other store enhancers if any
);
const reducers = combineReducers({
  auth: AuthReducer,
  user: ConnectedUser,
  selectedTab: LandLordSelectReducer,
  search: SearchReducer,
  errors: ErrorReducer,
  lists: SubsUserReducer,
  amount: AmountReducer,
  receipt: ReceiptReducer,
  messenger: MessengerReducer
});

const persistedReducers = persistReducer(persistConfig, reducers);

const Store = createStore(persistedReducers, enhancer);

export default Store;
export const persistor = persistStore(Store);
