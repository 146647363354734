import { apiUrl } from "../config/config";

export const registerUser = (userData, formDataLogin) => (dispatch) => {
  fetch(apiUrl + "admin/subscribtion", {
    method: "POST",
    mode: "cors",
    headers: {},
    body: userData,
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(formDataLogin);
      for (var pair of formDataLogin.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }
      if (data.status === 201) {
        dispatch(loginUser(formDataLogin));
      } else if (data.status === 400) {
        dispatch({
          type: "GET_ERRORS_REGISTER",
          payload: "User name is already in use!",
        });
      } else if (data.status === 404) {
        dispatch({
          type: "GET_ERRORS_REGISTER",
          payload: "Subuser not found!",
        });
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: "GET_ERRORS_REGISTER",
        payload: "err",
      });
    });
};

export const loginUser = (userData) => (dispatch) => {
  fetch(apiUrl + "admin/login", {
    method: "POST",
    mode: "cors",
    headers: {},
    body: userData,
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === 200) {
        if (data.factorAuthentication) {
          dispatch({
            type: "setToken&number",
            token: data.token, factorAuthentication: data.factorAuthentication
          });
          window.location = '/smsVerfication'
        } else {
          const { token } = data;
          localStorage.setItem("jwtToken", token);
          dispatch(setCurrentUser({ admin: { adminId: data.adminId, name: data.nom }, token: token }));
        }
      } else if (data.status === 429) {

        dispatch({
          type: "GET_ERRORS",
          payload: "bruteForce",
        });
      } else {

        dispatch({
          type: "GET_ERRORS",
          payload: "erreur",
        });
      }

    })
    .catch((err) => {
      console.log(err);
    });
};

export const setCurrentUser = (decoded) => {
  return {
    type: "SET_CURRENT_USER",
    payload: decoded,
  };
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  dispatch({ type: "LOGOUT" });
};

export const getConnectedAdmin = (token) => {
  return (dispatch) => {
    fetch(apiUrl + "api/admin/getConnectedAdmin", {
      method: "GET",
      mode: "cors",
      headers: { Authorization: "Bearer " + token },
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "GET_CONNECTED_USER",
          payload: data.admin,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getPendingSubusers = (token) => {
  return (dispatch) => {
    fetch(apiUrl + `api/admin/getPendingSubList`, {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "GET_PENDING_SUBUSERS",
          payload: data.pendingSubusers
        })

      })
      .catch((err) => {
        console.log(err);
      });
  }
}

export const getActiveSubusers = (token) => {
  return (dispatch) => {
    fetch(apiUrl + `api/admin/getActiveSubList`, {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "GET_ACTIVE_SUBUSRES",
          payload: data.activeSubusers
        })

      })
      .catch((err) => {
        console.log(err);
      });
  }
}

export const getReceipt = (id, token) => {
  return (dispatch) => {
    fetch(apiUrl + "api/contract/getReceiptByID/" + id, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        response.json().then((data) => {
          if (data.status === 200) {
            dispatch({
              type: "SET_RECEIPT",
              payload: data.receipt
            })
          } else {
            dispatch({
              type: "SET_RECEIPT",
              payload: {}
            })
          }

        });
      })
      .catch((err) => {
        console.log("error ", err);
      });
  }
}

export const getReceiptByInvoiceID = (invoice_id, token) => {
  return (dispatch) => {
    fetch(apiUrl + `api/getReceiptByInvoiceID/${invoice_id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        response.json().then((data) => {
          if (data.status === 200) {
            dispatch({
              type: "SET_RECEIPT",
              payload: data.receipt
            })
          } else {
            dispatch({
              type: "SET_RECEIPT",
              payload: {}
            })
          }

        });
      })
      .catch((err) => {
        console.log("error ", err);
      });
  }
}

export const getReceiptByDepositID = (deposit_id, token) => {
  return (dispatch) => {
    fetch(apiUrl + `api/contract/getReceiptByContractID/${deposit_id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        response.json().then((data) => {
          if (data.status === 200) {
            dispatch({
              type: "SET_RECEIPT",
              payload: data.receipt
            })
          } else {
            dispatch({
              type: "SET_RECEIPT",
              payload: {}
            })
          }

        });
      })
      .catch((err) => {
        console.log("error ", err);
      });
  }
}