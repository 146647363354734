
import { Button, Col, Row } from "antd";
import React from "react";
import { connect } from "react-redux";
import translator from "../../../lang/translator";

class ProfileDetails extends React.Component {
    constructor(props) {
        super(props)
        this.lang = translator(this.props.lang)
    }

    render() {
        return (
            <div >
                <Row className="">
                    <Col className="profile_name">{this.lang.password}</Col>
                </Row>
                <Row>
                    <Col className="profile_email">
                        {this.lang.changeYourPassword}
                    </Col>
                </Row>
                <Row >
                    <Col>
                        <Button
                            className="change_password_button"
                            onClick={this.props.onChangePassword}
                        >{this.lang.changePassword}
                        </Button>
                    </Col>
                </Row>
            </div>

        )
    }

}
const mapStateToProps = state => {
    return {
        auth: state.auth,
        lang: state.auth.lang
    };
};
const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);

