"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.styles = void 0;
var styles = {
  legendRowContainer: {
    padding: '5px 0',
    display: 'inline',
    
  },
  legendRowTitleContainer: {
    display: 'inline-block',
    fontSize: 14,
    
  },
  legendRowTitle: {
    fontSize: 14,
    color: '#999',
    paddingLeft: 14,
  },
  legendRowBody: {
    float: 'right',
    fontSize: 14,
    color: '#333',
    fontWeight: 600,
  },
  legendContainer: {
    display: 'block',
    float: 'left',
    boxSizing: 'border-box',
    width: '100%',
  },
  colorBarSectionsContainer: {
    display: 'flex',
    borderRadius: 5,
    overflow: 'hidden',
    height: 10,
    width: '100%'
  },
  colorBarSectionsContainer2: {
    display: 'flex',
    border :".5px solid #8088A1",
    borderRadius: 5,
    overflow: 'hidden',
    height: 10,
    width: '100%'
  }
};
exports.styles = styles;