import { Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from "react-redux";
import translator from "../../lang/translator";
class DeactivateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.lang = translator(this.props.lang);
    }
    render() {
        return (
            <Modal
                centered
                width='317px'
                className='deactivate_modal'
                footer={null}
                closable={false}
                visible={this.props.isDeactivateVisible} >
                <p>{this.props.isDeactivated ? this.lang.surtoreactivate : this.lang.surtodeactivate} </p>
                <div className={this.props.lang + "_btnEndContP"}>
                    <div
                        className={this.props.lang + "_btnendModulCi deact_btn"}
                        onClick={this.props.isDeactivated ? () => this.props.reactivateLandlord() : () => this.props.deactivateLandlord()}
                    >
                        {this.props.isDeactivated ? 'Reactivate' : 'Deactivate'}
                    </div>
                    <div
                        className={this.props.lang + "_btnCancelContract"}
                        onClick={this.props.openDeactivateModal}
                    >
                        {this.lang.CancelApr}
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
    };
};

export default connect(mapStateToProps, null)(DeactivateModal);