import React, { Component } from "react";
import logoWhite from "../../../assets/images/logoWhite.svg";
import checked from "../../../assets/Property/checkEnabled.svg";
import GroupeProperty from "../../../assets/images/GroupeProperty.svg";
import UIKITBUTTON from "../../../UIKIT/button/UIKITBUTTON";
import { connect } from "react-redux";
import { Card } from "antd";
import { Row, Col } from "antd";
import { apiUrl } from "../../../config/config";
import translator from "../../../lang/translator";
const { Meta } = Card;
const SMS_NUMBER_LENGHT = 4;
class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      num: 0,
      code: "",
      errorMsg: "",
      code1: "",
      code2: "",
      code3: "",
      code4: "",
    };
    this.lang = translator(this.props.lang);
  }
  handelClick() {
    let code1 = this.state.code1;
    let code2 = this.state.code2;
    let code3 = this.state.code3;
    let code4 = this.state.code4;
    let code = code1.concat(code2, code3, code4);
    code = parseInt(code);
    if (this.props.auth.factorAuthentication) {
      const formData = new FormData();
      formData.append("phone_number", this.props.auth.phone_number);
      formData.append("verificationCode", code);
      fetch(apiUrl + "api/admin/checkVerificationCode", {
        method: "POST",
        mode: "cors",
        headers: { Authorization: "Bearer " + this.props.auth.tokenFactor },
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === 400) {
            this.setState({ errorMsg: this.lang.forgotPasswordWarning });
          } else if (responseJson.status === 200) {
            let action = { type: "SET_CURRENT_USER", payload: { admin: { adminId: responseJson.adminId, name: responseJson.nom }, token: this.props.auth.tokenFactor } };
            this.props.dispatch(action);
            this.props.history.push("/");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const formData = new FormData();
      formData.append("phone_number", this.props.auth.phone_number);
      formData.append("verification_code", code);
      fetch(apiUrl + "admin/verifyActivationCode", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => {
          response.json().then((data) => {
            if (data.status == 201) {
              this.props.history.push("/subUserRegister");
            } else {
              this.setState({ errorMsg: "This code is invalid" });
            }
          });
        })
        .catch((err) => {
          console.log("error ");
        });
    }

  }

  SendCodeAgain = () => {
    const formData = new FormData();
    formData.append("phone_number", this.props.auth.factorAuthentication ? this.props.auth.phone_number : "+" + this.props.auth.phone_number);
    let path = this.props.auth.factorAuthentication ? "api/admin/resendVerificationCodeForFactorAuthentication" : 'admin/resendActivationCode'
    fetch(apiUrl + path, {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => {
        response.json().then((data) => {
          if (data.status == 200) {
            console.log("success");
          } else {
            this.setState({ errorMsg: "this code is invalid" });
          }
        });
      })
      .catch((err) => {
        console.log("error ");
      });
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "#36d1e0",
          height: "100vh",
        }}
      >
        <img
          src={logoWhite}
          style={{
            marginLeft: "8%",
            marginTop: "2%",
          }}
          alt="aa"
        />
        <Row>
          <Col span={8} xs={0} sm={0} md={8} lg={8} xl={8}></Col>
          <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card
              hoverable
              style={{
                width: 445,
                height: 525,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "calc( (100vh - 521px)/3 )",
                zIndex: 10,
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                  paddingTop: "3%",
                }}
              >
                <img
                  alt="example"
                  style={{ width: 92, height: 92 }}
                  src={checked}
                />
                <h1> {this.lang.forgotPasswordThankyou}</h1>
                <p>{this.lang.forgotPasswordMessage}</p>
              </div>
              <div
                style={{
                  paddingTop: "15%",
                }}
              >
                <input
                  autoFocus
                  style={{
                    border: 0,
                    outline: 0,
                    background: "transparent",
                    width: 40,
                    borderBottom: "1px solid gray",
                    marginLeft: 50,
                    textAlign: "center",
                  }}
                  type="text"
                  pattern="[0-9]*"
                  value={this.state.code1}
                  maxLength={1}
                  onChange={(event) => {
                    document.getElementById("code2").focus();

                    this.setState({
                      code1: event.target.value.slice(0, 1),
                    });
                  }}
                />
                <input
                  id="code2"
                  autoFocus={this.state.code1 != "" ? true : false}
                  style={{
                    border: 0,
                    outline: 0,
                    background: "transparent",
                    width: 40,
                    borderBottom: "1px solid gray",
                    marginLeft: 50,
                    textAlign: "center",
                  }}
                  type="text"
                  pattern="[0-9]*"
                  maxLength={1}
                  value={this.state.code2}
                  onChange={(event) => {
                    document.getElementById("code3").focus();
                    this.setState({
                      code2: event.target.value.slice(0, 1),
                    });
                  }}
                />
                <input
                  id="code3"
                  style={{
                    border: 0,
                    outline: 0,
                    background: "transparent",
                    width: 40,
                    borderBottom: "1px solid gray",
                    marginLeft: 50,
                    textAlign: "center",
                  }}
                  type="text"
                  pattern="[0-9]*"
                  value={this.state.code3}
                  onChange={(event) => {
                    document.getElementById("code4").focus();
                    this.setState({
                      code3: event.target.value.slice(0, 1),
                    });
                  }}
                />
                <input
                  id="code4"
                  style={{
                    border: 0,
                    outline: 0,
                    background: "transparent",
                    width: 40,
                    borderBottom: "1px solid gray",
                    marginLeft: 50,
                    textAlign: "center",
                  }}
                  type="text"
                  pattern="[0-9]*"
                  maxLength={1}
                  value={this.state.code4}
                  onChange={(event) =>
                    this.setState({
                      code4: event.target.value.slice(0, 1),
                    })
                  }
                />
              </div>
              <div
                style={{
                  paddingTop: "15%",
                  textAlign: "center",
                  paddingRight: "10%",
                  paddingLeft: "10%",
                }}
              >
                <p className='errorMsg'> {this.state.errorMsg} </p>
                <UIKITBUTTON
                  text={"Done"}
                  disabledbtn={
                    this.state.code1 == "" ||
                    this.state.code2 == "" ||
                    this.state.code3 == "" ||
                    this.state.code4 == ""
                  }
                  action={() => {
                    this.handelClick();
                  }}
                />
                <br />
                <div className="pt-20">
                  <a onClick={this.SendCodeAgain}>
                    {this.lang.forgotPasswordResend}
                  </a>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={8} xs={0} sm={0} md={8} lg={8} xl={8}></Col>
        </Row>
        <img
          src={GroupeProperty}
          alt=""
          style={{
            position: "fixed",
            bottom: "0px",
            paddingLeft: "15%",
            paddingRight: "15%",
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
