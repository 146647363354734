import React from "react";
import { Avatar, Button, Col, Divider, Row } from "antd";
import persone from '../../../assets/images/defaultAdminPhoto.png'
import { imagepath } from "../../../config/config"
import translator from "../../../lang/translator";
import { connect } from "react-redux";
import File from "../../Shared/File";


class ProfileDetails extends React.Component {
    constructor(props) {
        super(props)
        this.lang = translator(this.props.lang)
    }

    render() {
        if (this.props.data.adminPhoto) {
            var image = imagepath + this.props.data.adminPhoto.substring(9, this.props.data.adminPhoto.length)
        }

        return (

            <div >
                <Row gutter={20}>
                    <Col span={10} lg={22} className="avatar_col">

                        <div className="name_email_container">
                            <div>
                                {image ? <File size={99} src={image} isAvatar /> : <Avatar size={99} src={persone} />}
                            </div>
                            <div className="name_email_div" >
                                <Row >
                                    <div className="profile_name">
                                        {this.props.data.adminFullName}
                                    </div>
                                </Row>
                                <Row >
                                    <div className="profile_email">
                                        {this.props.data.adminEmail}
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col span={2} lg={2} className="edit_profile_button_col">
                        <span onClick={this.props.handleEditProfil} className="edit_button_profile" >
                            {this.lang.edit}
                        </span>
                    </Col>
                </Row>
                <Divider />

                <Row className="super_admin_contact_label">
                    <Col className="profile_name" >{this.lang.superAdminContact}</Col>
                </Row>
                <Row>
                    <Col className="profile_email" >{this.lang.phoneNumber}</Col>
                </Row>
                <Row>
                    <Col className="profile_phone_number">{this.props.data.adminPhoneNumber}</Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        lang: state.auth.lang
    };
};
const mapDispatchToProps = dispatch => {
    return {
        dispatch: action => {
            dispatch(action);
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
