import detectBrowserLanguage from "detect-browser-language";

let lang = detectBrowserLanguage();

lang = lang.substring(0, 2);
if (lang !== "ar") {
  lang = "en";
}
const initialState = {
  phone_number: "",
  isAuthenticated: false,
  admin: {},
  lang: lang,
  token: null,
  adminconnected: true,
  totalNotificationPendingBanks: 0,
  totalNotificationPendingProperties: 0,
  totalNotificationClaims: 0,
  risk: '',
  income: '',
  tokenFactor: null
};

function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        isAuthenticated: true,
        admin: action.payload.admin,
        token: action.payload.token,
        tokenFactor: null
      };
    case "SET_LANDLORD_RISK":
      return {
        ...state,
        risk: action.risk,
        income: action.income,
      };
    case "SET_PENDING_NOTIF":
      return {
        ...state,
        totalNotificationPendingBanks: action.totalPendingBanks,
        totalNotificationPendingProperties: action.totalPendingProperties,
        totalNotificationClaims: action.totalPendingClaims,
      };
    case "SET_PHONE_NUMBER":
      return {
        ...state,
        phone_number: action.payload,
      };
    case "setToken&number":
      return Object.assign({}, state, {
        ...state,
        tokenFactor: action.token,
        factorAuthentication: action.factorAuthentication
      });
    case "LOGOUT":
      return {
        phone_number: "",
        isAuthenticated: false,
        admin: {},
        lang: lang,
        token: null,
        adminconnected: true,
        totalNotificationPendingBanks: 0,
        totalNotificationPendingProperties: 0,
        totalNotificationClaims: 0,
        risk: '',
        income: '',
        tokenFactor: null
      };
    default:
      return state;
  }
}

export default AuthReducer;
