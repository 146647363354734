import React, { Component } from "react";
import { Modal, Button } from 'antd';

import { connect } from "react-redux";

import translator from "../../../lang/translator";
import { apiUrl } from "../../../config/config";
import io from "socket.io-client";
const ENDPOINT = apiUrl;
let socket;




class Approve extends Component {
  translator = null;
  constructor(props) {
    super(props);
    this.lang = translator(this.props.lang);
    this.state = {
      visible: false

    };
  }



  ApprovMeth = () => {
    if (this.props.type === "banks") {
      fetch(apiUrl + "api/admin/approvePendingBank/" + this.props.id, {
        method: "GET",
        mode: "cors",
        headers: {
          'Authorization': 'Bearer ' + this.props.auth.token
        },
      }).then(response => {
        response.json().then(data => {
          if (data.status == '200') {
            const info = {
              id_landlord: data.id_landlord
            }
            socket.emit('bankNotif', info, () => {

            });
            this.setState({
              visible: false,
            });
            this.props.getBankTable()
          }
        }
        );
      }).catch(err => console.log(err));
    }
    if (this.props.type === "properties") {
      fetch(apiUrl + "api/admin/approvePendingProperty/" + this.props.id, {
        method: "GET",
        mode: "cors",
        headers: {
          'Authorization': 'Bearer ' + this.props.auth.token
        },
      }).then(response => {
        response.json().then(data => {
          if (data.status == '200') {
            this.setState({
              visible: false,
            });
            this.props.getBankTable()
          }
        }
        );
      }).catch(err => console.log(err));
    }
  }


  componentDidMount() {
    socket = io(ENDPOINT);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };


  handleOk = e => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Button disabled={this.props.user.view_only ? true : false}
          onClick={this.showModal}
          className={this.props.lang + "_approve_reject_btn"}>
          {this.lang.Approve}
        </Button>
        <Modal className={this.props.lang + "_modalApprove"}
          footer={null}
          closable={false}
          visible={this.state.visible} >
          <p>{this.lang.msgforApprv} </p>
          <div className={this.props.lang + "_btnEndContP"}>
            <div
              className={this.props.lang + "_btnendModulCi"}
              onClick={() => this.ApprovMeth()}
            >
              {this.lang.Approve}
            </div>
            <div
              className={this.props.lang + "_btnCancelContract"}
              onClick={this.handleCancel}
            >
              {this.lang.CancelApr}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
    lang: state.auth.lang,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Approve);
