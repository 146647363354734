import React, { Component } from "react";
import { Row, Col } from "antd";
import translator from "../../lang/translator";
import { connect } from "react-redux";
import VericalNav from "./Util/verticalNavBar";
import { Switch, Route } from "react-router-dom";
import SubuserList from "./SubUsers/SubuserList";
import subUserInvite from "./subUserInvite";
import SubUser from "./SubUser";
import Profile from "./Profile/Profile"
import activityLog from "./ActivityLog/ActivityLogTable";
import MultiFactorAuth from "./MultiFactor/MultiFactor";
import Subscriptions from "./Subscriptions/Subscriptions";
import SubUserInvite from "./subUserInvite";

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.lang = translator(this.props.lang);
  }
  render() {
    return (
      <div className={this.props.lang + "_setting-big-container"}>
        <div
          className={this.props.lang + "_containerDashboard"}
          style={{ backgroundColor: "#F7F7F7" }}
        >
          <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
            <Col xs={14} sm={14} md={5} lg={5} xl={5}>
              <VericalNav logout={this.props.logout} />
            </Col>

            <Col xs={20} sm={20} md={19} lg={19} xl={19}>
              <Route
                path="/settings/superAdminAccount"
                component={Profile}
                state={this.props.auth}
                exact
              />
              <Route
                path="/settings/subUser"
                component={SubUser}
                state={this.props.auth}
                exact
              />
              <Route
                path="/settings/subUserList"
                component={SubuserList}
                state={this.props.auth}
                exact
              />
              <Route
                path="/settings/subUserInvite"
                state={this.props.auth}
                component={(props) => <SubUserInvite {...props} setLandlords={this.props.setLandlords} landlords={this.props.landlords}/>}
                exact
              />
              <Route
                path="/settings/ActivityLog"
                component={activityLog}
                state={this.props.auth}
                exact
              />
              <Route
                path="/settings/subscriptions"
                component={Subscriptions}
                state={this.props.auth}
                exact
              />
              <Route
                path="/settings/multiFactorAuth"
                component={MultiFactorAuth}
                state={this.props.auth}
                exact
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
  };
};

export default connect(mapStateToProps, null)(Setting);
