import React, { Component } from 'react';
import { Button, DatePicker } from "antd";
import translator from "../../lang/translator";
import { connect } from "react-redux";
import date from "../../assets/Accounting/date.svg";
class ExpiryDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.lang = translator(this.props.lang);
    }
    render() {
        return (
            <div >
                <div className={this.props.container} >
                    <DatePicker
                        value={this.props.expiryDate}
                        inputReadOnly={true}
                        suffixIcon={false}
                        allowClear={true}
                        format={"DD-MM-YYYY"}
                        className={this.props.className}
                        placeholder={this.lang.expiryDate}
                        onChange={(value, dataString) => this.props.onChangeExpiryDate(value, dataString)}
                    />
                    <Button className={this.props.buttonClassName}>
                        <img src={date} alt="" />
                    </Button>
                </div >
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        errors: state.errors,
        auth: state.auth,
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpiryDatePicker);
