import { apiUrl } from "../config/config"
import store from "../Redux/Store"

const getToken = () => {
    return store.getState().auth.token
}
const apiGetFile = async ({ url }) => {
    const response = await fetch(url, {
        method: "get",
        headers: {
            "Authorization": `Bearer ${getToken()}`
        }
    })
    if (response.status === 200) {
        const blob = await response.blob()
        const objectUrl = URL.createObjectURL(blob)
        return {
            blob: blob,
            url: objectUrl,
            status: 200
        }

    } else {
        const resJson = await response.json()
        return { message: resJson, status: 404 }
    }
}
const downloadFile = async ({ path, type, window = null }) => {
    const link = path?.replace("./public/", "")
    const response = await apiGetFile({ url: apiUrl + "api/files/downloadFile/" + link })
    if (response.status === 200) {
        if (type === "pdf") {
            window.location.href = response.url;
        } else {
            const a = document.createElement('a')
            a.href = response.url
            a.download = path?.replace("./public/" + type + "/", "")
            a.click();
        }
    }
}
export {
    apiGetFile,
    downloadFile
}