import { Col, Row } from 'antd'
import React, { Component } from 'react'
import translator from "../../lang/translator";
import logo from "../../assets/icons/logo.svg";
import betaLogo from "../../assets/images/betaLogo.svg";
import checkEnabled from '../../assets/images/checkEnabled.svg'
import checkFailed from '../../assets/images/failed.svg'
import { connect } from 'react-redux';
import { apiUrl } from '../../config/config';

class EmailVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            success: true,
            loading: true
        }
        this.lang = translator(this.props.lang);
    }
    componentDidMount() {
        const formData = new FormData();
        formData.append("token", this.props.location.pathname.slice(20));
        fetch(apiUrl + "api/admin/validateAdminEmailViaEmail", {
            method: "POST",
            mode: "cors",
            body: formData,
        })
            .then((response) => {
                response.json().then((responseJson) => {
                    if (responseJson.status === 200) {
                        this.setState({
                            success: true,
                            loading: false
                        })
                    } else {
                        this.setState({
                            success: false,
                            loading: false
                        })
                    }
                });
            })
            .catch((err) => console.log());
    }
    handleGoToDashboard = () => {
        window.location = "/";
    }
    render() {
        return (
            this.state.loading ?
                null
                :
                <Row>
                    <Col span={8} xs={24} sm={24} md={12} lg={24} xl={24}>
                        <div>
                            <div className="email_verif_logo">
                                <div className="navbar_logo_container">
                                    <img className="tenantive_logo_image" alt='logo'
                                        src={logo}
                                    />
                                    <div className={this.props.lang + "_text_align_left"}>
                                        <img className="beta_logo_image" src={betaLogo} alt='beta logo' />
                                    </div>
                                </div>
                            </div>
                            <div className={`${this.props.lang + '_PaymentSuccess-Block'}`}>
                                <img alt="" src={this.state.success ? checkEnabled : checkFailed} className={`${this.props.lang + '_PaymentSuccess-img'}`} />
                                <div className={`${this.props.lang + '_PaymentSuccess-successTxt'}`}>
                                    {this.state.success ? this.lang.MailVerifSuccessfully : this.lang.MailVerifFailed}
                                </div>
                                <div className={`${this.props.lang + '_PaymentSuccess-enjoyTxt'}`}>
                                    {this.state.success ? this.lang.goToDashoardSuccess : this.lang.goToDashoardFailed}
                                </div>
                                <div onClick={() => this.handleGoToDashboard()} className={`${this.props.lang + '_PaymentSuccess-ButtonDashboard'}`}>
                                    {this.lang.MailVerifDashboard}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        lang: state.auth.lang,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
