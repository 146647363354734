import React, { Component } from "react";
import { Row, Col, Pagination } from "antd";
import update from "immutability-helper";
import { connect } from "react-redux";
import translator from "../../../lang/translator";
import Approve from "./Apporove";
import Reject from "./Reject";
import { apiUrl } from "../../../config/config";
import Filter from "./FiltersBq";
import moment from "moment";
import Arrow from "../../../assets/icons/arrowdown.svg";
import { Fragment } from "react";
import ExpiryDatePicker from "../../PendingBanks/ExpiryDatePicker"
import Empty from "antd/es/empty";
import { downloadFile as download } from "../../../Api";

const PAGES = 10

class ListBank extends Component {
  translator = null;
  constructor(props) {
    super(props);
    this.lang = translator(this.props.lang);
    this.state = {
      data: [],
      Rejected: "",
      Approved: "",
      activekey: [],
      nbrElements: 0,
      currentPage: 1,
      expiryDate: "",
      windowWidth: window.innerWidth,
    };
  }

  onShow = (i) => {
    if (!this.state.activekey.includes(i)) {
      this.setState({
        activekey: [...this.state.activekey, i],
      });
    }
  };
  hide = (i) => {
    this.state.activekey.includes(i) &&
      this.setState({
        activekey: this.state.activekey.filter((el) => el !== i),
      });
  };
  pagination = (page, pageSize) => {
    this.setState({
      currentPage: page
    })
    this.searchBankTable(page)
  }
  getBankTable = () => {
    let x = this.props.match.params.id;
    var url = new URL(apiUrl + "api/admin/getLandlordConnectedBanks/" + x),
      params = { searchedName: this.state.searchValue, page: 1, per_page: PAGES };
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token,
      },
    })
      .then((response) => {
        response.json().then((data) => {
          if (data.status === 200) {
            this.setState({
              data: data.connectedBanks,
              nbrElements: data.nbrElements,
            });
          }
        });
      })
      .catch((err) => console.log());
  };
  searchBankTable = (page) => {
    let x = this.props.match.params.id;
    var url = new URL(apiUrl + "api/admin/searchLandlordConnectedBanks/" + x),
      params = { searchedProperty: this.props.search.SearchConnectedBank, status: this.props.search.ConnectedBankStatus, page: page, per_page: PAGES };
    Object.keys(params).forEach((key) =>
      url.searchParams.append(key, params[key])
    );
    fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token,
      },
    })
      .then((response) => {
        response.json().then((data) => {
          if (data.status === 200) {
            this.setState({
              data: data.bankPaginate,
              nbrElements: data.nbrElements
            });
          }
        });
      })
      .catch((err) => console.log());
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.search.SearchConnectedBank !== this.props.search.SearchConnectedBank) {
      this.searchBankTable(1)
      this.setState({ currentPage: 1 })
    }
    if (prevProps.search.ConnectedBankStatus !== this.props.search.ConnectedBankStatus) {
      this.searchBankTable(1)
      this.setState({ currentPage: 1 })
    }
  }

  DownloadId = (x) => {
    fetch(apiUrl + "api/admin/downloadBankDocs/" + x, {
      method: "Get",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + this.props.auth.token,
      },
    })
      .then((response) => {
        if (response.status == 200) {
          window.open(response.url);
        }
      })

      .catch((err) => console.log("err", err));
  };

  componentDidMount = () => {
    window.addEventListener("resize", () => {
      this.setState(() => {
        return {
          windowWidth: window.innerWidth,
        };
      });
    });
    this.getBankTable();
    this.calculateExpiryDateDifference()
  };

  moveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      })
    );
  };
  showFile = (path) => {
    let newWindow = window.open()
    let link = path.replace("./public/", "")
    download({ path: link, type: "pdf", window: newWindow })
  }
  downloadFile = (path) => {
    download({ path })
  }
  calculateTotalDocs = (el) => {
    let total = 0;
    if (el.title_deed && el.title_deed.length > 0) {
      total += el.title_deed.length
    }
    if (el.civil_id && el.civil_id.length > 0) {
      total += el.civil_id.length
    }
    if (el.signature_authorization && el.signature_authorization != undefined && el.signature_authorization.length > 0) {
      total += el.signature_authorization.length
    }
    if (el.company_license && el.company_license != undefined && el.company_license.length > 0) {
      total += el.company_license.length
    }
    if (el.civil_ID_for_authorized_signatory && el.civil_ID_for_authorized_signatory != undefined && el.civil_ID_for_authorized_signatory.length > 0) {
      total += el.civil_ID_for_authorized_signatory.length
    }
    if (el.property_management_company_license && el.property_management_company_license != undefined && el.property_management_company_license.length > 0) {
      total += el.property_management_company_license.length
    }
    if (el.property_management_company_signature_authorization && el.property_management_company_signature_authorization != undefined && el.property_management_company_signature_authorization.length > 0) {
      total += el.property_management_company_signature_authorization.length
    }
    if (el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company && el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company != undefined && el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company.length > 0) {
      total += el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company.length
    }
    if (el.Power_of_attorney && el.Power_of_attorney != undefined && el.Power_of_attorney.length > 0) {
      total += el.Power_of_attorney.length
    }
    if (el.Company_signature_authorization && el.Company_signature_authorization != undefined && el.Company_signature_authorization.length > 0) {
      total += el.Company_signature_authorization.length
    }
    if (el.Civil_ID_for_authorized_signatory_in_the_Company && el.Civil_ID_for_authorized_signatory_in_the_Company != undefined && el.Civil_ID_for_authorized_signatory_in_the_Company.length > 0) {
      total += el.Civil_ID_for_authorized_signatory_in_the_Company.length
    }
    if (el.Property_manager_civil_ID && el.Property_manager_civil_ID != undefined && el.Property_manager_civil_ID.length > 0) {
      total += el.Property_manager_civil_ID.length
    }
    if (el.Landlord_Civil_ID && el.Landlord_Civil_ID != undefined && el.Landlord_Civil_ID.length > 0) {
      total += el.Landlord_Civil_ID.length
    }
    if (el.more_files && el.more_files != undefined && el.more_files.length > 0) {
      total += el.more_files.length
    }
    return total
  }
  onChangeExpiryDate = (value, dateString) => {
    this.setState({
      expiryDate: value
    })
  }

  addExpiryDate = (value, document, bankID) => {
    const formData = new FormData()
    formData.append("idBank", bankID)
    formData.append(document, value)
    fetch(apiUrl + "api/admin/addExpiryDateToBanksDocuments", {
      method: "POST",
      mode: "cors",
      headers: { Authorization: "Bearer " + this.props.auth.token },
      body: formData,
    }).then(response => response.json())
      .then((response) => {
        if (response.status == 200) {
          let index = this.state.data.map((e) => {
            return e.id
          }).indexOf(bankID)
          let newTab = this.state.data.slice()
          newTab[index] = response.bankDetails
          this.setState({
            data: newTab
          })
          this.props.getExpiredBanksToasts()
        }
      })

      .catch((err) => console.log("err", err));
  }
  onChangeTitleDeedExpiryDate = (value, document, bankID) => {
    this.setState({
      titleDeedExpiryDate: value
    })
    this.addExpiryDate(value, document, bankID)
  }
  onChangeLanlordCivilIDExpiryDate = (value, document, bankID) => {
    this.setState({
      expiryDate: value
    })
    this.addExpiryDate(value, document, bankID)
  }
  onChangeCivilIDForAuthorizedSignatoryExpiryDate = (value, document, bankID) => {
    this.setState({
      expiryDate: value
    })
    this.addExpiryDate(value, document, bankID)
  }
  onChangeCivilIDForAuthorizedSignatoryInTheCompanyExpiryDate = (value, document, bankID) => {
    this.setState({
      expiryDate: value
    })
    this.addExpiryDate(value, document, bankID)
  }
  onChangeCivilIDForAuthorizedSignatoryInThePropertyManagementCompanyExpiryDate = (value, document, bankID) => {
    this.setState({
      expiryDate: value
    })
    this.addExpiryDate(value, document, bankID)
  }
  onChangeCompanyLicenseExpiryDate = (value, document, bankID) => {
    this.setState({
      expiryDate: value
    })
    this.addExpiryDate(value, document, bankID)
  }
  onChangeCompanySignatureAuthorizationExpiryDate = (value, document, bankID) => {
    this.setState({
      expiryDate: value
    })
    this.addExpiryDate(value, document, bankID)
  }
  onChangeLanlordCivilIDExpiryDate = (value, document, bankID) => {
    this.setState({
      expiryDate: value
    })
    this.addExpiryDate(value, document, bankID)
  }
  onChangePowerOfAttorneyExpiryDateExpiryDate = (value, document, bankID) => {
    this.setState({
      expiryDate: value
    })
    this.addExpiryDate(value, document, bankID)
  }
  onChangePropertyManagementCompanyLicenseExpiryDate = (value, document, bankID) => {
    this.setState({
      expiryDate: value
    })
    this.addExpiryDate(value, document, bankID)
  }
  onChangePropertyManagementCompanySignatureAuthorizationExpiryDate = (value, document, bankID) => {
    this.setState({
      expiryDate: value
    })
    this.addExpiryDate(value, document, bankID)
  }
  onChangePropertyManagerCivilIDExpiryDate = (value, document, bankID) => {
    this.setState({
      expiryDate: value
    })
    this.addExpiryDate(value, document, bankID)
  }
  onChangeSignatureAuthorizationExpiryDate = (value, document, bankID) => {
    this.setState({
      expiryDate: value
    })
    this.addExpiryDate(value, document, bankID)
  }
  onChangeTitleDeedExpiryDate = (value, document, bankID) => {
    this.setState({
      expiryDate: value
    })
    this.addExpiryDate(value, document, bankID)
  }
  calculateExpiryDateDifference = (expiryDate) => {
    let dateofvisit = moment(moment(expiryDate), 'DD-MM-YYYY');
    let today = moment();
    let difference = dateofvisit.diff(today, 'days');
    let isInExpiryPeriod = false
    if (difference <= 30) {
      isInExpiryPeriod = true
    } else {
      isInExpiryPeriod = false
    }
    return isInExpiryPeriod
  }
  renderDcoumentName(document) {
    if (this.state.windowWidth < 1600) {
      return document.name.length > 8 ? document.name.substr(0, 3) + "..." + document.name.substr(document.name.length - 3) : document.name
    } else {
      return document.name.length > 15 ? document.name.substr(0, 10) + "..." + document.name.substr(document.name.length - 3) : document.name
    }
  }
  render() {
    return (
      <section className={this.props.lang + "_Container-Render"}>

        <Filter />
        {(this.props.user.type === "admin" || this.props.user.userPermissions.includes("Accept bank account")) ?
          <div className={this.props.lang + "_table-acounting-rental"}>
            <Row className={this.props.lang + "_connected_bank fixed_bank_row"}>
              <Col
                span={8}
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}>
                {this.lang.Bankdocs}
              </Col>
              <Col
                span={4}
                xs={{ span: 4 }}
                sm={{ span: 4 }}
                md={{ span: 4 }}>
                {this.lang.Properties}
              </Col>
              <Col
                span={6}
                xs={{ span: 6 }}
                sm={{ span: 6 }}
                md={{ span: 6 }}>
                {this.lang.iban_bank_name}
              </Col>
              <Col
                span={6}
                xs={{ span: 6 }}
                sm={{ span: 6 }}
                md={{ span: 6 }}>
                {this.lang.BanklistAction}
              </Col>
            </Row>
            {this.state.data && this.state.data.length == 0 ?
              <div className="noData_Container_ mt-51">
                <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                <p className='noData'>
                  No Data !
                </p>
              </div>
              :
              this.state.data && this.state.data.map((el, i) => {
                return (
                  <Fragment>
                    <Row className={`${this.props.lang}_connected_bank_row expiry ${i === 0 ? 'mt-55' : ''}`}>
                      <Col
                        span={4}
                        xs={{ span: 2 }}
                        sm={{ span: 2 }}
                        md={{ span: 2 }}
                      >
                        <div className="downProp">
                          <div className="arrow-down" onClick={
                            !this.state.activekey.includes(i)
                              ? () => this.onShow(i)
                              : () => this.hide(i)
                          }>
                            <img className={!this.state.activekey.includes(i) ? 'arroww' : "arrowUP"}

                              src={Arrow}
                              alt="arrow"
                            />
                          </div>
                        </div>
                        {el.expiredBankDocs && <div className='connected_banks_red_point'></div>}
                      </Col>
                      <Col
                        span={6}
                        xs={{ span: 6 }}
                        sm={{ span: 6 }}
                        md={{ span: 6 }} className="totalDocs">
                        <span>{this.calculateTotalDocs(el)}{this.lang.documents}</span>
                        <span
                          className={this.props.lang + "_BankdocsColumn"}
                          onClick={() => this.DownloadId(el.id)}
                        >
                          {this.lang.downloadAll}
                        </span>
                      </Col>
                      <Col
                        span={4}
                        xs={{ span: 4 }}
                        sm={{ span: 4 }}
                        md={{ span: 4 }}>
                        <div className='column_propertyName'>
                          <span>{el.property_name.length === 1 ? el.property_name.length + ' Property' : el.property_name.length + ' Properties'}</span>
                        </div>
                      </Col>
                      <Col className="connected_bank_iban_name"
                        span={6}
                        xs={{ span: 6 }}
                        sm={{ span: 6 }}
                        md={{ span: 6 }}>
                        <span className="bank_documents_date">
                          {el.iban}
                          <span className="connected_bank_name">{el.bank_name}</span>
                        </span>
                      </Col>
                      <Col
                        span={6}
                        xs={{ span: 6 }}
                        sm={{ span: 6 }}
                        md={{ span: 6 }}>
                        {
                          el.status === "pending" ?
                            (
                              <div className={this.props.lang + "_btnAppReject"}>
                                <div className={this.props.lang + "_btn_column_action1"}>
                                  <div className={this.props.lang + "_btn_column_action"}>
                                    <Approve getBankTable={this.getBankTable} type="banks" id={el.id} />
                                  </div>
                                </div>

                                <div
                                  className={this.props.lang + "_btn_column_action1"}>
                                  <div className={this.props.lang + "_btn_column_action"}>
                                    <Reject getBankTable={this.getBankTable} type="banks" id={el.id} />
                                  </div>
                                </div>
                              </div>
                            )
                            :
                            el.status === "approved" ?
                              <div className={this.props.lang + "_bank_actions"}>
                                <div className={this.props.lang + "_btn_colAprove"}>
                                  <span className={this.props.lang + "_btnApproved"}>
                                    {this.lang.Approved}
                                  </span>
                                </div>
                                <div className={this.props.lang + "_bank_date_time"}>
                                  <span>{moment(new Date()).format("MMMM DD YYYY   hh:mm A")}</span>
                                </div>
                              </div>
                              :
                              <div className={this.props.lang + "_bank_actions"}>
                                <div className={this.props.lang + "_btn_colReject"}>
                                  <span className={this.props.lang + "_btnApproved"}>
                                    {this.lang.Rejected}
                                  </span>
                                </div>
                                <div className={this.props.lang + "_bank_date_time"}>
                                  <span>{moment(new Date()).format("MMMM DD YYYY   hh:mm A")}</span>
                                </div>
                              </div>
                        }
                      </Col>
                      <Col
                        md={8}
                        className={
                          this.state.activekey.includes(i)
                            ? this.props.lang + "_display_content"
                            : this.props.lang + "_hide_content"
                        }>
                        {
                          el.title_deed && el.title_deed.map((title_deed, index) => {
                            return (
                              <div key={index} >
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(title_deed)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(title_deed.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(title_deed.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.title_deed_expiry_date && this.calculateExpiryDateDifference(el.title_deed_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.title_deed_expiry_date && moment(el.title_deed_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) => this.onChangeTitleDeedExpiryDate(value, "title_deed_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.signature_authorization && el.signature_authorization.map((signature_authorization, index) =>
                            <div>
                              <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                <span className="file_name_connected_banks">
                                  {this.renderDcoumentName(signature_authorization)}
                                </span>
                                <span
                                  className={this.props.lang + "_BankdocsColumn"}
                                  onClick={() => this.downloadFile(signature_authorization.path)}
                                >
                                  {this.lang.Download}
                                </span>
                                <div
                                  onClick={() => this.showFile(signature_authorization.path)}
                                  className={this.props.lang + "_btn-action-View"}
                                >
                                  {this.lang.View}
                                </div>
                              </div>
                              {
                                el.status === "approved" && (index !== 0 ?
                                  <div className="empty-expiry-picker"> </div>
                                  :
                                  <ExpiryDatePicker
                                    buttonClassName={"date-btn expiry_btn"}
                                    className={el.signature_authorization_expiry_date && this.calculateExpiryDateDifference(el.signature_authorization_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                    expiryDate={el.signature_authorization_expiry_date && moment(el.signature_authorization_expiry_date)}
                                    onChangeExpiryDate={(value, dataString) => this.onChangeSignatureAuthorizationExpiryDate(value, "signature_authorization_expiry_date", el.id)} />)
                              }
                            </div>
                          )
                        }
                        {
                          el.company_license && el.company_license.map((company_license, index) =>
                            <div>
                              <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                <span className="file_name_connected_banks">
                                  {this.renderDcoumentName(company_license)}
                                </span>
                                <span
                                  className={this.props.lang + "_BankdocsColumn"}
                                  onClick={() => this.downloadFile(company_license.path)}
                                >
                                  {this.lang.Download}
                                </span>
                                <div
                                  onClick={() => this.showFile(company_license.path)}
                                  className={this.props.lang + "_btn-action-View"}
                                >
                                  {this.lang.View}
                                </div>
                              </div>
                              {
                                el.status === "approved" && (index !== 0 ?
                                  <div className="empty-expiry-picker"> </div>
                                  :
                                  <ExpiryDatePicker
                                    buttonClassName={"date-btn expiry_btn"}
                                    className={el.company_license_expiry_date && this.calculateExpiryDateDifference(el.company_license_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                    expiryDate={el.company_license_expiry_date && moment(el.company_license_expiry_date)}
                                    onChangeExpiryDate={(value, dataString) => this.onChangeCompanyLicenseExpiryDate(value, "company_license_expiry_date", el.id)} />)
                              }
                            </div>
                          )
                        }
                        {
                          el.civil_ID_for_authorized_signatory && el.civil_ID_for_authorized_signatory.map((civil_ID_for_authorized_signatory, index) =>
                            <div>
                              <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                <span className="file_name_connected_banks">
                                  {this.renderDcoumentName(civil_ID_for_authorized_signatory)}
                                </span>
                                <span
                                  className={this.props.lang + "_BankdocsColumn"}
                                  onClick={() => this.downloadFile(civil_ID_for_authorized_signatory.path)}
                                >
                                  {this.lang.Download}
                                </span>
                                <div
                                  onClick={() => this.showFile(civil_ID_for_authorized_signatory.path)}
                                  className={this.props.lang + "_btn-action-View"}
                                >
                                  {this.lang.View}
                                </div>
                              </div>
                              {
                                el.status === "approved" && (index !== 0 ?
                                  <div className="empty-expiry-picker"> </div>
                                  :
                                  <ExpiryDatePicker
                                    buttonClassName={"date-btn expiry_btn"}
                                    className={el.civil_ID_for_authorized_signatory_expiry_date && this.calculateExpiryDateDifference(el.civil_ID_for_authorized_signatory_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                    expiryDate={el.civil_ID_for_authorized_signatory_expiry_date && moment(el.civil_ID_for_authorized_signatory_expiry_date)}
                                    onChangeExpiryDate={(value, dataString) => this.onChangeCivilIDForAuthorizedSignatoryExpiryDate(value, "civil_ID_for_authorized_signatory_expiry_date", el.id)} />)
                              }
                            </div>
                          )
                        }
                        {
                          el.property_management_company_license && el.property_management_company_license.map((property_management_company_license, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(property_management_company_license)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(property_management_company_license.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(property_management_company_license.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.property_management_company_license_expiry_date && this.calculateExpiryDateDifference(el.property_management_company_license_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.property_management_company_license_expiry_date && moment(el.property_management_company_license_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) => this.onChangePropertyManagementCompanyLicenseExpiryDate(value, "property_management_company_license_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.property_management_company_signature_authorization &&
                          el.property_management_company_signature_authorization.map((property_management_company_signature_authorization, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(property_management_company_signature_authorization)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(property_management_company_signature_authorization.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(property_management_company_signature_authorization.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.property_management_company_signature_authorization_expiry_dateééthis
                                        && this.calculateExpiryDateDifference(el.property_management_company_signature_authorization_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.property_management_company_signature_authorization_expiry_date && moment(el.property_management_company_signature_authorization_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) => this.onChangePropertyManagementCompanySignatureAuthorizationExpiryDate(value, "property_management_company_signature_authorization_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company &&
                          el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company.map((Civil_ID_for_authorized_signatory_in_the_Property_Management_Company, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(Civil_ID_for_authorized_signatory_in_the_Property_Management_Company)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(Civil_ID_for_authorized_signatory_in_the_Property_Management_Company.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(Civil_ID_for_authorized_signatory_in_the_Property_Management_Company.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company_expiry_date &&
                                        this.calculateExpiryDateDifference(el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company_expiry_date &&
                                        moment(el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) => this.onChangeCivilIDForAuthorizedSignatoryInThePropertyManagementCompanyExpiryDate(value, "Civil_ID_for_authorized_signatory_in_the_Property_Management_Company_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.Power_of_attorney && el.Power_of_attorney.map((Power_of_attorney, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(Power_of_attorney)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(Power_of_attorney.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(Power_of_attorney.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.Power_of_attorney_expiry_date && this.calculateExpiryDateDifference(el.Power_of_attorney_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.Power_of_attorney_expiry_date && moment(el.Power_of_attorney_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) => this.onChangePowerOfAttorneyExpiryDateExpiryDate(value, "Power_of_attorney_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.Company_signature_authorization &&
                          el.Company_signature_authorization.map((Company_signature_authorization, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(Company_signature_authorization)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(Company_signature_authorization.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(Company_signature_authorization.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.Company_signature_authorization_expiry_date &&
                                        this.calculateExpiryDateDifference(el.Company_signature_authorization_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.Company_signature_authorization_expiry_date && moment(el.Company_signature_authorization_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) =>
                                        this.onChangeCompanySignatureAuthorizationExpiryDate(value, "Company_signature_authorization_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.Civil_ID_for_authorized_signatory_in_the_Company &&
                          el.Civil_ID_for_authorized_signatory_in_the_Company.map((Civil_ID_for_authorized_signatory_in_the_Company, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(Civil_ID_for_authorized_signatory_in_the_Company)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(Civil_ID_for_authorized_signatory_in_the_Company.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(Civil_ID_for_authorized_signatory_in_the_Company.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.Civil_ID_for_authorized_signatory_in_the_Company_expiry_date &&
                                        this.calculateExpiryDateDifference(el.Civil_ID_for_authorized_signatory_in_the_Company_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.Civil_ID_for_authorized_signatory_in_the_Company_expiry_date &&
                                        moment(el.Civil_ID_for_authorized_signatory_in_the_Company_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) =>
                                        this.onChangeCivilIDForAuthorizedSignatoryInTheCompanyExpiryDate(value, "Civil_ID_for_authorized_signatory_in_the_Company_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.Property_manager_civil_ID && el.Property_manager_civil_ID.map((Property_manager_civil_ID, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(Property_manager_civil_ID)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(Property_manager_civil_ID.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(Property_manager_civil_ID.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.Property_manager_civil_ID_expiry_date &&
                                        this.calculateExpiryDateDifference(el.Property_manager_civil_ID_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.Property_manager_civil_ID_expiry_date &&
                                        moment(el.Property_manager_civil_ID_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) => this.onChangePropertyManagerCivilIDExpiryDate(value, "Property_manager_civil_ID_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.Landlord_Civil_ID && el.Landlord_Civil_ID.map((Landlord_Civil_ID, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(Landlord_Civil_ID)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(Landlord_Civil_ID.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(Landlord_Civil_ID.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.Landlord_Civil_ID_expiry_date && this.calculateExpiryDateDifference(el.Landlord_Civil_ID_expiry_date) ? 'expired_document_date' : 'expiry_date_picker'}
                                      expiryDate={el.Landlord_Civil_ID_expiry_date && moment(el.Landlord_Civil_ID_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) => this.onChangeLanlordCivilIDExpiryDate(value, "Landlord_Civil_ID_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {el.more_files && el.more_files.map((file, index) => {
                          return (
                            <div>
                              <div className="all_files_connected_banks">
                                <span className="file_name_connected_banks">
                                  {this.renderDcoumentName(file)}
                                </span>
                                <span
                                  className={this.props.lang + "_BankdocsColumn"}
                                  onClick={() => this.downloadFile(file.path)}
                                >
                                  {this.lang.Download}
                                </span>
                                <div
                                  onClick={() => this.showFile(file.path)}
                                  className={this.props.lang + "_btn-action-View"}
                                >
                                  {this.lang.View}
                                </div>
                              </div>
                              <div> </div>
                            </div>
                          )
                        })}
                      </Col>
                      <Col span={4} className={
                        this.state.activekey.includes(i)
                          ? this.props.lang + "_display_content"
                          : this.props.lang + "_hide_content"
                      }>
                        {el.property_name && el.property_name.map((property, j) => {
                          return (
                            <div>
                              <span className="connected_bank_properties">
                                {property}
                              </span>
                            </div>
                          )
                        })}
                      </Col>
                    </Row>
                  </Fragment>
                )
              })}
          </div>
          :
          <div className={this.props.lang + "_table-acounting-rental_noaccept"}>
            <Row className={this.props.lang + "_connected_bank"}>
              <Col
                span={9}
                xs={{ span: 9 }}
                sm={{ span: 9 }}
                md={{ span: 9 }}>
                {this.lang.Bankdocs}
              </Col>
              <Col
                span={7}
                xs={{ span: 7 }}
                sm={{ span: 7 }}
                md={{ span: 7 }}>
                {this.lang.Properties}
              </Col>
              <Col
                span={8}
                xs={{ span: 8 }}
                sm={{ span: 8 }}
                md={{ span: 8 }}>
                {this.lang.Bankaccount}
              </Col>
            </Row>

            {this.state.data && this.state.data.length == 0 ?
              <div className="noData_Container_ mt-51">
                <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                <p className='noData'>
                  No Data !
                </p>
              </div>
              :
              this.state.data && this.state.data.map((el, i) => {
                return (
                  <Fragment>
                    <Row className={`${this.props.lang}_connected_bank_row ${i === 0 ? 'mt-55' : ''}`}>
                      <Col
                        span={4}
                        xs={{ span: 2 }}
                        sm={{ span: 2 }}
                        md={{ span: 2 }}
                      >
                        <div className="downProp">

                          <div className="arrow-down" onClick={
                            !this.state.activekey.includes(i)
                              ? () => this.onShow(i)
                              : () => this.hide(i)
                          }>
                            <img className={!this.state.activekey.includes(i) ? 'arroww' : "arrowUP"}

                              src={Arrow}
                              alt="arrow"

                            />
                          </div>

                        </div>
                      </Col>
                      <Col
                        span={7}
                        xs={{ span: 7 }}
                        sm={{ span: 7 }}
                        md={{ span: 7 }}>
                        <span
                          className={this.props.lang + "_BankdocsColumn"}
                          onClick={() => this.DownloadId(el.id)}
                        >
                          {this.lang.downloadAll}
                        </span>
                      </Col>
                      <Col
                        span={7}
                        xs={{ span: 7 }}
                        sm={{ span: 7 }}
                        md={{ span: 7 }}>
                        <div className='column_propertyName'>
                          <span>{el.property_name.length === 1 ? el.property_name.length + ' Property' : el.property_name.length + ' Properties'}</span>
                        </div>
                      </Col>
                      <Col
                        span={8}
                        xs={{ span: 8 }}
                        sm={{ span: 8 }}
                        md={{ span: 8 }}>
                        <div>
                          {el.iban}
                        </div>
                      </Col>
                      <Col span={5} offset={2} className={
                        this.state.activekey.includes(i)
                          ? this.props.lang + "_display_content"
                          : this.props.lang + "_hide_content"
                      }>
                        {
                          el.civil_id && el.civil_id.map((civil, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {civil.name.length > 5 ? civil.name.substr(0, 5) + "..." : civil.name}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(civil.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(civil.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={'expiry_date_picker'}
                                      expiryDate={this.state.civilIdExpiryDate}
                                      onChangeExpiryDate={(value, dataString) => this.onChangeExpiryDate(value, dataString)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.title_deed && el.title_deed.map((title_deed, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(title_deed)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(title_deed.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(title_deed.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.title_deed_expiry_date && this.calculateExpiryDateDifference(el.title_deed_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.title_deed_expiry_date && moment(el.title_deed_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) => this.onChangeTitleDeedExpiryDate(value, "title_deed_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.signature_authorization && el.signature_authorization.map((signature_authorization, index) =>
                            <div>
                              <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                <span className="file_name_connected_banks">
                                  {this.renderDcoumentName(signature_authorization)}
                                </span>
                                <span
                                  className={this.props.lang + "_BankdocsColumn"}
                                  onClick={() => this.downloadFile(el.signature_authorization.path)}
                                >
                                  {this.lang.Download}
                                </span>
                                <div
                                  onClick={() => this.showFile(el.signature_authorization.path)}
                                  className={this.props.lang + "_btn-action-View"}
                                >
                                  {this.lang.View}
                                </div>
                              </div>
                              {
                                el.status === "approved" && (index !== 0 ?
                                  <div className="empty-expiry-picker"> </div>
                                  :
                                  <ExpiryDatePicker
                                    buttonClassName={"date-btn expiry_btn"}
                                    className={el.signature_authorization_expiry_date && this.calculateExpiryDateDifference(el.signature_authorization_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                    expiryDate={el.signature_authorization_expiry_date && moment(el.signature_authorization_expiry_date)}
                                    onChangeExpiryDate={(value, dataString) => this.onChangeSignatureAuthorizationExpiryDate(value, "signature_authorization_expiry_date", el.id)} />)
                              }
                            </div>
                          )
                        }
                        {
                          el.company_license && el.company_license.map((company_license, index) =>
                            <div>
                              <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                <span className="file_name_connected_banks">
                                  {this.renderDcoumentName(company_license)}
                                </span>
                                <span
                                  className={this.props.lang + "_BankdocsColumn"}
                                  onClick={() => this.downloadFile(company_license.path)}
                                >
                                  {this.lang.Download}
                                </span>
                                <div
                                  onClick={() => this.showFile(company_license.path)}
                                  className={this.props.lang + "_btn-action-View"}
                                >
                                  {this.lang.View}
                                </div>
                              </div>
                              {
                                el.status === "approved" && (index !== 0 ?
                                  <div className="empty-expiry-picker"> </div>
                                  :
                                  <ExpiryDatePicker
                                    buttonClassName={"date-btn expiry_btn"}
                                    className={el.company_license_expiry_date && this.calculateExpiryDateDifference(el.company_license_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                    expiryDate={el.company_license_expiry_date && moment(el.company_license_expiry_date)}
                                    onChangeExpiryDate={(value, dataString) => this.onChangeCompanyLicenseExpiryDate(value, "company_license_expiry_date", el.id)} />)
                              }
                            </div>
                          )
                        }
                        {
                          el.civil_ID_for_authorized_signatory && el.civil_ID_for_authorized_signatory.map((civil_ID_for_authorized_signatory, index) =>
                            <div>
                              <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                <span className="file_name_connected_banks">
                                  {this.renderDcoumentName(civil_ID_for_authorized_signatory)}
                                </span>
                                <span
                                  className={this.props.lang + "_BankdocsColumn"}
                                  onClick={() => this.downloadFile(civil_ID_for_authorized_signatory.path)}
                                >
                                  {this.lang.Download}
                                </span>
                                <div
                                  onClick={() => this.showFile(civil_ID_for_authorized_signatory.path)}
                                  className={this.props.lang + "_btn-action-View"}
                                >
                                  {this.lang.View}
                                </div>
                              </div>
                              {
                                el.status === "approved" && (index !== 0 ?
                                  <div className="empty-expiry-picker"> </div>
                                  :
                                  <ExpiryDatePicker
                                    buttonClassName={"date-btn expiry_btn"}
                                    className={el.civil_ID_for_authorized_signatory_expiry_date && this.calculateExpiryDateDifference(el.civil_ID_for_authorized_signatory_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                    expiryDate={el.civil_ID_for_authorized_signatory_expiry_date && moment(el.civil_ID_for_authorized_signatory_expiry_date)}
                                    onChangeExpiryDate={(value, dataString) => this.onChangeCivilIDForAuthorizedSignatoryExpiryDate(value, "civil_ID_for_authorized_signatory_expiry_date", el.id)} />)
                              }
                            </div>
                          )
                        }
                        {
                          el.property_management_company_license && el.property_management_company_license.map((property_management_company_license, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(property_management_company_license)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(property_management_company_license.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(property_management_company_license.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.property_management_company_license_expiry_date && this.calculateExpiryDateDifference(el.property_management_company_license_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.property_management_company_license_expiry_date && moment(el.property_management_company_license_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) => this.onChangePropertyManagementCompanyLicenseExpiryDate(value, "property_management_company_license_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.property_management_company_signature_authorization &&
                          el.property_management_company_signature_authorization.map((property_management_company_signature_authorization, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(property_management_company_signature_authorization)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(property_management_company_signature_authorization.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(property_management_company_signature_authorization.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.property_management_company_signature_authorization_expiry_dateééthis
                                        && this.calculateExpiryDateDifference(el.property_management_company_signature_authorization_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.property_management_company_signature_authorization_expiry_date && moment(el.property_management_company_signature_authorization_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) => this.onChangePropertyManagementCompanySignatureAuthorizationExpiryDate(value, "property_management_company_signature_authorization_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company &&
                          el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company.map((Civil_ID_for_authorized_signatory_in_the_Property_Management_Company, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(Civil_ID_for_authorized_signatory_in_the_Property_Management_Company)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(Civil_ID_for_authorized_signatory_in_the_Property_Management_Company.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(Civil_ID_for_authorized_signatory_in_the_Property_Management_Company.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company_expiry_date &&
                                        this.calculateExpiryDateDifference(el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company_expiry_date &&
                                        moment(el.Civil_ID_for_authorized_signatory_in_the_Property_Management_Company_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) => this.onChangeCivilIDForAuthorizedSignatoryInThePropertyManagementCompanyExpiryDate(value, "Civil_ID_for_authorized_signatory_in_the_Property_Management_Company_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.Power_of_attorney && el.Power_of_attorney.map((Power_of_attorney, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(Power_of_attorney)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(Power_of_attorney.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(Power_of_attorney.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.Power_of_attorney_expiry_date && this.calculateExpiryDateDifference(el.Power_of_attorney_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.Power_of_attorney_expiry_date && moment(el.Power_of_attorney_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) => this.onChangePowerOfAttorneyExpiryDateExpiryDate(value, "Power_of_attorney_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.Company_signature_authorization && el.Company_signature_authorization.map((Company_signature_authorization, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(Company_signature_authorization)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(Company_signature_authorization.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(Company_signature_authorization.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.Company_signature_authorization_expiry_date &&
                                        this.calculateExpiryDateDifference(el.Company_signature_authorization_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.Company_signature_authorization_expiry_date && moment(el.Company_signature_authorization_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) =>
                                        this.onChangeCompanySignatureAuthorizationExpiryDate(value, "Company_signature_authorization_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.Civil_ID_for_authorized_signatory_in_the_Company &&
                          el.Civil_ID_for_authorized_signatory_in_the_Company.map((Civil_ID_for_authorized_signatory_in_the_Company, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(Civil_ID_for_authorized_signatory_in_the_Company)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(Civil_ID_for_authorized_signatory_in_the_Company.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(Civil_ID_for_authorized_signatory_in_the_Company.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.Civil_ID_for_authorized_signatory_in_the_Company_expiry_date &&
                                        this.calculateExpiryDateDifference(el.Civil_ID_for_authorized_signatory_in_the_Company_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.Civil_ID_for_authorized_signatory_in_the_Company_expiry_date &&
                                        moment(el.Civil_ID_for_authorized_signatory_in_the_Company_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) =>
                                        this.onChangeCivilIDForAuthorizedSignatoryInTheCompanyExpiryDate(value, "Civil_ID_for_authorized_signatory_in_the_Company_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.Property_manager_civil_ID && el.Property_manager_civil_ID.map((Property_manager_civil_ID, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(Property_manager_civil_ID)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(Property_manager_civil_ID.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(Property_manager_civil_ID.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.Property_manager_civil_ID_expiry_date &&
                                        this.calculateExpiryDateDifference(el.Property_manager_civil_ID_expiry_date) ? 'expired_document_date' : 'expiry_date_picker '}
                                      expiryDate={el.Property_manager_civil_ID_expiry_date &&
                                        moment(el.Property_manager_civil_ID_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) => this.onChangePropertyManagerCivilIDExpiryDate(value, "Property_manager_civil_ID_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {
                          el.Landlord_Civil_ID && el.Landlord_Civil_ID.map((Landlord_Civil_ID, index) => {
                            return (
                              <div>
                                <div className={index === 0 ? "all_files_connected_banks" : "connected_banks_container"}>
                                  <span className="file_name_connected_banks">
                                    {this.renderDcoumentName(Landlord_Civil_ID)}
                                  </span>
                                  <span
                                    className={this.props.lang + "_BankdocsColumn"}
                                    onClick={() => this.downloadFile(Landlord_Civil_ID.path)}
                                  >
                                    {this.lang.Download}
                                  </span>
                                  <div
                                    onClick={() => this.showFile(Landlord_Civil_ID.path)}
                                    className={this.props.lang + "_btn-action-View"}
                                  >
                                    {this.lang.View}
                                  </div>
                                </div>
                                {
                                  el.status === "approved" && (index !== 0 ?
                                    <div className="empty-expiry-picker"> </div>
                                    :
                                    <ExpiryDatePicker
                                      buttonClassName={"date-btn expiry_btn"}
                                      className={el.Landlord_Civil_ID_expiry_date && this.calculateExpiryDateDifference(el.Landlord_Civil_ID_expiry_date) ? 'expired_document_date' : 'expiry_date_picker'}
                                      expiryDate={el.Landlord_Civil_ID_expiry_date && moment(el.Landlord_Civil_ID_expiry_date)}
                                      onChangeExpiryDate={(value, dataString) => this.onChangeLanlordCivilIDExpiryDate(value, "Landlord_Civil_ID_expiry_date", el.id)} />)
                                }
                              </div>
                            )
                          })
                        }
                        {el.more_files && el.more_files.map((file, index) => {
                          return (
                            <div>
                              <div className="all_files_connected_banks">
                                <span className="file_name_connected_banks">
                                  {this.renderDcoumentName(file)}
                                </span>
                                <span
                                  className={this.props.lang + "_BankdocsColumn"}
                                  onClick={() => this.downloadFile(file.path)}
                                >
                                  {this.lang.Download}
                                </span>
                                <div
                                  onClick={() => this.showFile(file.path)}
                                  className={this.props.lang + "_btn-action-View"}
                                >
                                  {this.lang.View}
                                </div>
                              </div>
                              <div> </div>
                            </div>
                          )
                        })}
                      </Col>
                      <Col span={5} className={
                        this.state.activekey.includes(i)
                          ? this.props.lang + "_display_content"
                          : this.props.lang + "_hide_content"
                      }>
                        {el.property_name && el.property_name.map((property, j) => {
                          return (
                            <div>
                              <span className="connected_bank_properties">
                                {property}
                              </span>
                            </div>
                          )
                        })}
                      </Col>
                    </Row>
                  </Fragment>
                )
              })}
          </div>
        }
        <div className={(this.props.user.type === "admin" || this.props.user.userPermissions.includes("Accept bank account")) ? this.props.lang + "_table-acounting-rental mt-10" : this.props.lang + "_table-acounting-rental_noaccept  mt-10"}>
          {
            this.state.data !== 0 ?
              <Pagination
                current={this.state.currentPage}
                defaultCurrent={1}
                className="test"
                pageSize={PAGES}
                total={this.state.nbrElements}
                showSizeChanger={false}
                responsive={true}
                onChange={this.pagination} /> : null
          }
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    lang: state.auth.lang,
    search: state.search,
    user: state.user,

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListBank);
