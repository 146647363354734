export const formateNumber = (number) => {
    let value = 0
    if (number) {
        if (number === 0) {
            value = number
        } else {
            let numberTab = number.toString().split('.')
            let rest = Number.parseFloat(Number('0.' + numberTab[1]).toFixed(3))
            let restTab = rest.toString().split('.')
            value = new Intl.NumberFormat("en-US").format(Number(numberTab[0])) + `${restTab[1] ? '.' + restTab[1] : ''}`
        }
    }
    return value
}