import React, { useEffect, useState } from "react";
import ConversationList from "./ConversationList";
import MessageList from "./MessageList";
import { connect, useDispatch } from "react-redux";
import { apiUrl } from "../../config/config";
import InfiniteScroll from 'react-infinite-scroll-component';
import translator from "../../lang/translator";


function Messenger(props) {
    const [loading, setLoading] = useState('')
    const [MyHistory, setMyHistory] = useState({ "discussion": [] })
    const [last_Id, setLastId] = useState('')
    const lang = translator(props.lang);
    const dispatch = useDispatch()
    // const getLandlordConversations = () => {
    //     fetch(apiUrl + "api/admin/getConversationAdminLandlord", {
    //         method: "GET",
    //         mode: "cors",
    //         headers: { Authorization: "Bearer " + props.auth.token },
    //     })
    //         .then((response) => response.json())
    //         .then((responseJson) => {
    //             if (responseJson.status === 200) {
    //                 let newConversations = responseJson.conversations.map((result) => {
    //                     return {
    //                         id_conversation: result.id_conversation,
    //                         photo: result.photo,
    //                         name: result.full_name,
    //                         text: result.message,
    //                         createdAt: result.createdAt,
    //                         discussion: result.discussion,
    //                         id_receiver: result.landlord,
    //                         lu: result.lu
    //                     };
    //                 });
    //                 newConversations.sort((prevMessage, nextMessage) => {
    //                     return (
    //                         new Date(nextMessage.createdAt) - new Date(prevMessage.createdAt)
    //                     );
    //                 });

    //                 let action = {
    //                     type: "SET_LANDLORD_CONVERSATION_LIST",
    //                     value: [...newConversations],
    //                 };
    //                 dispatch(action);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // };
    // const getTenantsConversations = () => {
    //     fetch(apiUrl + "api/admin/getConversationAdminTenant", {
    //         method: "GET",
    //         mode: "cors",
    //         headers: { Authorization: "Bearer " + props.auth.token },
    //     })
    //         .then((response) => response.json())
    //         .then((responseJson) => {
    //             if (responseJson.status === 200) {
    //                 let newConversations = responseJson.conversations.map((result) => {
    //                     return {
    //                         id_conversation: result.id_conversation,
    //                         photo: result.photo,
    //                         name: result.full_name,
    //                         text: result.message,
    //                         createdAt: result.createdAt,
    //                         discussion: result.discussion,
    //                         id_receiver: result.tenant,
    //                         lu: result.lu
    //                     };
    //                 });
    //                 newConversations.sort((prevMessage, nextMessage) => {
    //                     return (
    //                         new Date(nextMessage.createdAt) - new Date(prevMessage.createdAt)
    //                     );
    //                 });

    //                 let action = {
    //                     type: "SET_TENANT_CONVERSATION_LIST",
    //                     value: [...newConversations],
    //                 };
    //                 dispatch(action);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // };
    // const getLandlordsTenantsList = () => {
    //     fetch(apiUrl + "api/admin/getListOfLandlordAndTenant", {
    //         method: "GET",
    //         mode: "cors",
    //         headers: { Authorization: "Bearer " + props.auth.token },
    //     })
    //         .then((response) => response.json())
    //         .then((responseJson) => {
    //             if (responseJson.status === 200) {
    //                 let action = {
    //                     type: "SET_LANDLORDS_TENANTS_LIST",
    //                     landlords: responseJson.landlords,
    //                     tenants: responseJson.tenants,
    //                 };
    //                 dispatch(action);
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // };
    useEffect(() => {
        // getLandlordConversations()
        // getTenantsConversations()
        // getLandlordsTenantsList()
        props.messenger.Broadcast_category === "landlord" ?
            setMyHistory(props.messenger.landlordConversationsList.filter(el => el.id_conversation === props.messenger.id_conversation).find(el => el))
            :
            setMyHistory(props.messenger.tenantConversationsList.filter(el => el.id_conversation === props.messenger.id_conversation).find(el => el))
    }, [])
    useEffect(() => {
        setLastId('')

    }, [props.messenger.id_conversation])
    const getMessages = () => {
        setLoading(lang.loadingHistory)
        const formData = new FormData();
        let messageId = MyHistory && MyHistory.discussion[MyHistory && MyHistory.discussion.length - 1]._id
        if (last_Id.length > 0) {
            formData.append("idLastMsg", last_Id)
        } else {
            formData.append("idLastMsg", messageId);
        }
        fetch(
            apiUrl +
            "api/viewMessagesChatHistory/" + props.messenger.id_conversation,
            {
                method: "POST",
                mode: "cors",
                headers: { Authorization: "Bearer " + props.auth.token },
                body: formData
            }
        ).then((response) => {
            response.json().then((res) => {
                if (res.status === 200) {
                    setLoading('')
                    if (res.messagesDetails.length > 9) {
                        setLastId(res.messagesDetails[res.messagesDetails.length - 1].id_message)
                    } else {
                        setLastId('45')
                        setLoading(lang.noMore_message)
                        setTimeout(() => {
                            setLoading('');
                        }, 5000);
                    }
                    var newTempMessage = res.messagesDetails.map((result, index) => {

                        return {
                            attachments: result.attachments,
                            createdAt: result.createdAt,
                            lu: result.lu,
                            sender: result.sender,
                            text: result.text,
                            updatedAt: result.updatedAt,
                            _id: result.id_message

                        };


                    }).reverse()

                    let action = { type: "SET_MESSAGE_HISTORY", value: [...newTempMessage], id_conversation: props.messenger.id_conversation };
                    props.dispatch(action);

                }
            })
        })

    }
    const MyHiLength = MyHistory && MyHistory.discussion.length
    return (
        <>
            <div className={props.auth.lang + "_messenger"}>
                <div className="scrollable sidebar">
                    <ConversationList MyHistory={MyHistory} />
                </div>
                <div id='messageListContainer' className="scrollable messenger-content">
                    <InfiniteScroll
                        dataLength={MyHistory && MyHiLength}
                        next={MyHistory && MyHiLength >= 30 ? getMessages : null}
                        style={{ display: 'flex', flexDirection: 'column-reverse', marginTop: "1Opx" }} //To put endMessage and loader to the top.
                        inverse={true} //
                        hasMore={true}
                        loader={<h4 className={props.auth.lang + '_history_load'}> {loading} </h4>}
                        scrollableTarget="messageListContainer"
                    >
                        <MessageList />
                    </InfiniteScroll>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        messenger: state.messenger,
        lang: state.auth.lang,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Messenger);
