import React, { useEffect, useState, useRef, Fragment } from "react";
import Compose from "./Compose";
import Toolbar from "./Toolbar";
import Message from "./Message";
import moment from "moment";
import { connect } from "react-redux";
import translator from "../../lang/translator";
import { apiUrl } from "./../../config/config";
import InfiniteScroll from 'react-infinite-scroll-component';
function MessageList(props) {
    const lang = translator(props.auth.lang);
    const [last_Id, setLastId] = useState('')
    const MY_USER_ID = props.user.type === "subuser" ? props.user.idSuperAdmin : props.auth.admin.adminId;

    const messagesEndRef = useRef()

    const [loading, setLoading] = useState('')
    const [MyHistory, setMyHistory] = useState({ "discussion": [] })
    useEffect(() => {
        if (props.messenger.Broadcast_category === "landlord") {
            setMyHistory(props.messenger.landlordConversationsList.filter(el => el.id_conversation === props.messenger.id_conversation).find(el => el))
        } else {
            setMyHistory(props.messenger.tenantConversationsList.filter(el => el.id_conversation === props.messenger.id_conversation).find(el => el))
        }
    }, [])
    useEffect(() => {
        setLastId('')

    }, [props.messenger.id_conversation])
    const getMessages = () => {
        setLoading(lang.loadingHistory)
        const formData = new FormData();
        let messageId = MyHistory && MyHistory.discussion[MyHistory && MyHistory.discussion.length - 1]._id
        if (last_Id.length > 0) {
            formData.append("idLastMsg", last_Id)
        } else {
            formData.append("idLastMsg", messageId);
        }
        fetch(
            apiUrl +
            "api/viewMessagesChatHistory/" + props.messenger.id_conversation,
            {
                method: "POST",
                mode: "cors",
                headers: { Authorization: "Bearer " + props.auth.token },
                body: formData
            }
        ).then((response) => {
            response.json().then((res) => {
                if (res.status === 200) {
                    setLoading('')
                    if (res.messagesDetails.length > 9) {
                        setLastId(res.messagesDetails[res.messagesDetails.length - 1].id_message)
                    } else {
                        setLastId('45')
                        setLoading(lang.noMore_message)
                        setTimeout(() => {
                            setLoading('');
                        }, 5000);
                    }
                    var newTempMessage = res.messagesDetails.map((result, index) => {

                        return {
                            attachments: result.attachments,
                            createdAt: result.createdAt,
                            lu: result.lu,
                            sender: result.sender,
                            text: result.text,
                            updatedAt: result.updatedAt,
                            _id: result.id_message

                        };


                    })
                        .reverse()

                    let action = { type: "SET_MESSAGE_HISTORY", value: [...newTempMessage], id_conversation: props.messenger.id_conversation };
                    props.dispatch(action);

                }
            })
        })

    }
    const MyHiLength = MyHistory && MyHistory.discussion.length


    let filterMessage = props.messenger.Broadcast_category === "landlord" ?
        props.messenger.landlordConversationsList && props.messenger.landlordConversationsList.filter((el) => el.id_conversation === props.messenger.id_conversation)
        :
        props.messenger.tenantConversationsList && props.messenger.tenantConversationsList.filter((el) => el.id_conversation === props.messenger.id_conversation)
    if (filterMessage.length !== 0) {
        var messages = filterMessage[0].discussion.map((result, index) => {
            return {
                id: index + 1,
                photo: result.photo,
                author: result.sender,
                message: result.text,
                timestamp: result.createdAt,
                attachement: result.attachments

            };
        }).reverse();
    } else {
        messages = []
    }
    const renderMessages = () => {
        let i = 0;
        let messageCount = messages.length;
        let tempMessages = [];
        while (i < messageCount) {
            let previous = messages[i - 1];
            let current = messages[i];
            let next = messages[i + 1];
            let isMine = current.author === MY_USER_ID;
            let currentMoment = moment(current.timestamp);
            let prevBySameAuthor = false;
            let nextBySameAuthor = false;
            let startsSequence = true;
            let endsSequence = true;
            let showTimestamp = true;
            if (previous) {
                let previousMoment = moment(previous.timestamp);
                let previousDuration = moment.duration(
                    currentMoment.diff(previousMoment)
                );
                prevBySameAuthor = previous.author === current.author;
                if (prevBySameAuthor && previousDuration.as("hours") < 1) {
                    startsSequence = false;
                }
                if (previousDuration.as("hours") < 1) {
                    showTimestamp = false;
                }
            }
            if (next) {
                let nextMoment = moment(next.timestamp);
                let nextDuration = moment.duration(nextMoment.diff(currentMoment));
                nextBySameAuthor = next.author === current.author;
                if (nextBySameAuthor && nextDuration.as("hours") < 1) {
                    endsSequence = false;
                }
            }
            tempMessages.push(
                <Fragment id='message'>
                    <Message
                        key={i}
                        isMine={isMine}
                        startsSequence={startsSequence}
                        endsSequence={endsSequence}
                        showTimestamp={showTimestamp}
                        data={messages[i]}
                    />
                </Fragment>
            );
            i += 1;
        }
        return tempMessages;
    };
    const scrollToBottom = () => {
        const objDiv = document.getElementById('myDIV');
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    useEffect(() => {
            scrollToBottom()
    }, [props.messenger.id_conversation])

    return (
        <div id={`myDIV`} className={props.auth.lang + "_message-list"} ref={messagesEndRef}>
            <Toolbar
                title="Conversation Title"
                conversationDeleted={messages.length !== 0 ? true : false}
            />
            <div id='messageListContainerResponsive' className={props.auth.lang + "_message-list-container"}>

                {
                    props.messenger.Broadcast ?
                        <div
                            className={[
                                "message",
                                `${"mine"}`,
                                `${"start"}`
                            ].join(" ")}
                        >
                            <div className={props.auth.lang + "_discussion_container_mine"}>
                                <div className={props.auth.lang + "_discussion_noLogo_container"}>
                                </div>
                                {props.messenger.broadcast_message.reverse().map(el => <div className="bubble-container">
                                    <div className="bubble" >
                                        <p className='message_date_messenger' >{moment(new Date()).startOf('minut').fromNow()}</p>
                                        {el}

                                    </div>
                                </div>)}
                            </div>
                        </div>
                        :
                        MyHistory && MyHiLength != 0 ?
                            <InfiniteScroll
                                dataLength={MyHistory && MyHiLength}
                                next={MyHistory && MyHiLength >= 30 ? getMessages : null}
                                style={{ display: 'flex', flexDirection: 'column-reverse', position: 'relative' }} //To put endMessage and loader to the top.
                                inverse={true}
                                hasMore={true}
                                loader={<h4 className={props.auth.lang + '_history_load'}> {loading} </h4>}
                                scrollableTarget={`myDIV`}
                            >
                                {renderMessages().reverse()
                                }
                            </InfiniteScroll>
                            : renderMessages()
                }
            </div>
            {props.messenger.id_receiver === '' ? null : <Compose ScrollToBottom={scrollToBottom} />}
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        user: state.user,
        tenant: state.tenant,
        messenger: state.messenger
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: (action) => {
            dispatch(action);
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageList);