import React from "react"



class ButtonUtil extends React.Component {
  constructor(props) {
    super(props)

  }
  static defaultProps = {
    textColor: "white",
    classNameButton: "loginFormButton",
    text: "",
    onClick: null,
    backgroundColor: "#E6E6E6",
    width: "100%",
    fontSizeText: "16px"

  }

  render() {

    return (
      <>

        <button
          onClick={this.props.onClick}
          type="primary"
          htmlType="submit"

          style={{
            width: this.props.width,
            height: "40px",
            backgroundColor: this.props.backgroundColor,
            borderColor: this.props.backgroundColor,
            fontSize: "14px",
            borderStyle: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontFamily: "SF-Pro-Display-Semibold",
            display: "inline-block"
          }}

        >
          <span
            style={{
              fontWeight: 500,
              color: this.props.textColor,
              paddingTop: "7px",
              fontSize: "14px",
              fontFamily: "SF-Pro-Display-Semibold"
            }}
          >
            {this.props.text}
          </span>
        </button>

      </>
    )
  }
}

export default ButtonUtil;




