const initial = {
    SearchProperty: "",
    SearchRentals: "",
    SearchInvoices: "",
    SearchConnectedBank: "",
    PropertyStatus: "",
    ConnectedBankStatus: "",
    StartPropertyDate: "",
    StartInvoicesDate: "",
    EndPropertyDate: "",
    EndInvoicesDate: "",
    StartRentalsDate: "",
    EndRentalsDate: "",

}

const SearchReducer = (state = initial, action) => {
    if (action.type === "SET_PROPERTY_SEARCH") {
        return {
            ...state,
            SearchProperty: action.payload
        }
    } else if (action.type === "SET_RENTALAS_SEARCH") {
        return {
            ...state,
            SearchRentals: action.payload
        }
    } else if (action.type === "SET_CONNECTEDBANK_SEARCH") {
        return {
            ...state,
            SearchConnectedBank: action.payload
        }
    } else if (action.type === "SET_PROPERTY_DATE") {
        return {
            ...state,
            StartPropertyDate: action.payload.startDate,
            EndPropertyDate: action.payload.endDate

        }
    } else if (action.type === "SET_RENTALS_DATE") {
        return {
            ...state,
            StartRentalsDate: action.payload.startDate,
            EndRentalsDate: action.payload.endDate
        }
    } else if (action.type === "SET_PROPERTY_STATUS") {
        return {
            ...state,
            PropertyStatus: action.payload,

        }
    } else if (action.type === "SET_CONNECTED_BANK_STATUS") {
        return {
            ...state,
            ConnectedBankStatus: action.payload,
        }
    } else if (action.type === "SET_INVOICES_SEARCH") {
        return {
            ...state,
            SearchInvoices: action.payload
        }
    } else if (action.type === "SET_INVOICES_DATE") {
        return {
            ...state,
            StartInvoicesDate: action.payload.startDate,
            EndInvoicesDate: action.payload.endDate
        }
    } else if (action.type === "DEPOSIT_SELECTED") {
        return {
            ...state,
            StartRentalsDate: action.startDate,
            EndRentalsDate: action.endDate
        }
    } else if (action.type === "RENTALS_SELECTED") {
        return {
            ...state,
            StartRentalsDate: action.startDate,
            EndRentalsDate: action.endDate
        }
    }

    else return state
}

export default SearchReducer