import React, { Component } from "react";
import { Modal } from 'antd';

import { connect } from "react-redux";
import translator from "../../../lang/translator";
import Groupe from "../../../assets/images/Groupe 1731.png"
class ActionView extends Component {
  translator = null;
  constructor(props) {
    super(props);
    this.lang = translator(this.props.lang);
    this.state = {
        visible: false

    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };


  handleOk =() => {
  
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };


  render() {
let action =this.props.action.split(" ")
let pos1=action.indexOf(":")+1
let pos2=action.indexOf("for")

let bq=action.slice(pos1,pos2)

    return (
        <div>
        <div onClick={this.showModal  }  className={this.props.lang + "_LogActionTitle"} >
     {this.props.action}  
        </div>
        <Modal  className={this.props.lang + "_LogAction"}
     footer={null}
     closable={false}
          visible={this.state.visible} >
          <p className={this.props.lang + "_LogDTime1"} > {this.props.time1} </p>
          <img onClick={this.handleOk }  className={this.props.lang + "_imgModalDel"}    src={Groupe}></img>
    <div className={this.props.lang + "_LogDetail"} >   <p className={this.props.lang + "_LogDescriChild"} > {this.props.user} </p>
       {this.formatMessage(this.props.action)}
          </div>
            
        </Modal>
      </div>
    );
  }

  formatMessage=(msg)=>{
    let msgTab =msg.split(" ")
    if(msgTab[0]=="Accepted"){
      let pos1=msgTab.indexOf(":")
      let pos2=msgTab.indexOf("for")
      return (
        <p>
        Accepted {msgTab[1]} account :
          <span className={this.props.lang + "_LogDescriChild"} > {msgTab.slice(pos1+1,pos2)+" "}</span>
            for <br></br><span className={this.props.lang + "_LogDescriChild"} >{msgTab.slice(pos2+1)}</span>
           </p>
      )
    }

    if(msgTab[0]=="Rejected"){
      let pos1=msgTab.indexOf(":")+1
      let pos2=msgTab.indexOf("for")
      let bq=msg.slice(pos1,pos2)
      return (
        <p>
          Rejected {msgTab[1]} account : 
          <span className={this.props.lang + "_LogDescriChild"} > {msgTab.slice(pos1+1,pos2)+" "}</span>for<br></br> <span className={this.props.lang + "_LogDescriChild"} >{msgTab.slice(pos2+1)}</span>
             </p>
      )
    }

    if(msgTab[0]=="Transferred"){
      /******Ammount****** */
      let pos1=msgTab.indexOf("Transferred")+1
 
         let bqAccountTo=msgTab.indexOf(":")
         let posFor=msgTab.indexOf("for")

      /****** Num BQ****** */
      
    
    
      return (
        <p>
          Transferred <span className={this.props.lang + "_LogDescriChild"}> {msgTab.slice(pos1,pos1+1)} {/* */} KWD </span> <br></br>
        to bank account :<span className={this.props.lang + "_LogDescriChild"}> {msgTab.slice(bqAccountTo+1,posFor)} </span>
        <br></br>
        <span > {msgTab.slice(posFor,posFor+1)} </span> 
        <span className={this.props.lang + "_LogDescriChild"}> {'"'+ msgTab.slice(posFor+1).join(' ')+ '"'} </span>
        </p> 
      )
    }

    return(
      <p>{msg}</p>
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
  };
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    lang: state.auth.lang,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionView);
