import { Modal, Form, Button } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import translator from "../../../../lang/translator";
import PhoneNumber from "../../../../UIKIT/PhoneInput/index";
class PhoneNumberUpdateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.lang = translator(this.props.lang);
    }

    render() {
        return (
            <Modal
                className="tenant_phone_number_ModalPhone"
                visible={this.props.isUpdateModal}
                footer={null}
                closable={false}
                centered
            >
                <p className="update_phone_header">
                    {this.lang.update_number}
                </p>
                <p className="update_phone_description">
                    {this.props.description}
                </p>
                <Form.Item>
                    <PhoneNumber
                        label={false}
                        value={this.props.landlordNumber}
                        onChange={(phone_number, a, b, c) => this.props.ChangePhoneVerif(phone_number, a, b, c)}
                    />
                    <span style={{ color: "red", display: "flex", paddingTop: "10px" }}>
                        {this.props.errorMessage}
                    </span>
                </Form.Item>
                <p className="update_error_message">{this.props.errorMsg}</p>
                <Button
                    className="update_phone_button"
                    onClick={this.props.verifNumber}
                    icon={this.props.buttonLabel}
                >
                </Button>
                <span
                    onClick={this.props.update}
                    className="update_phone_cancel">
                    {this.lang.cancel}
                </span>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
    };
};

export default connect(mapStateToProps, null)(PhoneNumberUpdateModal);