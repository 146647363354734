import React, { Component } from 'react';
import { connect } from "react-redux";
import translator from "../../lang/translator";
import { apiUrl } from "../../config/config";
import search from "../../assets/icons/baseline-keyboard_arrow_down-24px.svg";
import moment from "moment";

import {
    Modal,
    Row,
    Col,
    Button,
    Input,
    Select,
    Upload,
    message,
    Checkbox,
    notification,
} from "antd";
import { formateNumber } from '../helpers/Helper';
const { Dragger } = Upload;
class AddTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attachement: [],
            rentalsId: [],
            collections: [],
            search: "",
            checkedtedt: [],
            checkedtedtDiscount: [],
            tenants: [],
            tenantWithDiscount: [],
            idBank: '',
            amount: 0,
            invoiceAmount: 0,
            rentAmount: 0,
            deposit: 0,
            checkedList: [],
            checkAll: false,
            transaction_fees: 0,
            actual_transfer: 0,
            checkAllWithDiscount: false,
            percentage: "",
        };
        this.lang = translator(this.props.lang);
    }
    btnCancelPressedModal() {
        this.setState({
            amount: 0,
            idBank: this.lang.selectBank,
            attachement: [],
            tenants: [],
            transaction_fees: 0,
            actual_transfer: 0,
            invoiceAmount: 0,
            rentAmount: 0,
            collections: [],
            deposit: 0,
            checkedtedt: []
        });
        this.props.closeModalTransfer()
    }
    openNotificationWithIcon = (type) => {
        notification[type]({
            message: this.lang.successTransfer,
            duration: null,
            top: 125,
            className: this.props.lang + "_PendingBanknotification",
            placement: "topRight",
        });
    };
    btnOkPressedModal(discount) {
        const formData = new FormData();

        formData.append("landlordId", this.props.data.landLordId);
        formData.append("discount", discount);
        this.state.attachement.forEach((el) => {
            formData.append("attachments", el.originFileObj);
        });
        formData.append("bankAccountId", this.state.idBank);
        this.state.collections.forEach((el) => {
            formData.append("collections", el);
        });
        fetch(apiUrl + "api/admin/addTransfer", {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.auth.token
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status === 201) {
                    this.props.closeModalTransfer()
                    this.openNotificationWithIcon("success");
                    this.setState({
                        amount: 0,
                        idBank: this.lang.selectBank,
                        attachement: [],
                        tenants: [],
                        tenantWithDiscount: [],
                        transaction_fees: 0,
                        actual_transfer: 0,
                        invoiceAmount: 0,
                        rentAmount: 0,
                        collections: [],
                        deposit: 0,
                        percentage: "",
                        checkAll: false,
                        checkAllWithDiscount: false,
                        checkedtedt: [],
                        checkedtedtDiscount: [],
                    })

                }
            })
            .catch((error) => {
                console.error(error);
            });
    }
    onChangeBank = (value) => {
        this.setState({
            idBank: value,
        });
        const formData = new FormData();
        formData.append("bankAccountId", value);
        fetch(apiUrl + "api/admin/getTenantsTransfers", {
            method: "POST",
            mode: "cors",
            headers: { Authorization: "Bearer " + this.props.auth.token },
            body: formData,
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 200) {
                    let tenantWithDiscount = []
                    let tenantWithoutDiscount = []
                    responseJson.tenants.map(el => {
                        let dateNowDisc = moment(new Date()).startOf('day')
                        let endDateDisc = moment(new Date(el.discount?.period_to)).startOf('day')
                        let diffDays = endDateDisc.diff(dateNowDisc, 'days')
                        if (el.discount && el.discount.properties.indexOf(el.property) !== -1 && diffDays > 0) {
                            tenantWithDiscount.push(el)
                        }
                        else {
                            tenantWithoutDiscount.push(el)
                        }
                    })
                    this.setState({
                        tenants: tenantWithoutDiscount,
                        tenantWithDiscount: tenantWithDiscount,
                    });
                    if (tenantWithDiscount.length !== 0) {
                        this.setState({
                            percentage: tenantWithDiscount[0].discount.percentage
                        });
                    }
                }

            })
            .catch((error) => {
                console.error(error);
            });
    };

    onChangeAttachement = (info) => {
        this.setState({
            attachement: info.fileList,
        });
    };
    Ammount() {
        if (this.state.checkedtedt.length == 0) {
            this.setState({
                invoiceAmount: 0,
                rentAmount: 0,
                amount: 0,
                collections: [],
                transaction_fees: 0,
                actual_transfer: 0,
                deposit: 0
            });
        } else {
            const formData = new FormData();
            formData.append("landlordId", this.props.data.landLordId)
            for (let i = 0; i < this.state.checkedtedt.length; i++) {
                formData.append("unitId", this.state.checkedtedt[i]);
            }
            fetch(apiUrl + "api/admin/getActualAmount", {
                method: "POST",
                mode: "cors",
                headers: { Authorization: "Bearer " + this.props.auth.token },
                body: formData,
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status === 200) {
                        this.setState({
                            invoiceAmount: responseJson.invoiceAmount,
                            rentAmount: responseJson.rentAmount,
                            amount: responseJson.amount,
                            collections: responseJson.collections_list,
                            transaction_fees: responseJson.transaction_fees,
                            actual_transfer: responseJson.actual_transfer,
                            deposit: responseJson.depositAmount
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
    AmmountWithDiscount() {
        if (this.state.checkedtedtDiscount.length == 0) {
            this.setState({
                invoiceAmount: 0,
                rentAmount: 0,
                amount: 0,
                collections: [],
                transaction_fees: 0,
                actual_transfer: 0,
                deposit: 0
            });
        } else {
            const formData = new FormData();
            formData.append("landlordId", this.props.data.landLordId)
            for (let i = 0; i < this.state.checkedtedtDiscount.length; i++) {
                formData.append("unitId", this.state.checkedtedtDiscount[i]);
            }
            fetch(apiUrl + "api/admin/getActualAmount", {
                method: "POST",
                mode: "cors",
                headers: { Authorization: "Bearer " + this.props.auth.token },
                body: formData,
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status === 200) {
                        this.setState({
                            invoiceAmount: responseJson.invoiceAmount,
                            rentAmount: responseJson.rentAmount,
                            amount: responseJson.amount,
                            collections: responseJson.collections_list,
                            transaction_fees:
                                responseJson.transaction_fees - (responseJson.transaction_fees * parseInt(this.state.percentage)) / 100
                            ,
                            actual_transfer: responseJson.amount - (responseJson.transaction_fees - (responseJson.transaction_fees * parseInt(this.state.percentage)) / 100),
                            deposit: responseJson.depositAmount
                        });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.checkedtedt.length !== this.state.checkedtedt.length) {
            this.Ammount();
        }
        if (prevState.checkedtedt.length !== this.state.checkedtedt.length && this.state.checkedtedt.length === this.state.tenants.length) {
            this.Ammount();
            this.setState({
                checkAll: true
            })
        }
        if (prevState.checkedtedt.length !== this.state.checkedtedt.length && this.state.checkedtedt.length !== this.state.tenants.length) {
            this.Ammount();
            this.setState({
                checkAll: false
            })
        }

        if (prevState.checkedtedtDiscount.length !== this.state.checkedtedtDiscount.length) {
            this.AmmountWithDiscount();
        }
        if (prevState.checkedtedtDiscount.length !== this.state.checkedtedtDiscount.length && this.state.checkedtedtDiscount.length === this.state.tenantWithDiscount.length) {
            this.AmmountWithDiscount();
            this.setState({
                checkAllWithDiscount: true
            })
        }
        if (prevState.checkedtedtDiscount.length !== this.state.checkedtedtDiscount.length && this.state.checkedtedtDiscount.length !== this.state.tenantWithDiscount.length) {
            this.AmmountWithDiscount();
            this.setState({
                checkAllWithDiscount: false
            })
        }

    }
    onChangeCheckbox = (el, id) => {
        if (this.state.checkedtedt.indexOf(el) === -1) {
            this.setState({
                checkedtedt: [...this.state.checkedtedt, el],
            });
        } else {
            this.setState({
                checkedtedt: this.state.checkedtedt.filter((e) => e !== el),
                checkAll: false,
            });
        }
        if (this.state.tenants.length === this.state.checkedtedt.length) {
            this.setState({
                checkAll: true,
            });
        } else {
            this.setState({
                checkAll: false,
            });
        }
    }
    onChangeCheckboxDiscount = (el, id) => {
        if (this.state.checkedtedtDiscount.indexOf(el) === -1) {
            this.setState({
                checkedtedtDiscount: [...this.state.checkedtedtDiscount, el],
            });
        } else {
            this.setState({
                checkedtedtDiscount: this.state.checkedtedtDiscount.filter((e) => e !== el),
                checkAllWithDiscount: false,
            });
        }
        if (this.state.tenantWithDiscount.length === this.state.checkedtedtDiscount.length) {
            this.setState({
                checkAllWithDiscount: true,
            });
        } else {
            this.setState({
                checkAllWithDiscount: false,
            });
        }
    }
    onCheckAllChange = (e) => {
        this.setState({
            checkedtedt: e.target.checked
                ? this.state.tenants.map((el) => el._id)
                : [],
            checkAll: e.target.checked,
        });
    };
    onCheckAllChangeDiscount = (e) => {
        this.setState({
            checkedtedtDiscount: e.target.checked
                ? this.state.tenantWithDiscount.map((el) => el._id)
                : [],
            checkAllWithDiscount: e.target.checked,
        });
    };
    render() {
        const props1 = {
            name: "file",
            multiple: true,
            action: "https://www.google.com",
        };
        return (
            <Modal
                centered
                closable={false}
                visible={this.props.isTransferModalVisible}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                footer={null}
                width="820px"
            >
                <Row className={this.props.lang + "_ModalAddTransferCol"}>
                    <Col span={10}>
                        <span className={this.props.lang + "_ModalAddTransferTitle"}>
                            {this.lang.AddTransfert}
                        </span>
                    </Col>
                </Row>
                <Row className={this.props.lang + "_ModalAddTransferCol"}>
                    <Col xl={10} md={10} lg={10} xs={24}>
                        <label
                            className={this.props.lang + "_ModalAddTransferPrincipalLabel"}
                        >
                            {this.lang.Bankaccount}
                        </label>

                        <Select
                            value={this.state.idBank}
                            showSearch
                            className='addTransfer_select'
                            placeholder={this.lang.selectBank}
                            optionFilterProp="children"
                            onChange={this.onChangeBank}
                        >
                            {this.props.data.approuvedBank &&
                                this.props.data.approuvedBank.map((item) => {
                                    return <option value={item.id}>{item.bank_name}</option>;
                                })}
                        </Select>

                    </Col>
                    <Col xl={{ span: 10, offset: 4 }} lg={{ span: 10, offset: 4 }} md={{ span: 10, offset: 4 }} xs={24}>
                        <label
                            className={this.props.lang + "_ModalAddTransferPrincipalLabel"}
                        >
                            {this.lang.Attachement}
                        </label>
                        <Dragger
                            listType="picture"
                            fileList={this.state.attachement}
                            onChange={this.onChangeAttachement}
                            accept=".pdf ,.PDF, .jpg,.JPEG, .PNG"
                            shape="round"
                            className={this.props.lang + "_dragBulk"}
                            {...props1}
                        >
                            <span className={this.props.lang + "_uploadtext"}>
                                {this.lang.browse}
                            </span>
                            <span
                                className='or_style'
                            >
                                {this.lang.or}
                            </span>
                            <span className={"drag_drop"}>
                                {this.lang.drag_and_drop}
                            </span>
                        </Dragger>
                    </Col>
                </Row>
                <Row className={this.props.lang + "_ModalAddTransferCol"}>
                    <Col span={10}>
                        <label
                            className={this.props.lang + "_ModalAddTransferPrincipalLabel"}
                        >
                            {this.lang.tenantTransfer}
                        </label>
                        <div span={24}>
                            <Input
                                allowClear={true}
                                className={this.props.lang + "_adminReceipView_searchInput"}
                                prefix={<img src={search} />}
                                placeholder={this.lang.Search}
                                onChange={(e) => this.setState({
                                    search: e.target.value, tenants: this.state.tenants.filter((e) =>
                                        e.name.toLowerCase().includes(this.state.search.toLowerCase().trim())
                                    )
                                })}
                            />
                        </div>
                        <div className={this.props.lang + "_AllTenants_entete"}>
                            <Checkbox
                                className={!this.state.checkAll && this.state.checkedtedt.length > 0 ? "EditLandlords_semi" : "EditLandlords_Check"}
                                onChange={this.onCheckAllChange}
                                checked={
                                    this.state.checkAll ||
                                    (this.state.tenants.length ===
                                        this.state.checkedtedt.length &&
                                        this.state.checkedtedt.length > 0)
                                }
                                disabled={this.state.checkedtedtDiscount.length !== 0}
                            >
                                {" "}
                                {this.lang.All_tenants}
                            </Checkbox>
                        </div>
                        <div className='tenantList_container'>
                            <ul className={this.props.lang + "_ModalAddTransferUl"}>
                                {this.state.tenants.map((el, i) => (
                                    <li key={i}>
                                        <Row className='tenant_info'>
                                            <Col span={1}>
                                                {
                                                    <Checkbox
                                                        onChange={(e) =>
                                                            this.onChangeCheckbox(el._id, el.id)
                                                        }
                                                        checked={this.state.checkedtedt.indexOf(el._id) !== -1}
                                                        disabled={this.state.checkedtedtDiscount.length !== 0}
                                                    >
                                                    </Checkbox>
                                                }
                                            </Col>

                                            <Col
                                                offset={1}
                                                span={13}
                                                className={this.props.lang + "_ModalAddTransferName"}
                                            >
                                                {el.name}
                                            </Col>
                                            <Col span={9}
                                                className={
                                                    this.props.lang + "_ModalAddTransferAmountValue2"
                                                }
                                            >
                                                {formateNumber(el.amount)}
                                            </Col>
                                            <Col span={24} className="addTransfer_date">
                                                <label>{moment(el.payment_date).utcOffset(0).format("MMMM DD YYYY")}</label>
                                            </Col>
                                            <Col span={24} className="addTransfer_time">
                                                <div>{moment(el.payment_date).utcOffset(0).format("hh:mm A")}</div>
                                            </Col>
                                        </Row>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className={this.props.lang + "_AllTenants_entete"}>
                            <Checkbox
                                className={!this.state.checkAllWithDiscount && this.state.checkedtedtDiscount.length > 0 ? "EditLandlords_semi" : "EditLandlords_Check"}
                                onChange={this.onCheckAllChangeDiscount}
                                checked={
                                    this.state.checkAllWithDiscount ||
                                    (this.state.tenantWithDiscount.length ===
                                        this.state.checkedtedtDiscount.length &&
                                        this.state.checkedtedtDiscount.length > 0)
                                }
                                disabled={this.state.checkedtedt.length !== 0}
                            >
                                {" "}
                                {this.lang.All_landlords_discount}
                            </Checkbox>
                        </div>
                        <div className='tenantList_container'>
                            <ul className={this.props.lang + "_ModalAddTransferUl"}>
                                {this.state.tenantWithDiscount.map((el, i) => (
                                    <li key={i}>
                                        <Row className='tenant_info'>
                                            <Col span={1}>
                                                {
                                                    <Checkbox
                                                        onChange={(e) =>
                                                            this.onChangeCheckboxDiscount(el._id, el.id)
                                                        }
                                                        checked={this.state.checkedtedtDiscount.indexOf(el._id) !== -1}
                                                        disabled={this.state.checkedtedt.length !== 0}
                                                    >
                                                    </Checkbox>
                                                }
                                            </Col>

                                            <Col
                                                offset={1}
                                                span={13}
                                                className={this.props.lang + "_ModalAddTransferName"}
                                            >
                                                {el.name}
                                            </Col>
                                            <Col span={9}
                                                className={
                                                    this.props.lang + "_ModalAddTransferAmountValue2"
                                                }
                                            >
                                                {formateNumber(el.amount)}
                                            </Col>
                                            <Col span={24} className="addTransfer_date">
                                                <label>{moment(el.payment_date).utcOffset(0).format("MMMM DD YYYY")}</label>
                                            </Col>
                                            <Col span={24} className="addTransfer_time">
                                                <div>{moment(el.payment_date).utcOffset(0).format("hh:mm A")}</div>
                                            </Col>
                                        </Row>
                                    </li>
                                ))}
                            </ul>
                        </div>

                    </Col>
                    <Col span={8} offset={4}>
                        <label className={this.props.lang + "_ModalAddTransferPrincipalLabel"}>
                            {this.lang.amount_kwd}
                        </label>
                        <div className="ModalAddTransferRectangle">

                            <Row className="ModalAddTransferItem">
                                <Col span={14}>
                                    <span
                                        className="ModalAddTransferLabel"
                                    >
                                        {this.lang.Rent}
                                    </span>
                                </Col>
                                <Col span={10}>
                                    <span
                                        className="ModalAddTransferAmountValue ModaladdTransferLabelMedium"
                                    >
                                        {this.state.rentAmount !== 0 && formateNumber(this.state.rentAmount)}
                                    </span>
                                </Col>
                            </Row>
                            <Row className="ModalAddTransferItem">

                                <Col span={14}>
                                    <span
                                        className="ModalAddTransferLabel"
                                    >
                                        {this.lang.Invoices}
                                    </span>
                                </Col>
                                <Col span={10}>
                                    <span
                                        className="ModalAddTransferAmountValue ModaladdTransferLabelMedium"
                                    >
                                        {this.state.invoiceAmount !== 0 && formateNumber(this.state.invoiceAmount)}
                                    </span>
                                </Col>
                            </Row>
                            <Row className="ModalAddTransferItem">
                                <Col span={14}>
                                    <span
                                        className="ModalAddTransferLabel"
                                    >
                                        {this.lang.Deposit}
                                    </span>
                                </Col>
                                <Col span={10}>
                                    <span
                                        className="ModalAddTransferAmountValue ModaladdTransferLabelMedium"
                                    >
                                        {this.state.deposit !== 0 && formateNumber(this.state.deposit)}
                                    </span>
                                </Col>
                            </Row>
                            <Row className="ModalAddTransferItem">
                                <Col span={14}>
                                    <span
                                        className="ModalAddTransferLabel ModaladdTransferKWDBold"
                                    >
                                        {this.lang.totalAmount}
                                    </span>
                                </Col>
                                <Col span={10}>
                                    <span
                                        className="ModalAddTransferAmountValue ModaladdTransferKWDBold"
                                    >
                                        {this.state.amount !== 0 && formateNumber(this.state.amount)}
                                    </span>
                                </Col>
                            </Row>
                        </div>
                        <Col span={10} className="ModalAddTransferCol2">
                            <div className="ModalAddTransferRectangle ModalAddTransferRectangleFees">
                                <Row className="ModalAddTransferItem">
                                    <Col span={14}>
                                        <span
                                            className="ModalAddTransferLabel"
                                        >
                                            {this.lang.transactionFees}
                                        </span>
                                    </Col>
                                    <Col span={10}>
                                        <span
                                            className="ModalAddTransferAmountValue red_value"

                                        >
                                            {this.state.transaction_fees !== 0 && formateNumber(this.state.transaction_fees)}
                                        </span>
                                    </Col>
                                </Row>
                                <Row
                                    className="ModalAddTransferItem ModalAddTransferItemLast"
                                >
                                    <Col span={14}>
                                        <span
                                            className="ModalAddTransferLabel"
                                        >
                                            {this.lang.Actual_transfer}
                                        </span>
                                    </Col>
                                    <Col span={10}>
                                        <span
                                            className="ModalAddTransferAmountValue green_value"
                                        >
                                            {this.state.actual_transfer !== 0 && formateNumber(this.state.actual_transfer)}
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                    </Col>
                </Row>
                <div
                    className='addTransfer_btn_container' >

                    <Button
                        disabled={this.state.collections && this.state.collections.length == 0}
                        className={this.props.lang + "_styleButtonDelete"}
                        onClick={() => this.btnOkPressedModal(this.state.checkedtedtDiscount.length !== 0 && "discount")}
                    >
                        {`${this.lang.AddTransfert} ${this.state.checkedtedt.length !== 0 ? "" : "discount"}`}
                    </Button>
                    <Button
                        className={this.props.lang + "_styleButtonCancel"}
                        onClick={() => this.btnCancelPressedModal()}
                    >
                        {this.lang.cancel}
                    </Button>
                </div>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        selectedTab: state.selectedTab,
        auth: state.auth,
        user: state.user,
        amount: state.amount
    };
};
export default connect(mapStateToProps, null)(AddTransfer);