import React, { Component } from "react";
import { Row, Col, Input, Form, Divider, ConfigProvider, Checkbox, Switch } from "antd";
import FormItem from "antd/lib/form/FormItem";
import translator from "../../lang/translator";
import { connect } from "react-redux";
import Checked from "../../assets/icons/IconCheckcircle.svg";
import EditLandlords from "./EditLandlords";
import ButtonUtil from "./SubUsers/utils/Button";
import { apiUrl } from "../../config/config";
import PhoneNumber from "../../UIKIT/PhoneInput/index"
import { convertArabicNumbers } from "./Profile/Utils/ArabicConverter";
class SubUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: "",
      phone_number: "",
      addTransfert: false,
      messenger: false,
      claims: false,
      kyc: false,
      acceptBank: false,
      visible: false,
      landlords: [],
      length: 0,
      permission: [],
      viewOnly: false,
      phoneObject: '+965',
      checkedList: [],
      pemissionErrorMessage: ''
    };
    this.lang = translator(this.props.lang);
  }
  onChangeCheckedList = (list) => {
    this.setState({
      checkedList: list
    })
  }
  onShow = (visibe) => {
    this.setState({
      visible: visibe,
    });
  };
  landlordsNumber = (number, length) => {
    this.setState({
      //landlords: number,
      length: length,
    });
  };

  onSubmit = () => {
    const fomdata = new FormData();
    fomdata.append("full_name", this.state.full_name);
    fomdata.append("phone_number", this.state.phone_number);
    this.state.acceptBank &&
      fomdata.append("user_permissions", "Accept bank account");
    this.state.addTransfert &&
      fomdata.append("user_permissions", "Add transfers");
    this.state.messenger &&
      fomdata.append("user_permissions", "Messenger");
    this.state.claims &&
      fomdata.append("user_permissions", "Claims");
    this.state.kyc &&
      fomdata.append("user_permissions", "KYC");
    fomdata.append("view_only", this.state.viewOnly);
    this.state.checkedList.length !== 0 ?
      this.state.checkedList.forEach(el => {
        return fomdata.append("landlord", el)
      }) :
      this.props.landlords.forEach(el => {
        return fomdata.append("landlord", el._id)
      });
    fetch(apiUrl + `api/admin/subUserInvite`, {
      method: "POST",
      headers: { Authorization: "Bearer " + this.props.auth.token },
      body: fomdata,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 200) {
          this.props.history.push(`/settings/subUserList`)
        } else if (data.status === 404) {
          this.setState({ errorMessage: "Subuser already invited" })
        } else if (data.status === 400 && data.message.includes("user_permissions")) {
          this.setState({ pemissionErrorMessage: "User permissions are required" })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onChangeViewOnly = (checked) => {
    this.setState({
      viewOnly: checked
    })
  }
  onChangePhoneNumber = (phone_number, a, b, c) => {
    if (b.nativeEvent && b.nativeEvent.data) {
      let num = convertArabicNumbers(b.nativeEvent.data)
      let regex = /[0-9]/g
      this.setState({
        phone_number: num.toString().match(regex) && !b.nativeEvent.data.toString().match(regex) ? "+" + phone_number + num : "+" + phone_number,
      })
    } else {
      this.setState({
        phone_number: "+" + phone_number,
      })
    }
  }
  render() {
    return (
      <ConfigProvider direction={this.props.lang === "en" ? "ltr" : "rtl"}>
        <div
          style={{
            marginLeft: "6%",
            marginTop: "4px",
          }}
        >
          <p className="SubuserTitle">{this.lang.Invite_subusers}</p>
          <p className="SubUserlabel">{this.lang.user_info}</p>
          <Row>
            <Col xs={24} sm={15} md={14} lg={16} xl={10}>
              <Form>
                <div className="sub_input_label">{this.lang.full_name}</div>
                <FormItem>
                  <Input
                    style={{ borderRadius: "5px", height: "40px" }}
                    value={this.state.full_name}
                    required
                    onChange={(event) => {
                      this.setState({ full_name: event.target.value });
                    }}
                    placeholder={this.lang.Enter_name}
                  />
                </FormItem>

                <label className="sub_input_label">
                  {this.lang.phoneNumber}
                </label>
                <FormItem>
                  <PhoneNumber

                    label={false}
                    value={this.state.phone_number}
                    onChange={(phone_number, a, b, c) => this.onChangePhoneNumber(phone_number, a, b, c)}
                  />
                  <span className="user-permissions-error">
                    {this.state.errorMessage}
                  </span>
                </FormItem>
                <Divider style={{ width: "50%" }} orientation="left" />
                <label className="SubUserlabel">
                  {this.lang.User_permissions}
                </label>
                <Col>
                  <div className="subuser_view_only">
                    <span>{this.lang.viewOnly}</span>
                    <Switch checked={this.state.viewOnly} onChange={this.onChangeViewOnly} />
                  </div>
                  <div className="user_permission">
                    <Checkbox
                      onChange={() =>
                        this.setState({ acceptBank: !this.state.acceptBank, pemissionErrorMessage: '' })
                      }
                      className="checkBoxEdit"
                    >
                      <span className="checkbox_text">
                        {this.lang.accept_bank_account}
                      </span>
                    </Checkbox>
                    <Checkbox
                      onChange={() =>
                        this.setState({
                          addTransfert: !this.state.addTransfert, pemissionErrorMessage: ''
                        })
                      }
                      className="checkBoxEdit"
                    >
                      <span className="checkbox_text">
                        {this.lang.AddTransfert}
                      </span>
                    </Checkbox>
                    <Checkbox
                      onChange={() =>
                        this.setState({
                          messenger: !this.state.messenger, pemissionErrorMessage: ''
                        })
                      }
                      className="checkBoxEdit"
                    >
                      <span className="checkbox_text">
                        {this.lang.messenger}
                      </span>
                    </Checkbox>
                    <Checkbox
                      onChange={() =>
                        this.setState({
                          claims: !this.state.claims, pemissionErrorMessage: ''
                        })
                      }
                      className="checkBoxEdit"
                    >
                      <span className="checkbox_text">
                        {this.lang.claims}
                      </span>
                    </Checkbox>
                    <Checkbox
                      onChange={() =>
                        this.setState({
                          kyc: !this.state.kyc, pemissionErrorMessage: ''
                        })
                      }
                      className="checkBoxEdit"
                    >
                      <span className="checkbox_text">
                        {this.lang.kyc}
                      </span>
                    </Checkbox>
                  </div>
                  <span className="user-permissions-error" >
                    {this.state.pemissionErrorMessage}
                  </span>
                </Col>
                <Col>
                  <label className="SubUserlabel">{this.lang.landlords}</label>
                  <div className="SubUsersect" style={{ marginTop: "30px" }}>
                    <div>
                      <img
                        className={this.props.lang + "_checked"}
                        src={Checked}
                        alt="checked"
                      />
                      <span className="sub_landlord">
                        {this.state?.checkedList?.length === 0
                          ? `${this.props?.landlords?.length} ${this.lang.landlords}`
                          : `${this.state?.checkedList?.length} ${this.lang.landlords}`}
                      </span>
                    </div>
                    <div
                      className="sub_user_Edit"
                      onClick={() => this.onShow(true)}
                    >
                      {this.lang.edit}
                    </div>
                  </div>
                </Col>

                <Form.Item>
                  <button
                    onClick={this.onSubmit}
                    className={this.props.lang + "_tenantive-btn-edit"}
                  >
                    {this.lang.invite_user}
                  </button>

                  <p style={{ color: "red" }}> {this.state.errorMsg} </p>
                </Form.Item>
                <Form.Item>
                  <ButtonUtil
                    text="Cancel"
                    backgroundColor="transparent"
                    textColor="#4D4D4D"
                    width="100%"
                    onClick={() => this.props.history.goBack()}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
        <EditLandlords
          visible={this.state.visible}
          onShow={(visibe) => this.onShow(visibe)}
          landlordsNumber={(landlords, length) =>
            this.landlordsNumber(landlords, length)
          }
          landlords={this.props.landlords}
          setLandlords={this.props.setLandlords}
          onChangeCheckedList={this.onChangeCheckedList}
          checkedList={this.state.checkedList}
        />
      </ConfigProvider >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.auth.lang,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, null)(SubUser);
