import React, { Component } from "react";
import { DatePicker, Input, Row, Col, Button, Select } from "antd";
import date from "../../assets/Accounting/date.svg";
import translator from "../../lang/translator";
import { connect } from "react-redux";
import { Table, Pagination, Empty } from "antd";
import { apiUrl } from "../../config/config"
import Receipt from './Receipt'
import { getReceipt } from '../../Redux/Actions'
import LoaderTenantive from "../../UIKIT/LoaderTenantive"
import { formateNumber } from "../helpers/Helper";
import TenantInfo from "./TenantInfo";
import { downloadFile } from "../../Api";
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
class Rentals extends Component {
    constructor(props) {
        super(props);
        this.lang = translator(this.props.lang);
        this.state = {
            color: "#F5D8D8",
            search: "",
            rentalsData: [],
            elementnumber: 0,
            pagenumber: 1,
            ReceiptModal: false,
            transfered: '',
            dateStart: '',
            dateEnd: '',
            receipt: {},
            Export: '',
            LoadingTableData: false,
            isTenantInfoVisible: false,
            tenant_id: '',
            property: '',
            unit: '',
            columns: [{
                title: this.lang.dateandtime,
                dataIndex: "payment_date",
                key: "payment_date",
                width: 100,
                render: (text, record) => (
                    <div style={{ fontFamily: "SF-Pro-Display-Medium" }}>
                        {record.payment_date != undefined ? this.GetDateAndTime(record.payment_date) : null}
                    </div>
                )
            },
            {
                title: this.lang.Property,
                dataIndex: "property",
                key: "property",
                width: 100,
                render: (text, record) => (
                    <div>
                        <p style={{ color: '#212121', fontFamily: "SF-Pro-Display-Medium" }}>
                            {record.property}
                        </p>
                    </div>
                )
            },
            {
                title: this.lang.Unit,
                dataIndex: "unit",
                key: "unit",
                width: 100,
                render: (text, record) => (
                    <div>
                        <p style={{ color: '#212121', fontFamily: "SF-Pro-Display-Medium" }}>
                            {"Unit " + record.unit}
                        </p>
                    </div>
                )
            },
            {
                title: this.lang.Tenant,
                dataIndex: "tenant",
                key: "tenant",
                width: 100,
                render: (text, record) => (
                    <div>
                        <p onClick={() => this.onChangeTenantDetailsModalt(record)} className='tenant_rental_name' style={{ color: '#212121', fontFamily: "SF-Pro-Display-Medium" }}>
                            {record.tenant}
                        </p>
                    </div>
                )
            },
            {
                title: this.lang.amount_kwd,
                dataIndex: "amount",
                key: "amount",
                width: 100,
                render: (text, record) => (
                    <div>
                        <span className="Amount">{formateNumber(record.amount)}</span>
                        {record.discount && <div className={this.props.lang + '_discount_label'} >
                            {` (Discount ${record.discount}%) `}
                        </div>}
                    </div>
                )
            },
            {
                title: this.lang.BanklistAction,
                dataIndex: "landlord",
                key: "landlord",
                width: 100,
                render: (text, record) => (
                    <div style={{ width: "100px", height: 40, backgroundColor: '#DDE3E9', borderRadius: 5, cursor: 'pointer', justifySelf: 'flex-start' }} onClick={() => this.ShowReceiptModal(record)}>
                        <p style={{ color: '#1D456D', fontSize: 14, textAlign: 'center', paddingTop: 7, fontFamily: "SF-Pro-Display-Semibold" }}>{this.lang.ViewReceipt}</p>
                    </div>
                )
            },
            {
                width: 8,
                render(text, record) {
                    return {
                        props: {
                            style: { borderRight: record.transferred ? "8px solid #DFF0E0" : "8px solid #F5D8D8" }
                        },
                    };
                },

            },
            ]

        };

    }
    onChangeTenantDetailsModalt = (record) => {
        this.setState({
            isTenantInfoVisible: !this.state.isTenantInfoVisible,
            tenant_id: record.tenant_id,
            property: record.property,
            unit: record.unit
        })
    }
    GetDateAndTime(str) {
        let date = str.split('T')
        let formdate = date[0].split('-')
        let time = date[1].split(':')
        let dayornigth = ''
        if (time[0] - '12' > '0') {
            dayornigth = 'PM'
            time[0] = time[0] - 12
        }
        else { dayornigth = 'AM' }
        return (
            <div style={{ height: 10, marginTop: -20 }}>
                <p style={{ color: '#212121' }}>{formdate[2] + '/' + formdate[1] + '/' + formdate[0]}</p>
                <Row style={{ marginLeft: 5, marginTop: -15 }}>
                    <p style={{ color: '#808080' }}>{time[0] + ':' + time[1]}</p>
                    <p style={{ color: '#808080', marginLeft: 2 }}>{dayornigth}</p>
                </Row>
            </div>
        )
    }
    GetRentalsList(page, search, transfered, dateStart, dateEnd) {
        this.setState({
            LoadingTableData: true
        })
        fetch(apiUrl + "api/admin/rentalsSearchAndFiltration?searchedRental=" + search + "&transfered=" + transfered + "&dateStart=" + dateStart + "&dateEnd=" + dateEnd + "&page=" + page + "&per_page=10", {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.token,
            },
        })
            .then((response) => {
                response.json().then((data) => {

                    this.setState({
                        rentalsData: data.tabPaginate,
                        elementnumber: data.nbrElements,
                        LoadingTableData: false
                    })
                });
            })
            .catch((err) => {
                console.log("error ", err);
            });
    }
    ExportRentalsExecl = (search, dateStart, dateEnd, transfered) => {
        fetch(apiUrl + "api/admin/rentalsExport?searchedRental=" + search + "&dateStart=" + dateStart + "&dateEnd=" + dateEnd + "&transfered=" + transfered, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: "Bearer " + this.props.token,
            },
        })
            .then((response) => {
                response.json().then((data) => {
                    if (data.status === 200) { downloadFile({ path: data.link, type: "excel" }) }
                });

            })
            .catch((err) => {
                console.log("error ", err);
            });
    }
    componentDidMount() {
        this.GetRentalsList(1, '', '', '', '')
    }
    pagination = (page) => {
        this.GetRentalsList(page, this.state.search, this.state.transfered, this.state.dateStart, this.state.dateEnd)
        this.setState({
            pagenumber: page
        })
    }



    ShowReceiptModal = (record) => {
        this.setState({
            ReceiptModal: true,
            receipt: record
        })
        this.props.getReceipt(record.receipt, this.props.token)


    }
    HideReceitptModal() {
        this.setState({
            ReceiptModal: false
        })
    }
    Search(value) {
        this.state.pagenumber = 1
        this.state.search = value
        this.GetRentalsList(this.state.pagenumber, this.state.search, this.state.transfered, this.state.dateStart, this.state.dateEnd)

    }
    FilterTransfered(value) {
        this.state.transfered = value.key
        this.state.pagenumber = 1
        this.GetRentalsList(this.state.pagenumber, this.state.search, this.state.transfered, this.state.dateStart, this.state.dateEnd)
    }
    FilterDate(value) {
        if (value != null) {
            let firstdate = new Date(value[0]._d)
            let secondedate = new Date(value[1]._d)
            let day = ''
            let month = ''
            { firstdate.getDate() > 10 ? day = firstdate.getDate() : day = "0" + firstdate.getDate() }
            { (firstdate.getMonth() + 1) > 10 ? month = (firstdate.getMonth() + 1) : month = "0" + (firstdate.getMonth() + 1) }
            let startdate = firstdate.getFullYear() + "-" + month + "-" + day
            { secondedate.getDate() > 10 ? day = secondedate.getDate() : day = "0" + secondedate.getDate() }
            { (secondedate.getMonth() + 1) > 10 ? month = (secondedate.getMonth() + 1) : month = "0" + (secondedate.getMonth() + 1) }
            let enddate = secondedate.getFullYear() + "-" + month + "-" + day
            this.setState({
                dateStart: startdate,
                dateEnd: enddate,
            })
            this.state.pagenumber = 1
            this.GetRentalsList(this.state.pagenumber, this.state.search, this.state.transfered, startdate, enddate)
        }
        else {
            this.setState({
                dateStart: '',
                dateEnd: '',
            })
            this.GetRentalsList(this.state.pagenumber, this.state.search, this.state.transfered, '', '')
        }
    }

    resetTable = (e) => {

        if (e.target.value === '') {

            this.GetRentalsList(1, '', '', '', '')
        }
    }


    render() {
        return (
            <div className={this.props.lang + "_accounting_transfer_bigContainer"}>
                <Row className={this.props.lang + "_row-transfers-btn"}>
                    <Col
                        span={5}
                        xs={24}
                        sm={24}
                        md={8}
                        lg={5}
                        xl={5}
                        style={{
                            height: "40px",
                            marginBottom: "10px"
                        }}
                    >
                        <Search
                            allowClear={true}
                            style={{
                                height: "100% ",
                                direction: "ltr",
                            }}
                            placeholder={this.lang.Search}
                            onSearch={(value) => this.Search(value)}
                            enterButton
                            onChange={(value) => this.resetTable(value)}
                            className='search-input'
                        />
                    </Col>
                    <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                    <Col
                        style={{ marginBottom: "10px" }}
                        span={5}
                        xs={24}
                        sm={24}
                        md={8}
                        lg={5}
                        xl={5}
                        className={this.props.lang + "_transfers-picker-container"}

                    >
                        <RangePicker
                            style={{ width: '100%' }}
                            className={this.props.lang + "_transfers-date-picker"}
                            allowEmpty={true}
                            separator=""
                            placeholder={[this.lang.dateSearch, ""]}
                            onChange={(value) => this.FilterDate(value)}
                            format="DD/MM/YYYY"
                            suffixIcon={false}
                        />
                        <Button
                            onClick={this.dateSearch}
                            id={this.props.lang + "_date-btn-transfers"}
                        >
                            {" "}
                            <img src={date} alt="" />{" "}
                        </Button>
                    </Col>
                    <Col xs={0} sm={0} md={1} lg={1} xl={1}></Col>
                    <Col
                        span={5}
                        xs={24}
                        sm={24}
                        md={8}
                        lg={5}
                        xl={5}
                        className={this.props.lang + "_transfers-picker-container"}
                        style={{ marginBottom: "10px" }}
                    >
                        <Select
                            size={'large'}
                            labelInValue
                            defaultValue={{ key: '' }}
                            style={{ width: '100%' }}
                            onChange={(value) => this.FilterTransfered(value)}
                        >
                            <Option value="">All</Option>
                            <Option value="true">Transferred</Option>
                            <Option value="false">Not Transferred</Option>
                        </Select>
                    </Col>
                    <Col xs={0} sm={0} md={1} lg={5} xl={2}></Col>
                    <Col
                        span={5}
                        xs={24}
                        sm={24}
                        md={8}
                        lg={5}
                        xl={5}
                        className={this.props.lang + "_transfers-picker-container"}
                    >
                        <div className="Export_EXCEL" onClick={() => this.ExportRentalsExecl(this.state.search, this.state.dateStart, this.state.dateEnd, this.state.transfered)}>

                            <p style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: 14, textAlign: 'center', marginTop: 7 }} >{this.lang.export_excel}</p>



                        </div>

                    </Col>

                </Row>
                <Row>
                    <Col span={24}>
                        <div style={{ marginTop: '25px' }}>
                            <div className={this.props.lang + "_table-RentalsView"}>
                                {this.state.LoadingTableData ?
                                    <LoaderTenantive />
                                    :
                                    <Table
                                        locale={{
                                            emptyText: (<div>
                                                <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                <p className='noData'>
                                                    No data !
                                                </p>
                                            </div>)
                                        }}

                                        scroll={{ x: 500 }}
                                        columns={this.state.columns}

                                        dataSource={this.state.rentalsData}
                                        components={this.components}
                                        pagination={false}
                                        onRow={(record, index) => ({
                                            index,
                                            moveRow: this.moveRow,
                                        })}
                                    />
                                }
                            </div>
                            {this.state.isTenantInfoVisible && <TenantInfo tenant_id={this.state.tenant_id}
                                property={this.state.property}
                                unit_number={this.state.unit}
                                isTenantInfoVisible={this.state.isTenantInfoVisible}
                                onChangeTenantDetailsModalt={this.onChangeTenantDetailsModalt} />}
                            {this.state.elementnumber !== 0 ?
                                (
                                    <Pagination
                                        defaultCurrent={1}
                                        current={this.state.pagenumber}
                                        className="test"
                                        pageSize={10}
                                        total={this.state.elementnumber}
                                        showSizeChanger={false}
                                        responsive={true}
                                        onChange={this.pagination}
                                    />
                                ) : null}

                        </div>
                    </Col>

                </Row>

                <Receipt
                    visible={this.state.ReceiptModal}
                    hide={this.HideReceitptModal.bind(this)}
                    category='Rentals'

                />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lang: state.auth.lang,
        token: state.auth.token,


    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getReceipt: (id, token) => dispatch(getReceipt(id, token))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Rentals);





