import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { apiUrl } from "../../config/config";
import fleche from '../../assets/icons/fleche.svg'
import file from '../../assets/icons/file.svg'
import pictureFile from '../../assets/icons/pictureFile.svg'
import { Spin } from 'antd';
import "moment/locale/ar-ly";
import "moment/locale/en-gb";
import { LoadingOutlined } from '@ant-design/icons';
import Personne from "../../assets/images/defaultAvatar.png";
import File from "../Shared/File";

function Message(props) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const { data, isMine, startsSequence, endsSequence, showTimestamp } = props;
    const onDownloadAttachement = () => {
        window.open(apiUrl + data.attachement[0].path.slice(9))

    }
    const translatefriendlyTimestamp = () => {

        if (props.lang === "ar") {
            moment.locale("ar-ly");
            let dating = friendlyTimestamp
            return dating;
        } else {
            moment.locale("en-gb");
            let dating = friendlyTimestamp
            return dating;
        }
    };
    const onShowAttachement = () => {
        if (data.attachement[0].path.slice(-3).toUpperCase() === 'PNG' || data.attachement[0].path.slice(-3).toUpperCase() === 'GIF' || data.attachement[0].path.slice(-3).toUpperCase() === 'JPG' || data.attachement[0].path.slice(-4).toUpperCase() === 'JPEG') {
            return <div onClick={onDownloadAttachement} className='file-message-container'>
                <img src={pictureFile} alt="" />
                <p  > {data.attachement[0].name && data.attachement[0].name.length > 30 ? data.attachement[0].name.slice(0, 30) + "..." : data.attachement[0].name} </p>
            </div>
        }

        else if (data.attachement[0].path.slice(-3).toUpperCase() !== 'PNG' || data.attachement[0].path.slice(-3).toUpperCase() !== 'GIF' || data.attachement[0].path.slice(-3).toUpperCase() !== 'JPG' || data.attachement[0].path.slice(-4).toUpperCase() !== 'JPEG') {

            return <div onClick={onDownloadAttachement} className='file-message-container'>
                <img src={file} alt="" />
                <p  > {data.attachement[0].name && data.attachement[0].name.length > 30 ? data.attachement[0].name.slice(0, 30) + "..." : data.attachement[0].name} </p>
            </div>

        }

    }
    const translateDate = () => {

        if (props.lang === "ar") {
            moment.locale("ar-ly");
            let dating = moment(data.timestamp).startOf("minut").fromNow()
            return dating;
        } else {
            moment.locale("en-gb");
            let dating = moment(data.timestamp).startOf("minut").fromNow()
            return dating;
        }
    };
    const ShowLoader = () => {
        if (!props.data.attachement && !props.data.message) {
            return <div className='attachement_loader_container' >
                <img src={file} alt="" />
                <Spin className='attachement_loader' indicator={antIcon} />
            </div>
        }
    }
    const friendlyTimestamp = moment(data.timestamp).format("LLLL");
    return (
        <div
            className={[
                "message",
                `${isMine ? "mine" : ""}`,
                `${startsSequence ? "start" : ""}`,
                `${endsSequence ? "end" : ""}`,
            ].join(" ")}
        >
            {showTimestamp && <div id={props.lang + '_time_style'} className="timestamp">{translatefriendlyTimestamp()}</div>}
            <div className={isMine ? props.auth.lang + "_discussion_container_mine" : props.auth.lang + "_discussion_container"}>
                <div className={isMine ? props.auth.lang + "_discussion_noLogo_container" : props.auth.lang + "_discussion_logo_container"}>
                    {props.messenger.photo ?
                        <File className={props.auth.lang + "_conversation-photo-history"} src={props.messenger.photo.replace('./public/', apiUrl)} alt="" width="72"
                        height= "72"/>
                        :
                        <img className={props.auth.lang + "_conversation-photo-history"} src={Personne} alt="" />
                    }
                    <img src={fleche} alt="" className={props.auth.lang + "_conversation-fleche-bubble"} />
                </div>
                <div className="bubble-container">
                    <div className="bubble" title={translatefriendlyTimestamp()}>
                        <p className={props.lang + "_message_date_messenger"} > {translateDate()}</p>
                        {data.attachement && data.attachement.length > 0 ? null : data.message}

                        {data.attachement && data.attachement.length > 0 ? onShowAttachement() : ShowLoader()}
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        messenger: state.messenger,
        lang: state.auth.lang
    };
};
export default connect(mapStateToProps, null)(Message)