import detectBrowserLanguage from "detect-browser-language";

let lang = detectBrowserLanguage();

lang = lang.substring(0, 2);
if (lang != "ar") {
    lang = "en";
}

const initialState = {
    conversationsList: [],
    tenantConversationsList: [],
    landlordConversationsList: [],
    messageHistory: [],
    id_conversation: null,
    Broadcast: false,
    Broadcast_category: '',
    broadcast_message: '',
    lastId: '',
    landlords: [],
    tenants: [],
    onlineTab: []
};

function messenger(state = initialState, action) {

    switch (action.type) {
        case "TENANT_CONVERSATION_INFO":
            let PreviousStatesTenant = { ...state }
            let NewTenantConversationsListe = PreviousStatesTenant.tenantConversationsList.filter(el => el.id_conversation === action.id_conversation)
            NewTenantConversationsListe[0].lu = action.lu
            return Object.assign({}, state, {
                ...state,
                id_conversation: action.id_conversation,
                full_name: action.value.name,
                photo: action.value.photo,
                message: action.value.text,
                updatedAt: action.value.updatedAt,
                id_receiver: action.value.id_receiver,
                lu: action.lu
            });
        case "LANDLORD_CONVERSATION_INFO":
            let PreviousStatesLandlord = { ...state }
            let NewlandlordConversationsListe = PreviousStatesLandlord.landlordConversationsList.filter(el => el.id_conversation === action.id_conversation)
            NewlandlordConversationsListe[0].lu = action.lu
            return Object.assign({}, state, {
                ...state,
                id_conversation: action.id_conversation,
                full_name: action.value.name,
                photo: action.value.photo,
                message: action.value.text,
                updatedAt: action.value.updatedAt,
                id_receiver: action.value.id_receiver,
                lu: action.lu
            });
        case "SET_ID_SENDER":
            return Object.assign({}, state, {
                ...state,
                id_receiver: action.value

            });
        case "CLEAR_REDUX_MESSENGER":
            return (state = {
                id_conversation: null,
                full_name: null,
                photo: null,
                message: null,
                createdAt: null,
                id_receiver: null,
                lastId: '',
            });
        case "SET_LANDLORD_CONVERSATION_LIST":
            return Object.assign({}, state, {
                ...state,
                landlordConversationsList: action.value,
            });
        case "SET_TENANT_CONVERSATION_LIST":
            return Object.assign({}, state, {
                ...state,
                tenantConversationsList: action.value,
            });
        case "SET_LANDLORDS_TENANTS_LIST":
            return Object.assign({}, state, {
                ...state,
                landlords: action.landlords,
                tenants: action.tenants,
            });
        case "SET_MESSAGE":
            let PrevState = { ...state }
            let NewLandlordConversationsList = PrevState.landlordConversationsList.filter(el => el.id_conversation === action.id_conversation)
            let NewTenantConversationsList = PrevState.tenantConversationsList.filter(el => el.id_conversation === action.id_conversation)

            if (state.Broadcast_category === "landlord") {
                NewLandlordConversationsList[0].text = action.value.text
                NewLandlordConversationsList[0].lu = action.lu
                let discussionLandlord = NewLandlordConversationsList[0].discussion
                if (discussionLandlord[0]._id != action._id) {
                    discussionLandlord.unshift({ lu: false, attachments: action.attachments, _id: action._id, text: action.value.text, sender: action.sender, createdAt: action.createdAt, updatedAt: action.createdAt })
                }
                return {
                    ...state,
                    last_Id: action._id,
                    landlordConversationsList: [...NewLandlordConversationsList, ...state.landlordConversationsList.filter(el => el.id_conversation !== action.id_conversation)],

                }
            } else {
                NewTenantConversationsList[0].text = action.value.text
                NewTenantConversationsList[0].lu = action.lu
                let discussionTenant = NewTenantConversationsList[0].discussion
                if (discussionTenant[0]._id != action._id) {
                    discussionTenant.unshift({ lu: false, attachments: action.attachments, _id: action._id, text: action.value.text, sender: action.sender, createdAt: action.createdAt, updatedAt: action.createdAt })
                }
                return {
                    ...state,
                    last_Id: action._id,
                    tenantConversationsList: [...NewTenantConversationsList, ...state.tenantConversationsList.filter(el => el.id_conversation !== action.id_conversation)],

                }
            }

        case "SET_BROADCAST":
            return Object.assign({}, state, {
                ...state,
                Broadcast: action.value,
                id_conversation: null,
                full_name: '',
                photo: '',
                message: '',
                updatedAt: '',
                id_receiver: '',
                broadcast_message: [],
                Broadcast_category: "tenant"

            });
        case "SET_BROADCAST_ID":
            return Object.assign({}, state, {
                ...state,
                id_receiver: action.value,
                photo: action.photo,

            });
        case "SET_BROADCAST_CATEGORY":
            return Object.assign({}, state, {
                ...state,
                Broadcast_category: action.value,
                id_conversation: null,
                full_name: '',
                photo: '',
                message: '',
                updatedAt: '',
                id_receiver: '',
                broadcast_message: []

            });
        case "SET_MY_NEW_BROADCAST":
            return Object.assign({}, state, {
                ...state,
                broadcast_message: [action.value.text, ...state.broadcast_message]

            });
        case "SET_MESSAGE_HISTORY":
            let PreviousState = { ...state }
            let AllconversationsList = PreviousState.conversationsList.map(el => el.id_conversation === action.id_conversation ?
                { ...el, discussion: el.discussion.concat(action.value.reverse()) } : { ...el })
            const resultArr = AllconversationsList.filter((data, index) => {
                return AllconversationsList.indexOf(data) === index;
            })
            return {
                ...state,
                conversationsList: [...resultArr],

            }
        case "DELETE_BROADCAST_ID":
            return Object.assign({}, state, {
                ...state,
                id_receiver: [],
                photo: [],
                broadcast_message: []

            });
        case "PUSH_NEW_CONVERSATION":
            let oldState = { ...state };

            let PushNewconversation = oldState.conversationsList
            PushNewconversation.unshift(action.conversation);
            return {
                ...state,
                conversationsList: [
                    ...PushNewconversation
                ],
            };
        case "DELETE_CONVERSATION":
            let oldStates = { ...state };
            let newConversationList = oldStates.conversationsList.filter(el => el.id_conversation != action.id_conversation)
            return {
                ...state,
                conversationsList: [
                    ...newConversationList
                ]
            };

        case "ADD_ONLINE_USER":
            let newOnlineTab = (action.userId !== action.myId && state.onlineTab.indexOf(action.userId) === -1) ? [...state.onlineTab, action.userId] : state.onlineTab
            return Object.assign({}, state, {
                ...state,
                onlineTab: newOnlineTab
            });

        case "REMOVE_USER":
            let onlineNewTab = state.onlineTab.filter(el => el !== action.userId)
            return Object.assign({}, state, {
                ...state,
                onlineTab: onlineNewTab
            });

        case "ADD_ONLINE_Tab":
            return Object.assign({}, state, {
                ...state,
                onlineTab: action.online
            });
            
        case "LOGOUT":
            return initialState;
        default:
            return state;
    }

}

export default messenger;
