import React, { Component } from "react";
import { Menu, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import translator from "../../../lang/translator";
import { logoutUser } from '../../../Redux/Actions'
import arrowIcon from "../../../assets/icons/arrowIcon.svg";
import arrowIconClicked from "../../../assets/icons/arrowIconClicked.svg";
import { createBrowserHistory } from "history";

class VerticalNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: "superAdminAccount"

    };
    this.lang = translator(this.props.lang);
  }

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };
  getCurrentRoute = () => {
    const history = createBrowserHistory();
    if (history.location.pathname === "/settings/superAdminAccount") {
      this.setState({
        current: "superAdminAccount"
      })
    } else if (history.location.pathname === "/settings/ActivityLog") {
      this.setState({
        current: "Activity Log"
      })
    } else if (history.location.pathname === "/settings/multiFactorAuth") {
      this.setState({
        current: "multiFactorAuth"
      })
    }else if (history.location.pathname === "/settings/subscriptions") {
      this.setState({
        current: "subscriptions"
      })
    }
     else {
      this.setState({
        current: "SubUser"
      })
    }
  }
  componentDidMount() {
    this.getCurrentRoute()
  }
  render() {
    return (
      <div className="en_directioncomponent">
        <Menu
          className={this.props.lang + "_setting-menu"}
          onClick={(e) => this.handleClick(e)}
          selectedKeys={[this.state.current]}
          mode="vertical"
          style={{ borderBottom: 0, color: "#B3B3B3" }}
        >
          <Menu.Item key="superAdminAccount" className="vertical_item">
            <p className="en_setting-menu-text">
              <Link
                to="/settings/superAdminAccount"
                className={this.state.current === "superAdminAccount" ? "setting_vertical_navbar_label_active" : "setting_vertical_navbar_label"}
              >
                {this.lang.superAdminAccount}
              </Link>
              <span><img
                className="navbar_item_icon"
                src={
                  this.state.current === "superAdminAccount"
                    ? arrowIconClicked
                    : arrowIcon
                }
              />  </span>
            </p>
          </Menu.Item>
          <Menu.Item key="SubUser">
            <p className={this.props.lang + "_setting-menu-text"}>
              {" "}
              <Link
                // className={this.props.lang + "_vertival-nav-link"}
                to={
                  this.props.lists && (this.props.lists.pendingSubusers.length !== 0 || this.props.lists.activeSubusers.length !== 0)
                    ? "/settings/subUserList"
                    : "/settings/subUser"

                }
                style={this.state.current === "SubUser" ? { color: "#212121", fontFamily: "SF-Pro-Display-Medium", fontSize: "18px" } : { fontSize: "18px", color: "#B3B3B3", fontFamily: "SF-Pro-Display-Medium" }}
              >
                {" "}
                {this.lang.subUser}
              </Link>{" "}
              <span><img
                src={
                  this.state.current === "SubUser"
                    ? arrowIconClicked
                    : arrowIcon
                }
              />  </span>{" "}
            </p>
          </Menu.Item>
          <Menu.Item key="Activity Log" >
            <p className={this.props.lang + "_setting-menu-text"}>
              {" "}
              <Link
                // className={this.props.lang + "_vertival-nav-link"}
                to="/settings/ActivityLog"
                style={this.state.current === "Activity Log" ? { fontSize: "18px", color: "#212121", fontFamily: "SF-Pro-Display-Medium" } : { fontSize: "18px", color: "#B3B3B3", fontFamily: "SF-Pro-Display-Medium" }}

              >
                {this.lang.activityLog}
              </Link>
              <span>
                <img
                  src={
                    this.state.current === "Activity Log"
                      ? arrowIconClicked
                      : arrowIcon
                  }
                />
              </span>
            </p>
          </Menu.Item>
          <Menu.Item key="subscriptions" >
            <p className={this.props.lang + "_setting-menu-text"}>
              {" "}
              <Link
                to="/settings/subscriptions"
                style={this.state.current === "subscriptions" ? { fontSize: "18px", color: "#212121", fontFamily: "SF-Pro-Display-Medium" } : { fontSize: "18px", color: "#B3B3B3", fontFamily: "SF-Pro-Display-Medium" }}

              >
                {this.lang.subscriptions}
              </Link>
              <span>
                <img
                  src={
                    this.state.current === "subscriptions"
                      ? arrowIconClicked
                      : arrowIcon
                  }
                />
              </span>
            </p>
          </Menu.Item>
          <Menu.Item key="multiFactorAuth" className="vertical_item">
            <p className="en_setting-menu-text">
              <Link
                to="/settings/multiFactorAuth"
                className={this.state.current === "multiFactorAuth" ? "setting_vertical_navbar_label_active" : "setting_vertical_navbar_label"}
              >
                {this.lang.multiFactorAuth}
              </Link>
              <span><img
                className="navbar_item_icon"
                src={
                  this.state.current === "multiFactorAuth"
                    ? arrowIconClicked
                    : arrowIcon
                }
              />  </span>
            </p>
          </Menu.Item>
          <Menu.Item key="Log out" style={{ marginTop: "50px" }}>
            <p className={this.props.lang + "_setting-menu-text"}>
              {" "}
              <Link
                // className={this.props.lang + "_vertival-nav-link"}
                style={{ color: "#B3B3B3", fontFamily: "SF-Pro-Display-Medium", fontSize: "18px" }}
                to="/login"
                onClick={this.props.logout}
              >
                {this.lang.logout}
              </Link>
            </p>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    lang: state.auth.lang,
    user: state.user,
    lists: state.lists
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: (action) => {
      dispatch(action);
    },
    logoutUser: () => dispatch(logoutUser())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerticalNav);
