import React, { Component } from "react";

export default class UIKITBUTTON extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  _RenderBtn = () => {
    const isdisabled = this.props.disabledbtn;

    if (isdisabled) {
      return (
        <button
          onClick={() => this.props.action()}
          type="primary"
          htmlType="submit"
          className="loginFormButtonDisabled"
        >
          <span
            style={{
              fontSize: 16,
              fontWeight: 550,
              color: "white",
              paddingTop: "7px",
            }}
          >
            {this.props.text}
          </span>
        </button>
      );
    } else {
      return (
        <button
          onClick={() => this.props.action()}
          type="primary"
          htmlType="submit"
          className="loginFormButton"
          disabled={this.props.disabled}
        >
          <span
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: "white",
              paddingTop: "7px",
            }}
          >
            {this.props.text}
          </span>
        </button>
      );
    }
  };
  render() {
    return <React.Fragment>{this._RenderBtn()}</React.Fragment>;
  }
}
